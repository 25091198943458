import React, { useState, useEffect } from "react";

const PerformanceNav = ({ data, rout, setRout, handleTitleClick }) => {
  // Initialize with the first route's id
  const [activePageId, setActivePageId] = useState(data[0]?.id || "");

  useEffect(() => {
    // Set the initial route when the component mounts
    setRout(activePageId);
  }, [activePageId, setRout]);

  const handleClick = (id) => {
    setActivePageId(id);
    setRout(id);
  };

  return (
    <div className="w-[100%] rounded-lg">
      <nav className="bg-gray-50 dark:bg-blue-600">
        <div className="px-4 py-3 mx-auto">
          <div className="flex flex-wrap justify-center md:justify-start">
            <ul className="grid grid-cols-2 md:grid-cols-5 gap-4 items-center justify-center w-full font-medium mt-0 text-sm">
              {data.map((el) => (
                <li key={el.id} className="w-full">
                  <div
                    onClick={() => handleClick(el.id)}
                    className={`text-gray-900 mpi p-2 rounded-lg shadow-xl font-serif dark:text-white hover:font-bold transition-all duration-200 ease-in-out text-center ${
                      el.id === activePageId ? "bg-yellow-400 rounded-lg" : ""
                    }`}
                  >
                    <p className={`text-base font-fredoka w-full ${el.id === activePageId ? "bg-yellow-400" : ""}`}>
                      {el.title}
                    </p>
                    <p className={`text-base w-full font-fredoka ${el.id === activePageId ? "bg-yellow-400" : ""}`}>
                      {el.sub}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default PerformanceNav;
