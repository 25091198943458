import React from "react";
import { Line } from "rc-progress";
import "../Circular/CircularProgressBar.css";

const LineChart1 = ({ percentage }) => {
  // Function to determine the color based on the percentage
  const getStrokeColor = (percentage) => {
    if (percentage >= 80 && percentage <= 100) {
      return "rgb(255,255,0)";  // 80-100%   yellow
    } else if (percentage >= 60 && percentage <= 80) {
      return "rgb(34,197,94)";   // 60-80%  green
    } else if (percentage >= 40 && percentage <= 60) {
      return "rgb(59,130,246)";    // 40-60% blue
    } else if (percentage >= 20 && percentage <= 40) {
      return "rgb(126,34,206)";  // 20-40% purple
    } else {
      return "rgb(239,68,68)";     // 0-20%  red
    }
  };

  return (
    <div className="Progress-Container w-[100%]">
      <Line
        percent={percentage}
        strokeWidth={1}
        trailWidth={2}
        strokeColor={getStrokeColor(percentage)} // Dynamic stroke color
        className="horizontal-progress-Bar"
      />
    </div>
  );
};

export default LineChart1;
