import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Worm2 = () => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    fetchDataFromBackend();
  }, []);

  const fetchDataFromBackend = async () => {
    try {
      const response = await fetch('https://test.xqminds.com/api/quiz/report-generate', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      // console.log(+data.allReportData.allScores.attention.indexValue, "dataaaa");
      setChartData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error (e.g., show error message)
    }
  };

  // console.log(chartData,"chart")
  const options = {
    chart: {
      zoomType: 'xy',
    },
    title: {
      text: 'Skill Distribution',
    },
    xAxis: {
      categories: ['Attention', 'Memory', 'Critical Thinking', 'Creative Thinking', 'Mindset', 'Attitude', 'Expression', 'Communication', 'Collaboration', 'leadership'],
    },
    yAxis: {
      title: {
        text: 'index Value',
        style: {
          color: '#7cb5ec',
        },
      },
      labels: {
        format: '{value} ',
        style: {
          color: '#7cb5ec',
        },
      },
      opposite: true,
    },
    tooltip: {
      shared: true,
    },
    credits: {
      enabled: false // This will remove the Highcharts.com tag
    },
    series: chartData ? [{
      type: 'spline',
      name: 'index Value',
      data:[+chartData.allReportData.allScores.attention.indexValue, +chartData.allReportData.allScores.memory.indexValue, +chartData.allReportData.allScores.critical.indexValue, +chartData.allReportData.allScores.creative.indexValue, +chartData.allReportData.allScores.mindset.indexValue, +chartData.allReportData.allScores.attitude.indexValue, +chartData.allReportData.allScores.expression.indexValue, +chartData.allReportData.allScores.communication.indexValue, +chartData.allReportData.allScores.collaboration.indexValue, +chartData.allReportData.allScores.leadership.indexValue] ,
      tooltip: {
        pointFormat: '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f} </b>',
      },
    }] : [],
  };

  return (
    <div className='w-full max-w-4xl mx-auto  md:p-2'>
      {chartData ? (
      <HighchartsReact highcharts={Highcharts} options={options} />
    ) : (
      <p>Loading chart data...</p>
    )}
    </div>
  );
};

export default Worm2;
