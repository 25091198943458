// import React, { useEffect, useRef, useState } from 'react';
// import ApexCharts from 'apexcharts';

// const RadialBarChart = () => {

//   const [chartData, setChartData] = useState({});

//   useEffect(() => {
//     fetchDataFromBackend();
//   }, []);

//   const fetchDataFromBackend = async () => {
//     try {
//       const response = await fetch('https://test.xqminds.com/api/quiz/prideScore', {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${localStorage.getItem('token')}`,
//         },
//       });
//       if (!response.ok) {
//         throw new Error('Failed to fetch data');
//       }
//       const data = await response.json();
//       // console.log(data, "dataaaa");
//       setChartData(data);
//       localStorage.setItem( "MpiConsistency", data.MpiConsistency)
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       // Handle error (e.g., show error message)
//     }
//   }
//   const chartRef = useRef(null);

//   useEffect(() => {
//     const options = {
//       series: [44, 67, 83],
//       chart: {
//         height: 350,
//         type: 'radialBar',
//       },
//       plotOptions: {
//         radialBar: {
//           dataLabels: {
//             name: {
//               fontSize: '22px',
//             },
//             value: {
//               fontSize: '16px',
//             },
//             total: {
//               show: true,
//               label: 'Total',
//               formatter: function (w) {
//                 // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
//                 return 100;
//               }
//             }
//           }
//         }
//       },
//       labels: ['Apples', 'Oranges', 'Bananas'],
//     };

//     const chart = new ApexCharts(chartRef.current, options);
//     chart.render();

//     // Cleanup function to destroy the chart when component unmounts
//     return () => {
//       chart.destroy();
//     };
//   }, []);

//   return <div id="chart" ref={chartRef}></div>;
// };

// export default RadialBarChart;


































// import React, { useEffect, useRef, useState } from 'react';
// import ApexCharts from 'apexcharts';

// const RadialBarChart = () => {
//   const [chartData, setChartData] = useState({ series: [], labels: [] });

//   // useEffect(() => {
//   //   fetchDataFromBackend();
//   // }, []);

//   // const fetchDataFromBackend = async () => {
//   //   try {
//   //     const response = await fetch('https://test.xqminds.com/api/quiz/prideScore', {
//   //       method: 'GET',
//   //       headers: {
//   //         'Content-Type': 'application/json',
//   //         Authorization: `Bearer ${localStorage.getItem('token')}`,
//   //       },
//   //     });
//   //     if (!response.ok) {
//   //       throw new Error('Failed to fetch data');
//   //     }
//   //     const data = await response.json();
//   //     // Assuming data contains advantageAccuracy, applicationAccuracy, and awarenessAccuracy
//   //     const updatedChartData = {
//   //       series: [data.advantageAccuracy, data.applicationAccuracy, data.awarenessAccuracy],
//   //       labels: ['Advantage', 'Application', 'Awareness']
//   //     };
//   //     setChartData(updatedChartData);
//   //     localStorage.setItem("MpiConsistency", data.MpiConsistency);
//   //   } catch (error) {
//   //     console.error('Error fetching data:', error);
//   //     // Handle error (e.g., show error message)
//   //   }
//   // };

//   const chartRef = useRef(null);

//   useEffect(() => {
//     if (chartData.series.length > 0 && chartData.labels.length > 0) {
//       const options = {
//         series: chartData.series,
//         chart: {
//           height: 350,
//           type: 'radialBar',
//         },
//         plotOptions: {
//           radialBar: {
//             dataLabels: {
//               name: {
//                 fontSize: '22px',
//               },
//               value: {
//                 fontSize: '16px',
//               },
//               // total: {
//               //   show: true,
//               //   label: 'Average Accuracy',
//               //   formatter: function () {
//               //     // Return the average of all series
//               //     const total = chartData.series.reduce((a, b) => a + b, 0);
//               //     const average = total / chartData.series.length;
//               //     return `${average.toFixed(2)}%`; // Format the average as a percentage with 2 decimal places
//               //   }
//               // }
//               total: {
//                 show: true,
//                 label: 'Score',
//                 formatter: function () {
//                   // Always return 100 for the total label
//                   // return "100%";
//                 }
//               }
//             }
//           }
//         },
//         labels: chartData.labels,
//       };

//       const chart = new ApexCharts(chartRef.current, options);
//       chart.render();

//       // Cleanup function to destroy the chart when component unmounts
//       return () => {
//         chart.destroy();
//       };
//     }
//   }, [chartData]);

//   return <div id="chart" ref={chartRef}></div>;
// };

// export default RadialBarChart;














import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';

const RadialBarChart = ({ radialChartData = { series: [], labels: [] } }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (radialChartData.series && radialChartData.labels) {
      const options = {
        series: radialChartData.series,
        chart: {
          height: 350,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '22px',
              },
              value: {
                fontSize: '16px',
              },
              total: {
                show: true,
                label: 'Score',
                
                formatter: function () {
                  // You can customize the formatter here if needed
                },
              },
            },
          },
        },
        labels: radialChartData.labels,
      };

      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      // Cleanup function to destroy the chart when component unmounts
      return () => {
        chart.destroy();
      };
    }
  }, [radialChartData]);

  return <div id="chart" ref={chartRef}></div>;
};

export default RadialBarChart;
