


// import React, { useEffect, useState } from "react";
// import { Carousel } from 'react-responsive-carousel';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
// import { useNavigate } from "react-router-dom";

// const Strength = () => {
//   const [data, setData] = useState(null);
//   const navigate = useNavigate()
//   const fetchData = () => {
//     fetch("https://test.xqminds.com/api/quiz/SkillContent", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((res) => {
//         setData(res.contentObject);
//         // console.log(res, "API response");
//       })
//       .catch((err) => console.log(err));
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const extractMainPoints = (data) => {
//     const mainPoints = [];
//     for (const category in data) {
//       if (data[category].awareness && data[category].awareness.strength[0]) {
//         mainPoints.push({
//           category,
//           point: data[category].awareness.strength[0]
//         });

//       }
//     }
//     console.log(mainPoints,"mainPoints")
//     return mainPoints;
//   };
//   const capitalizeFirstLetter = (string) => {
//     return string.charAt(0).toUpperCase() + string.slice(1);
//   };
//   if (!data) {
//     return <div>Loading...</div>;
//   }

//   const mainPoints = extractMainPoints(data);

//   const handleItemClick = (item) => {
//     // Combine all data into a single object
//     const dataToSend = { 
//         selectedstrength: item, 
//         someKey: item.category // Replace with your actual data
//     };

//     // Navigate to the /studentprofile route with the combined data
//     navigate("/studentprofile", { state: dataToSend });
// };

//   return (
//     <div className="carousel-container  ">
//       <Carousel showThumbs={false} autoPlay showStatus={false} showIndicators={false} infiniteLoop>
//         {mainPoints.map((item, index) => (
//           <div key={index} className="flex flex-col justify-center items-center h-[130px] p-2" onClick={() => handleItemClick(item)}>
//              <h2 className="text-center text-xl font-bold">
//               {capitalizeFirstLetter(item.category)}
//             </h2>
//             <h3 className="text-center text-lg font-semibold mt-2">{item.point}</h3>
//             <p className="text-blue-500 mt-2 cursor-pointer">Read more...</p>
//           </div>
//         ))}
//       </Carousel>
//     </div>
//   );
// };

// export default Strength;






// import React, { useEffect, useState } from "react";
// import { Carousel } from 'react-responsive-carousel';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
// import { useNavigate } from "react-router-dom";

// const Strength = () => {
//   const [data, setData] = useState(null);
//   const navigate = useNavigate()
//   const fetchData = () => {
//     fetch("https://test.xqminds.com/api/quiz/SkillContent", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((res) => {
//         setData(res.contentObject);
//         // console.log(res, "API response");
//       })
//       .catch((err) => console.log(err));
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const extractMainPoints = (data) => {
//     const mainPoints = [];
//     for (const category in data) {
//       if (data[category].awareness && data[category].awareness.strength[0]) {
//         mainPoints.push({
//           category,
//           point: data[category].awareness.strength[0]
//         });

//       }
//     }
//     console.log(mainPoints,"mainPoints")
//     return mainPoints;
//   };
//   const capitalizeFirstLetter = (string) => {
//     return string.charAt(0).toUpperCase() + string.slice(1);
//   };
//   if (!data) {
//     return <div>Loading...</div>;
//   }

//   const mainPoints = extractMainPoints(data);

//   const handleItemClick = (item) => {
//     // Combine all data into a single object
//     const dataToSend = { 
//         selectedstrength: item, 
//         someKey: item.category // Replace with your actual data
//     };

//     // Navigate to the /studentprofile route with the combined data
//     navigate("/studentprofile", { state: dataToSend });
// };

//   return (
//     <div className="carousel-container  ">
//       <Carousel showThumbs={false} autoPlay showStatus={false} showIndicators={false} infiniteLoop>
//         {mainPoints.map((item, index) => (
//           <div key={index} className="flex flex-col justify-center items-center h-[190px] p-2" onClick={() => handleItemClick(item)}>
//              <h2 className="text-center text-xl font-bold">
//               {capitalizeFirstLetter(item.category)}
//             </h2>
//             <h3 className="text-center text-lg font-semibold mt-2">{item.point}</h3>
//             <p className="text-blue-500 mt-2 cursor-pointer">Read more...</p>
//           </div>
//         ))}
//       </Carousel>
//     </div>
//   );
// };

// export default Strength;







import React, { useEffect, useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";

const Strength = () => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  
  const fetchData = () => {
    fetch("https://test.xqminds.com/api/quiz/SkillContentAndRecommendation", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res.contentObject);
        // console.log(res)  
        })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const extractMainPoints = (data) => {
    const mainPoints = [];
    for (const category in data) {
      if (data[category].awareness && data[category].awareness.strength[0]) {
        mainPoints.push({
          category,
          point: data[category].awareness.strength[0]
        });
      }
    }
    return mainPoints;
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  const mainPoints = extractMainPoints(data);

  const handleItemClick = (category) => {
    navigate("/studentprofile", { state: { selectedSkill: category } });
  };

  return (
    <div className="carousel-container">
      <Carousel showThumbs={false} autoPlay showStatus={false} showIndicators={false} infiniteLoop>
        {mainPoints.map((item, index) => (
          <div key={index} className="flex flex-col justify-center items-center h-[190px] p-2" onClick={() => handleItemClick(item.category)}>
            <h2 className="text-center text-xl font-fredoka">
              {capitalizeFirstLetter(item.category)}
            </h2>
            <h3 className="text-center  mt-2">{item.point}</h3>
            <p className="text-blue-500 mt-2 cursor-pointer">Read more...</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Strength;
