import React, { useState } from 'react';
import SchoolSideBar from '../SchoolSideBar/SchoolSideBar';
import NextExamDate from "../SchoolDashboard/SchoolExamDate/NextExamDate";
import notification from "../../../image/notification.gif";

const AddTeacher = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const initialState = {
    name: '',
    email: '',
    phone: '',
    role: '',
    assignedTo: [],
  };
  const [spocs, setSpocs] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSpocs(prev => ({
      ...prev,
      [name]: name === "assignedTo" ? value.split(',').map((item) => item.trim()) : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('https://test.xqminds.com/api/schoolData/addSpocs', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(spocs), // Correctly set the body
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Teacher registered successfully', data);
        // Reset form data if needed
        setSpocs(initialState);
      })
      .catch(error => {
        console.error('Error registering teacher:', error.message);
      });
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div className="flex gap-5">
      <div className={`flex h-full m-auto gap-5 sticky top-1 ${isSidebarOpen ? 'block' : 'hidden'} md:block`}>
         <SchoolSideBar />
        </div>
        <div className="w-[91.8%] mx-auto">
        
           <div className="md:flex md:flex-row flex flex-col-reverse justify-start items-center  shadow-lg rounded-lg p-3 gap-4 sticky top-0 z-10 bg-white">
           <NextExamDate />
            <form className="flex items-center max-w-sm mx-auto">
              <label htmlFor="simple-search" className="sr-only">
                Search
              </label>
              <div className="relative w-full">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-yellow-500 dark:text-yellow-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="simple-search"
                  className="border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-blue-600 dark:placeholder-yellow-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search..."
                  required
                />
              </div>
              <button
                type="submit"
                className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            <div>
              <img className="w-[50px]" src={notification} alt="Notification" />
            </div>
            <button
        data-collapse-toggle="navbar-search"
        type="button"
        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-search"
        aria-expanded={isSidebarOpen}
        onClick={toggleSidebar}
      >
        <span className="sr-only">Open main menu</span>
        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
            </form>
          </div>
        <div className='mpi p-4 shadow-lg rounded-lg'>
        <h1 className="text-2xl font-bold text-center mb-4">Register Teacher</h1>
        <form onSubmit={handleSubmit}>
          <div className='flex gap-5'>
            <div className='w-[50%]'>
              <label htmlFor="name" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Name</label>
              <input
                type="text"
                name="name"
                value={spocs.name}
                id="name"
                placeholder="Name"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                onChange={handleChange}
              />
            </div>
            <div className='w-[50%]'>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Email</label>
              <input
                type="email"
                name="email"
                value={spocs.email}
                id="email"
                placeholder="Email"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='flex gap-5'>
            <div className='w-[50%]'>
              <label htmlFor="phone" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Phone</label>
              <input
                type="text"
                name="phone"
                value={spocs.phone}
                id="phone"
                placeholder="Phone"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                onChange={handleChange}
              />
            </div>
            <div className='w-[50%]'>
              <label htmlFor="role" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Role</label>
              <input
                type="text"
                name="role"
                value={spocs.role}
                id="role"
                placeholder="Role"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='flex gap-5'>
            <div className='w-[50%]'>
              <label htmlFor="assignedTo" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Assigned To</label>
              <input
                type="text"
                name="assignedTo"
                value={spocs.assignedTo.join(', ')} // Join the array into a comma-separated string for display
                id="assignedTo"
                placeholder="Comma-separated sections or schools"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                onChange={handleChange}
              />
            </div>
          </div>
          <button type="submit" className="w-full text-black bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Register Teacher</button>
        </form>
        </div>
      </div>
      {isSidebarOpen && (
        <div className="fixed inset-0 z-50 flex md:hidden">
          <div className=" bg-white shadow-lg">
            <SchoolSideBar />
          </div>
          <div className="flex-grow" onClick={toggleSidebar}></div>
        </div>
      )}
    </div>
  );
};

export default AddTeacher;
