import {
    FETCH_TEST_OPTION_REQUEST,
    FETCH_TEST_OPTION_SUCCESS,
    FETCH_TEST_OPTION_FAILURE,
  } from './actionType';
  
  export const fetchTestOptionRequest = () => ({
    type: FETCH_TEST_OPTION_REQUEST,
  });
  
  export const fetchTestOptionSuccess = (data) => ({
    type: FETCH_TEST_OPTION_SUCCESS,
    payload: data,
  });
  
  export const fetchTestOptionFailure = (error) => ({
    type: FETCH_TEST_OPTION_FAILURE,
    payload: error,
  });
  
  
export const fetchTestOption = () => {
    return (dispatch) => {
      dispatch(fetchTestOptionRequest());
      fetch("https://test.xqminds.com/api/quiz/testOption", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          // console.log('API Data from redux:', data); // Log the data received from the API
          dispatch(fetchTestOptionSuccess(data));
        })
        .catch((error) => {
          console.error('API Error:', error); // Log any error from the API
          dispatch(fetchTestOptionFailure(error.message));
        });
    };
  };