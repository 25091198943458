import {
    FETCH_SCHOOL_SCORES_REQUEST,
    FETCH_SCHOOL_SCORES_SUCCESS,
    FETCH_SCHOOL_SCORES_FAILURE,
  } from './actionType';
  
  const initialState = {
    loading: false,
    schoolScores: {},
    error: '',
  };
  
  const schoolScoresReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SCHOOL_SCORES_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_SCHOOL_SCORES_SUCCESS:
        return {
          loading: false,
          schoolScores: action.payload,
          error: '',
        };
      case FETCH_SCHOOL_SCORES_FAILURE:
        return {
          loading: false,
          schoolScores: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default schoolScoresReducer;
  