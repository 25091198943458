import {
    FETCH_SCHOOL_SCORES_REQUEST,
    FETCH_SCHOOL_SCORES_SUCCESS,
    FETCH_SCHOOL_SCORES_FAILURE,
  } from './actionType';
  

  export const FETCH_SENIOR_SCHOOL_SCORES_REQUEST = 'FETCH_SENIOR_SCHOOL_SCORES_REQUEST';
  export const FETCH_SENIOR_SCHOOL_SCORES_SUCCESS = 'FETCH_SENIOR_SCHOOL_SCORES_SUCCESS';
  export const FETCH_SENIOR_SCHOOL_SCORES_FAILURE = 'FETCH_SENIOR_SCHOOL_SCORES_FAILURE'; 
  
  
  export const fetchSchoolScoresRequest = () => ({
    type: FETCH_SCHOOL_SCORES_REQUEST,
  });
  
  export const fetchSchoolScoresSuccess = (data) => ({
    type: FETCH_SCHOOL_SCORES_SUCCESS,
    payload: data,
  });
  
  export const fetchSchoolScoresFailure = (error) => ({
    type: FETCH_SCHOOL_SCORES_FAILURE,
    payload: error,
  });
  
  export const fetchSchoolScores = () => {
    return (dispatch) => {
      dispatch(fetchSchoolScoresRequest());
      fetch('https://test.xqminds.com/api/schoolData/calculate-scores?level=junior', {   
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }
          return response.json();
        })
        .then((data) => {
          // console.log('School Scores API Data:', data); // Log the data received from the API
          dispatch(fetchSchoolScoresSuccess(data));
        })
        .catch((error) => {
          console.error('School Scores API Error:', error); // Log any error from the API
          dispatch(fetchSchoolScoresFailure(error.message));
        });
    };
  };
  