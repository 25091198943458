import React from 'react';
import { generatePDF } from '../Report/utils/pdfGenerator';


// const DownloadPDFButton = ({ contentRef, filename }) => {
//   const handleDownload = () => {
//     generatePDF(contentRef, filename);
//   };

//   return (
//     <button
//       onClick={handleDownload}
//       className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none"
//     >
//       Download PDF
//     </button>
//   );
// };

const DownloadPDFButton = ({ contentRef, filename }) => {
    const handleDownload = () => {
      if (contentRef.current) {
        generatePDF(contentRef, filename);
      } else {
        console.error('contentRef is not attached to any element.');
      }
    };

   
      
  
    return (
      <button
        onClick={handleDownload}
        className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none"
      >
        Download Report
      </button>
    );
  };
  

export default DownloadPDFButton;


