
import React, { useEffect, useMemo, useState } from "react";
import { Container } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import SchoolSideBar from "../SchoolSideBar/SchoolSideBar";
import NextExamDate from "../SchoolDashboard/SchoolExamDate/NextExamDate";
// import DATA from "../../School/ViewStudent/allstudent.json"
// import axios from "axios";
import notification from "../../../image/notification.gif";


const ViewStudent = () => {
//   const dispatch = useDispatch();
//   const [allStudentScores, setAllStudentScores] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fullscreen, setFullscreen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);


const GetRequest = () => {
    fetch("https://test.xqminds.com/api/schoolData/students", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        setData(res);
        setLoading(false); // Set loading state to false after data is fetched
      })
      .catch((err) => {
        console.log(err);
        setLoading(false); // Set loading state to false even if there is an error
      });
  };

  useEffect(() => {
    GetRequest();
  }, []);



const columns = [
    { accessorKey: 'photo', header: 'Photo', Cell: ({ cell }) => <img src={cell.getValue()} alt="Student" width="50" /> },
    { accessorKey: 'studentName', header: 'Student Name' },
    { accessorKey: 'fatherName', header: 'Father Name' },
    { accessorKey: 'motherName', header: 'Mother Name' ,
       muiTableBodyCellProps: ({
    cell
    }) => ({
    sx: {
      // backgroundColor: 'rgba(22, 184, 44, 0.5)' ,
      // fontWeight: '700'
    }
  })
  // muiTableBodyCellProps: ({
  //   cell
  // }) => ({
  //   sx: {
  //     backgroundColor: cell.getValue<number>() > 40 ? 'rgba(22, 184, 44, 0.5)' : undefined,
  //     fontWeight: cell.column.id === 'age' && cell.getValue<number>() > 40 ? '700' : '400'
  //   }
  // })
    },
    { accessorKey: 'email', header: 'Email' },
    { accessorKey: 'studentId', header: 'Student ID' },
    { accessorKey: 'enrollmentNo', header: 'Enrollment No' },
    // { accessorKey: 'userName', header: 'Username' },
    { accessorKey: 'schoolName', header: 'School Name' },
    { accessorKey: 'state', header: 'State' },
    { accessorKey: 'city', header: 'City' },
    { accessorKey: 'schoolCity', header: 'School City' },
    { accessorKey: 'dob', header: 'Date of Birth', Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString() },
    { accessorKey: 'mobile', header: 'Mobile' },
    { accessorKey: 'standard', header: 'Standard' },
    { accessorKey: 'gender', header: 'Gender' },
  ];

  const table = useMemo(() => ({
    columns,
    data: data,
    initialState: { pagination: { pageSize: 5, pageIndex: 0 } },
  }), [data, columns]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  
//   if (!isVisible) return null;

  return (
    <div className="flex w-full gap-4">
       <div className={`flex h-full m-auto gap-5 sticky top-1 ${isSidebarOpen ? 'block' : 'hidden'} md:block`}>
         <SchoolSideBar />
        </div>
        <div className="w-[91.8%] mx-auto">
        
           <div className="md:flex md:flex-row flex flex-col-reverse justify-start items-center  shadow-lg rounded-lg p-3 gap-4 sticky top-0 z-10 bg-white">
           <NextExamDate />
            <form className="flex items-center max-w-sm mx-auto">
              <label htmlFor="simple-search" className="sr-only">
                Search
              </label>
              <div className="relative w-full">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-yellow-500 dark:text-yellow-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="simple-search"
                  className="border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-blue-600 dark:placeholder-yellow-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search..."
                  required
                />
              </div>
              <button
                type="submit"
                className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            <div>
              <img className="w-[50px]" src={notification} alt="Notification" />
            </div>
            <button
        data-collapse-toggle="navbar-search"
        type="button"
        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-search"
        aria-expanded={isSidebarOpen}
        onClick={toggleSidebar}
      >
        <span className="sr-only">Open main menu</span>
        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
            </form>
          </div>
        <div className="flex items-center justify-center border rounded-lg ">
      <div className="relative bg-white p-5 rounded-lg w-[100%] max-w-[100%]">
        <div className="flex justify-end">
         
        </div>
        <Container sx={{ py: 2 }}>
          <div className={fullscreen ? 'h-full' : 'h-[80vh] '}> {/* Adjust height based on fullscreen mode */}
            <MaterialReactTable {...table} 
             columns={columns} data={data} muiTableHeadCellProps={{
              sx: theme => ({
                // background: 'lightblue',
                borderRight: '1px solid rgba(224,224,224,1)',
                color: "rgba(22, 184, 44, 0.5)",
                fontWeight:"800",
                fontSize: '18px',
              })
            }}  />
          </div>
        </Container>
      </div>
    </div>
       </div>
    </div>
  );
};

export default ViewStudent;



























//Delete Method



// import React, { useEffect, useMemo, useState } from "react";
// import { Container } from "@mui/material";
// import { MaterialReactTable } from "material-react-table";
// import SchoolSideBar from "../SchoolSideBar/SchoolSideBar";
// import NextExamDate from "../SchoolDashboard/SchoolExamDate/NextExamDate";
// import notification from "../../../image/notification.gif";

// const ViewStudent = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [fullscreen, setFullscreen] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   const GetRequest = () => {
//     fetch("https://test.xqminds.com/api/schoolData/students", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((res) => {
//         console.log(res);
//         setData(res);
//         setLoading(false); // Set loading state to false after data is fetched
//       })
//       .catch((err) => {
//         console.log(err);
//         setLoading(false); // Set loading state to false even if there is an error
//       });
//   };

//   useEffect(() => {
//     GetRequest();
//   }, []);

//   const handleDeleteRow = (studentId) => {
//     fetch(`https://test.xqminds.com/api/schoolData/students/${studentId}`, {
//       method: "DELETE",
//       headers: {
//         "Content-Type": "application/json",
//         authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((res) => {
//         if (res.success) {
//           setData((prevData) => prevData.filter((student) => student.studentId !== studentId));
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   const columns = [
//     { accessorKey: 'photo', header: 'Photo', Cell: ({ cell }) => <img src={cell.getValue()} alt="Student" width="50" /> },
//     { accessorKey: 'studentName', header: 'Student Name' },
//     { accessorKey: 'fatherName', header: 'Father Name' },
//     { accessorKey: 'motherName', header: 'Mother Name' },
//     { accessorKey: 'email', header: 'Email' },
//     { accessorKey: 'studentId', header: 'Student ID' },
//     { accessorKey: 'enrollmentNo', header: 'Enrollment No' },
//     { accessorKey: 'schoolName', header: 'School Name' },
//     { accessorKey: 'state', header: 'State' },
//     { accessorKey: 'city', header: 'City' },
//     { accessorKey: 'schoolCity', header: 'School City' },
//     { accessorKey: 'dob', header: 'Date of Birth', Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString() },
//     { accessorKey: 'mobile', header: 'Mobile' },
//     { accessorKey: 'standard', header: 'Standard' },
//     { accessorKey: 'gender', header: 'Gender' },
//     {
//       accessorKey: 'actions',
//       header: 'Actions',
//       Cell: ({ row }) => (
//         <button
//           className="bg-red-500 text-white py-1 px-2 rounded"
//           onClick={() => handleDeleteRow(row.original.studentId)}
//         >
//           Delete
//         </button>
//       ),
//     },
//   ];

//   const table = useMemo(
//     () => ({
//       columns,
//       data: data,
//       initialState: { pagination: { pageSize: 5, pageIndex: 0 } },
//     }),
//     [data, columns]
//   );

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   return (
//     <div className="flex w-full gap-4">
//       <div className={`flex h-full m-auto gap-5 sticky top-1 ${isSidebarOpen ? 'block' : 'hidden'} md:block`}>
//         <SchoolSideBar />
//       </div>
//       <div className="w-[91.8%] mx-auto">
//         <div className="md:flex md:flex-row flex flex-col-reverse justify-start items-center shadow-lg rounded-lg p-3 gap-4 sticky top-0 z-10 bg-white">
//           <NextExamDate />
//           <form className="flex items-center max-w-sm mx-auto">
//             <label htmlFor="simple-search" className="sr-only">
//               Search
//             </label>
//             <div className="relative w-full">
//               <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
//                 <svg
//                   className="w-4 h-4 text-yellow-500 dark:text-yellow-400"
//                   aria-hidden="true"
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 18 20"
//                 >
//                   <path
//                     stroke="currentColor"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"
//                   />
//                 </svg>
//               </div>
//               <input
//                 type="text"
//                 id="simple-search"
//                 className="border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-blue-600 dark:placeholder-yellow-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
//                 placeholder="Search..."
//                 required
//               />
//             </div>
//             <button
//               type="submit"
//               className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//             >
//               <svg
//                 className="w-4 h-4"
//                 aria-hidden="true"
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 20 20"
//               >
//                 <path
//                   stroke="currentColor"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
//                 />
//               </svg>
//               <span className="sr-only">Search</span>
//             </button>
//             <div>
//               <img className="w-[50px]" src={notification} alt="Notification" />
//             </div>
//             <button
//               data-collapse-toggle="navbar-search"
//               type="button"
//               className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
//               aria-controls="navbar-search"
//               aria-expanded={isSidebarOpen}
//               onClick={toggleSidebar}
//             >
//               <span className="sr-only">Open main menu</span>
//               <svg
//                 className="w-5 h-5"
//                 aria-hidden="true"
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 17 14"
//               >
//                 <path
//                   stroke="currentColor"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="M1 1h15M1 7h15M1 13h15"
//                 />
//               </svg>
//             </button>
//           </form>
//         </div>
//         <div className="flex items-center justify-center border rounded-lg">
//           <div className="relative bg-white p-5 rounded-lg w-[100%] max-w-[100%]">
//             <div className="flex justify-end"></div>
//             <Container sx={{ py: 2 }}>
//               <div className={fullscreen ? 'h-full' : 'h-[80vh] '}> {/* Adjust height based on fullscreen mode */}
//                 <MaterialReactTable
//                   {...table}
//                   columns={columns}
//                   data={data}
//                   muiTableHeadCellProps={{
//                     sx: (theme) => ({
//                       borderRight: '1px solid rgba(224,224,224,1)',
//                       color: "rgba(22, 184, 44, 0.5)",
//                       fontWeight: "800",
//                       fontSize: '18px',
//                     }),
//                   }}
//                 />
//               </div>
//             </Container>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ViewStudent;
