// import React, { useEffect, useState } from "react";
// import Sidebar from "../Sidebar/Sidebar";
// import SchoolPerformanceNav from "./SchoolPerformanceNav";
// import { Route, Routes } from "react-router-dom";

// import "./Performance.css";
// import SchoolSideBar from "../SchoolSideBar/SchoolSideBar";
// import Radars from "../SchoolCharts/Radar";
// import Worm2 from "../SchoolCharts/Worm2";
// import RadialBarChart from "../SchoolCharts/RadialBarChart";

// const SchoolPerformance = () => {
//   const [rout, setRout] = useState(1);
//   const [isTitleHovered, setIsTitleHovered] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const [selectedStandard, setSelectedStandard] = useState('6K');
//   const [standardsInSchool, setStandardsInSchool] = useState([]);
//   const [categoryMapping, setCategoryMapping] = useState({});
//   const [caraosalsScore, setCaraosalsScore] = useState(null);
//   const [radialChartData, setRadialChartData] = useState({ series: [], labels: [] });
//   const [wormChartData, setWormChartData] = useState(null);
//   const [radarChartData, setRadarChartData] = useState({}); 

 

//   const data = [
//     {
//       id: 1,
//       title: "PRIDE ",
//       sub: "Profile",
//     },
//     {
//       id: 2,
//       title: "Skills",
//       sub: "Performance",
//     },
//     {
//       id: 3,
//       title: "Intelligence ",
//       sub: "Capacities",
//     },
//   ];
//   const handleTitleHover = () => {
//     setIsTitleHovered(true);
//   };

//   const handleTitleLeave = () => {
//     setIsTitleHovered(false);
//   };

//   const handleTitleClick = () => {
//     setIsTitleHovered(true);
//   };
//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };



//   return (
//     <div className="">
//       <div className="flex">
//       <div className={`flex h-full gap-5 sticky top-1 ${isSidebarOpen ? 'block' : 'hidden'} md:block`}>
//           <SchoolSideBar />
//         </div>


//         <div className="md:w-[91%] w-[95%] m-auto flex flex-col gap-5">
//           <div className="  flex flex-col-reverse justify-start items-center  shadow-lg rounded-lg p-3 gap-4 sticky top-0 z-10 bg-white  md:hidden ">
           
//             <form className="flex items-center md:hidden max-w-sm mx-auto">
//               <label htmlFor="simple-search" className="sr-only">
//                 Search
//               </label>
//               <div className="relative w-full">
//                 <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
//                   <svg
//                     className="w-4 h-4 text-yellow-500 dark:text-yellow-400"
//                     aria-hidden="true"
//                     xmlns="http://www.w3.org/2000/svg"
//                     fill="none"
//                     viewBox="0 0 18 20"
//                   >
//                     <path
//                       stroke="currentColor"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                       strokeWidth="2"
//                       d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"
//                     />
//                   </svg>
//                 </div>
//                 <input
//                   type="text"
//                   id="simple-search"
//                   className="border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-blue-600 dark:placeholder-yellow-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
//                   placeholder="Search..."
//                   required
//                 />
//               </div>
//               <button
//                 type="submit"
//                 className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//               >
//                 <svg
//                   className="w-4 h-4"
//                   aria-hidden="true"
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 20 20"
//                 >
//                   <path
//                     stroke="currentColor"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
//                   />
//                 </svg>
//                 <span className="sr-only">Search</span>
//               </button>
//             <div>
//               {/* <img className="w-[50px]" src={notification} alt="Notification" /> */}
//             </div>
//             <button
//         data-collapse-toggle="navbar-search"
//         type="button"
//         className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
//         aria-controls="navbar-search"
//         aria-expanded={isSidebarOpen}
//         onClick={toggleSidebar}
//       >
//         <span className="sr-only">Open main menu</span>
//         <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
//           <path
//             stroke="currentColor"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth="2"
//             d="M1 1h15M1 7h15M1 13h15"
//           />
//         </svg>
//       </button>
//             </form>
//           </div>

//         <div className="w-[100%]  md:mt-1   h-[100vh]  ">
//           <div className="w-full">
//             <SchoolPerformanceNav
//               onMouseEnter={handleTitleHover}
//               onMouseLeave={handleTitleLeave}
//               onClick={handleTitleClick}
//               isTitleHovered={isTitleHovered}
//               data={data}
//               rout={rout}
//               setRout={setRout}
//             />
//           </div>
         
//           {rout === 1 && (
//            <div className="flex flex-col p-5 justify-center  items-center h-auto gap-5  shadow-xl ">
//            <div className="flex  flex-col md:flex-row gap-8">
//              <div
//                className="md:w-[37%] w-[95%] flex flex-col gap-2 shadow-lg rounded-lg p-2"
//                style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
//                <p className="  underline text-start text-lg inline-block  font-fredoka">
//                PRIDE Profile
//                </p>
//                <p className="flex  font-arial text-left p-2 ">
//                  Our everyday needs will require us to use one to five human
//                  capabilities simultaneously or in tandem to satisfy the
//                  situational demands. Any Individual who uses maximum
//                  capabilities in tandem will be preferred the most in any
//                  work life environment.
//                </p>
//                {/* <img className="w-[180px] m-auto" src={pride} alt="" /> */}
//              </div>

//                 <div
//                   className="rounded-lg shadow-xl md:w-[63%] w-[95%] flex flex-col  justify-center items-center p-5"
//                   style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                
//       <Radars  />

//                   <p>PRIDE Profile </p>
//                 </div>
//               </div>
             
//             </div>
//           )}
//           {rout === 2 && (
//            <div className="flex flex-col  p-5 justify-center items-center  gap-5  shadow-xl ">
//            <div className="flex  flex-col md:flex-row gap-8 ">
//              <div
//                className="md:w-[37%] md:h-[450px] w-[95%] h-auto m-auto flex flex-col gap-2 shadow-lg rounded-lg  p-2"
//                style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
//                <p className="flex justify-start text-lg items-center  font-fredoka underline">
//                Skills Performance
//                </p>
//                <p className="text-left font-arial p-2 ">
//                Whenever we are put into a tight or risky situation, it becomes inevitable to take a decision accurately as well as immediately at the least possible time to come out of the situation safely and happily for which our BPSE is the most influencing factor.
//                </p>
//                {/* <img
//                  className="w-[180px] m-auto"
//                  src={ProductivityProfile}
//                  alt=""
//                /> */}
//              </div>
//                 <div
//                   className="rounded-lg  shadow-xl md:w-[63%] w-[95%] m-auto flex flex-col  justify-center items-center p-5"
//                   style={{
//                     borderRadius: "10px",
//                     boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
//                   }}>
      
//            <Worm2 />
//                 </div>
//               </div>
             
//             </div>
//           )}
//           {rout === 3 && (
//               <div className="flex flex-col p-5 justify-center items-center h-auto gap-5  shadow-xl ">
//               <div className="flex  flex-col md:flex-row gap-8">
//                 <div
//                   className="md:w-[37%] w-[95%] flex flex-col gap-2 shadow-lg rounded-lg p-2"
//                   style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
//                   <p className=" flex justify-start text-lg font-fredoka underline">
//                   Intelligence Capacities
//                   </p>
//                   <p className="flex  font-arial text-left p-2  ">
//                   Intelligence is supposed to be the exhibiting capacity of every individual to do or solve things in a unique & different way which was learnt through the same knowledge taught for everyone at school
//                   </p>
//                   {/* <img className="w-[180px] m-auto" src={intelligence} alt="" /> */}
//                 </div>
//                 <div
//                className="rounded-lg shadow-xl md:w-[63%] w-[95%] flex flex-col  justify-center items-center p-5"

//                   style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                     
//       <RadialBarChart  />
//                 </div>
//               </div>

             
//             </div>
//           )}
         
//         </div>
//         </div>
//         {/* <div className="w-[35%] mt-5  flex sticky top-0 h-full ">
//           <SideData />
//         </div> */}
//       </div>
//       {isSidebarOpen && (
//         <div className="fixed inset-0 z-50 flex md:hidden">
//           <div className=" bg-white shadow-lg">
//             <Sidebar />
//           </div>
//           <div className="flex-grow" onClick={toggleSidebar}></div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default SchoolPerformance;



import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useLocation
import Sidebar from "../Sidebar/Sidebar";
import SchoolPerformanceNav from "./SchoolPerformanceNav";
import { Route, Routes } from "react-router-dom";

import "./Performance.css";
import SchoolSideBar from "../SchoolSideBar/SchoolSideBar";
import Radars from "../SchoolCharts/Radar";
import Worm2 from "../SchoolCharts/Worm2";
import RadialBarChart from "../SchoolCharts/RadialBarChart";
import { useStandard } from "../SchoolDashboard/StandardContext";


const SchoolPerformance = () => {
  const [rout, setRout] = useState(1);
  const [isTitleHovered, setIsTitleHovered] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [standard, setStandard] = useState('');
  const [standardsInSchool, setStandardsInSchool] = useState([]);
  //const [categoryMapping, setCategoryMapping] = useState({});
  const [caraosalsScore, setCaraosalsScore] = useState(null);
  //const [radialChartData, setRadialChartData] = useState({ series: [], labels: [] });
  const [wormChartData, setWormChartData] = useState(null);
  const [radarChartData, setRadarChartData] = useState({}); 

  const [perceiveIndex,setPerceiveIndex]=useState(0)
  const [resolveIndex,setResolveIndex]=useState(0)
  const [influenceIndex,setInfluenceIndex]=useState(0)
  const [deliverIndex,setDeliverIndex]=useState(0)
  const [engageIndex,setEngageIndex]=useState(0)

  const [attentionIndex, setAttentionIndex] = useState(0);
  const [memoryIndex, setMemoryIndex] = useState(0);
  const [criticalIndex, setCriticalIndex] = useState(0);
  const [creativeIndex, setCreativeIndex] = useState(0);
  const [mindsetIndex, setMindsetIndex] = useState(0);
  const [expressionIndex, setExpressionIndex] = useState(0);
  const [attitudeIndex, setAttitudeIndex] = useState(0);
  const [communicationIndex, setCommunicationIndex] = useState(0);
  const [collaborationIndex, setCollaborationIndex] = useState(0);
  const [leadershipIndex, setLeadershipIndex] = useState(0);

  const [awarenessIndex,setAwarenessIndex]=useState(0)
  const [advantageIndex,setAdvantageIndex]=useState(0)
  const [applicationIndex,setApplicationIndex]=useState(0)

  const location = useLocation(); // Initialize useLocation hook
  const navigate = useNavigate()
  const { selectedStandard, categoryMapping } = useStandard();

  const getQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('standard');
  };

  useEffect(() => {
    const standardFromUrl = getQueryParams();
    if (standardFromUrl) {
      console.log('Selected standard from URL:', standardFromUrl); // Log the standard query parameter
      setStandard(standardFromUrl); // Set the state if needed
    } 
  }, [location]);

  useEffect(()=>{
    if (selectedStandard) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set('standard', selectedStandard);
      navigate(`${window.location.pathname}?${queryParams.toString()}`, { replace: true });
    }
    if (standard === 'school') {
      const token = localStorage.getItem("token");
      fetch('https://test.xqminds.com/api/schoolData/school-dashboard-average', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${token}`
        }
      })
        .then((res) => res.json())
        .then((res) => {
          setPerceiveIndex(Number(res.averages.averageTotalPerceiveIndexValue)||0);
          setResolveIndex(Number(res.averages.averageTotalResolveIndexValue)||0);
          setInfluenceIndex(Number(res.averages.averageTotalInfluenceIndexValue)||0)
          setDeliverIndex(Number(res.averages.averageTotalDeliverIndexValue)||0)
          setEngageIndex(Number(res.averages.averageTotalEngageIndexValue)||0)
          setAttentionIndex(Number(res.averages.averageTotalAttentionIndexValue)||0)
          setMemoryIndex(Number(res.averages.averageTotalMemoryIndexValue)||0)
          setCriticalIndex(Number(res.averages.averageTotalCriticalIndexValue)||0)
          setCreativeIndex(Number(res.averages.averageTotalCreativeIndexValue)||0)
          setMindsetIndex(Number(res.averages.averageTotalMindsetIndexValue)||0)
          setExpressionIndex(Number(res.averages.averageTotalExpressionIndexValue)||0)
          setAttitudeIndex(Number(res.averages.averageTotalAttitudeIndexValue)||0)
          setCommunicationIndex(Number(res.averages.averageTotalCommunicationIndexValue)||0)
          setCollaborationIndex(Number(res.averages.averageTotalCollaborationIndexValue)||0)
          setLeadershipIndex(Number(res.averages.averageTotalLeadershipIndexValue)||0)
          setAdvantageIndex(Number(res.averages.averageTotalAdvantageIndexValue*10)||0)
          setAwarenessIndex(Number(res.averages.averageTotalAwarenessIndexValue*10)||0)
          setApplicationIndex(Number(res.averages.averageTotalApplicationIndexValue*10)||0)
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    } else if (categoryMapping[standard]) {
      const token = localStorage.getItem("token");
      const category = categoryMapping[standard];
      const apiUrl = `https://test.xqminds.com/api/schoolData/section-wise-result?standard=${standard}&level=${category}`;

      fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setPerceiveIndex(Number(res.result.averageTotalPerceiveIndexValue)||0);
          setResolveIndex(Number(res.result.averageTotalResolveIndexValue)||0);
          setInfluenceIndex(Number(res.result.averageTotalInfluenceIndexValue)||0)
          setDeliverIndex(Number(res.result.averageTotalDeliverIndexValue)||0)
          setEngageIndex(Number(res.result.averageTotalEngageIndexValue)||0)
          setAttentionIndex(Number(res.result.averageTotalAttentionIndexValue)||0)
          setMemoryIndex(Number(res.result.averageTotalMemoryIndexValue)||0)
          setCriticalIndex(Number(res.result.averageTotalCriticalIndexValue)||0)
          setCreativeIndex(Number(res.result.averageTotalCreativeIndexValue)||0)
          setMindsetIndex(Number(res.result.averageTotalMindsetIndexValue)||0)
          setExpressionIndex(Number(res.result.averageTotalExpressionIndexValue)||0)
          setAttitudeIndex(Number(res.result.averageTotalAttitudeIndexValue)||0)
          setCommunicationIndex(Number(res.result.averageTotalCommunicationIndexValue)||0)
          setCollaborationIndex(Number(res.result.averageTotalCollaborationIndexValue)||0)
          setLeadershipIndex(Number(res.result.averageTotalLeadershipIndexValue)||0)
          setAdvantageIndex(Number(res.result.averageTotalAdvantageIndexValue*10)||0)
          setAwarenessIndex(Number(res.result.averageTotalAwarenessIndexValue*10)||0)
          setApplicationIndex(Number(res.result.averageTotalApplicationIndexValue*10)||0)
        })
        .catch((error) => {
          console.error(`Error fetching data for standard ${standard}`, error);
        });
    }
  },[standard, categoryMapping,navigate])

  const radialChartData = {
    series: [awarenessIndex, advantageIndex, applicationIndex],
    labels: ['Awareness', 'Advantage', 'Application'],
  };

  console.log({standard},'standard')
  const data = [
    {
      id: 1,
      title: "PRIDE ",
      sub: "Profile",
    },
    {
      id: 2,
      title: "Skills",
      sub: "Performance",
    },
    {
      id: 3,
      title: "Intelligence ",
      sub: "Capacities",
    },
  ];
  const handleTitleHover = () => {
    setIsTitleHovered(true);
  };

  const handleTitleLeave = () => {
    setIsTitleHovered(false);
  };

  const handleTitleClick = () => {
    setIsTitleHovered(true);
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };



  return (
    <div className="">
      <div className="flex">
      <div className={`flex h-full gap-5 sticky top-1 ${isSidebarOpen ? 'block' : 'hidden'} md:block`}>
          <SchoolSideBar />
        </div>


        <div className="md:w-[91%] w-[95%] m-auto flex flex-col gap-5">
          <div className="  flex flex-col-reverse justify-start items-center  shadow-lg rounded-lg p-3 gap-4 sticky top-0 z-10 bg-white  md:hidden ">
           
            <form className="flex items-center md:hidden max-w-sm mx-auto">
              <label htmlFor="simple-search" className="sr-only">
                Search
              </label>
              <div className="relative w-full">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-yellow-500 dark:text-yellow-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="simple-search"
                  className="border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-blue-600 dark:placeholder-yellow-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search..."
                  required
                />
              </div>
              <button
                type="submit"
                className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            <div>
              {/* <img className="w-[50px]" src={notification} alt="Notification" /> */}
            </div>
            <button
        data-collapse-toggle="navbar-search"
        type="button"
        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-search"
        aria-expanded={isSidebarOpen}
        onClick={toggleSidebar}
      >
        <span className="sr-only">Open main menu</span>
        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
            </form>
          </div>

        <div className="w-[100%]  md:mt-1   h-[100vh]  ">
          <div className="w-full">
            <SchoolPerformanceNav
              onMouseEnter={handleTitleHover}
              onMouseLeave={handleTitleLeave}
              onClick={handleTitleClick}
              isTitleHovered={isTitleHovered}
              data={data}
              rout={rout}
              setRout={setRout}
            />
          </div>
         
          {rout === 1 && (
             <div className="flex flex-col p-5 justify-center  items-center h-auto gap-5  shadow-xl ">
             <div className="flex  flex-col md:flex-row gap-8">
               <div
                 className="md:w-[37%] w-[95%] flex flex-col gap-2 shadow-lg rounded-lg p-2"
                 style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                 <p className="  underline text-start text-lg inline-block  font-fredoka">
                 PRIDE Profile
                 </p>
                 <p className="flex  font-arial text-left p-2 ">
                   Our everyday needs will require us to use one to five human
                   capabilities simultaneously or in tandem to satisfy the
                   situational demands. Any Individual who uses maximum
                   capabilities in tandem will be preferred the most in any
                   work life environment.
                 </p>
                 {/* <img className="w-[180px] m-auto" src={pride} alt="" /> */}
               </div>
  
                  <div
                    className="rounded-lg shadow-xl md:w-[63%] w-[95%] flex flex-col  justify-center items-center p-5"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                <Radars 
                    perceiveIndex={perceiveIndex}
                    resolveIndex={resolveIndex}
                    influenceIndex={influenceIndex}
                    deliverIndex={deliverIndex}
                    engageIndex={engageIndex}
                />

                  <p>PRIDE Profile </p>
                </div>
              </div>
             
            </div>
          )}
          {rout === 2 && (
            <div className="flex flex-col  p-5 justify-center items-center  gap-5  shadow-xl ">
            <div className="flex  flex-col md:flex-row gap-8 ">
              <div
                className="md:w-[37%] md:h-[450px] w-[95%] h-auto m-auto flex flex-col gap-2 shadow-lg rounded-lg  p-2"
                style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                <p className="flex justify-start text-lg items-center  font-fredoka underline">
                Skills Performance
                </p>
                <p className="text-left font-arial p-2 ">
                Whenever we are put into a tight or risky situation, it becomes inevitable to take a decision accurately as well as immediately at the least possible time to come out of the situation safely and happily for which our BPSE is the most influencing factor.
                </p>
                {/* <img
                  className="w-[180px] m-auto"
                  src={ProductivityProfile}
                  alt=""
                /> */}
              </div>
                 <div
                   className="rounded-lg  shadow-xl md:w-[63%] w-[95%] m-auto flex flex-col  justify-center items-center p-5"
                   style={{
                     borderRadius: "10px",
                     boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                   }}>
      <Worm2 
        attentionIndex={attentionIndex}
        memoryIndex={memoryIndex}
        criticalIndex={criticalIndex}
        creativeIndex={creativeIndex}
        mindsetIndex={mindsetIndex}
        expressionIndex={expressionIndex}
        attitudeIndex={attitudeIndex}
        communicationIndex={communicationIndex}
        collaborationIndex={collaborationIndex}
        leadershipIndex={leadershipIndex}
      />
                </div>
              </div>
             
            </div>
          )}
          {rout === 3 && (
              <div className="flex flex-col p-5 justify-center items-center h-auto gap-5  shadow-xl ">
              <div className="flex  flex-col md:flex-row gap-8">
                <div
                  className="md:w-[37%] w-[95%] flex flex-col gap-2 shadow-lg rounded-lg p-2"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                  <p className=" flex justify-start text-lg font-fredoka underline">
                  Intelligence Capacities
                  </p>
                  <p className="flex  font-arial text-left p-2  ">
                  Intelligence is supposed to be the exhibiting capacity of every individual to do or solve things in a unique & different way which was learnt through the same knowledge taught for everyone at school
                  </p>
                  {/* <img className="w-[180px] m-auto" src={intelligence} alt="" /> */}
                </div>
                <div
               className="rounded-lg shadow-xl md:w-[63%] w-[95%] flex flex-col  justify-center items-center p-5"

                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                     
                     <RadialBarChart radialChartData={radialChartData} />
                </div>
              </div>

             
            </div>
          )}
         
        </div>
        </div>
        {/* <div className="w-[35%] mt-5  flex sticky top-0 h-full ">
          <SideData />
        </div> */}
      </div>
      {isSidebarOpen && (
        <div className="fixed inset-0 z-50 flex md:hidden">
          <div className=" bg-white shadow-lg">
            <Sidebar />
          </div>
          <div className="flex-grow" onClick={toggleSidebar}></div>
        </div>
      )}
    </div>
  );
};

export default SchoolPerformance;
