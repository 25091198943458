import {
    FETCH_TEST_OPTION_REQUEST,
    FETCH_TEST_OPTION_SUCCESS,
    FETCH_TEST_OPTION_FAILURE,
  } from './actionType';
  
  const initialState = {
    loading: false,
    testOption: {},
    error: '',
  };
  
  const testOptionReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_TEST_OPTION_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_TEST_OPTION_SUCCESS:
        return {
          loading: false,
          testOption: action.payload,
          error: '',
        };
      case FETCH_TEST_OPTION_FAILURE:
        return {
          loading: false,
          testOption: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default testOptionReducer;
  