import React, { useEffect, useState } from 'react';
import ReactSpeedometer from "react-d3-speedometer";

const Gaurge = () => {
  const [activityValue, setActivityValue] = useState(0);



  const fetchDataFromBackend = async () => {
    try {
      const response = await fetch('https://test.xqminds.com/api/quiz/report-generate', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      // console.log(data,"dataa"); 
      setActivityValue(data.allReportData.mentalSpeedInSec);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchDataFromBackend();
  }, []);
      // console.log(activityValue)
  return (
    <div className='flex flex-col  items-center justify-center '>
      <ReactSpeedometer
         width={270}  
         height={120} 
        needleHeightRatio={0.6}
        value={+activityValue*10 }
        segmentColors={["yellow", "lightgreen", "rgb(59,130,246)", "rgb(126,34,206)", "red"]} 
        startColor="lightgreen"
        endColor="red"
        currentValueText="Speed"
        customSegmentLabels={[
          {
            text: "",
            color: "white",
            fontSize: "8px"
          },
          {
            text: "",
            color: "white",
            fontSize: "8px"
          },
          {
            text: "",
            color: "white",
            fontSize: "8px"
          },
          {
            text: "",
            color: "white",
            fontSize: "8px"
          },
          {
            text: "",
            color: "white",
            fontSize: "8px"
          },
         
          
          
        ]}
        ringWidth={30}
        needleTransitionDuration={3333}
        needleTransition="easeElastic"
        needleColor={"#90f2ff"}
        textColor={"white"}
      />
      <p className='font-semibold'>{activityValue} sec</p>
    </div>
  );
};

export default Gaurge;
