
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

const Radars = () => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    fetchDataFromBackend();
  }, []);

  const fetchDataFromBackend = async () => {
    try {
      const response = await fetch('https://test.xqminds.com/api/quiz/report-generate', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
       console.log(data, "dataaaa");
      setChartData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error (e.g., show error message)
    }
  };

  useEffect(() => {
    if (chartData) { // Check if chartData is not null
      renderChart();
    }
  }, [chartData]);

  const renderChart = () => {
    const ctx = chartRef.current.getContext('2d');
    new Chart(ctx, {
      type: 'radar',
      data: {
        labels: ['Perceive', 'Resolve', 'Influence', 'Deliver', 'Engage'],
        datasets: [{
          label: '',

          data: [
            chartData.allReportData.allScores.perceive.indexValue || 0,
            chartData.allReportData.allScores.resolve.indexValue || 0,
            chartData.allReportData.allScores.influence.indexValue || 0,
            chartData.allReportData.allScores.deliver.indexValue || 0,
            chartData.allReportData.allScores.engage.indexValue || 0
          ],
          backgroundColor: 'rgba(54, 162, 235, 0.1)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth:2,
         
        }],
      },
      options: {
        elements: {
          line: {
            tension: 0,
          },
        },
        scales: {
          r: {
            suggestedMin: 0,
            suggestedMax: 10,
            ticks: {
              stepSize: 2,
            },
            grid: {
              color: (context) => {
                const index = context.index;
                return index === 5 ? 'black' : 'grey';
              }
            },
            scaleLabel: {
              display: false 
            }
           
          },

        },
        plugins: {
          legend: {
            display: false // Remove the legend
          }
        }
      },
    });
  };

  return (
    <div style={{ width: '300px', height: '300px' }}>
    <canvas id="radarChart" ref={chartRef} />
  </div>
  );
};

export default Radars;
