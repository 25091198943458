// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';

// export const generatePDF = async (contentRef, filename = 'report.pdf') => {
//   const content = contentRef.current;
//   const canvas = await html2canvas(content);
//   const imgData = canvas.toDataURL('image/png');
//   const pdf = new jsPDF('p', 'pt', 'a4');
//   const pdfWidth = pdf.internal.pageSize.getWidth();
//   const pdfHeight = pdf.internal.pageSize.getHeight();
//   const imgWidth = canvas.width;
//   const imgHeight = canvas.height;
//   const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
//   const imgX = (pdfWidth - imgWidth * ratio) / 2;
//   const imgY = (pdfHeight - imgHeight * ratio) / 2;
//   pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
//   pdf.save(filename);
// };



import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const generatePDF = async (contentRef, filename = 'report.pdf') => {
  const content = contentRef.current;

  const canvas = await html2canvas(content, {
    scale: 2, // Increase the scale to get better resolution
    useCORS: true, // Enables cross-origin images
  });

  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF('p', 'pt', 'a4');
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();

  const imgWidth = canvas.width;
  const imgHeight = canvas.height;

  let heightLeft = imgHeight;
  let position = 0;

  const ratio = pdfWidth / imgWidth;
  const imgX = 0;
  const imgY = 0;

  // Render the first page
  pdf.addImage(imgData, 'PNG', imgX, position, pdfWidth, imgHeight * ratio);
  heightLeft -= pdfHeight / ratio;
  position = heightLeft * ratio;

  // Add more pages if needed
  while (heightLeft > 0) {
    pdf.addPage();
    pdf.addImage(imgData, 'PNG', imgX, -position, pdfWidth, imgHeight * ratio);
    heightLeft -= pdfHeight / ratio;
    position += pdfHeight;
  }

  pdf.save(filename);
};