// import React, { useEffect, useRef, useState } from 'react';
// import ApexCharts from 'apexcharts';

// const RadialBarChart = () => {

//   const [chartData, setChartData] = useState({});

//   useEffect(() => {
//     fetchDataFromBackend();
//   }, []);

//   const fetchDataFromBackend = async () => {
//     try {
//       const response = await fetch('https://test.xqminds.com/api/quiz/prideScore', {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${localStorage.getItem('token')}`,
//         },
//       });
//       if (!response.ok) {
//         throw new Error('Failed to fetch data');
//       }
//       const data = await response.json();
//       // console.log(data, "dataaaa");
//       setChartData(data);
//       localStorage.setItem( "MpiConsistency", data.MpiConsistency)
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       // Handle error (e.g., show error message)
//     }
//   }
//   const chartRef = useRef(null);

//   useEffect(() => {
//     const options = {
//       series: [44, 67, 83],
//       chart: {
//         height: 350,
//         type: 'radialBar',
//       },
//       plotOptions: {
//         radialBar: {
//           dataLabels: {
//             name: {
//               fontSize: '22px',
//             },
//             value: {
//               fontSize: '16px',
//             },
//             total: {
//               show: true,
//               label: 'Total',
//               formatter: function (w) {
//                 // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
//                 return 100;
//               }
//             }
//           }
//         }
//       },
//       labels: ['Apples', 'Oranges', 'Bananas'],
//     };

//     const chart = new ApexCharts(chartRef.current, options);
//     chart.render();

//     // Cleanup function to destroy the chart when component unmounts
//     return () => {
//       chart.destroy();
//     };
//   }, []);

//   return <div id="chart" ref={chartRef}></div>;
// };

// export default RadialBarChart;


// #FF8080
// #FFCF96

// #CDFAD5


import React, { useEffect, useRef, useState } from 'react';
import ApexCharts from 'apexcharts';

const RadialBarChart = () => {
  const [chartData, setChartData] = useState({ series: [], labels: [] });

  useEffect(() => {
    fetchDataFromBackend();
  }, []);

  const fetchDataFromBackend = async () => {
    try {
      const response = await fetch('https://test.xqminds.com/api/quiz/report-generate', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      const updatedChartData = {
        series: [
          data.allReportData.allScores.advantage.indexValue * 10, 
          data.allReportData.allScores.application.indexValue * 10, 
          data.allReportData.allScores.awareness.indexValue * 10
        ],
        labels: ['Advantage', 'Application', 'Awareness']
      };
      setChartData(updatedChartData);
      // console.log(data, "data");
      localStorage.setItem("MpiConsistency", data.MpiConsistency);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const chartRef = useRef(null);

  useEffect(() => {
    if (chartData.series.length > 0 && chartData.labels.length > 0) {
      const options = {
        series: chartData.series,
        chart: {
          height: 500,
          width: 500,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '22px',
              },
              value: {
                fontSize: '16px',
              },
              total: {
                show: true,
                label: 'Score',
                formatter: function () {
                  // Custom logic for the total label if needed
                }
              }
            }
          }
        },
        labels: chartData.labels,
        colors: ['#FF8080', '#FFCF96', '#CDFAD5'], // Add your custom colors here
        legend: {
          show: true,
          position: 'right',
        }
      };

      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      // Cleanup function to destroy the chart when component unmounts
      return () => {
        chart.destroy();
      };
    }
  }, [chartData]);

  return <div id="chart" ref={chartRef}></div>;
};

export default RadialBarChart;
