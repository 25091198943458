
// import React from "react";
// import {
//   TextRevealCard,
//   TextRevealCardDescription,
//   TextRevealCardTitle,
// } from "../Break/Breakdesign";

// function TextRevealCardPreview({isvisible,onClose}) {
//   if(!isvisible) return null
//   return (
//     <div className="flex items-center fixed inset-0  md:h-[100vh] backdrop-blur-sm justify-center bg-[#0E0E10] h-[50rem]  w-full">
      
//       <TextRevealCard
//         text="Its Time To  Take a Deap Breath"
//         revealText="Now Give Your Remaining Test "
//       >
//         <div className="flex justify-between mb-2 w-full items-center">
//           <TextRevealCardTitle>
            
//           </TextRevealCardTitle>
//           <button className="text-white rounded-lg border mb-5 border-white text-md px-3 py-1"  onClick={() => onClose()}>Resume</button>
//         </div>
//         {/* <TextRevealCardTitle>
//          x
//         </TextRevealCardTitle> */}
//         <TextRevealCardDescription>
//          When you feel comfort and you are ready to take the test then click on resume
//         </TextRevealCardDescription>
//       </TextRevealCard>
//     </div>
//   );
// }

// export default TextRevealCardPreview;




import React from 'react'
import testbackground4 from "../../image/testbackground4.png";
import  breakPage from "../../image/breakPage.png"

const TextRevealCardPreview=({isvisible,onClose})=> {
  if(!isvisible) return null
  return (
    <div className="flex items-center fixed inset-0 md:h-[100vh] backdrop-blur-sm justify-center bg-[#0E0E10] h-[50rem] w-full">
    <img src={breakPage} alt="Background" className="w-full h-[100vh] sm:h-[100vh] md:h-[100vh] lg:h-[100vh] xl:h-[100vh]" />
  
    <div className="absolute flex flex-col gap-8 min-h-full w-[100%] justify-center items-center">
      <p className="text-4xl w-[70%] font-arial text-center">When you feel ready to take the test, click the resume button.</p>
      <p className="text-4xl w-[60%] font-arial text-center">Take a deep breath – it's time to begin.</p>
      <div className="flex w-[65%] justify-center pr-10"> {/* Added pr-10 for right padding */}
        <button
          className="rounded-lg font-fredoka border border-blue-500 text-white bg-blue-600 mb-5 text-3xl px-3 py-1 shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none"
          onClick={() => onClose()}
        >
          Resume
        </button>
      </div>
    </div>
  </div>
  
  )
}

export default TextRevealCardPreview