import React from 'react'
import { useSpring,animated } from 'react-spring'
const NumberSpring = ({n}) => {
    const {number} = useSpring({
        from:{number:0},
        number:n,
        delay:200,
        config:{mass:1,tension:7,friction:8}
    })
  return (
    <div>
<animated.div>{number.to((n)=>n.toFixed(0))}</animated.div>
    </div>
  )
}

export default NumberSpring