import React, { useState } from 'react';
import SchoolSideBar from "../../School/SchoolSideBar/SchoolSideBar";
 import axios from 'axios';
import NextExamDate from '../SchoolDashboard/SchoolExamDate/NextExamDate';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import Resizer from 'react-image-file-resizer';
import notification from "../../../image/notification.gif";

import Compressor from 'compressorjs';

const AddStudent = () => {
  const [photoPreview, setPhotoPreview] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [classValue, setClassValue] = useState('');
  const [sectionValue, setSectionValue] = useState('');
  const [formData, setFormData] = useState({
    photo: '',
    studentName: '',
    middleName: '',
    lastName: '',
    nickName: '',
    gender: '',
    dob: '',
    mobile: '',
    wpNumber: '',
    fatherName: '',
    fatherWpNumber: '',
    motherName: '',
    motherWpNumber: '',
    email: '',
    password: '',
    studentId: '',
    enrollmentNo: '',
    standard: '',
    schoolName: '',
    board: '',
    town: '',
    city: '',
    district: '',
    state: '',
    pincode: '',
    assessmentPackageName: '',
    packageName: ''
  });

  const handleClassChange = (e) => {
    setClassValue(e.target.value);
    setFormData({
      ...formData,
      standard: `${e.target.value}${sectionValue}`
    });
  };

  const handleSectionChange = (e) => {
    setSectionValue(e.target.value);
    setFormData({
      ...formData,
      standard: `${classValue}${e.target.value}`
    });
  };

const handlePhotoChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    // Resize the image before setting state
    Resizer.imageFileResizer(
      file,
      120, // max width
      120, // max height
      'JPEG', // format
      30, // quality
      0, // rotation
      (uri) => {
        setPhotoPreview(uri);
        setFormData({ ...formData, photo: uri });
      },
      'base64'
    );
  } else {
    setPhotoPreview(null);
  }
};
 

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "schoolName") {
        updatedValue = value.toLowerCase();
      }
    // Check if the field is "standard" and convert its value to uppercase
    if (name === "standard") {
      updatedValue = value.toUpperCase();
    }
  
  
  
    setFormData({
      ...formData,
      [name]: updatedValue,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };


  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post('https://test.xqminds.com/api/student/register', formData);
  //     console.log(response.data, "student data");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://test.xqminds.com/api/student/register', formData);
      toast.success("You have successfully registered the student!");
      console.log(response.data, "student data");
    } catch (error) {
      toast.error("Failed to register the student. Please try again.");
      console.error(error.msg);
    }
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div className='flex gap-5'>
      
      <div className={`flex h-[99vh]  gap-5 sticky top-1 ${isSidebarOpen ? 'block' : 'hidden'} md:block`}>
         <SchoolSideBar />
        </div>
        <div className="w-[91.8%] mx-auto">
        
           <div className="md:flex md:flex-row flex flex-col-reverse justify-start items-center  shadow-lg rounded-lg p-3 gap-4 sticky top-0 z-10 bg-white">
           <NextExamDate />
            <form className="flex items-center max-w-sm mx-auto">
              <label htmlFor="simple-search" className="sr-only">
                Search
              </label>
              <div className="relative w-full">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-yellow-500 dark:text-yellow-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="simple-search"
                  className="border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-blue-600 dark:placeholder-yellow-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search..."
                  required
                />
              </div>
              <button
                type="submit"
                className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            <div>
              <img className="w-[50px]" src={notification} alt="Notification" />
            </div>
            <button
        data-collapse-toggle="navbar-search"
        type="button"
        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-search"
        aria-expanded={isSidebarOpen}
        onClick={toggleSidebar}
      >
        <span className="sr-only">Open main menu</span>
        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
            </form>
          </div>
      <div className='mpi w-[100%] p-4 m-auto'>
       <p className='flex justify-start items-start font-semibold text-2xl underline'>Add Student</p>

        <form className="space-y-4 md:space-y-4 w-full  p-4" onSubmit={handleSubmit}>
        <div className='flex w-full gap-5'>
        <div>
              <label htmlFor="photo" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Upload Photo</label>
              <div className='flex'>
                <input type="file" id="photo" name="photo"  onChange={handlePhotoChange} />
{/*              
              <div>
        <label htmlFor="photo">Photo:</label>
        <input type="file" id="photo" onChange={handlePhotoChange} />
       {photoPreview && <img src={photoPreview} alt="Photo Preview" />}
       </div> */}
               {/* <input type="file" onChange={handleFileChange} /> */}
              
            </div>
            </div>
          </div>
          <div className='flex w-full gap-5'>
            <div className='w-[50%]'>
              <label htmlFor="studentName" className="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
              <input type="text" name="studentName" id="studentName" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Name" required onChange={handleChange} />
            </div>
            <div className='w-[50%]'>
              <label htmlFor="middleName" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Middle Name</label>
              <input type="text" name="middleName" id="middleName" placeholder="Middle Name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
            <div className='w-[50%]'>
              <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Last Name</label>
              <input type="text" name="lastName" id="lastName" placeholder="Last Name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
           
          </div>
          <div className='flex gap-5'>
            <div className='w-[50%]'>
              <label htmlFor="nickName" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Nick Name</label>
              <input type="text" name="nickName" id="nickName" placeholder="Nick Name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
         
      <div className='w-[50%]'>
        <label htmlFor="gender" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
          Gender
        </label>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          name="gender"
          id="gender"
          value={formData.gender}
          onChange={handleInputChange}
          required
        >
          <option value="">Select gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
      </div>
            <div className='w-[50%]'>
              <label htmlFor="dob" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">DOB</label>
              <input type="date" name="dob" id="dob" placeholder="Date of birth" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
            <div className='w-[50%]'>
              <label htmlFor="mobile" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Mobile Number</label>
              <input type="number" name="mobile" id="mobile" placeholder="Mobile Number" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
          </div>
          <div className='flex gap-5'>
            <div className='w-[50%]'>
              <label htmlFor="wpNumber" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">WhatsApp Number</label>
              <input type="number" name="wpNumber" id="wpNumber" placeholder="WhatsApp Number" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
            <div className='w-[50%]'>
              <label htmlFor="fatherName" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Father Name</label>
              <input type="text" name="fatherName" id="fatherName" placeholder="Father Name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
            <div className='w-[50%]'>
              <label htmlFor="fatherWpNumber" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Father WhatsApp Number</label>
              <input type="number" name="fatherWpNumber" id="fatherWpNumber" placeholder="Father WhatsApp Number" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
           
          </div>
          <div className='flex gap-5'>
          <div className='w-[50%]'>
              <label htmlFor="motherName" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Mother Name</label>
              <input type="text" name="motherName" id="motherName" placeholder="Mother Name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
            <div className='w-[50%]'>
              <label htmlFor="motherWpNumber" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Mother WhatsApp Number</label>
              <input type="number" name="motherWpNumber" id="motherWpNumber" placeholder="Mother WhatsApp Number" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
            <div className='w-[50%]'>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Email</label>
              <input type="email" name="email" id="email" placeholder="Email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
           
          </div>
          <div className='flex gap-5'>
          <div className='w-[50%]'>
              <label htmlFor="password" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Password</label>
              <input type="password" name="password" id="password" placeholder="Password" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
            <div className='w-[50%]'>
              <label htmlFor="studentId" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Student ID</label>
              <input type="text" name="studentId" id="studentId" placeholder="Student ID" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
            <div className='w-[50%]'>
              <label htmlFor="enrollmentNo" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Enrollment No</label>
              <input type="text" name="enrollmentNo" id="enrollmentNo" placeholder="Enrollment No" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
           
          </div>
          <div className='flex gap-5'>
          {/* <div className='w-[50%]'>
              <label htmlFor="standard" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Standard</label>
              <input type="text" name="standard" id="standard" placeholder="6A" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div> */}
            <div className='w-[50%]'>
        <label htmlFor="standard" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
          Standard
        </label>
        <div className="flex space-x-2">
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-1/2 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            id="class"
            value={classValue}
            onChange={handleClassChange}
            required
          >
            <option value="">Select class</option>
            {[...Array(8).keys()].map(i => (
              <option key={i + 5} value={i + 5}>{i + 5}</option>
            ))}
          </select>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-1/2 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            id="section"
            value={sectionValue}
            onChange={handleSectionChange}
            required
          >
            <option value="">Select section</option>
            {['A', 'B', 'C', 'D', 'E'].map(section => (
              <option key={section} value={section}>{section}</option>
            ))}
          </select>
        </div>
      </div>
            <div className='w-[50%]'>
              <label htmlFor="schoolName" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">School Name</label>
              <input type="text" name="schoolName" id="schoolName" placeholder="School Name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
            <div className='w-[50%]'>
              <label htmlFor="board" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Board</label>
              <input type="text" name="board" id="board" placeholder="Board" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
          </div>
       
          <div className='flex gap-5'>
         
            <div className='w-[50%]'>
              <label htmlFor="town" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Town</label>
              <input type="text" name="town" id="town" placeholder="Town" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
            <div className='w-[50%]'>
              <label htmlFor="city" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">City</label>
              <input type="text" name="city" id="city" placeholder="City" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
            <div className='w-[50%]'>
              <label htmlFor="district" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">District</label>
              <input type="text" name="district" id="district" placeholder="District" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
            <div className='w-[50%]'>
              <label htmlFor="state" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">State</label>
              <input type="text" name="state" id="state" placeholder="State" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
         
          </div>
          <div className='flex gap-5'>
          <div className='w-[50%]'>
              <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Pincode</label>
              <input type="number" name="pincode" id="pincode" placeholder="Pincode" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
            <div className='w-[50%]'>
              <label htmlFor="assessmentPackageName" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Assesment Package Name</label>
              <input type="text" name="assessmentPackageName" id="assessmentPackageName" placeholder="Assesment Package Name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
            <div className='w-[50%]'>
              <label htmlFor="packageName" className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">Package Name</label>
              <input type="text" name="packageName" id="packageName" placeholder="Package Name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange} />
            </div>
          </div>
<div className='flex justify-end'>
<button type="submit" className=" bg-primary-600 text-white text-lg bg-green-400 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg  px-5 py-2.5 text-center border flex justify-end dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Register Student</button>

</div>
        </form>
      </div>
   </div>
   <ToastContainer />

   {isSidebarOpen && (
        <div className="fixed inset-0 z-50 flex md:hidden">
          <div className=" bg-white shadow-lg">
            <SchoolSideBar />
          </div>
          <div className="flex-grow" onClick={toggleSidebar}></div>
        </div>
      )}
    </div>
  );
};

export default AddStudent;









// import React, { useState } from 'react';
// import axios from 'axios';
// import Compressor from 'compressorjs';

// const AddStudent = () => {
//   const [formData, setFormData] = useState({
//     // your form data here
//     photo: null,
//     studentName: '',
//     middleName: '',
//     lastName: '',
//     nickName: '',
//     gender: '',
//     dob: '',
//     mobile: '',
//     wpNumber: '',
//     fatherName: '',
//     fatherWpNumber: '',
//     motherName: '',
//     motherWpNumber: '',
//     email: '',
//     password: '',
//     studentId: '',
//     enrollmentNo: '',
//     standard: '',
//     schoolName: '',
//     board: '',
//     town: '',
//     city: '',
//     district: '',
//     state: '',
//     pincode: '',
//     assessmentPackageName: '',
//     packageName: '',
//   });

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       new Compressor(file, {
//         quality: 0.6,
//         success(result) {
//           setFormData({ ...formData, photo: result });
//         },
//         error(err) {
//           console.log(err.message);
//         },
//       });
//     }
//   };

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const onSubmit = async (e) => {
//     e.preventDefault();

//     const data = new FormData();
//     Object.keys(formData).forEach(key => {
//       data.append(key, formData[key]);
//     });

//     try {
//       const response = await axios.post('https://test.xqminds.com/api/student/register', data, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       console.log('Success:', response.data);
//     } catch (error) {
//       console.error('Error:', error.msg);
//     }
//   };

//   return (
//     // <form onSubmit={onSubmit}>
//     //   {/* Your form fields here */}
//     //   <input type="text" name="studentName" value={formData.studentName} onChange={handleChange} required />
//     //   <input type="file" onChange={handleFileChange} />
//     //   <button type="submit">Submit</button>
//     // </form>
//   );
// };

// export default AddStudent;
