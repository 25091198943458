import React from 'react';

const PerformanceTable = () => {
  const tableData = [
    { srNo: 1, score: '8-10', expertNote: 'Performing', definition: 'An exceptional proficiency and readiness' },
    { srNo: 2, score: '6-8', expertNote: 'Progressing', definition: 'Strong capabilities with good progress towards higher performance' },
    { srNo: 3, score: '4-6', expertNote: 'Promising', definition: 'Promising abilities showing considerable improvement' },
    { srNo: 4, score: '2-4', expertNote: 'Preparing', definition: 'A strong foundation with room for improvement' },
    { srNo: 5, score: '0-2', expertNote: 'Potential', definition: 'Developing potential that can be nurtured with consistent effort' },
  ];

  return (
    <div className="overflow-x-auto">
      <h2 className="text-xl font-medium font-fredoka mb-4">*Performance Table</h2>
      <table className="min-w-full border border-gray-300">
        <thead className="bg-gray-100">
          <tr>
            <th className="border border-gray-300 px-4 py-2">Sr No</th>
            <th className="border border-gray-300 px-4 py-2">Score</th>
            <th className="border border-gray-300 px-4 py-2">Expert Note</th>
            <th className="border border-gray-300 px-4 py-2">Definition</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td className="border border-gray-300 px-4 py-2">{row.srNo}</td>
              <td className="border border-gray-300 px-4 py-2">{row.score}</td>
              <td className="border border-gray-300 px-4 py-2">{row.expertNote}</td>
              <td className="border border-gray-300 px-4 py-2">{row.definition}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PerformanceTable;
