import {
    FETCH_SENIOR_SCORES_REQUEST,
    FETCH_SENIOR_SCORES_SUCCESS,
    FETCH_SENIOR_SCORES_FAILURE,
  } from './actionType';
  
  const initialState = {
    loading: false,
    seniorScores: {},
    error: '',
  };
  
  const seniorScoresReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SENIOR_SCORES_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_SENIOR_SCORES_SUCCESS:
        return {
          loading: false,
          seniorScores: action.payload,
          error: '',
        };
      case FETCH_SENIOR_SCORES_FAILURE:
        return {
          loading: false,
          seniorScores: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default seniorScoresReducer;
  