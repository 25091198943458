import React from "react";
import {Line } from "rc-progress";
import "../Circular/CircularProgressBar.css";


const LineChart = ({ percentage }) => {
  return (
    <div className="Progress-Container w-[100%] ">
         <div className="percentage-label text-purple-950"> {percentage} % </div>
    <Line
      percent={percentage}
      strokeWidth={1}
      trailWidth={2}
      strokeColor="blue"
      className="horizontal-progress-Bar"
    //   onDrag={(percent) => handleDrag(percent)}
    />
    {/* <div className="percentage-label">{percentage}%</div> */}
   
  </div>
 
  );
};

export default LineChart