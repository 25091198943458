import React, { useState, useEffect } from "react";

const questions = [
  {
    id: 1,
    question: "What is the capital of France?",
    options: ["London", "Berlin", "Paris", "Madrid"],
    answer: "Paris",
  },
  {
    id: 2,
    question: "Which planet is known as the Red Planet?",
    options: ["Mars", "Venus", "Jupiter", "Saturn"],
    answer: "Mars",
  },
  // Add more questions here
];

const Assignment = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showQuestion, setShowQuestion] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const [timer, setTimer] = useState(10); // Timer for both question and options
  const [optionSelected, setOptionSelected] = useState(false);
  const [selectedOptionData, setSelectedOptionData] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    if (timer === 0) {
      clearInterval(interval);
      if (showQuestion) {
        setShowQuestion(false);
        setShowOptions(true);
        setTimer(20); // Start timer for options
      } else {
        handleNextQuestion();
      }
    }

    return () => clearInterval(interval);
  }, [timer, showQuestion]);

  const handleOptionSelect = (selectedOption) => {
    const optionData = {
      questionId: questions[currentQuestion].id,
      selectedOption: selectedOption,
      time: 10 - timer, // Time when the option was clicked
    };
    setSelectedOptionData([...selectedOptionData, optionData]);
    setShowOptions(false);
    setOptionSelected(true);
    handleNextQuestion();
  };

  const handleNextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setShowQuestion(true);
      setTimer(10); // Start timer for next question
      setOptionSelected(false); // Reset option selected state
    } else {
      // Quiz completed
      console.log("Quiz completed!");
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 border border-gray-300 rounded-md">
      <h2 className="text-2xl font-bold mb-4">Questionnaire</h2>
      {showQuestion && (
        <div className="mb-4">
          <p>Time Left for Question: {timer} seconds</p>
          <h3 className="text-lg font-semibold">
            Question {questions[currentQuestion].id}:{" "}
            {questions[currentQuestion].question}
          </h3>
        </div>
      )}
      {showOptions && (
        <div>
          <p>Time Left for Options: {timer} seconds</p>
          <ul className="mb-4">
            {questions[currentQuestion].options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionSelect(option)}
                className="cursor-pointer py-2"
              >
                {option}
              </li>
            ))}
          </ul>
          {!optionSelected && (
            <p className="text-red-500">Please select an option before proceeding.</p>
          )}
          {currentQuestion !== questions.length - 1 && (
            <button
              disabled={!optionSelected}
              onClick={handleNextQuestion}
              className="px-4 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Next
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Assignment;
