import React from 'react'

import StackedBarChart from './StackedBarChart'
import HorizontalBarChart from './HorizontalBarChart'
import RadialBarChart from './RadialBarChart'
import BarChart from './BarChart'
import PieChart from './PieChart'
import DonutChart from './DonutChart'
import PictorialStackedChart from './PictorialStackedChart'

const AllCharts = () => {
  return (
    <div className='w-[50%] m-auto'>
        <BarChart/>
        <StackedBarChart/>
        <HorizontalBarChart/>
        <RadialBarChart/>
        <PieChart/>
        <DonutChart/>
        <PictorialStackedChart/>
    </div>
  )
}

export default AllCharts