

// import React, { useEffect, useState } from 'react';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';

// const Recommendation = () => {
//   const [skills, setSkills] = useState([]);

//   useEffect(() => {
//     fetch("https://test.xqminds.com/api/quiz/recommendation", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((res) => {
//          const fetchedSkills = extractSkills(res.Recommendation);
//         setSkills(fetchedSkills);
//         console.log(fetchedSkills)
//       })
//       .catch((err) => console.log(err));
//   }, []);

//   const extractSkills = (data) => {
//     const skillCategories = ['memory', 'critical', 'creative', 'mindset', 'attitude', 'communication', 'collaboration', 'leadership'];
//     return skillCategories.filter(skill => data[skill] && data[skill].length > 0);
//   };

//   if (skills.length === 0) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="carousel-container">
//       <Carousel showThumbs={false} autoPlay showStatus={false} showIndicators={false} infiniteLoop>
//         {skills.map((skill, index) => (
//           <div key={index} className="flex flex-col justify-center items-center h-[190px] p-2">
//             <h2 className="text-center text-xl font-bold">
//               {skill.charAt(0).toUpperCase() + skill.slice(1)}
//             </h2>
//           </div>
//         ))}
//       </Carousel>
//     </div>
//   );
// };

// export default Recommendation;

















// import React, { useEffect, useState } from 'react';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';

// const Recommendation = () => {
//   const [skills, setSkills] = useState([]);
//   const [expandedIndexes, setExpandedIndexes] = useState({});

//   useEffect(() => {
//     fetch("https://test.xqminds.com/api/quiz/recommendation", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((res) => {
//         const fetchedSkills = extractSkills(res.Recommendation);
//         setSkills(fetchedSkills);
//       })
//       .catch((err) => console.log(err));
//   }, []);

//   const extractSkills = (data) => {
//     const skillCategories = ['memory', 'critical', 'creative', 'mindset', 'attitude', 'communication', 'collaboration', 'leadership'];
//     const skillsWithSuggestions = [];

//     skillCategories.forEach((skill) => {
//       if (data[skill] && data[skill].length > 0) {
//         data[skill].forEach((item) => {
//           skillsWithSuggestions.push({
//             skill: skill,
//             suggestion: item.suggestion
//           });
//         });
//       }
//     });

//     return skillsWithSuggestions;
//   };

//   const truncateText = (text, wordLimit) => {
//     const words = text.split(' ');
//     if (words.length > wordLimit) {
//       return words.slice(0, wordLimit).join(' ') + '...';
//     }
//     return text;
//   };

//   const toggleExpand = (index) => {
//     setExpandedIndexes(prevState => ({
//       ...prevState,
//       [index]: !prevState[index]
//     }));
//   };

//   if (skills.length === 0) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="carousel-container">
//       <Carousel showThumbs={false} autoPlay showStatus={false} showIndicators={false} infiniteLoop>
//         {skills.map((item, index) => (
//           <div key={index} className="flex flex-col justify-center items-center h-[190px] p-2">
//             <h2 className="text-center text-xl font-bold">
//               {item.skill.charAt(0).toUpperCase() + item.skill.slice(1)}
//             </h2>
//             <p className="text-center font-semibold text-lg mt-2">
//               {expandedIndexes[index] ? item.suggestion : truncateText(item.suggestion, 6)}
//             </p>
//             {item.suggestion.split(' ').length > 6 && (
//               // <p 
//               //   className="text-blue-500 mt-2 cursor-pointer"
//               //   onClick={() => toggleExpand(index)}
//               // >
//               //   {expandedIndexes[index] ? 'Show less' : 'Read more...'}
//               // </p>
//               <p className='text-blue-500 mt-2 cursor-pointer'>Read more...</p>
          
//             )}
//           </div>
//         ))}
//       </Carousel>
//     </div>
//   );
// };

// export default Recommendation;










// import React, { useEffect, useState } from 'react';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import { useNavigate } from 'react-router-dom';

// const Recommendation = () => {
//   const [skills, setSkills] = useState([]);
//   const [expandedIndexes, setExpandedIndexes] = useState({});
//   const navigate = useNavigate(); // Initialize the navigate function

//   useEffect(() => {
//     fetch("https://test.xqminds.com/api/quiz/SkillContentAndRecommendation", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((res) => {
//         const fetchedSkills = extractSkills(res.Recommendation);
//         setSkills(fetchedSkills);
//       })
//       .catch((err) => console.log(err));
//   }, []);

//   const extractSkills = (data) => {
//     const skillCategories = ['memory', 'critical', 'creative', 'mindset', 'attitude', 'communication', 'collaboration', 'leadership'];
//     const skillsWithSuggestions = [];

//     skillCategories.forEach((skill) => {
//       if (data[skill] && data[skill].length > 0) {
//         data[skill].forEach((item) => {
//           skillsWithSuggestions.push({
//             skill: skill,
//             suggestion: item.suggestion
//           });
//         });
//       }
//     });

//     return skillsWithSuggestions;
//   };

//   const truncateText = (text, wordLimit) => {
//     const words = text.split(' ');
//     if (words.length > wordLimit) {
//       return words.slice(0, wordLimit).join(' ') + '...';
//     }
//     return text;
//   };

//   const toggleExpand = (index) => {
//     setExpandedIndexes(prevState => ({
//       ...prevState,
//       [index]: !prevState[index]
//     }));
//   };

//   const handleClick = (skill) => {
//     navigate('/studentprofile', { state: { selectedSkill: skill } }); // Navigate to the Accordion component with the selected skill
//   };

//   if (skills.length === 0) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="carousel-container">
//       <Carousel showThumbs={false} autoPlay showStatus={false} showIndicators={false} infiniteLoop>
//         {skills.map((item, index) => (
//           <div key={index} className="flex flex-col justify-center items-center h-[190px] p-2" onClick={() => handleClick(item.skill)}>
//             <h2 className="text-center text-xl font-bold">
//               {item.skill.charAt(0).toUpperCase() + item.skill.slice(1)}
//             </h2>
//             <p className="text-center font-semibold text-lg mt-2">
//               {expandedIndexes[index] ? item.suggestion : truncateText(item.suggestion, 6)}
//             </p>
//             {item.suggestion.split(' ').length > 6 && (
//               <p className='text-blue-500 mt-2 cursor-pointer'>Read more...</p>
//             )}
//           </div>
//         ))}
//       </Carousel>
//     </div>
//   );
// };

// export default Recommendation;













// import React, { useEffect, useState } from 'react';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import { useNavigate } from 'react-router-dom';

// const Recommendation = () => {
//   const [skills, setSkills] = useState([]);
//   const [expandedIndexes, setExpandedIndexes] = useState({});
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetch("https://test.xqminds.com/api/quiz/recommendation", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((res) => {
//         const fetchedSkills = extractSkills(res.Recommendation);
//         setSkills(fetchedSkills);
//       })
//       .catch((err) => console.log(err));
//   }, []);

//   const extractSkills = (data) => {
//     const skillsWithSuggestions = [];

//     Object.keys(data).forEach((skill) => {
//       if (data[skill] && data[skill].length > 0) {
//         data[skill].forEach((item) => {
//           skillsWithSuggestions.push({
//             skill: skill,
//             suggestion: item.suggestion
//           });
//         });
//       }
//     });

//     return skillsWithSuggestions;
//   };

//   const truncateText = (text, wordLimit) => {
//     const words = text.split(' ');
//     if (words.length > wordLimit) {
//       return words.slice(0, wordLimit).join(' ') + '...';
//     }
//     return text;
//   };

//   const toggleExpand = (index) => {
//     setExpandedIndexes(prevState => ({
//       ...prevState,
//       [index]: !prevState[index]
//     }));
//   };

//   const handleClick = (skill) => {
//     navigate('/studentprofile', { state: { selectedSkill: skill } });
//   };

//   if (skills.length === 0) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="carousel-container">
//       <Carousel showThumbs={false} autoPlay showStatus={false} showIndicators={false} infiniteLoop>
//         {skills.map((item, index) => (
//           <div key={index} className="flex flex-col justify-center items-center h-[190px] p-2" onClick={() => handleClick(item.skill)}>
//             <h2 className="text-center text-xl font-bold">
//               {item.skill.charAt(0).toUpperCase() + item.skill.slice(1)}
//             </h2>
//             <p className="text-center font-semibold text-lg mt-2">
//               {expandedIndexes[index] ? item.suggestion : truncateText(item.suggestion, 6)}
//             </p>
//             {item.suggestion.split(' ').length > 6 && (
//               <p className='text-blue-500 mt-2 cursor-pointer' onClick={(e) => {
//                 e.stopPropagation(); // Prevent triggering the handleClick function
//                 toggleExpand(index);
//               }}>Read more...</p>
//             )}
//           </div>
//         ))}
//       </Carousel>
//     </div>
//   );
// };

// export default Recommendation;



// import { Button } from '@chakra-ui/react';
// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// const WeaknessCarousel = ({ weaknessData }) => {
//   const [currentWeaknessIndex, setCurrentWeaknessIndex] = useState(0);
//   const navigate= useNavigate()
//   const handleProfilePage=()=>{
//     navigate("/studentprofile")
//   }
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentWeaknessIndex(prevIndex => (prevIndex + 1) % weaknessData.length);
//     }, 8000);

//     return () => clearInterval(interval);
//   }, [weaknessData]);

//   return (
//     <div  className='h-[120px]  font-semibold flex flex-col justify-center items-center'>
//        {weaknessData.length > 0 ? (
//         <div>
//           <div className='relative'>
//           <p>{weaknessData[currentWeaknessIndex]}</p>
//           </div>
//           <Button
//       className='fixed top-2 border rounded-lg shadow-lg px-2 py-1  text-blue-600 '
//       onClick={handleProfilePage}
//       title="Check more your Weak points" // Hover message
//     >
//      <p className='text-sm'> read more...</p>
//     </Button>
//         </div>
//       ) : (
//         <p>No weakness data available</p>
//       )}
//     </div>
//   );
// };

// const Recommendation = () => {
//   const [weaknessData, setWeaknessData] = useState([]);

//   const fetchData = () => {
//     fetch("https://test.xqminds.com/api/quiz/SkillContentAndRecommendation", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((res) => {
//         // console.log(res, "API response");
//         const weaknesses = Object.values(res.contentObject).flatMap(category =>
//           Object.values(category).flatMap(subCategory =>
//             subCategory.weakness
//           )
//         );
//         setWeaknessData(weaknesses);
        
//       })
//       .catch((err) => console.log(err));
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   return (
//     <div>
//       <WeaknessCarousel weaknessData={weaknessData} />
//     </div>
//   );
// };

// export default Recommendation;




import React, { useEffect, useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";

const Recommendation = () => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  
  const fetchData = () => {
    fetch("https://test.xqminds.com/api/quiz/SkillContentAndRecommendation", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res.contentObject);
        // console.log(res);  
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const extractRecommendations = (data) => {
    const recommendations = [];
    for (const category in data) {
      if (
        data[category] &&
        data[category].awareness &&
        data[category].awareness.recommendations &&
        Array.isArray(data[category].awareness.recommendations) &&
        data[category].awareness.recommendations.length > 0
      ) {
        recommendations.push({
          category,
          suggestion: data[category].awareness.recommendations[0].suggestion
        });
      }
      // console.log( data[category].awareness.recommendations,"recoons")
    }
    return recommendations;
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const truncateText = (text, maxWords) => {
    const words = text.split(' ');
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }
    return text;
  }; 
  
  if (!data) {
    return <div>Loading...</div>;
  }

  const recommendations = extractRecommendations(data);

  const handleItemClick = (category) => {
    navigate("/actionRecomendation", { state: { selectedSkill: category } });
  };

  return (
    <div className="carousel-container">
      <Carousel showThumbs={false} autoPlay showStatus={false} showIndicators={false} infiniteLoop>
        {recommendations.map((item, index) => (
          <div key={index} className="flex flex-col justify-center items-center h-[190px] p-2" onClick={() => handleItemClick(item.category)}>
            <h2 className="text-center text-xl font-fredoka">
              {capitalizeFirstLetter(item.category)}
            </h2>
            <h3 className="text-center mt-2">{truncateText(item.suggestion, 15)}</h3>
            <p className="text-blue-500 mt-2 cursor-pointer">Read more...</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Recommendation;
