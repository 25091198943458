import React, { useState, useEffect } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import student from "../../image/student.png";
import madam from "../../image/madam.png"
const AutoplaySlider = withAutoplay(AwesomeSlider);

 export const SideCarousol = () => {
  const [slider, setSlider] = useState(null);

  useEffect(() => {
    const sliderInstance = (
      <AutoplaySlider
        play={true}
        organicArrows={false}
        bullets={false}
        fillParent={false}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={2000}
      >
        <div  >

            <img  className='w-[500px]' src={student} alt="" />
        </div>
        <div >
            <img className='w-[500px]' src={madam} alt="" />
        </div>
     
      </AutoplaySlider>
    );

    setSlider(sliderInstance);
  }, []);

  return slider;
};


