import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';

const PieChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const options = {
      series: [44, 55, 13, 43],
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: ['Auditory', 'Visual', 'Audio Visual', 'Read/Write'],
      colors: ['#FF8193', '#6FFDCC', '#DDA0DD', '#FFA500'], // Custom colors
      legend: {
        position: 'bottom',
        horizontalAlign: 'center', // Align horizontally in the center
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    // Cleanup function to destroy the chart when component unmounts
    return () => {
      chart.destroy();
    };
  }, []);

  return <div id="chart" ref={chartRef} className="flex justify-center"></div>;
};

export default PieChart;
