// import React, { useState } from 'react';
// import 'tailwindcss/tailwind.css';

// function ColorCircle({ percentage }) {
//     const [message, setMessage] = useState('');

//     const getColor = (percentage) => {
//         if (percentage >= 0 && percentage <= 20) {
//             return 'bg-red-500';
//         } else if (percentage > 20 && percentage <= 40) {
//             return 'bg-blue-500';
//         } else if (percentage > 40 && percentage <= 60) {
//             return 'bg-purple-500';
//         } else if (percentage > 60 && percentage <= 80) {
//             return 'bg-green-500';
//         } else if (percentage > 80 && percentage <= 100) {
//             return 'bg-yellow-500';
//         } else {
//             return 'bg-gray-500'; // Default color if percentage is out of range
//         }
//     };

//     const getMessageOnHover = (percentage) => {
//         if (percentage > 80 && percentage <= 100) {
//             return 'Yellow circle -- Perfect';
//         } else if (percentage > 40 && percentage <= 60) {
//             return 'Purple circle -- Average';
//         } else if (percentage >= 0 && percentage <= 20) {
//             return 'Red circle -- Need to work on it';
//         } else {
//             return '';
//         }
//     };

//     const getMessageOnClick = (percentage) => {
//         if (percentage > 80 && percentage <= 100) {
//             return 'Yellow circle means best';
//         } else if (percentage >= 0 && percentage <= 20) {
//             return 'Red circle means bad';
//         } else {
//             return '';
//         }
//     };

//     const handleMouseEnter = () => {
//         setMessage(getMessageOnHover(percentage));
//     };

//     const handleMouseLeave = () => {
//         setMessage('');
//     };

//     const handleCircleClick = () => {
//         const clickMessage = getMessageOnClick(percentage);
//         if (clickMessage) {
//             alert(clickMessage);
//         }
//     };

//     return (
//         <div className="flex flex-col items-center justify-center ">
//             <div 
//                 id="circle" 
//                 className={`w-4 h-4 rounded-full flex items-center justify-center text-2xl cursor-pointer ${getColor(percentage)}`} 
//                 // onMouseEnter={handleMouseEnter}
//                 // onMouseLeave={handleMouseLeave}
//                 onClick={handleCircleClick}
//             >
//                 {/* {percentage} */}
//             </div>
//             {message && <div className="mt-4 text-lg">{message}</div>}
//         </div>
//     );
// }

// export default ColorCircle;

import { useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';

// const notify = () => toast('Here is your toast.');

const ColorCircle = ({ percentage }) => {
    
 
    const getColor = (percentage) => {
        const colorRanges = [
            { range: [0, 20], color: 'bg-red-500' },
            { range: [20.1, 40], color: 'bg-purple-500' },
            { range: [40.1, 60], color: 'bg-blue-500' },
            { range: [60.1, 80], color: 'bg-green-500' },
            { range: [80.1, 100], color: 'bg-yellow-500' },
        ];

       
        for (let i = 0; i < colorRanges.length; i++) {
            const { range, color } = colorRanges[i];
            if (percentage >= range[0] && percentage <= range[1]) {
                return color;
            }
        }

        return 'bg-gray-500'; // Default color if percentage is out of range
    };

    const colorClass = getColor(percentage);
   
    // const notify = () => {
    //     toast(
         
    //       <div>
    //          <div className="flex items-center mb-2">
    //         <div className="w-4 h-4 bg-red-500 rounded-full mr-2"></div>
    //         <span>Poor performance</span>
    //       </div>
    //       <div className="flex items-center mb-2">
    //         <div className="w-4 h-4 bg-blue-500 rounded-full mr-2"></div>
    //         <span>Weak performance</span>
    //       </div>
    //       <div className="flex items-center mb-2">
    //         <div className="w-4 h-4 bg-purple-500 rounded-full mr-2"></div>
    //         <span>Average performance</span>
    //       </div>
    //       <div className="flex items-center mb-2">
    //         <div className="w-4 h-4 bg-green-500 rounded-full mr-2"></div>
    //         <span>Good performance</span>
    //       </div>
    //       <div className="flex items-center mb-2">
    //         <div className="w-4 h-4 bg-yellow-500 rounded-full mr-2"></div>
    //         <span>Excellent performance</span>
    //       </div>
    //     </div>,
    //       {
    //         duration: 8000,
    //         style: {
    //           background: 'white',
    //           color: '#167D5A',
    //         },
    //       }
    //     );
    //   };
    
    
      return (
        <div
          className={`w-4 h-4 rounded-full flex items-center justify-center ${colorClass}`}
          // onClick={notify}
        >
          {/* <Toaster 
            toastOptions={{
              success: {
                style: {
                  background: '#4caf50',
                  color: '#fff',
                },
              },
              error: {
                style: {
                  background: '#f44336',
                  color: '#fff',
                },
              },
              duration: 10000,
            }}
          /> */}
        </div>
      );
};

export default ColorCircle;
