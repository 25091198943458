import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import SideData from "../SideData/SideData";
import PerformanceNav from "./PerformanceNav";
import { Route, Routes } from "react-router-dom";
import Speed from "../Chart/Speed";
import Radars from "../Chart/Radar";
import Worm from "../Chart/Worm";
import ProgressiveBar from "../Chart/ProgressiveBar";
import Worm2 from "../Chart/Worm2";
import Speed2 from "../Chart/Speed2";
import Gauge from "../Chart/Gaurge";
import boy from "../../reportImages/boy.jpg";
import twokid from "../../reportImages/twokid.jpg";
import ProductivityProfile from "../../reportImages/ProductivityProfile.jpg";
import intelligence from "../../reportImages/intelligence.jpg";
import intelligence2 from "../../image/intelligence2.jpg";
import ProgressiveBar2 from "../Chart/ProgressiveBar2";
import "./Performance.css";
import PieLearning from "../Chart/PieLearning";
import pride from "../../image/Pride.jpg";
import MyPlot from "../Chart/MyPlot";
import RadialBarChart from "../School/Charts/RadialBarChart";
import notification from "../../image/notification.gif";

import PieChart from "../School/Charts/PieChart";
import NextExam from "../NextExamDate/NextExam";
const Performance = () => {
  const [rout, setRout] = useState(1);
  const [isTitleHovered, setIsTitleHovered] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const data = [
    {
      id: 1,
      title: "PRIDE ",
      sub: "Profile",
    },
    {
      id: 2,
      title: "Skills",
      sub: "Performance",
    },
    {
      id: 3,
      title: "Intelligence ",
      sub: "Capacities",
    },
    // {
    //   id: 4,
    //   title: "Learning ",
    //   sub: "Styles",
    // },
    // {
    //   id: 5,
    //   title: "Life ",
    //   sub: "Preferences",
    // },
  ];
  const handleTitleHover = () => {
    setIsTitleHovered(true);
  };

  const handleTitleLeave = () => {
    setIsTitleHovered(false);
  };

  const handleTitleClick = () => {
    setIsTitleHovered(true);
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div className="">
      <div className="flex">
      <div className={`flex h-full gap-5 sticky top-1 ${isSidebarOpen ? 'block' : 'hidden'} md:block`}>
          <Sidebar />
        </div>


        <div className="md:w-[91%] w-[95%] m-auto flex flex-col gap-5">
          <div className="  flex flex-col-reverse justify-start items-center  shadow-lg rounded-lg p-3 gap-4 sticky top-0 z-10 bg-white  md:hidden ">
           
            <form className="flex items-center md:hidden max-w-sm mx-auto">
              <label htmlFor="simple-search" className="sr-only">
                Search
              </label>
              <div className="relative w-full">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-yellow-500 dark:text-yellow-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="simple-search"
                  className="border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-blue-600 dark:placeholder-yellow-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search..."
                  required
                />
              </div>
              <button
                type="submit"
                className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            <div>
              <img className="w-[50px]" src={notification} alt="Notification" />
            </div>
            <button
        data-collapse-toggle="navbar-search"
        type="button"
        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-search"
        aria-expanded={isSidebarOpen}
        onClick={toggleSidebar}
      >
        <span className="sr-only">Open main menu</span>
        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
            </form>
          </div>

        <div className="w-[100%]  md:mt-1   h-[100vh]  ">
          <div className="w-full">
            <PerformanceNav
              onMouseEnter={handleTitleHover}
              onMouseLeave={handleTitleLeave}
              onClick={handleTitleClick}
              isTitleHovered={isTitleHovered}
              data={data}
              rout={rout}
              setRout={setRout}
            />
            
          </div>
          {/* {rout === 1 && (
            <div className="flex flex-col p-5 justify-center items-center h-auto gap-5  shadow-xl ">
              <div className=" flex flex-col gap-2 shadow-lg rounded-lg p-2 " style={{boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                <p className="font-bold flex justify-start">Speed</p>
                <p className="flex  ">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet
                  doloribus enim nam commodi fugit quae omnis aliquid quis
                  quaerat animi molestias, ipsum modi cum, velit qui nesciunt
                  est? Quas, error.
                </p>
              </div>
              <div className="shadow-xl w-[40%] h-[250px] flex flex-col justify-center rounded-lg items-center"style={{boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}} >
                <Gauge />
                <p>Speed </p>
              </div>
              <div className="flex flex-col gap-2 p-2 shadow-lg rounded-lg "style={{boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}} >
                <p className="font-bold flex justify-start">Expert Note :</p>
                <p  >
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Doloremque, consequuntur earum ut tempora veniam repudiandae
                  quo quidem unde id quia rerum, consectetur molestiae itaque
                  provident non odit beatae quasi! Hic?
                </p>
              </div>
            </div>
          )} */}
          {rout === 1 && (
            <div className="flex flex-col p-5 justify-center  items-center h-auto gap-5  shadow-xl ">
              <div className="flex  flex-col md:flex-row gap-8">
                <div
                  className="md:w-[37%] w-[95%] flex flex-col gap-2 shadow-lg rounded-lg p-2"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                  <p className="  underline text-start text-lg inline-block  font-fredoka">
                  PRIDE Profile
                  </p>
                  <p className="flex  font-arial text-left p-2 ">
                    Our everyday needs will require us to use one to five human
                    capabilities simultaneously or in tandem to satisfy the
                    situational demands. Any Individual who uses maximum
                    capabilities in tandem will be preferred the most in any
                    work life environment.
                  </p>
                  <img className="w-[180px] m-auto" src={pride} alt="" />
                </div>

                <div
                  className="rounded-lg shadow-xl md:w-[63%] w-[95%] flex flex-col  justify-center items-center p-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                  <Radars />

                  <p>PRIDE Profile </p>
                </div>
              </div>
              {/* <div
                className=" text-start rounded-lg p-2  w-full"
                style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                <p className="font-bold  text-start  inline-block border-b-2 border-black ">
                  Expert Note:
                </p>

                <p className="font-semibold text-center h-40 justify-center">
                  COMING SOON ...........
                </p>
              </div> */}
            </div>
          )}
          {rout === 2 && (
            <div className="flex flex-col  p-5 justify-center items-center  gap-5  shadow-xl ">
              <div className="flex  flex-col md:flex-row gap-8 ">
                <div
                  className="md:w-[37%] md:h-[450px] w-[95%] h-auto m-auto flex flex-col gap-2 shadow-lg rounded-lg  p-2"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                  <p className="flex justify-start text-lg items-center  font-fredoka underline">
                  Skills Performance
                  </p>
                  <p className="text-left font-arial p-2 ">
                  Whenever we are put into a tight or risky situation, it becomes inevitable to take a decision accurately as well as immediately at the least possible time to come out of the situation safely and happily for which our BPSE is the most influencing factor.
                  </p>
                  <img
                    className="w-[180px] m-auto"
                    src={ProductivityProfile}
                    alt=""
                  />
                </div>

                <div
                  className="rounded-lg  shadow-xl md:w-[63%] w-[95%] m-auto flex flex-col  justify-center items-center p-5"
                  style={{
                    borderRadius: "10px",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}>
                  <Worm2 />
                </div>
              </div>
              <div className="flex w-full justify-center items-center gap-5 rounded-lg">
                {/* <div
                  className=" text-start rounded-lg p-2 w-full"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                  <p className="font-bold  text-start  inline-block border-b-2 border-black ">
                    Expert Note:
                  </p>

                  <p className="font-semibold text-center justify-center">
                    COMING SOON ...........
                  </p>
                </div> */}
              </div>
            </div>
          )}
          {rout === 3 && (
            <div className="flex flex-col p-5 justify-center items-center h-auto gap-5  shadow-xl ">
              <div className="flex  flex-col md:flex-row gap-8">
                <div
                  className="md:w-[37%] w-[95%] flex flex-col gap-2 shadow-lg rounded-lg p-2"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                  <p className=" flex justify-start text-lg font-fredoka underline">
                  Intelligence Capacities
                  </p>
                  <p className="flex  font-arial text-left p-2  ">
                  Intelligence is supposed to be the exhibiting capacity of every individual to do or solve things in a unique & different way which was learnt through the same knowledge taught for everyone at school
                  </p>
                  <img className="w-[180px] m-auto" src={intelligence} alt="" />
                </div>
                <div
               className="rounded-lg shadow-xl md:w-[63%] w-[95%] flex flex-col  justify-center items-center p-5"

                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                 <RadialBarChart/>
                </div>
              </div>

              {/* <div className="  shadow-lg rounded-lg gap-3 p-2 w-full">
                <div
                  className=" text-start rounded-lg p-2 w-full"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                  <p className="font-bold  text-start  inline-block border-b-2 border-black ">
                    Expert Note:
                  </p>

                  <p className="font-semibold text-center justify-center">
                    COMING SOON ...........
                  </p>
                </div>
              </div> */}
            </div>
          )}
          {rout === 4 && (
            <div className="flex flex-col p-5 justify-center items-center h-auto gap-5  shadow-xl ">
              <div className="flex  flex-col md:flex-row gap-8">
                <div
                  className="md:w-[37%] w-[95%] flex flex-col gap-2 shadow-lg rounded-lg p-2"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                  <p className="font-bold flex justify-start w-[120px]  border-b-2 border-black">
                    Learning Styles
                  </p>
                  <p className="flex  text-left p-2 italic">
                  Every Individual prefers a certain way/method to take any new information through their sensory pathways. This input method gives them the precision of the information with high efficiency. For example we come across some people who read the subtitles to understand the story better even when the audio is available while watching a movie, this is due to the convenience of authenticity of information.
                  </p>
                  <img
                    className="w-[180px] m-auto"
                    src={intelligence2}
                    alt=""
                  />
                </div>
                <div
             className="rounded-lg shadow-xl md:w-[63%] w-[95%] flex flex-col  justify-center items-center p-5"

                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                  {/* <PieLearning /> */}
                  <PieChart/>
                </div>
              </div>

              <div className=" flex flex-col shadow-lg rounded-lg gap-3 p-2 w-full">
                <div
                  className=" text-start rounded-lg p-2 w-full"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                  <p className="font-bold  text-start  inline-block border-b-2 border-black ">
                    Expert Note:
                  </p>

                  <p className="font-semibold text-center justify-center">
                    COMING SOON ...........
                  </p>
                </div>
              </div>
            </div>
          )}
          {rout === 5 && (
            <div className="flex flex-col p-5 justify-center items-center h-auto gap-5  shadow-xl ">
              <div className="flex  flex-col md:flex-row gap-8">
                <div
                  className="md:w-[50%] w-[95%] flex flex-col gap-2 shadow-lg rounded-lg p-2"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                  <p className="font-bold flex justify-start w-[125px]  border-b-2 border-black">
                    Life Preferences
                  </p>
                  <p className="flex  w-full text-left p-2 italic">
                  COMING SOON ...........
                  </p>
                  <img
                    className="w-[180px] m-auto"
                    src={intelligence2}
                    alt=""
                  />
                </div>
                {/* <div
                  className=" shadow-lg rounded-lg  w-[63%]"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                  <MyPlot/> 
                </div> */}

                <div
        className="rounded-lg shadow-xl md:w-[63%] w-[95%] flex flex-col  justify-center items-center p-5"

                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                  <p className="font-semibold text-center">
                    COMING SOON ...........
                  </p>
                </div>
              </div>

              <div className=" flex flex-col shadow-lg rounded-lg gap-3 p-2 w-full ">
                <div
                  className=" text-start rounded-lg p-2 w-full"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                  <p className="font-bold  text-start  inline-block border-b-2 border-black ">
                    Expert Note:
                  </p>

                  <p className="font-semibold text-center justify-center">
                    COMING SOON ...........
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        </div>
        {/* <div className="w-[35%] mt-5  flex sticky top-0 h-full ">
          <SideData />
        </div> */}
      </div>
      {isSidebarOpen && (
        <div className="fixed inset-0 z-50 flex md:hidden">
          <div className=" bg-white shadow-lg">
            <Sidebar />
          </div>
          <div className="flex-grow" onClick={toggleSidebar}></div>
        </div>
      )}
    </div>
  );
};

export default Performance;
