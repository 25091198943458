import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Worm2 = ({
  attentionIndex,
  memoryIndex,
  criticalIndex,
  creativeIndex,
  mindsetIndex,
  expressionIndex,
  attitudeIndex,
  communicationIndex,
  collaborationIndex,
  leadershipIndex,
}) => {
  const options = {
    chart: {
      zoomType: 'xy',
    },
    title: {
      text: 'Skill Distribution',
    },
    xAxis: {
      categories: [
        'Attention',
        'Memory',
        'Critical Thinking',
        'Creative Thinking',
        'Mindset',
        'Attitude',
        'Expression',
        'Communication',
        'Collaboration',
        'Leadership',
      ],
    },
    yAxis: {
      tickPositions: [0, 2, 4, 6, 8, 10], // Define custom tick positions
      title: {
        text: '', // Remove the y-axis title
      },
      labels: {
        format: '{value}', // Format y-axis labels as numbers
        style: {
          color: '#7cb5ec',
        },
      },
      opposite: true,
    },
    tooltip: {
      shared: true,
    },
    credits: {
      enabled: false, // This will remove the Highcharts.com tag
    },
    series: [{
      type: 'spline',
      name: '', // Remove the series name to hide it in the tooltip
      data: [
        attentionIndex || 0,
        memoryIndex || 0,
        criticalIndex || 0,
        creativeIndex || 0,
        mindsetIndex || 0,
        attitudeIndex || 0,
        expressionIndex || 0,
        communicationIndex || 0,
        collaborationIndex || 0,
        leadershipIndex || 0,
      ],
      tooltip: {
        pointFormat: '<span style="font-weight: bold; color: {series.color}">{point.y:.1f}</b>',
      },
    }],
  };

  return (
    <div className='w-full max-w-4xl mx-auto md:p-2'>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default Worm2;
