// import React, { useEffect, useState } from 'react';
// import Topper from '../Topper/Topper';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchSchoolScores } from '../../../Redux/JuniorAction';
// import { fetchSeniorScores } from '../../../Redux/SeniorAction';
// import { fetchYouthScores } from '../../../Redux/YouthAction';



// const TopRanker = () => {
//   const dispatch = useDispatch();

//   const {
//     loading: schoolScoresLoading,
//     schoolScores,
//     error: schoolScoresError,
//   } = useSelector((state) => state.schoolScores);
//   const {
//     loading: seniorScoresLoading,
//     seniorScores,
//     error: seniorScoresError,
//   } = useSelector((state) => state.seniorScores);
//   const {
//     loading: youthScoresLoading,
//     youthScores,
//     error: youthScoresError,
//   } = useSelector((state) => state.youthScores);

//   useEffect(() => {
//     dispatch(fetchSchoolScores());
//     dispatch(fetchSeniorScores());
//     dispatch(fetchYouthScores());
//   }, [dispatch]);

//   useEffect(() => {
//     if (
//       !schoolScoresLoading &&
//       !seniorScoresLoading &&
//       !youthScoresLoading &&
//       !schoolScoresError &&
//       !seniorScoresError &&
//       !youthScoresError
//     ) {
//       // console.log(schoolScores, "schoolScores");
//       // console.log(seniorScores, "seniorScores");
//       // console.log(youthScores, "youthScores");
//     }
//   }, [
//     schoolScoresLoading,
//     seniorScoresLoading,
//     youthScoresLoading,
//     schoolScores,
//     seniorScores,
//     youthScores,
//     schoolScoresError,
//     seniorScoresError,
//     youthScoresError,
//   ]);

//   const allStudentScores = [
//     ...(schoolScores?.studentsScores || []),
//     ...(seniorScores?.studentsScores || []),
//     ...(youthScores?.studentsScores || []),
//   ];

//   const displayData = allStudentScores.sort((a,b)=>b.convertedMpiScore-a.convertedMpiScore).slice(0, 7);
//   //  console.log(displayData,"displayData")


//   // const displayData = data.slice(0, 3);

  

//   return (
//     <div className="overflow-x-auto rounded-lg border w-full max-w-4xl mx-auto my-4 shadow-lg">
//       <p className="text-purple-500 text-lg font-semibold text-center my-2">Top Performances</p>
      
//       <table className="min-w-full bg-white border-collapse border border-gray-200">
//         <thead className="bg-gray-100 sticky top-0">
//           <tr>
//             <th 
//               className="py-2 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-600" 
//               title="Student Name"
//             >
//               Name
//             </th>
//             <th 
//               className="py-2 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-600" 
//               title="Class or Standard"
//             >
//               Class
//             </th>
//             <th 
//               className="py-2 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-600" 
//               title="MPI Score"
//             >
//               MPI Score
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {displayData.map((row, index) => (
//             <tr 
//               key={row.id} 
//               className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-purple-100 transition-colors duration-150`}
//             >
//               <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{row.name}</td>
//               <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{row.standard}</td>
//               <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{row.convertedMpiScore}</td>
              
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default TopRanker;





























// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const TopRanker = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const token = localStorage.getItem('token'); // Retrieve token from localStorage

//         if (!token) {
//           throw new Error('No token found');
//         }

//         const response = await axios.get('https://test.xqminds.com/api/schoolData/school-dashboard-average', {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         // Accessing the scores array from the response data
//         setData(response.data.scores || []);
//         setLoading(false);
//       } catch (err) {
//         setError(err.message);
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   if (loading) {
//     return <p>Loading...</p>;
//   }

//   if (error) {
//     return <p>Error: {error}</p>;
//   }

//   // Convert `convertedMpiScore` to number and sort
//   const displayData = data
//     .map((item) => ({
//       ...item,
//       convertedMpiScore: parseFloat(item.convertedMpiScore),
//     }))
//     .sort((a, b) => b.convertedMpiScore - a.convertedMpiScore)
//     .slice(0, 7);

//   return (
//     <div className="overflow-x-auto rounded-lg border w-full max-w-4xl mx-auto my-4 shadow-lg">
//       <p className="text-purple-500 text-lg font-semibold text-center my-2">Top Performances</p>
//       <table className="min-w-full bg-white border-collapse border border-gray-200">
//         <thead className="bg-gray-100 sticky top-0">
//           <tr>
//             <th className="py-2 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-600" title="Student Name">
//               Name
//             </th>
//             <th className="py-2 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-600" title="Class or Standard">
//               Class
//             </th>
//             <th className="py-2 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-600" title="MPI Score">
//               MPI Score
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {displayData.map((row, index) => (
//             <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-purple-100 transition-colors duration-150`}>
//               <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{row.name}</td>
//               <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{row.standard}</td>
//               <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{row.convertedMpiScore.toFixed(2)}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default TopRanker;






















import React from 'react';

const TopRanker = ({ data }) => {
  // Convert `convertedMpiScore` to number and sort
  const displayData = data
    .map((item) => ({
      ...item,
      convertedMpiScore: parseFloat(item.convertedMpiScore),
    }))
    .sort((a, b) => b.convertedMpiScore - a.convertedMpiScore)
    .slice(0, 7);

  return (
    <div className="overflow-x-auto rounded-lg border w-full max-w-4xl mx-auto my-4 shadow-lg">
      <p className=" text-lg  text-center my-2 font-fredoka font-medium ">Top Performances</p>
      <table className="min-w-full bg-white border-collapse border border-gray-200">
        <thead className="bg-gray-100 sticky top-0">
          <tr>
            <th className="py-2 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-600" title="Student Name">
              Name
            </th>
            <th className="py-2 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-600" title="Class or Standard">
              Class
            </th>
            <th className="py-2 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-600" title="MPI Score">
              MPI Score
            </th>
          </tr>
        </thead>
        <tbody>
          {displayData.map((row, index) => (
            <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-purple-100 transition-colors duration-150`}>
              <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{row.name}</td>
              <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{row.standard}</td>
              <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{row.convertedMpiScore.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopRanker;
