import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Quiz from "../image/Quiz.png";
import video from "../image/video.mp4";
import LineChart from "./Circular/LineChart";
import TestBackground from "../../src/image/TestBackground.jpg";
import QuestionBackground from "../../src/image/QuestionBackground.png";
import OptionBackground from "../../src/image/OptionBackground.png";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import prev from "../image/prev.gif";
import next from "../image/next.gif";
// import Background from "../../src/image/background.png";
import Background from "../../src/image/backgroundagain.png";
import testbackground1 from "../../src/image/testbackground1.png";
import testbackground2 from "../../src/image/testbackground2.png";
import testbackground3 from "../../src/image/testbackground3.png";
import testbackground4 from "../../src/image/testbackground4.png";
import testbackground5 from "../../src/image/testbackground5.png";
import testbackground6 from "../../src/image/testbackground6.png";
import testbackground7 from "../../src/image/testbackground7.png";
import left from "../../src/image/left.png";
import right from "../../src/image/Right.png";
import "../App.css";
import TextRevealCardPreview from "./Break/Break";

const abc = ["Option 1", "Option 2", "Option 3", "Option 4"];
const background = [
  testbackground1,
  testbackground2,
  testbackground3,
  testbackground4,
  testbackground5,
  testbackground6,
  testbackground7,
];
const Test3 = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showQuestion, setShowQuestion] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const [questionTimer, setQuestionTimer] = useState(0);
  const [answerTimer, setAnswerTimer] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [testData, setTestData] = useState([]);
  const [userSelected, setUserSelected] = useState(false);
  const questionInterval = useRef(null);
  const [percentage, setPercentage] = useState(0);
  const [media, setShowMedia] = useState(false);
  const [discription, setDiscription] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const totalQuestions = 45;
  const navigate = useNavigate();
  let count = 0;

  useEffect(() => {

    fetchDataFromBackend();
      const savedProgress = JSON.parse(localStorage.getItem('test2Progress'));
    if (savedProgress) {
      setTestData(savedProgress.testData || []);
      setCurrentQuestion(savedProgress.currentQuestion || 0);
      setQuestionTimer(savedProgress.questionTimer || 0);
      setAnswerTimer(savedProgress.answerTimer || 0);
      setSelectedOption(savedProgress.selectedOption || null);
      setPercentage(savedProgress.percentage || 0);
    }
  }, []);


  const fetchDataFromBackend = async () => {
    try {
      const response = await fetch(
        "https://test.xqminds.com/api/questionBank/questions?phase=2",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      // Sort the data according to the sort-order
      const sortedData = data.sort((a, b) => {
        // Assuming sort-order is a property of each question object
        return a.sort_order - b.sort_order; // Change this based on your sort-order property
      });
      setQuestions(sortedData);
      // console.log(sortedData.length, "questionssss");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // questions.sort();
    // console.log(questions.length, "questionssss");

  useEffect(() => {
    // Start question timer
    if (questionInterval.current) {
      clearInterval(questionInterval.current);
    }
    questionInterval.current = setInterval(() => {
      setQuestionTimer((prevTime) => {
        if (prevTime === 100000) {
          handleShowAnswer();
          return prevTime;
        } else {
          return prevTime + 1;
        }
      });
    }, 1000);

    // Clean up function
    return () => clearInterval(questionInterval.current);
  }, [currentQuestion]);

  useEffect(() => {
    // Start answer timer
    if (showOptions) {
      //console.log(answerTimer,"answertimer",answerTimerCount,"answerTimerCount")
      let timer = answerTimer || 0;

      const answerInterval = setInterval(() => {
        timer++;
        setAnswerTimer((prev) => {
          //console.log(prev,"prev", answerTimer,"ans")
          return timer;
        });
        if (timer === 11000) {
          clearInterval(answerInterval);
          handleNextQuestion(timer);
        }
      }, 1000);

      // Clean up function
      return () => clearInterval(answerInterval);
    }
  }, [showOptions, selectedOption]); // Include selectedOption in the dependency array

  useEffect(() => {
    // Calculate the percentage when currentQuestion changes
    const calculatedPercentage = ((currentQuestion + 1) / totalQuestions) * 100;
    setPercentage(calculatedPercentage.toFixed(2));
  }, [currentQuestion]);
  // console.log(answerTimer,"answertimermrmrm")
  const handleNextQuestion = (timer) => {
    // Store data for current question
    const newData = {
      sort_order: questions[currentQuestion].sort_order,
      question: questions[currentQuestion].question_body,
      selectedOption,
      questionReadTime: questionTimer,
      optionReadTime: answerTimer,
    };
    // console.log(newData, "All data that user have done");
  
    setTestData((prevData) => {
      const updatedData = [...prevData, newData];
      localStorage.setItem("test2Progress", JSON.stringify({
        testData: updatedData,
        currentQuestion: currentQuestion + 1,
        questionTimer: 0,
        answerTimer: 0,
        selectedOption: null,
        percentage: ((currentQuestion + 2) / totalQuestions) * 100,
      }));

  
      // If it's the last question, call handleFinishTest with the latest data
      if (currentQuestion + 1 >= questions.length) {
        setTimeout(() => {
          handleFinishTest(updatedData);
        }, 100); // Small timeout to ensure data is saved before navigating
      }
  
      return updatedData;
    });
  
    // Reset timers and options
    let percentage = (newData.sort_order / questions.length) * 100;
    setPercentage(percentage.toFixed(2));
    setQuestionTimer(0);
    setAnswerTimer(0);
    setUserSelected(false);
    setSelectedOption(null);
  
    if (currentQuestion < totalQuestions - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setShowQuestion(true);
      setShowOptions(false);
      setShowMedia(false);
    }
    
    else {
      // If it was the last question, the setTestData callback will handle the submission
    }
  };



  // const sendCombinedData = async () => {
  //   const test1Data = JSON.parse(localStorage.getItem('test1Progress')).testData || [];
  //   console.log(testData," testData of test1")
  //   const combinedData = [...test1Data, ...testData];
    
  //   try {
  //     const response = await fetch("https://test.xqminds.com/api/quiz", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //       body: JSON.stringify({ data: combinedData }),
  //     });
      
  //     if (!response.ok) {
  //       throw new Error("Failed to send data");
  //     }

  //     const result = await response.json();
  //     console.log(result,"result combine")
  //     navigate("/result", { state: { result } });
  //   } catch (error) {
  //     console.error("Error sending data:", error);
  //   }
  // };


  // console.log(questions[currentQuestion].video, "currentQuestiohjhjhj");
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setUserSelected(true);
  };

  const handleSubmitAnswer = () => {
    // Move to the next question if an option is selected
    // if (selectedOption == null||selectedOption!==null) {
    //   handleNextQuestion();
    // }
    if (selectedOption !== null) {
      handleNextQuestion();
    }
  };

  //  console.log(selectedOption,"selected option")
  const handleShowAnswer = () => {
    setShowQuestion(false);
    setShowOptions(true);
    setAnswerTimer(0);
    setShowMedia(false);
    clearInterval(questionInterval.current);
  };
  const handleShowVideo = () => {
    setShowQuestion(false);
    setDiscription(true);
    setShowMedia(true);
    setShowOptions(false);
    // setAudio(false);
    // setImage(false);
  };
  const handleShowImage = () => {
    setShowQuestion(false);
    setShowOptions(false);
    setDiscription(true);
    setShowMedia(true);
  };

  const handleShowAudio = () => {
    setShowQuestion(false);
    setDiscription(true);
    setShowMedia(true);
  };

  // const navigate = useNavigate();
  // console.log(currentQuestion, questions.length, "question length");
  window.onload = function() {
    disableBackButton();
};

function disableBackButton() {
    // Add a new history entry so the user can't go back to the previous page
    window.history.pushState(null, null, window.location.pathname);

    window.onpopstate = function() {
        // When the user tries to go back, push the same state again
        window.history.pushState(null, null, window.location.pathname);
    };
}


  const handleFinishTest = (updatedData) => {
      //  const test1Data = JSON.parse(localStorage.getItem('test1Progress')).testData || [];
      //  console.log(test1Data,"test1Data")
    // console.log(testData," testData of test1")
    // const combinedData = [testData];
    // console.log(combinedData," combinedData of test12")
    // console.log("Final Data:", updatedData);
    if (!Array.isArray(updatedData)) {
      console.error("updatedData is not an array");
      return;
    }
    
    const payload = {
      answers: updatedData.map((data) => ({
        sort_order: data.sort_order,
        question: data.question,
        selectedOption: data.selectedOption,
        questionReadTime: data.questionReadTime,
        optionReadTime: data.optionReadTime,
      })),
    };
    // const payload = {           
    //   answers: updatedData,
    // };
    // handleNextQuestion();
    // navigate("/result");
     console.log(payload, "payloaaaaaaaaads"); 

    fetch("https://test.xqminds.com/api/quiz/update-latest-quiz", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(testData, "all submit amsweeeeerrr");
        alert("Your Test is Submitted!");
         // localStorage.removeItem('questionProgress');
         localStorage.removeItem('test2Progress');
          navigate("/result");
          disableBackButton()
        // localStorage.setItem("token", res.token);
        //navigate(location.state , {replace:true} )
        //console.log(location.state)
      })

      .catch((err) => {
        console.log(err);
        alert("Something went wrong. Please try again.");
      });
  };










  // const handleFinishTest = () => {
    
  //   const payload = {
  //     answers: testData,
  //   };
  //   // handleNextQuestion();
  //   // navigate("/result");
  //   // console.log(payload, "payloaaaaaaaaads");

  //   fetch("https://test.xqminds.com/api/quiz", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //     body: JSON.stringify(payload),
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       // console.log(testData, "all submit amsweeeeerrrrrrrrrrrrrrrrrrrrrrrrr");
  //       alert("Your Test is Submitted!");
  //       localStorage.removeItem('questionProgress');
  //       navigate("/result");
  //       // localStorage.setItem("token", res.token);
  //       //navigate(location.state , {replace:true} )
  //       //console.log(location.state)
  //     })

  //     .catch((err) => {
  //       console.log(err);
  //       alert("Something went wrong. Please try again.");
  //     });
  // };
  const isImage = questions?.options?.startsWith("http");
  const splitQuestionBody = (questionBody) => {
    return questionBody.split("\n");
  };
  const questionBodyLines = splitQuestionBody(
    questions[currentQuestion]?.question_body || ""
  );





  return (
    <div
      className="   mx-auto  rounded-lg shadow-md "
      // style={{ backgroundImage: `url(${Background})` }}
    >
      <img
        className="md:h-[100vh] xl:h-[100vh] lg:h-[100vh]   h-[100vh]  w-[100%]"
        src={background[currentQuestion % background.length]}
        alt=""
      />

      <div className="absolute  top-0 w-[100%]   ">
        <div className="w-[100%]  sm:mt-20 sm:flex-row lg:mt-20 lg:flex-row  md:mt-20 md:flex-row mt-16   flex flex-col items-center justify-between  ">
          <div
            className="w-auto lg:w-[10%] md:w-[10%]  sm:w-[10%] xl:w-[10%] md:mt-  flex justify-start md:ml-10 lg:ml-20 p-2 rounded-2xl "
            // style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
          >
            <LineChart percentage={percentage} />
          </div>
          {(showQuestion || media) && (
            <div
              className="flex w-[85%]  md:ml-10 lg:ml-16 xl:ml-28 sm:w-[60%] md:w-[55%] lg:w-[55%]  xl:w-[55%]  justify-left gap-4 md:mt- m-auto items-center rounded-2xl "
              style={{
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}>
             
              <p className="bg-yellow-500 p-4 font-fredoka rounded-l-2xl">Instruction</p>
              <p className="text-gray-800 text-[13px]  sm:text-[13px] md:text-[17px]  font-medium font-fredoka flex justify-start items-start">
                {questions[currentQuestion]?.instruction}
              </p>
            </div>
          )}
          <div>
            {currentQuestion + 1 === questions.length ? (
              <button
                onClick={handleFinishTest}
                className="flex items-center  md:mt-  mr-20 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                <span>Submit</span>
                <svg
                  className="w-5 h-5 ml-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                </svg>
              </button>
           ) : (
              <div
                className="flex  items-center md:mt-2 sm:ml font-fredoka   md:mr-2 xl:mr-20 lg:mr-2 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer"
                onClick={() => setShowModal(true)} // Assuming setShowModal is a function to show a modal or similar
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}>
                <p> Take a Break</p>
              </div>
            )}
          </div>
        </div>
        <div className="">
          <TextRevealCardPreview
            isvisible={showModal}
            onClose={() => setShowModal(false)}
          />
        </div>
        {/* <div
          className="w-[51%] flex justify-center m-auto p-2 rounded-2xl"
          style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
          <LineChart percentage={percentage} />
        </div> */}
        <div className="flex flex-col justify-center items-center   ">
          {showQuestion && (
            <div className="md:w-[90%]  lg:w-[85%] sm:h-[200px]  m-auto rounded-xl  ">
              {/* {showQuestion && (
                <div className=" w-[70%] m-auto bg-gradient-to-r from-purple-400 to-blue-400 rounded-xl shadow-2xl py-2">
                  <div className="container mx-auto px-4">
                    <div className="flex justify-around items-center">
                      <p className="text-white text-2xl font-semibold">
                        Mock Test
                      </p>
                      <div className="w-48">
                        <LineChart percentage={percentage} />
                      </div>
                    </div>
                  </div>
                </div>
              )} */}

              {/* <div
                className="flex w-[60%] justify-center mt-5 m-auto items-center rounded-2xl"
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}>
                <p className="bg-yellow-500 p-4 rounded-l-2xl">Instruction</p>
                <p className="text-gray-800  font-semibold m-auto text-center">
                  {questions[currentQuestion]?.instruction}
                </p>
              </div> */}

              <div
                className="mt-32  flex justify-center  items-center -screen bg-cover bg-center  "
                // style={{
                //   backgroundImage: `url(${QuestionBackground})`,
                //   backgroundSize: "100% auto",
                // }}
              >
                {showQuestion && (
                  <div
                    className="mx-auto  flex flex-col  md:w-[95%] lg:w-[80%] md:-mt-28 lg:-mt-16 sm:-mt-28 -mt-32 h-[48vh] rounded-lg  p-4  lg:h-[50vh]    md:h-[60vh] sm:h-[55vh]   "
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}>
                    <div className="flex justify-start  ">
                      <h2 className="lg:text-xl  sm:text-lg font-semibold   text-black  ">
                        {`Question ${currentQuestion + 1}`}
                      </h2>
                    </div>
                    <div className="flex w-[100%] lg:mt-5 justify-center m-auto items-center ">
                      {/* <p className=" text-[20px] flex justify-center items-center text-gray-500 font-semibold italic  m-auto text-center  ">
                        {questions[currentQuestion]?.question_body}
                      </p> */}
                      <div className="flex flex-col md:text-[16px] sm:text-[15px] text-[12px] lg:text-[17px] font-medium  font-arial m-auto justify-normal">

                        {questionBodyLines.map((line, index) => (
                          <p key={index} className="lg:mb-2 ">
                            {line}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="">
                {showQuestion && (
                  <div>
                    {questions[currentQuestion]?.video ? (
                      <div className=" flex w-[50%] m-auto  justify-evenly md:mt-4 lg:mt-8   ">
                        {/* <div
                          className="flex items-center bg-white gap-4 text-blue px-4 py-2 hover:border hover:border-blue-500 focus:outline-none focus:ring-2   focus:ring-blue-500 focus:ring-offset-2  rounded-l-3xl rounded-r-3xl cursor-pointer"
                          onClick={() => setShowModal(true)}
                          style={{
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          }}>
                          <p> Take a Break</p>
                        </div> */}
                        <div className="m-auto ">
                          <button
                            onClick={handleShowVideo}
                            className="bg-yellow-500  text-white px-4 py-2 rounded-md  hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300 ease-in-out">
                            See Video
                          </button>
                        </div>
                        {/* <div
                          className="flex items-center bg-white gap-4 text-blue px-4 py-2 hover:border hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 rounded-l-3xl rounded-r-3xl"
                          style={{
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          }}
                          onClick={() => handleNextQuestion()}>
                          <p> Next</p>
                          <div className="rounded-full  bg-yellow-500 flex  justify-center items-center ">
                            <img
                              className="w-[30px] h-[30px]"
                              src={next}
                              alt=""
                            />
                          </div>
                        </div> */}
                      </div>
                    ) : questions[currentQuestion]?.image ? (
                      <div className="flex w-[50%] m-auto  justify-evenly md:mt-4 lg:mt-8  ">
                        {/* <div
                          className="flex items-center bg-white gap-4 text-blue px-4 py-2 hover:border hover:border-blue-500 focus:outline-none focus:ring-2   focus:ring-blue-500 focus:ring-offset-2  rounded-l-3xl rounded-r-3xl cursor-pointer"
                          onClick={() => setShowModal(true)}
                          style={{
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          }}>
                          <p> Take a Break</p>
                        </div> */}
                        <div className="m-auto ">
                          <button
                            onClick={handleShowImage}
                            className="bg-yellow-500 text-white px-4 py-2 rounded-md  hover:border hover:border-blue-500 focus:outline-none focus:ring-2   focus:ring-blue-500 focus:ring-offset-2  transition duration-300 ease-in-out">
                            See Image
                          </button>
                        </div>
                        {/* <div
                          className="flex items-center bg-white gap-4 text-blue px-4 py-2 hover:border hover:border-blue-500 focus:outline-none focus:ring-2   focus:ring-blue-500 focus:ring-offset-2 rounded-l-3xl rounded-r-3xl"
                          style={{
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          }}
                          onClick={() => handleNextQuestion()}>
                          <p> Next</p>
                          <div className="rounded-full  bg-yellow-500 flex  justify-center items-center ">
                            <img
                              className="w-[30px] h-[30px]"
                              src={next}
                              alt=""
                            />
                          </div>
                        </div> */}
                      </div>
                    ) : questions[currentQuestion]?.audio ? (
                      <div className="flex w-[50%] m-auto gap-8 justify-evenly md:mt-4 lg:mt-8 ">
                        {/* <div
                          className="flex items-center bg-white gap-4 text-blue px-4 py-2 hover:border hover:border-blue-500 focus:outline-none focus:ring-2   focus:ring-blue-500 focus:ring-offset-2  rounded-l-3xl rounded-r-3xl cursor-pointer"
                          onClick={() => setShowModal(true)}
                          style={{
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          }}>
                          <p> Take a Break</p>
                        </div> */}

                        <div className="text-gray-800  m-auto">
                          <button
                            onClick={handleShowAudio}
                            className="bg-yellow-500 text-white px-4 py-2 rounded-md  hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300 ease-in-out">
                            Listen Audio
                          </button>
                        </div>
{/* 
                        <div
                          className="flex items-center bg-white gap-4 text-blue px-4 py-2  hover:border hover:border-blue-500 focus:outline-none focus:ring-2   focus:ring-blue-500 focus:ring-offset-2  rounded-l-3xl rounded-r-3xl"
                          style={{
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          }}
                          onClick={() => handleNextQuestion()}>
                          <p> Next</p>
                          <div className="rounded-full  bg-yellow-500 flex  justify-center items-center ">
                            <img
                              className="w-[30px] h-[30px]"
                              src={next}
                              alt=""
                            />
                          </div>
                        </div> */}
                      </div>
                    ) : (
                      <div className="flex  mt-4   w-[48%] m-auto  justify-evenly  ">
                        {/* <div
                          className="flex items-center bg-white gap-4 text-blue px-4 py-2 hover:border hover:border-blue-500 focus:outline-none focus:ring-2   focus:ring-blue-500 focus:ring-offset-2  rounded-l-3xl rounded-r-3xl cursor-pointer"
                          onClick={() => setShowModal(true)}
                          style={{
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          }}>
                          <p> Take a Break</p>
                        </div> */}

                        <div className="text-gray-800   lg:mt-8 m-auto text-center ">
                          <button
                            onClick={handleShowAnswer}
                            className="bg-yellow-500 text-white px-4 py-2 rounded-md  hover:border hover:border-blue-500 focus:outline-none focus:ring-2     focus:ring-blue-500 focus:ring-offset-2 transition duration-300 ease-in-out">
                            See Options
                          </button>
                        </div>

                        {/* <button
                          className="flex cursor-pointer items-center bg-white gap-4 text-blue px-4 py-2  hover:border hover:border-blue-500 focus:outline-none focus:ring-2   focus:ring-blue-500 focus:ring-offset-2  rounded-l-3xl rounded-r-3xl transition duration-300 ease-in-out"
                          style={{
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          }}
                          onClick={() => handleNextQuestion()}
                          disabled={
                            currentQuestion + 1 !== questions.length
                              ? false
                              : true
                          }>
                          <button> Next </button>
                          <div className="rounded-full  bg-yellow-500 flex  justify-center items-center ">
                            <img
                              className="w-[30px] h-[30px]"
                              src={next}
                              alt=""
                            />
                          </div>
                        </button> */}
                      </div>
                    )}
                  </div>   
                )}
              </div>
            </div>
          )}
        </div>

        {media && (
          <div className="flex w-[85%] m-auto flex-col  gap-4 justify-center items-center ">
            {/* <div
              className="flex w-[60%] justify-center mt-8 m-auto items-center rounded-2xl "
              style={{
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}>
              <p className="bg-yellow-500 p-4 rounded-l-2xl">Instruction</p>
              <p className="text-gray-800  font-semibold m-auto text-center">
                {questions[currentQuestion]?.instruction}
              </p>
            </div> */}

            {questions[currentQuestion]?.video ? (
              <div className="flex flex-col ">
                <div
                  className="lg:w-[65%] md:w-[75%] md:h-[50vh] lg:h-[45vh] h-[50vh]  flex flex-col gap-4 m-auto md:mt-2 lg:mt-10  "
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}>
                  <video
                    // width=""
                    // height=""
                    className="lg:w-[1000px] h-[45vh]   m-auto sm:w-[900px]  md:w-[1000px]  "
                    autoPlay
                    controls
                    // muted
                    // loop
                    // playsInline
                    frameborder="0"
                    allowfullscreen="true"
                    mozallowfullscreen="true"
                    webkitallowfullscreen="true">
                    <source
                      src={questions[currentQuestion]?.video}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className="flex w-[50%] m-auto gap-8 justify-evenly mt-6 lg:mt-8 sm:mt-24 ">
                  {/* <div
                    className="flex items-center bg-white gap-4 text-blue px-4 py-2 hover:border hover:border-blue-500 focus:outline-none focus:ring-2   focus:ring-blue-500 focus:ring-offset-2  rounded-l-3xl rounded-r-3xl cursor-pointer"
                    // onClick={()=>setShowModal(true)}
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}>
                    <div className="rounded-full  bg-yellow-500 flex  justify-center items-center ">
                      <img className="w-[30px] h-[30px]" src={left} alt="" />
                    </div>
                    <p>Prev</p>
                  </div> */}

                  <div className="text-gray-800 lg:mt-12  text-center ">
                    <button
                      onClick={handleShowAnswer}
                      className="bg-yellow-500 text-white px-4 py-2 rounded-md   hover:border hover:border-blue-500   focus:outline-none focus:ring-2   focus:ring-blue-500 focus:ring-offset-2   transition duration-300 ease-in-out">
                      See Options
                    </button>
                  </div>
                  {/* <div
                    className="flex cursor-pointer items-center bg-white gap-4 text-blue px-4 py-2 hover:border hover:border-blue-500 focus:outline-none focus:ring-2    focus:ring-blue-500 focus:ring-offset-2 rounded-l-3xl rounded-r-3xl"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                    onClick={() => handleNextQuestion()}>
                    <p> Next</p>
                    <div className="rounded-full  bg-yellow-500 flex  justify-center items-center ">
                      <img className="w-[30px] h-[30px]" src={next} alt="" />
                    </div>
                  </div> */}
                </div>
              </div>
            ) : questions[currentQuestion]?.image ? (
              <div className="flex flex-col ">
                <div
                  className="lg:w-[800px] border border-black xl:mt-10  lg:mt-10 md:mt-10 sm:mt-7 mt-5 p-2  sm:h-[60vh] md:h-[55vh] h-[50vh] lg:h-[50vh] m-auto flex justify-center items-center gap-4"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}>
                  <img
                    src={questions[currentQuestion]?.image}
                    alt="Question Image"
                    className="w-full h-[48vh] object-contain"
                  />
                </div>
                <div className="flex gap-16 justify-evenly lg:mt-8 ">
                  {/* <div
                    className="flex items-center bg-white gap-4 text-blue px-4 py-2 hover:border hover:border-blue-500 focus:outline-none focus:ring-2   focus:ring-blue-500 focus:ring-offset-2  rounded-l-3xl rounded-r-3xl cursor-pointer"
                    onClick={() => setShowModal(false)}
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}>
                    <div className="rounded-full  bg-yellow-500 flex  justify-center items-center ">
                      <img className="w-[30px] h-[30px]" src={left} alt="" />
                    </div>
                    <p> Prev</p>
                  </div> */}

                  <p className="text-gray-800 md:mt-3 lg:mt-5  m-auto text-center">
                    <div className="text-gray-800  text-center ">
                      <button
                        onClick={handleShowAnswer}
                        className="bg-yellow-500 text-white px-4 py-2 rounded-md  hover:border hover:border-blue-500 focus:outline-none focus:ring-2  transition duration-300 ease-in-out">
                        See Options
                      </button>
                    </div>
                  </p>
                  {/* <div
                    className="flex cursor-pointer items-center bg-white gap-4 text-blue px-4 py-2  hover:border hover:border-blue-500  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 rounded-l-3xl rounded-r-3xl"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                    onClick={() => handleNextQuestion()}>
                    <p> Next</p>
                    <div className="rounded-full  bg-yellow-500 flex  justify-center items-center ">
                      <img className="w-[30px] h-[30px]" src={next} alt="" />
                    </div>
                  </div> */}
                </div>
              </div>
            ) : questions[currentQuestion]?.audio ? (
              <div className="flex flex-col">
                <div
                  className="lg:w-[800px] xl:w-[800px] md:w-[550px] sm:w-[650px] sm:h-[60vh] md:h-[55vh] h-[45vh] lg:h-[45vh] md:mt-5 lg:mt-10  m-auto flex justify-center items-center   gap-4"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}>
                  <audio
                    src={questions[currentQuestion]?.audio}
                    controls // This adds default audio controls (play, pause, volume, etc.)
                    // autoPlay={isPlaying} // Auto play when isPlaying state is true
                  />
                </div>
                <div className="flex gap-8 justify-evenly mt-5  ">
                  {/* <div
                    className="flex items-center bg-white gap-4 text-blue px-4 py-2 hover:border hover:border-blue-500 focus:outline-none focus:ring-2   focus:ring-blue-500 focus:ring-offset-2  rounded-l-3xl rounded-r-3xl cursor-pointer"
                    onClick={() => setShowModal(false)}
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}>
                    <div className="rounded-full  bg-yellow-500 flex  justify-center items-center ">
                      <img className="w-[30px] h-[30px]" src={left} alt="" />
                    </div>
                    <p> Prev</p>
                  </div> */}

                  <div className="text-gray-800 lg:mt-5   text-center ">
                    <button
                      onClick={handleShowAnswer}
                      className="bg-yellow-500  text-white px-4 py-2 rounded-md  hover:border hover:border-blue-500 focus:outline-none focus:ring-2  transition duration-300 ease-in-out">
                      See Options
                    </button>
                  </div>

                  {/* <div
                    className="flex cursor-pointer items-center bg-white gap-4 text-blue px-4 py-2  hover:border hover:border-blue-500  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 rounded-l-3xl rounded-r-3xl"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                    onClick={() => handleNextQuestion()}>
                    <p> Next</p>
                    <div className="rounded-full  bg-yellow-500 flex  justify-center items-center ">
                      <img className="w-[30px] h-[30px]" src={next} alt="" />
                    </div>
                  </div> */}
                </div>
              </div>
            ) : (
              <p className="text-gray-800  text-center"></p>
            )}
          </div>
        )}

        <div className=" flex justify-center items-center md:-mt-20 lg:-mt-14">
          {showOptions && (
            <div className="lg:w-[80%] md:w-[95%]  lg:mt-20 md:mt-2 rounded-3xl p-4 ">
              {/* {showOptions && (
                <div
                  className="flex w-[67%] justify-center mt-12 m-auto items-center rounded-2xl  "
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}>
                  <p className="bg-yellow-500 p-4 rounded-l-2xl">Instruction</p>
                  <p className="text-gray-800  font-semibold m-auto text-center">
                    {questions[currentQuestion]?.instruction}
                  </p>
                </div>
              )} */}
              {showOptions && (
                <div
                  className={`grid ${
                    questions[currentQuestion]?.options.some((option) =>
                      option.option.startsWith("http")
                    )
                      ? "grid-cols-3 lg:grid-cols-6  xl:grid-cols-6 md:grid-cols-6 sm:grid-cols-3 md:mt-44 sm:mt-  sm:h-[55vh]  h-[55vh]"
                      : "lg:grid-cols-2 md:grid-cols-2  "
                  } gap-x-3 gap-y-4 lg:mt-16 md:mt-32  w-full sm:w-full  justify-center `}>
                  {questions[currentQuestion]?.options?.map((option, index) => {
                    const hasImage = questions[currentQuestion].options.some(
                      (opt) => opt.option.startsWith("http")
                    );
                    return (
                      <div
                        key={index}
                        className={`flex ${
                          hasImage
                            ? "lg:flex-col md:flex-col  flex-col md:-mt-6 lg:gap-5 md:gap-3 gap-0  md:justify-center lg:justify-between  items-start lg:h-[300px] md:h-[250px] h-[180px] "
                            : "lg:h-[70px] md:h-[80px] "
                        }  items-center text-start border bg-white md:gap-1 lg:gap-3 cursor-pointer justify-center hover:bg-slate-200 hover:shadow-lg rounded-md ${
                          selectedOption === option.option ? "checked" : ""
                        } ${
                          option?.option?.startsWith("http")
                            ? "  border-blue-800 "
                            : "  border-blue-800 "
                        }`
                      }
                        // style={{
                        //   height: hasImage ? "300px" : "70px",
                        // }}
                        onClick={() => handleOptionSelect(option.option)}>
                        <span className="p-2">
                          ({String.fromCharCode(97 + index)}){" "}
                        </span>
                        <input
                          type="radio"
                          id={`option_${index}`}
                          name="options"
                          value={option.option}
                          checked={selectedOption === option.option}
                          onChange={() => handleOptionSelect(option.option)}
                          className="hidden"
                        />
                        <label
                          htmlFor={`option_${index}`}
                          className={`lg:py-2 rounded-md w-[80%] min-w-[120px] text-base ${
                            selectedOption === option.option
                              ? "text-black  font-arial lg:font-semibold  md:text-xs transition-all duration-300 ease-in-out"
                              
                              : ""
                          } ${option?.option?.startsWith("http") ? "lg:h-[200px] md:h-[120px] sm:h-[120px] h-[120px]"  : ""}`}
                          
                          // className={`py-2 rounded-md w-[80%]  ${
                          //   selectedOption === option.option
                          //     ? "text-black font-serif lg:font-semibold md:text-xs transition-all duration-300 ease-in-out"
                          //     : ""
                          // }`}
                          // style={{
                          //   minWidth: "120px",
                          //   fontSize:
                          //     selectedOption === option.option
                          //       ? "1rem"
                          //       : "1rem",
                          //   height: option?.option?.startsWith("http")
                          //     ? "200px"
                          //     : "",
                          // }}
                          >
                          {option.option.startsWith("http") ? (
                            // If it's a URL, render an image
                            <img
                              className="w-[100%] xl:h-[100%] lg:h-[100%] md:h-[100%] sm:h-[100%] h-[100px] p-2 justify-center  items-center "
                              src={option.option}
                              alt="Content"
                            />
                          ) : (
                            // Otherwise, render text
                            <p className="lg:text-[18px] md:w-full lg:w-full sm:w-full w-full xl:w-full  md:text-sm md:leading-tight    ">{option.option}</p>
                          )}
                        </label>
                        <div
                          className={`w-5 h-5 border-2 rounded-full mr-4 flex items-center justify-center ${
                            selectedOption === option.option
                              ? "bg-blue-500"
                              : ""
                          }`}>
                          {selectedOption === option.option && (
                            <div className="w-2 h-2 bg-white rounded-full"></div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              <div className="flex justify-evenly gap-1 items-center mt-8   ">
                {/* //Previous button remove */}
                {/* <div
                  className="flex items-center bg-white gap-4 text-blue px-4 py-2 hover:border hover:border-blue-500 focus:outline-none focus:ring-2   focus:ring-blue-500 focus:ring-offset-2 rounded-l-3xl rounded-r-3xl"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}>
                  <div className="rounded-full  bg-yellow-500 flex  justify-center items-center ">
                    <img className="w-[30px] h-[30px]" src={left} alt="" />
                  </div>
                  <p> Previous</p>
                </div> */}
                {!showModal && (
                  <div
                    className=" fixed lg:bottom-10 md:bottom-11 sm:bottom-12 xl:bottom-10 bottom-11 left-1/2 transform -translate-x-1/2 flex items-center bg-white gap-4 text-blue px-4 py-2 hover:border hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 rounded-l-3xl rounded-r-3xl"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                    onClick={handleSubmitAnswer}
                    // onClick={() => {
                    //   if (currentQuestion + 1 !== questions.length) {
                    //     handleSubmitAnswer();
                    //   } else {
                    //     handleFinishTest();
                    //   }
                    // }}
                  >
                    
                    <button>Next</button>
                    <div className="rounded-full  bg-yellow-500 flex  justify-center items-center ">
                      <img className="w-[22px] h-[30px]" src={next} alt="" />
                    </div>
                  </div>
                )}

                {/* {currentQuestion + 1 !== questions.length ?  
                 <div
                  className="flex items-center bg-white gap-4 text-blue px-4 py-2 hover:border hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 rounded-l-3xl rounded-r-3xl"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                  onClick={handleSubmitAnswer}>
                  <button className="" >Next</button>
                  <div className="rounded-full  bg-yellow-500 flex  justify-center items-center ">
                    <img className="w-[30px] h-[30px]" src={next} alt="" />
                  </div>
                </div> : 
                  <div
                  className="flex items-center bg-white gap-4 text-blue px-4 py-2 hover:border hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 rounded-l-3xl rounded-r-3xl"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                  
                  onClick={handleFinishTest}>
                  <button className="">Next</button>
                  <div className="rounded-full  bg-yellow-500 flex  justify-center items-center ">
                    <img className="w-[30px] h-[30px]" src={next} alt="" />
                  </div>
                </div>
                } */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes < 10 ? "0" + minutes : minutes}:${
    seconds < 10 ? "0" + seconds : seconds
  }`;
};

export default Test3;
