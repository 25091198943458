import React, { useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from 'recharts';

// const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];
const colors = ['blue', 'blue', 'blue', 'blue', 'blue', 'blue'];
function preChart (chartData) {
return [
        {
          name: 'Awarness',
          uv: chartData.awarenessAccuracy,
          pv: 2400,
          amt: 2400,
        },
        {
          name: 'Application',
          uv: chartData.applicationAccuracy,
          pv: 1398,
          amt: 2210,
        },
       
        {
          name: 'Advantage',
          uv: chartData.advantageAccuracy,
          pv: 3908,
          amt: 2000,
        },
       
       
        
      ];

}

const getPath = (x, y, width, height) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
  Z`;
};

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

export  const  ProgressiveBar=()=> {
    const [chartData, setChartData] = useState({});

    useEffect(() => {
      fetchDataFromBackend();
    }, []);
  
    const fetchDataFromBackend = async () => {
      try {
        const response = await fetch('https://test.xqminds.com/api/quiz/prideScore', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        console.log(data, "dataaaa");
        setChartData(data);
        localStorage.setItem( "MpiConsistency", data.MpiConsistency)
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error (e.g., show error message)
      }
    };
  
   
  
  return (
    <BarChart
      width={500}
      height={300}
      data={preChart(chartData)}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Bar dataKey="uv" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
        {preChart(chartData).map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % 20]} />
        ))}
      </Bar>
    </BarChart>
  );
}

export default ProgressiveBar
// App.demoUrl = 'https://codesandbox.io/s/bar-chart-with-customized-shape-dusth';
