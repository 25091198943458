import { legacy_createStore, combineReducers, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';
// import dataReducer from './reducer';
import testOptionReducer from './reducer';
import schoolScoresReducer from './JuniorReducer';
import SeniorSchoolScoresReducer from './SeniorReducer';
import seniorScoresReducer from './SeniorReducer';
import youthScoresReducer from './YouthReducer';
const rootReducer = combineReducers({
//   data: dataReducer,
  testOption: testOptionReducer,
//   photos: photoReducer,
  schoolScores: schoolScoresReducer,
  seniorScores: seniorScoresReducer,
  youthScores: youthScoresReducer,
});

const store = legacy_createStore(rootReducer, applyMiddleware(thunk));

export default store;

