import {
    FETCH_YOUTH_SCORES_REQUEST,
    FETCH_YOUTH_SCORES_SUCCESS,
    FETCH_YOUTH_SCORES_FAILURE,
  } from './actionType';
  
  const initialState = {
    loading: false,
    youthScores: {},
    error: '',
  };
  
  const youthScoresReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_YOUTH_SCORES_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_YOUTH_SCORES_SUCCESS:
        return {
          loading: false,
          youthScores: action.payload,
          error: '',
        };
      case FETCH_YOUTH_SCORES_FAILURE:
        return {
          loading: false,
          youthScores: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default youthScoresReducer;
  