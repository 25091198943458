import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  AreaChart, 
  Area,
} from "recharts";
function preChart(chartData){
return [
    {
      name: "Attention",
      Accuracy: chartData.attentionAccuracy,
      pv: 2400,
    },
    {
      name: "Memory",
      Accuracy: chartData.memoryAccuracy,
      pv: 1398,
    },
    {
      name: "Critical Thinking",
      Accuracy: chartData.criticalThinkingAccuracy,
      pv: 9800,
    },
    {
      name: "Creative Thinking",
      Accuracy: chartData.creativeThinkingAccuracy,
      pv: 9800,
    },
    {
      name: "Mindset",
      Accuracy: chartData.mindsetAccuracy,
      pv: 3908,
    },
    {
      name: "Attitude",
      Accuracy: chartData.attitudeAccuracy,
      pv: 4800,
    },
    {
      name: "Expression",
      Accuracy: chartData.expressionAccuracy,
      pv: 3800,
    },
    {
      name: "Communication",
      Accuracy: chartData.communicationAccuracy,
      pv: 4300,
    },
    {
      name: "Collaboration",
      Accuracy: chartData.collaborationAccuracy,
      pv: 3800,
    },
    {
      name: "LeaderShip",
      Accuracy: chartData.leadershipAccuracy,
      pv: 4300,
    },
  ];
}



export  const Worm =()=> {
    const [chartData, setChartData] = useState({});

    useEffect(() => {
      fetchDataFromBackend();
    }, []);
  
    const fetchDataFromBackend = async () => {
      try {
        const response = await fetch('https://test.xqminds.com/api/quiz/prideScore', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        // console.log(data, "dataaaa");
        setChartData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error (e.g., show error message)
      }
    };
  return (
    <div>
      <h4>Skills Distribution</h4>
      <LineChart
        width={950}
        height={200}
        data={preChart(chartData)}
        syncId="anyId"
        margin={{
          top: 1,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="4 1" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="Accuracy" stroke="#8884d8" fill="#8884d8" />
      </LineChart>
    </div>
  );
}

export default Worm


// import React from 'react';
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   ReferenceArea,
//   LabelList,
//   ResponsiveContainer,
// } from 'recharts';

// // Dummy data
// const data = [
//   { skill: 'Focusing', value: 60 },
//   { skill: 'Recognising', value: 70 },
//   { skill: 'Recognising', value: 60 },
//   { skill: 'Infering', value: 70 },
//   { skill: 'memory', value: 60 },
//   { skill: 'critical Thinking', value: 70 },

//   // Add the rest of the skill set data here
// ];

// const CustomLabel = (props) => {
//   const { x, y, stroke, value } = props;
//   return <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">{value}</text>;
// };

// const Worm = () => {
//   return (
//     <ResponsiveContainer width="100%" height={400}>
//       <BarChart
//         data={data}
//         margin={{
//           top: 20, right: 30, left: 20, bottom: 5,
//         }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="skill" />
//         <YAxis domain={[0, 120]} />
//         <Tooltip />
//         <Bar dataKey="value" fill="#8884d8" minPointSize={5}>
//           <LabelList dataKey="value" content={<CustomLabel />} />
//         </Bar>
//         {/* Reference areas for background colors */}
//         <ReferenceArea x1="Focusing" x2="Recognising" strokeOpacity={0} fill="yellow" label="Perceive" />
//         <ReferenceArea x1="Recognising" x2="Infering" strokeOpacity={0} fill="green" label="Resolve" />
//         {/* Add more ReferenceAreas for other backgrounds */}
//       </BarChart>
//     </ResponsiveContainer>
//   );
// };

// export default Worm;