import './App.css';
import AllRoutes from './Component/AllRoutes/AllRoutes';

function App() {
  return (
    <div className="App">
    <AllRoutes/>
    </div>
  );
}

export default App;
