// import React, { useEffect, useMemo } from "react";
// import { Container } from "@mui/material";
// import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
// import RANK from "./rank.json";
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchSchoolScores } from '../../../Redux/JuniorAction';
// import { fetchSeniorScores } from '../../../Redux/SeniorAction';
// import { fetchYouthScores } from '../../../Redux/YouthAction';



// const Topper = ({ isVisible, onClose }) => {
//   const dispatch = useDispatch();

//   const {
//     loading: schoolScoresLoading,
//     schoolScores,
//     error: schoolScoresError,
//   } = useSelector((state) => state.schoolScores);
//   const {
//     loading: seniorScoresLoading,
//     seniorScores,
//     error: seniorScoresError,
//   } = useSelector((state) => state.seniorScores);
//   const {
//     loading: youthScoresLoading,
//     youthScores,
//     error: youthScoresError,
//   } = useSelector((state) => state.youthScores);

//   useEffect(() => {
//     dispatch(fetchSchoolScores());
//     dispatch(fetchSeniorScores());
//     dispatch(fetchYouthScores());
//   }, [dispatch]);

//   useEffect(() => {
//     if (
//       !schoolScoresLoading &&
//       !seniorScoresLoading &&
//       !youthScoresLoading &&
//       !schoolScoresError &&
//       !seniorScoresError &&
//       !youthScoresError
//     ) {
//       console.log(schoolScores, "schoolScores");
//       console.log(seniorScores, "seniorScores");
//       console.log(youthScores, "youthScores");
//     }
//   }, [
//     schoolScoresLoading,
//     seniorScoresLoading,
//     youthScoresLoading,
//     schoolScores,
//     seniorScores,
//     youthScores,
//     schoolScoresError,
//     seniorScoresError,
//     youthScoresError,
//   ]);

//   const allStudentScores = [
//     ...(schoolScores?.studentsScores || []),
//     ...(seniorScores?.studentsScores || []),
//     ...(youthScores?.studentsScores || []),
//   ];

//   const displayData = allStudentScores.sort((a,b)=>b.convertedMpiScore-a.convertedMpiScore);
//    console.log(displayData,"displayData")
//   const columns = useMemo(
//     () => [
//       {
//         accessorKey: "name",
//         header: "Full Name",
//         enableClickToCopy: false,
//       },
//       {
//         accessorKey: "email",
//         header: "E-mail Address",
//       },
//       {
//         accessorKey: "phone",
//         header: "Phone Number",
//         enableClickToCopy: true,
//       },
//     ],
//     []
//   );

//   const table = useMaterialReactTable({
//     columns,
//     data: RANK,
//     initialState: { pagination: { pageSize: 5, pageIndex: 0 } },
//   });
  
//   if (!isVisible) return null;

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
//       <div className="relative bg-white p-5 rounded-lg w-[90%] max-w-[800px]">
//         <div className="flex justify-end">
//           <button
//             className="bg-red-500 text-white py-1 px-2 rounded"
//             onClick={onClose}
//           >
//             Close
//           </button>
//         </div>
//         <Container sx={{ py: 2 }}>
//           <div style={{ height: '100%', overflowY: 'auto' }}>
//             <MaterialReactTable table={table} />
//           </div>
//         </Container>
//       </div>
//     </div>
//   );
// };

// export default Topper;

import React, { useEffect, useMemo, useState } from "react";
import { Container } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { useDispatch, useSelector } from 'react-redux';
import { fetchSchoolScores } from '../../../Redux/JuniorAction';
import { fetchSeniorScores } from '../../../Redux/SeniorAction';
//import { fetchYouthScores } from '../../../Redux/YouthAction';

const Topper = ({ isVisible, onClose }) => {
  const dispatch = useDispatch();
  const [allStudentScores, setAllStudentScores] = useState([]);

  const {
    loading: schoolScoresLoading,
    schoolScores,
    error: schoolScoresError,
  } = useSelector((state) => state.schoolScores);
  const {
    loading: seniorScoresLoading,
    seniorScores,
    error: seniorScoresError,
  } = useSelector((state) => state.seniorScores);
  // const {
  //   loading: youthScoresLoading,
  //   youthScores,
  //   error: youthScoresError,
  // } = useSelector((state) => state.youthScores);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(fetchSchoolScores()),
        dispatch(fetchSeniorScores()),
        // dispatch(fetchYouthScores())
      ]);
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (
      !schoolScoresLoading &&
      !seniorScoresLoading &&
      // !youthScoresLoading &&
      !schoolScoresError &&
      !seniorScoresError 
      // !youthScoresError
    ) {
      // console.log('schoolScores:', schoolScores);
      // console.log('seniorScores:', seniorScores);
      // console.log('youthScores:', youthScores);

      const combinedScores = [
        ...(schoolScores?.studentsScores || []),
        ...(seniorScores?.studentsScores || []),
        // ...(youthScores?.studentsScores || []),
      ];

      // console.log('Combined student scores before sorting:', combinedScores);
      const sortedScores = combinedScores.sort((a, b) => parseFloat(b.convertedMpiScore) - parseFloat(a.convertedMpiScore));
      // console.log('Sorted student scores:', sortedScores);

      // Add serial numbers to each student
      const scoredWithSerial = sortedScores.map((student, index) => ({
        ...student,
        serialNo: index + 1,
      }));

      setAllStudentScores(scoredWithSerial);
    }
  }, [
    schoolScoresLoading,
    seniorScoresLoading,
    // youthScoresLoading,
    schoolScores,
    seniorScores,
    // youthScores,
    schoolScoresError,
    seniorScoresError,
    // youthScoresError,
  ]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "serialNo",
        header: "S.No",
        size: 20,
        minSize: 20,
        maxSize: 20,
       
        muiTableBodyCellProps: ({
          cell
          }) => ({
          sx: {
            color: '#7ABD32' ,
            fontWeight: '600',
            fontSize: '18px',
            
          }
        })
      },
      {
        accessorKey: "name",
        header: "Name",
        // enableClickToCopy: true,
        size: 20,
        minSize: 20,
        maxSize: 20,
        muiTableBodyCellProps: ({ cell }) => ({
          sx: {
            color: "#3d96d3",
            fontWeight: "700",
            fontSize: "18px",
          },
        }),
      },
      {
        accessorKey: "standard",
        header: "Class",
        size: 20,
        minSize: 20,
        maxSize: 20,
        muiTableBodyCellProps: ({ cell }) => ({
          sx: {
            color: "#3d96d3",
            fontWeight: "700",
            fontSize: "18px",
          },
        }),
      },
      {
        accessorKey: "convertedMpiScore", // Corrected to show email address
        header: "MPI Score",
        size: 20,
        minSize: 20,
        maxSize: 20,
        muiTableBodyCellProps: ({ cell }) => ({
          sx: {
            color: "#3d96d3",
            fontWeight: "700",
            fontSize: "18px",
          },
        }),
      },

      {
        accessorKey: "viewProfile",
        header: "View Profile",
        Cell: ({ row }) => (
          <a
            // href={`/profile/${row.original.id}`} // Adjust the URL as needed
            // target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'blue', textDecoration: 'underline' }}
          >
            View Profile
          </a>
        ),
      },
    ],
    []
  );

  const table = useMemo(() => ({
    columns,
    data: allStudentScores,
    initialState: { pagination: { pageSize: 5, pageIndex: 0 } },
  }), [allStudentScores, columns]);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
     <div className="relative bg-white p-5 rounded-lg w-[90%] max-w-[65%]">
        <div className="flex justify-end">
          <button
            className="bg-red-500 text-white py-1 px-2 rounded"
            onClick={onClose}
          >
            Close
          </button>
        </div>
        <p className="text-[#1cc2f6] font-semibold text-xl">TOP PERFORMANCE</p>
        <Container sx={{ py: 2 }}>
        <div style={{ height: '100%',width: '100%', overflowY: 'auto' }}>
            <MaterialReactTable {...table} 
            muiTableHeadCellProps={{
              sx: {
               //  backgroundColor: 'lightblue',
                borderRight: '1px solid rgba(224,224,224,1)',
                color: '#7ABD32', // Adjusted color for better visibility
                fontWeight: '700',
                fontSize: '16px',
               
                
                 // fontSize: '18px',
              },
              align: 'center',
           }}
           
           muiTableBodyCellProps={{
             align: 'center'
           }} muiTableFooterCellProps={{
             align: 'center'
           }} 
           
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Topper;
