import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import SideData from "../SideData/SideData";
import notification from "../../image/notification.gif";
import { useLocation } from "react-router-dom";
import Accordian from "../Accordian/Accordian";
import NextExam from "../NextExamDate/NextExam";

  
const StudentProfile = () => {
  const [score, setScore] = useState([]);
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
 
  const { selectedstrength, someKey } = location.state || {};
  const [selectedSkill, setSelectedSkill] = useState(someKey||"attention");
  // const [data,setData] = useState(selectedstrength||[])
  
  // console.log(someKey,selectedSkill,"this is skill");
  const GetRequest = () => {
    fetch("https://test.xqminds.com/api/quiz/SkillContent", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())

      .then((res) => {
        // console.log(res, "reddddddd");

        setScore(res.contentObject);
      
      })

      .catch((err) => console.log(err));
  };
  // console.log(score, "score");
  useEffect(() => {
    GetRequest();
  }, []);
 
  
  const changeData = (skill) => {
    setSelectedSkill(skill);
  };
  //  console.log(selectedSkill, "attentionScoreeee");
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div className="flex  gap-1">
    <div className={`flex h-[99vh]  gap-5 sticky top-1 ${isSidebarOpen ? 'block' : 'hidden'} md:block`}>
      <Sidebar />
    </div>

    <div className="md:w-[91%] md:mt-1 w-[95%]  m-auto flex flex-col gap-5">
      <div className="md:flex md:flex-row flex flex-col-reverse justify-start items-center  shadow-lg rounded-lg p-3 gap-4 sticky top-0 z-10 bg-white">
        <NextExam />
        <form className="flex items-center max-w-sm mx-auto">
          <label htmlFor="simple-search" className="sr-only">
            Search
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-yellow-500 dark:text-yellow-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"
                />
              </svg>
            </div>
            <input
              type="text"
              id="simple-search"
              className="border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-blue-600 dark:placeholder-yellow-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search..."
              required
            />
          </div>
          <button
            type="submit"
            className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              className="w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
            <span className="sr-only">Search</span>
          </button>
        <div>
          <img className="w-[50px]" src={notification} alt="Notification" />
        </div>
        <button
    data-collapse-toggle="navbar-search"
    type="button"
    className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
    aria-controls="navbar-search"
    aria-expanded={isSidebarOpen}
    onClick={toggleSidebar}
  >
    <span className="sr-only">Open main menu</span>
    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 1h15M1 7h15M1 13h15"
      />
    </svg>
  </button>
        </form>
      </div>
        <div className="grid w-[93%] ml-7 mt-3 grid-cols-5 gap-x-8  justify-center  items-center">
         
        </div>
     
        <div className="ml-8">
        <Accordian/>
        </div>
      </div>

      {/* side data is here */}
      {/* <div className="flex sticky top-5 h-full w-[25%]">
        <SideData />
      </div> */}

{isSidebarOpen && (
        <div className="fixed inset-0 z-50 flex md:hidden">
          <div className=" bg-white shadow-lg">
            <Sidebar />
          </div>
          <div className="flex-grow" onClick={toggleSidebar}></div>
        </div>
      )}
    </div>
  );
};

export default StudentProfile;
