import {
    FETCH_SENIOR_SCORES_REQUEST,
    FETCH_SENIOR_SCORES_SUCCESS,
    FETCH_SENIOR_SCORES_FAILURE,
  } from './actionType';
  
  export const fetchSeniorScoresRequest = () => ({
    type: FETCH_SENIOR_SCORES_REQUEST,
  });
  
  export const fetchSeniorScoresSuccess = (data) => ({
    type: FETCH_SENIOR_SCORES_SUCCESS,
    payload: data,
  });
  
  export const fetchSeniorScoresFailure = (error) => ({
    type: FETCH_SENIOR_SCORES_FAILURE,
    payload: error,
  });
  
  export const fetchSeniorScores = () => {
    return async (dispatch) => {
      dispatch(fetchSeniorScoresRequest());
      try {
        const response = await fetch('https://test.xqminds.com/api/schoolData/calculate-scores?level=senior', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        // console.log('Senior Scores API Data:', data); // Log the data received from the API
        dispatch(fetchSeniorScoresSuccess(data));
      } catch (error) {
        console.error('Senior Scores API Error:', error); // Log any error from the API
        dispatch(fetchSeniorScoresFailure(error.message));
      }
    };
  };
  