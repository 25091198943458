import React, { useState } from "react";
import "../Sidebar/Sidebar.css";

import Dashboard from "../../../image/Dashboard.gif";
import Profile from "../../../image/Profile.gif";
import Performance from "../../../image/Performance.gif";
import ActionPlan from "../../../image/ActionPlan.gif";
import StudentProfile from "../../../image/StudentProfile.gif";
import Assessment from "../../../image/Assessment.gif";
import Login from "../../../image/Login.gif";
import logo from "../../../image/logo.png"
import office from "../../../image/office.png"

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Image,
  Toast,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
const SchoolSideBar = () => {
  const navigate = useNavigate();

  let token = localStorage.getItem("token");
  // console.log( token ,"token aa jaa")

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("checkExamStatus");
    navigate("/");
  };

  const sidebaarData = [
    {
      id: 1,
      img: Dashboard,
      title: "Home",
      subitem: [],
      route: "/schooldashboard",
    },
    // {
    //   id: 2,
    //   img: Profile,
    //   title: "Teacher Profile",
    //   subitem: [
    //     {
    //       id: 21,
    //       title: "Add Teacher",
    //       route: "/addteacher",
    //     },
    //     {
    //       id: 22,
    //       title: "View Teacher",
    //       route: "/viewteacher",
    //     },
    //   ],
      
    //   route: "",
    // },
    {
      id: 3,
      img: Performance,
      title: "School Performance",
      subitem: [
        // {
        //   id: 11,
        //   title: "Ranking",
        //   route: "/",
        // },
        // {
        //   id: 12,
        //   title: "Growth",
        //   route: "/",
        // },
        // // {
        // //   id:13,
        // //   title:"Chart",
        // //   route:"/"
        // // },
        // {
        //   id: 14,
        //   title: "Polarity",
        //   route: "/",
        // },
      ],
    
      route: "/schoolPerformance",
      // route: "/schooldashboard", 
    },
    // {
    //   id: 4,
    //   img: ActionPlan,
    //   title: "Action Plan",
    //   subitem: [],
    //   route: "",
    // },
    // {
    //   id:5,
    //   img:StudentProfile,
    //   title:"Student Profile",
    //   subitem:[
    //       {
    //       id: 11,
    //       title: "Add Student",
    //       route: "/addstudent",
    //     },
    //     {
    //       id: 12,
    //       title: "View Student",
    //       route: "/viewstudent",
    //     },
    //   ],
    //   route:""
    // },
    // {
    //   id:6,
    //   img:teacherImg,
    //   title:"Teachers Profile",
    //   subitem:[],
    //   route:"/"
    // },
    // {
    //   id: 7,
    //   img: Assessment,
    //   title: "Assessment",
    //   subitem: [],
    //   route: "",
    // },
    // {
    //     id: 8,
    //     img: office,
    //     title: "Office",
    //     subitem: [],
    //     route: "",
    //   },
  ];
  const [showParagraph, setShowParagraph] = useState(false);
  const toast= useToast()
  let checkexam=JSON.parse(localStorage.getItem("checkExamStatus"))
  //console.log()
  //  const handleRouteNavigate=(route)=>{
  //   // console.log(route,checkexam,"checking dataaaaaaaaaaaaaa")
  //   if(checkexam?.totalMarks&&route=="/term"){
  //     return  toast({
  //       description: `You have already taken the assessment. Please wait for your next assessment schedule.`,
  //       status: "success",
  //       duration: 4000,
  //       position: "top",
  //       isClosable: true,
  //     });
  //   }
  //   navigate(route)
  //  }
  const handleRouteNavigate = (route) => {
    const queryParams = new URLSearchParams(window.location.search).toString();
    if (checkexam?.totalMarks && route === "/term") {
      return toast({
        description: `You have already taken the assessment. Please wait for your next assessment schedule.`,
        status: "success",
        duration: 4000,
        position: "top",
        isClosable: true,
      });
    }
    if (route === "/schoolPerformance" && queryParams) {
      navigate(`${route}?${queryParams}`);
    } else {
      navigate(route);
    }
  };

  return (
    <div
    className={`rounded w-[100px] sticky   flex flex-col h-[99vh] items-center hover:w-[180px]  duration-500 ease-in-out shadow-2xl`}
    onMouseEnter={() => setShowParagraph(true)}
    onMouseLeave={() => setShowParagraph(false)}
  >
    <div className="flex justify-center w-[100px] h-[100px] mt-10  items-center">
      <img
        src={logo}
        alt="err"
        className={`w-[80px] ${showParagraph && "w-[200px]"}`}
      />
    </div>
  
    <Accordion defaultIndex={[0]}  allowMultiple>
      <div>
        {sidebaarData.map((el, index) => (
          <div key={index}>
            <AccordionItem borderColor="transparent">
              <h2>
                <AccordionButton
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                  _hover={{ backgroundColor: "lightblue" }}
                >
                 
                    <Box
                    onClick={()=>handleRouteNavigate(el.route)}
                      as="span"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                       marginTop:"5px"
                      }}
                      
                      textAlign="left"
                    >
                      <Image src={el.img} className="w-[32px]" />
                     { showParagraph && <p  className="duration-700 ease-in-out ">{ el.title}</p>}
                    </Box>
                 
                  {el.subitem.length > 0 && <AccordionIcon />}
                </AccordionButton>
              </h2>
              {el.subitem.length > 0 && (
                <AccordionPanel pb={4}>
                  {el.subitem.map((subEl, subIndex) => (
                    <Link key={subIndex} to={subEl.route}>
                     {showParagraph&& <p  className="duration-700  hover:bg-[lightblue]  p-2 flex flex-col gap-4  ease-in-out ">{subEl.title}</p>}
                    </Link>
                  ))}
                </AccordionPanel>
              )}
            </AccordionItem>
          </div>
        ))}
        {token ? (
          <button
            className="flex items-center gap-2 hover:bg-[lightblue] w-[100%] p-[10px]"
            onClick={handleLogout}
          >
            {" "}
            <img src={Login} className="w-[32px]" />
            {showParagraph && "Logout"}
          </button>
        ) : (
          <Link to="/">
            {" "}
            <button className="flex items-center gap-2 hover:bg-[lightblue] w-[100%] p-[10px]">
              {" "}
              <img src={Login} className="w-[32px]" />
             {showParagraph && "Login"}
            </button>
          </Link>
        )}
      </div>
    </Accordion>
   
  </div>
  
  );
};
export default SchoolSideBar;
const MenuItem = ({ image, text, handleClick }) => {
  return (
    <div className="menu-item" onClick={handleClick}>
      <img src={image} alt="" />
      <p>{text}</p>
    </div>
  );
};
