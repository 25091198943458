import React ,{ useEffect, useState } from 'react'
import Score from "../../image/Score.gif";
import CircularProgressBar from "../Circular/CircularProgressBar";
import { ChevronRightIcon } from "@heroicons/react/outline";
import studentillustration from "../../image/studentillustration.png";
import CircularProgressSpeedBar from "../Circular/CircularProgessSpeedBar";
import mpiGrowth from "../../image/mpiGrowth.gif";
import { useNavigate } from 'react-router-dom';
import StarRating from '../Circular/StarRating';
import Gaurge from '../Chart/Gaurge';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTestOption } from '../Redux/action'; 
import ColorCircle from '../School/ColorCircle/ColorCircle';

   
const StudentScore = () => {
  const [score, setScore] = useState({});
  const navigate = useNavigate();


  const dispatch = useDispatch();  
  const { loading: testOptionLoading, testOption, error: testOptionError } = useSelector((state) => state.testOption);
   
    //  console.log(testOption,"testOption")
    useEffect(() => {
    dispatch(fetchTestOption());
  }, [dispatch]);
  const GoToReport = () => {
    navigate("/report");
  };
  // const GetRequest = () => {
  //   fetch("https://test.xqminds.com/api/quiz/testOption", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //   })
  //     .then((res) => res.json())

  //     .then((res) => {
  //       //  console.log(res);
  //       setScore(res);
  //     })

  //     .catch((err) => console.log(err));
  // };
  // useEffect(() => {
  //   GetRequest();
  // }, []);
  return (
    <div className=" md:flex w-full gap-6  justify-center  ">
    <div className="colors  w-full md:w-[45%] h-[65vh] md:h-[48vh] flex flex-col-reverse md:flex md:flex-row  rounded-lg overflow-hidden">
  <div className="flex flex-col justify-center p-6 items-center space-y-4">
    <h1 className="text-3xl font-fredoka font-bold text-white">
      Welcome to PRIDE
     

    </h1>
    <p className="text-lg font-arial text-gray-300">
    
    </p>
    <button
      onClick={GoToReport}
      className="flex items-center justify-center gap-2 px-4 py-2 text-lg font-semibold bg-green-500 text-white rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75 transition-colors duration-300">
      Go to Report
      <ChevronRightIcon className="w-6 h-6 animate-bounce" />
    </button>
  </div>
  <div className="p-6 flex justify-center">
    <img
      className="w-[100%] md:h-[60%] h-[90%] m-auto"
      src={studentillustration}
      alt=""
    />
  </div>
</div>
                <div className="w-[100%] md:w-[55%] flex flex-col gap-4">
                  <div className="grid  grid-cols-1  md:grid-cols-2  w-full flex-row gap-4    " >


                    <div className="mpi relative group w-full h-[100%]  p-4 flex justify-between items-center rounded-lg transition duration-300 hover:bg-yellow-300  shadow-lg">
                      
                      <div className="flex flex-col justify-center items-center">
                      <p className="text-lg  mb-2 font-fredoka"     >
                      Mental Profiling Index (MPI) Score
                        </p>
                        <img src={Score} alt="score" className="w-16 h-12" />
                        {/* <p className="text-gray-600">
                          {score?.convertedMpiScore
                            ? score.convertedMpiScore
                            : 0}
                          /10
                        </p> */}
                        <p>
                          {" "}
                          {testOption.convertedMpiScore < 3
                            ? "Weak"
                            : testOption.convertedMpiScore >= 3 &&
                            testOption.convertedMpiScore <=6
                            ? "Better"
                            : testOption.convertedMpiScore >6 &&
                            testOption.convertedMpiScore <= 8
                          ? "Good"
                            : testOption.convertedMpiScore >8 &&
                            testOption.convertedMpiScore <= 10
                            ? "Excellent"
                            : ""}
                        </p>
                      </div>
                      <div className="relative">
                       
                     <div className=''>
                     <CircularProgressBar
                          percentage={testOption?.convertedMpiScore}
                        />
                                                  {/* <ColorCircle   percentage={testOption?.convertedMpiScore*10} /> */}

                     </div>
                        {/* <p className="text-lg font-bold">{score?.convertedMpiScore}</p> */}
                        <div className="group-hover:block hidden absolute right-0 top-full  ml-4 z-10 w-64 p-4 bg-gray-100 border border-gray-300 rounded-lg shadow-lg">
                          <p className="text-sm text-gray-800 mb-2 font-semibold font-fredoka">
                            MPI Score Explanation
                          </p>
                          <p className="text-xs text-gray-600">
                            A holistic measure reflecting a child's performance
                            across Accuracy, Speed, and Consistency, providing
                            an overall understanding of their Mental Performance
                            Index on a scale of 1 to 10.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mpi  relative group w-full p-4 flex justify-between items-center rounded-lg transition duration-300 hover:bg-yellow-300  shadow-lg">
                  <div>
                  <p className="text-lg  mb-2 font-fredoka">
                    Mental Profiling Index (MPI) Growth
                        </p>
                      <img src={mpiGrowth} alt="score" className="w-20 h-16 " />
                  </div>
                      <div className="relative">
                        
                        <div className="group-hover:block hidden absolute right-0 top-full  ml-4 z-10 w-64 p-4 bg-gray-100 border border-gray-300 rounded-lg shadow-lg">
                          <p className="text-sm text-gray-800 mb-2 font-semibold">
                            MPI Growth Explanation
                          </p>
                          <p className="text-xs text-gray-600">
                            MPI Growth: Illustrates the rate of change in a
                            child's MPI scores between current and previous
                            assessments, indicating positive or negative trends
                            in their development across performance domains.
                          </p>
                        </div>

                        <CircularProgressBar
                          percentage={
                            testOption?.prideGrowthPercentage
                              ? testOption.prideGrowthPercentage +"%"
                              : 0 
                          }
                        />
                      </div>
                    </div>
                  </div>
              

                  <div className="grid  grid-cols-2 h-[100%] md:grid-cols-3 gap-4 ">
                    <div className="mpi relative w-[100%] group p-4 flex flex-col gap-5  justify-center items-center shadow-xl rounded-lg transition duration-300 hover:bg-yellow-300 ">
                      <p className="text-lg  font-fredoka">
                        Accuracy
                      </p>
                      <div className="flex justify-between items-center">
                        <p className="text-lg font-bold"></p>
                        <div className="group-hover:block hidden absolute right-0 top-full  ml-4 z-10 w-64 p-4 bg-gray-100 border border-gray-300 rounded-lg shadow-lg">
                          <p className="text-sm text-gray-800  font-semibold">
                            Accuracy Explanation
                          </p>
                          <p className="text-xs text-gray-600">
                            Accuracy: Represents the ability to make correct
                            decisions or choices considering various factors,
                            essential for quality performance, measured as a
                            percentage between 0 to 100.
                          </p>
                        </div>

                        <div className='w-full h-[10vh]'>
                       <CircularProgressSpeedBar
                          percentage={testOption?.prideAccuracyScorePercentage}
                        />
                        <div className='ml-56'>
                          {/* <ColorCircle   percentage={testOption?.prideAccuracyScorePercentage} /> */}
                          {/* <ColorCircle percentage={100} /> */}
                        </div>
                       </div>
                      </div>
                    </div>

                    <div className="mpi relative group   flex flex-col justify-center items-center shadow-xl rounded-lg transition duration-300 hover:bg-yellow-300  ">
                      <p className="text-lg  font-fredoka">Speed</p>
                      <div className="flex flex-col justify-between items-center  ">
                      <div className=''>
             <Gaurge/>
             </div>
                        {/* <p className="text-2xl font-bold flex justify-center   items-center">
                          {score?.mentalSpeedInSec ? score.mentalSpeedInSec : 0}{" "}
                       
                        </p>
                        <p>sec</p> */}
                        <div className="group-hover:block hidden absolute right-0 top-full -mt-1 ml-4 z-10 w-64 p-4 bg-gray-100 border border-gray-300 rounded-lg shadow-lg">
                          <p className="text-sm text-gray-800  font-semibold">
                            Speed Explanation
                          </p>
                          <p className="text-xs text-gray-600">
                            Speed: Reflects the efficiency of processing
                            information and making decisions, crucial for timely
                            responses, measured in seconds with lower values
                            indicating better performance.
                          </p>
                        </div>

                        {/* <CircularProgressBar percentage={score?.
                                                         prideAccuracyScorePercentage} /> */}
                      </div>
                    </div>

                    <div className="  mpi relative group p-4 flex flex-col gap-8  justify-center items-center  shadow-xl rounded-lg transition duration-300 hover:bg-yellow-300 ">
                      <p className="text-lg font-fredoka ">
                        Consistency
                      </p>
                      <div className="flex flex-col justify-between items-center">
                        {/* <p className="text-2xl font-bold">
                          {score?.mpiConsistency
                            ? score.mpiConsistency
                            : 0} out of 5
                        </p> */}
                       <div>
                       <StarRating rating={testOption?.mpiConsistency ? testOption.mpiConsistency
                            : 0}/>
                       </div>
                       {/* <ColorCircle   percentage={testOption?.mpiConsistency*20} /> */}

                      <div>
                      <p>{testOption?.mpiConsistency
                            ? testOption.mpiConsistency
                            : 0} <span className='text-gray-500 text-[10px]'>out of <span className='text-lg font-semibold'>5</span></span>
                      </p>
                      </div>
                        <div className="group-hover:block hidden absolute right-0 top-full -mt-1 ml-4 z-10 w-64 p-4 bg-gray-100 border border-gray-300 rounded-lg shadow-lg">
                          <p className="text-sm text-gray-800  font-semibold">
                            Consistency Explanation
                          </p>
                          <p className="text-xs text-gray-600">
                            Consistency: Indicates the frequency of making
                            appropriate responses over a set of tasks,
                            reflecting reliability and adherence to standards,
                            displayed as a ratio where higher values signify
                            better consistency.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  )
}

export default StudentScore