import React, { useEffect, useState } from "react";
import { Circle } from "rc-progress";
import "../Circular/CircularProgressBar.css";

const CircularProgressBar = ({ percentage }) => {

  const getStrokeColor = (percentage) => {
    if (percentage >= 0 && percentage <= 2) {
      return "red";
    } else if (percentage >=2 && percentage <= 4) {
      return "rgb(168,85,247)";
    } else if (percentage > 4 && percentage <= 6) {
      return "#3498db";
    } else if (percentage > 6 && percentage <= 8) {
      return "lightgreen";
    } else if (percentage > 8 && percentage <= 10) {
      return "yellow";
    }
    return "lightgray"; // Default color if percentage is out of bounds
  };

  return (
    <div className="relative w-28 h-36">
      <Circle
        percent={percentage*10}
        strokeWidth="9"
        trailWidth="9"
        // strokeColor="#3498db"
        strokeColor={getStrokeColor(percentage)} 
         trailColor="#EEEEEE"
       
        // strokeColor={{
        //   '0%': 'lightblue',
        //   '100%': 'blue',
        // }}
        className="absolute top-0 left-0 w-full h-full"
      />
      <div className="absolute inset-0 flex items-center  justify-center text-lg">{percentage}</div>
    </div>
  );
};

export default CircularProgressBar


