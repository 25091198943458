



// import React, { useEffect, useMemo, useState } from "react";
// import { Container } from "@mui/material";
// import { MaterialReactTable } from "material-react-table";


// const  AllMpiClass = ({ Visible, close }) => {
  
//   const [students, setStudents] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [selectedStandard, setSelectedStandard] = useState(null);
//   const [standardData, setStandardData] = useState({});
  
//   const [studentData, setStudentData] = useState([]);

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     if (!token) {
//       console.log("Token not found");
//       setIsLoading(false);
//       return;
//     }

//     // Fetch junior scores
//     fetch("https://test.xqminds.com/api/schoolData/calculate-scores?level=junior", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         authorization: `Bearer ${token}`,
//       },
//     })
//       .then((res) => res.json())
//     //   console.log(res)
//       .then((juniorData) => {
//         // Fetch senior scores
//         fetch("https://test.xqminds.com/api/schoolData/calculate-scores?level=senior", {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             authorization: `Bearer ${token}`,
//           },
//         })
//           .then((res) => res.json())
//         //   console.log(res)
//           .then((seniorData) => {
//             // Combine junior and senior data
//             const combinedData = [
//               ...(juniorData.studentsScores || []),
//               ...(seniorData.studentsScores || []),
//             ];
//             setStudentData(combinedData);
//             console.log(combinedData,"combinedData")

//             setIsLoading(false);
//           })
//           .catch((seniorError) => {
//             console.log("Error fetching senior scores:", seniorError);
//             setIsLoading(false);
//           });
//       })
//       .catch((juniorError) => {
//         console.log("Error fetching junior scores:", juniorError);
//         setIsLoading(false);
//       });
//   }, []);

//   useEffect(() => {
//     console.log('studentData:', studentData);
//   }, [studentData]);


//   useEffect(() => {
//     const dataByStandard = {};

//     studentData.forEach((student) => {
//       const {
//         standard,
//         convertedMpiScore,
//         mentalSpeedInSec,
//         prideAccuracyScorePercentage,
//         mpiConsistency,
//         scores,
//       } = student;
//       const {
//         awarenessScoreAccuracy,
//         applicationScoreAccuracy,
//         advantageScoreAccuracy,
//       } = scores;
//       if (!dataByStandard[standard]) {
//         dataByStandard[standard] = {
//           totalConvertedMpiScore: 0,
//           totalMentalSpeedInSec: 0,
//           totalPrideAccuracyScorePercentage: 0,
//           totalMpiConsistency: 0,

//           totalAwarenessScoreAccuracy: 0,
//           totalApplicationScoreAccuracy: 0,
//           count: 0,
//         };
//       }
//       dataByStandard[standard].totalConvertedMpiScore +=
//       parseFloat(convertedMpiScore);
//     dataByStandard[standard].totalMentalSpeedInSec +=
//       parseFloat(mentalSpeedInSec);
//     dataByStandard[standard].totalPrideAccuracyScorePercentage += parseFloat(
//       prideAccuracyScorePercentage
//     );
//     dataByStandard[standard].totalMpiConsistency +=
//       parseFloat(mpiConsistency);
//     ///intelligence///
//     dataByStandard[standard].totalAwarenessScoreAccuracy += parseFloat(
//       awarenessScoreAccuracy
//     );
//     dataByStandard[standard].totalApplicationScoreAccuracy += parseFloat(
//       applicationScoreAccuracy
//     );
//     dataByStandard[standard].totalAdvantageScoreAccuracy += parseFloat(
//       advantageScoreAccuracy
//     );
//     dataByStandard[standard].count += 1;
//   });

//   const averagedData = {};
//   for (let standard in dataByStandard) {
//     averagedData[standard] = {
//       averageConvertedMpiScore: (
//         dataByStandard[standard].totalConvertedMpiScore /
//         dataByStandard[standard].count
//       ).toFixed(2),
//       averageMentalSpeedInSec: (
//         dataByStandard[standard].totalMentalSpeedInSec /
//         dataByStandard[standard].count
//       ).toFixed(2),
//       averagePrideScoreAccuracyPercentage: (
//         dataByStandard[standard].totalPrideAccuracyScorePercentage /
//         dataByStandard[standard].count
//       ).toFixed(2),
//       averageMpiConsistency: (
//         dataByStandard[standard].totalMpiConsistency /
//         dataByStandard[standard].count
//       ).toFixed(2),

//       ///intelligence///
//       averageAwarenessScoreAccuracy: (
//         dataByStandard[standard].totalAwarenessScoreAccuracy /
//         dataByStandard[standard].count
//       ).toFixed(2),
//       averageApplicationScoreAccuracy: (
//         dataByStandard[standard].totalApplicationScoreAccuracy /
//         dataByStandard[standard].count
//       ).toFixed(2),
//       averageAdvantageScoreAccuracy: (
//         dataByStandard[standard].totalAdvantageScoreAccuracy /
//         dataByStandard[standard].count
//       ).toFixed(2),

//     };
//   }

//   setStandardData(averagedData);
// }, []);

// const getCategory = (score) => {
//     if (score >= 0 && score <= 2) return 'Poor';
//     if (score >= 3 && score <= 4) return 'Bad';
//     if (score >= 5 && score <= 6) return 'Average';
//     if (score >= 7 && score <= 8) return 'Good';
//     if (score >= 9 && score <= 10) return 'Excellent';
//     return 'Unknown';
//   };

//   return (
// <div>
//   <h1>Average Scores by Standard</h1>
//   <table>
//     <thead>
//       <tr>
//         <th>Standard</th>
//         <th>Average Converted MPI Score</th>
//         <th>Average</th>
//         {/* <th>Avg Mental Speed in Sec</th>
//         <th>Average Awareness Score Accuracy</th>
//         <th>Avg Application Score Accuracy</th> */}
//       </tr>
//     </thead>
//     <tbody>
//       {Object.keys(standardData).map((standard) => (
//         <tr key={standard}>
//           <td>{standard}</td>
//           <td>{standardData[standard].averageConvertedMpiScore}</td>
//           <td>{getCategory(standardData[standard].averageConvertedMpiScore)}</td>
//           {/* <td>{standardData[standard].averageMentalSpeedInSec}</td>
//           <td>{standardData[standard].averageAwarenessScoreAccuracy}</td>
//           <td>{standardData[standard].averageApplicationScoreAccuracy}</td> */}
//         </tr>
//       ))}
//     </tbody>
//   </table>
// </div>



//   );
// }

// export default AllMpiClass;



















import React, { useEffect, useMemo, useState } from "react";
import { Container } from "@mui/material";
import { MaterialReactTable } from "material-react-table";

const AllMpiClass = ({isVisible,close}) => {
  const [studentData, setStudentData] = useState([]);
  const [standardData, setStandardData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("Token not found");
      setIsLoading(false);
      return;
    }

    // Fetch junior scores
    fetch("https://test.xqminds.com/api/schoolData/calculate-scores?level=junior", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((juniorData) => {
        // Fetch senior scores
        fetch("https://test.xqminds.com/api/schoolData/calculate-scores?level=senior", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        })
          .then((res) => res.json())
          .then((seniorData) => {
            // Combine junior and senior data
            const combinedData = [
              ...(juniorData.studentsScores || []),
              ...(seniorData.studentsScores || []),
            ];
            setStudentData(combinedData);
            setIsLoading(false);
          })
          .catch((seniorError) => {
            console.log("Error fetching senior scores:", seniorError);
            setIsLoading(false);
          });
      })
      .catch((juniorError) => {
        console.log("Error fetching junior scores:", juniorError);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const dataByStandard = {};

    studentData.forEach((student) => {
      const {
        standard,
        convertedMpiScore,
        mentalSpeedInSec,
        prideAccuracyScorePercentage,
        mpiConsistency,
        scores,
      } = student;
      const {
        awarenessScoreAccuracy,
        applicationScoreAccuracy,
        advantageScoreAccuracy,
      } = scores;
      if (!dataByStandard[standard]) {
        dataByStandard[standard] = {
          totalConvertedMpiScore: 0,
          totalMentalSpeedInSec: 0,
          totalPrideAccuracyScorePercentage: 0,
          totalMpiConsistency: 0,
          totalAwarenessScoreAccuracy: 0,
          totalApplicationScoreAccuracy: 0,
          totalAdvantageScoreAccuracy: 0,
          count: 0,
        };
      }
      dataByStandard[standard].totalConvertedMpiScore += parseFloat(convertedMpiScore);
      dataByStandard[standard].totalMentalSpeedInSec += parseFloat(mentalSpeedInSec);
      dataByStandard[standard].totalPrideAccuracyScorePercentage += parseFloat(prideAccuracyScorePercentage);
      dataByStandard[standard].totalMpiConsistency += parseFloat(mpiConsistency);
      dataByStandard[standard].totalAwarenessScoreAccuracy += parseFloat(awarenessScoreAccuracy);
      dataByStandard[standard].totalApplicationScoreAccuracy += parseFloat(applicationScoreAccuracy);
      dataByStandard[standard].totalAdvantageScoreAccuracy += parseFloat(advantageScoreAccuracy);
      dataByStandard[standard].count += 1;
    });

    const averagedData = {};
    for (let standard in dataByStandard) {
      averagedData[standard] = {
        averageConvertedMpiScore: (
          dataByStandard[standard].totalConvertedMpiScore / dataByStandard[standard].count
        ).toFixed(2),
        averageMentalSpeedInSec: (
          dataByStandard[standard].totalMentalSpeedInSec / dataByStandard[standard].count
        ).toFixed(2),
        averagePrideScoreAccuracyPercentage: (
          dataByStandard[standard].totalPrideAccuracyScorePercentage / dataByStandard[standard].count
        ).toFixed(2),
        averageMpiConsistency: (
          dataByStandard[standard].totalMpiConsistency / dataByStandard[standard].count
        ).toFixed(2),
        averageAwarenessScoreAccuracy: (
          dataByStandard[standard].totalAwarenessScoreAccuracy / dataByStandard[standard].count
        ).toFixed(2),
        averageApplicationScoreAccuracy: (
          dataByStandard[standard].totalApplicationScoreAccuracy / dataByStandard[standard].count
        ).toFixed(2),
        averageAdvantageScoreAccuracy: (
          dataByStandard[standard].totalAdvantageScoreAccuracy / dataByStandard[standard].count
        ).toFixed(2),
      };
    }

    setStandardData(averagedData);
  }, [studentData]);

  const getCategory = (score) => {
    if (score >= 0 && score <= 2) return 'Poor';
    if (score >= 2 && score <= 4) return 'Weak';
    if (score >= 4 && score <= 6) return 'Average';
    if (score >= 6 && score <= 8) return 'Good';
    if (score >= 8 && score <= 10) return 'Excellent';
    return 'Unknown';
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "sno",
        header: "S.No",
        size: 20,
        muiTableBodyCellProps: ({
          cell
          }) => ({
          sx: {
            color: '#7ABD32' ,
            fontWeight: '600',
            fontSize: '18px',
            
          }
        })
      },
      {
        accessorKey: "standard",
        header: "Class",
        size: 20,
        muiTableBodyCellProps: ({
          cell
        }) => ({
        sx: {
          color: '#EF4444' ,
          fontWeight:"700",
          fontSize: '17px',
        }
        })
      },
      {
        accessorKey: "averageConvertedMpiScore",
        header: " MPI Score",
        size: 20,
        muiTableBodyCellProps: ({
          cell
        }) => ({
        sx: {
          color: '#EF4444' ,
          fontWeight:"700",
          fontSize: '17px',
        }
        })
      },
      {
        accessorKey: "category",
        header: "Grade",
        size: 20,
        muiTableBodyCellProps: ({
          cell
        }) => ({
        sx: {
          color: '#EF4444' ,
          fontWeight:"700",
          fontSize: '17px',
        }
        })
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      Object.keys(standardData).map((standard, index) => ({
        sno: index + 1,
        standard,
        averageConvertedMpiScore: standardData[standard].averageConvertedMpiScore,
        category: getCategory(standardData[standard].averageConvertedMpiScore),
      })),
    [standardData]
  );

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
    <div className="relative bg-white p-2 md:p-5 rounded-lg w-[90%] md:w-[65%] max-w-[100%]">
      <div className="flex justify-end">
        
        <button
          className="bg-red-500 text-white py-1 px-2 rounded"
          onClick={close}
        >
          Close
        </button>
      </div>
      <p className="text-[#3b1886] font-semibold text-xl">OVERALL STUDENTS ASSIGNED</p>
        <Container sx={{ py: 2 }}>
          <MaterialReactTable
            columns={columns}
            data={data}
            state={{ isLoading }}
            muiTableHeadCellProps={{
              sx: {
                // backgroundColor: 'lightblue',
                borderRight: '1px solid rgba(224,224,224,1)',
                color: '#7ABD32',
                fontWeight: '700',
                fontSize: '16px',
              },
              align: 'center',
            }}
            muiTableBodyCellProps={{
              align: 'center',
              sx: {
                fontSize: '14px',
              },
            }}
            muiTableFooterCellProps={{
              align: 'center',
            }}
            initialState={{ pagination: { pageSize: 5, pageIndex: 0 } }}
          />
        </Container>
      </div>
    </div>
  );
};

export default AllMpiClass;




























//Speed

// import React, { useEffect, useMemo, useState } from "react";
// import { Container } from "@mui/material";
// import { MaterialReactTable } from "material-react-table";

// const AllMpiClass = () => {
//   const [studentData, setStudentData] = useState([]);
//   const [standardData, setStandardData] = useState({});
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     if (!token) {
//       console.log("Token not found");
//       setIsLoading(false);
//       return;
//     }

//     // Fetch junior scores
//     fetch("https://test.xqminds.com/api/schoolData/calculate-scores?level=junior", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         authorization: `Bearer ${token}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((juniorData) => {
//         // Fetch senior scores
//         fetch("https://test.xqminds.com/api/schoolData/calculate-scores?level=senior", {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             authorization: `Bearer ${token}`,
//           },
//         })
//           .then((res) => res.json())
//           .then((seniorData) => {
//             // Combine junior and senior data
//             const combinedData = [
//               ...(juniorData.studentsScores || []),
//               ...(seniorData.studentsScores || []),
//             ];
//             setStudentData(combinedData);
//             setIsLoading(false);
//           })
//           .catch((seniorError) => {
//             console.log("Error fetching senior scores:", seniorError);
//             setIsLoading(false);
//           });
//       })
//       .catch((juniorError) => {
//         console.log("Error fetching junior scores:", juniorError);
//         setIsLoading(false);
//       });
//   }, []);

//   useEffect(() => {
//     const dataByStandard = {};

//     studentData.forEach((student) => {
//       const {
//         standard,
//         convertedMpiScore,
//         mentalSpeedInSec,
//         prideAccuracyScorePercentage,
//         mpiConsistency,
//         scores,
//       } = student;
//       const {
//         awarenessScoreAccuracy,
//         applicationScoreAccuracy,
//         advantageScoreAccuracy,
//       } = scores;
//       if (!dataByStandard[standard]) {
//         dataByStandard[standard] = {
//           totalConvertedMpiScore: 0,
//           totalMentalSpeedInSec: 0,
//           totalPrideAccuracyScorePercentage: 0,
//           totalMpiConsistency: 0,
//           totalAwarenessScoreAccuracy: 0,
//           totalApplicationScoreAccuracy: 0,
//           totalAdvantageScoreAccuracy: 0,
//           count: 0,
//         };
//       }
//       dataByStandard[standard].totalConvertedMpiScore += parseFloat(convertedMpiScore);
//       dataByStandard[standard].totalMentalSpeedInSec += parseFloat(mentalSpeedInSec);
//       dataByStandard[standard].totalPrideAccuracyScorePercentage += parseFloat(prideAccuracyScorePercentage);
//       dataByStandard[standard].totalMpiConsistency += parseFloat(mpiConsistency);
//       dataByStandard[standard].totalAwarenessScoreAccuracy += parseFloat(awarenessScoreAccuracy);
//       dataByStandard[standard].totalApplicationScoreAccuracy += parseFloat(applicationScoreAccuracy);
//       dataByStandard[standard].totalAdvantageScoreAccuracy += parseFloat(advantageScoreAccuracy);
//       dataByStandard[standard].count += 1;
//     });

//     const averagedData = {};
//     for (let standard in dataByStandard) {
//       averagedData[standard] = {
//         averageConvertedMpiScore: (
//           dataByStandard[standard].totalConvertedMpiScore / dataByStandard[standard].count
//         ).toFixed(2),
//         averageMentalSpeedInSec: (
//           dataByStandard[standard].totalMentalSpeedInSec / dataByStandard[standard].count
//         ).toFixed(2),
//         averagePrideScoreAccuracyPercentage: (
//           dataByStandard[standard].totalPrideAccuracyScorePercentage / dataByStandard[standard].count
//         ).toFixed(2),
//         averageMpiConsistency: (
//           dataByStandard[standard].totalMpiConsistency / dataByStandard[standard].count
//         ).toFixed(2),
//         averageAwarenessScoreAccuracy: (
//           dataByStandard[standard].totalAwarenessScoreAccuracy / dataByStandard[standard].count
//         ).toFixed(2),
//         averageApplicationScoreAccuracy: (
//           dataByStandard[standard].totalApplicationScoreAccuracy / dataByStandard[standard].count
//         ).toFixed(2),
//         averageAdvantageScoreAccuracy: (
//           dataByStandard[standard].totalAdvantageScoreAccuracy / dataByStandard[standard].count
//         ).toFixed(2),
//       };
//     }

//     setStandardData(averagedData);
//   }, [studentData]);

//   const getCategory = (score) => {
//     if (score >= 0 && score <= 20) return 'Excellent';
//     if (score >= 20 && score <= 40) return 'Good';
//     if (score >= 40 && score <= 60) return 'Average';
//     if (score >= 60 && score <= 80) return 'Bad';
//     if (score >= 80 && score <= 100) return 'Poor';
//     return 'Unknown';
//   };

//   const columns = useMemo(
//     () => [
//       {
//         accessorKey: "sno",
//         header: "S.No",
//         size: 20,
//       },
//       {
//         accessorKey: "standard",
//         header: "Class",
//         size: 20,
//       },
//       {
//         accessorKey: "averageMentalSpeedInSec",
//         header: " Speed",
//         size: 20,
//       },
//       {
//         accessorKey: "category",
//         header: "Grade",
//         size: 20,
//       },
//     ],
//     []
//   );

//   const data = useMemo(
//     () =>
//       Object.keys(standardData).map((standard, index) => ({
//         sno: index + 1,
//         standard,
//         averageMentalSpeedInSec: standardData[standard].averageMentalSpeedInSec,
//         category: getCategory(standardData[standard].averageMentalSpeedInSec),
//       })),
//     [standardData]
//   );

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
//       <div className="relative bg-white p-5 rounded-lg w-[90%] max-w-[800px]">
//         <Container sx={{ py: 2 }}>
//           <MaterialReactTable
//             columns={columns}
//             data={data}
//             state={{ isLoading }}
//             muiTableHeadCellProps={{
//               sx: {
//                 backgroundColor: 'lightblue',
//                 borderRight: '1px solid rgba(224,224,224,1)',
//                 color: '#7ABD32',
//                 fontWeight: '700',
//                 fontSize: '16px',
//               },
//               align: 'center',
//             }}
//             muiTableBodyCellProps={{
//               align: 'center',
//               sx: {
//                 fontSize: '14px',
//               },
//             }}
//             muiTableFooterCellProps={{
//               align: 'center',
//             }}
//             initialState={{ pagination: { pageSize: 5, pageIndex: 0 } }}
//           />
//         </Container>
//       </div>
//     </div>
//   );
// };

// export default AllMpiClass;























//Accuracy





// import React, { useEffect, useMemo, useState } from "react";
// import { Container } from "@mui/material";
// import { MaterialReactTable } from "material-react-table";

// const AllMpiClass = () => {
//   const [studentData, setStudentData] = useState([]);
//   const [standardData, setStandardData] = useState({});
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     if (!token) {
//       console.log("Token not found");
//       setIsLoading(false);
//       return;
//     }

//     // Fetch junior scores
//     fetch("https://test.xqminds.com/api/schoolData/calculate-scores?level=junior", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         authorization: `Bearer ${token}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((juniorData) => {
//         // Fetch senior scores
//         fetch("https://test.xqminds.com/api/schoolData/calculate-scores?level=senior", {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             authorization: `Bearer ${token}`,
//           },
//         })
//           .then((res) => res.json())
//           .then((seniorData) => {
//             // Combine junior and senior data
//             const combinedData = [
//               ...(juniorData.studentsScores || []),
//               ...(seniorData.studentsScores || []),
//             ];
//             setStudentData(combinedData);
//             setIsLoading(false);
//           })
//           .catch((seniorError) => {
//             console.log("Error fetching senior scores:", seniorError);
//             setIsLoading(false);
//           });
//       })
//       .catch((juniorError) => {
//         console.log("Error fetching junior scores:", juniorError);
//         setIsLoading(false);
//       });
//   }, []);

//   useEffect(() => {
//     const dataByStandard = {};

//     studentData.forEach((student) => {
//       const {
//         standard,
//         convertedMpiScore,
//         mentalSpeedInSec,
//         prideAccuracyScorePercentage,
//         mpiConsistency,
//         scores,
//       } = student;
//       const {
//         awarenessScoreAccuracy,
//         applicationScoreAccuracy,
//         advantageScoreAccuracy,
//       } = scores;
//       if (!dataByStandard[standard]) {
//         dataByStandard[standard] = {
//           totalConvertedMpiScore: 0,
//           totalMentalSpeedInSec: 0,
//           totalPrideAccuracyScorePercentage: 0,
//           totalMpiConsistency: 0,
//           totalAwarenessScoreAccuracy: 0,
//           totalApplicationScoreAccuracy: 0,
//           totalAdvantageScoreAccuracy: 0,
//           count: 0,
//         };
//       }
//       dataByStandard[standard].totalConvertedMpiScore += parseFloat(convertedMpiScore);
//       dataByStandard[standard].totalMentalSpeedInSec += parseFloat(mentalSpeedInSec);
//       dataByStandard[standard].totalPrideAccuracyScorePercentage += parseFloat(prideAccuracyScorePercentage);
//       dataByStandard[standard].totalMpiConsistency += parseFloat(mpiConsistency);
//       dataByStandard[standard].totalAwarenessScoreAccuracy += parseFloat(awarenessScoreAccuracy);
//       dataByStandard[standard].totalApplicationScoreAccuracy += parseFloat(applicationScoreAccuracy);
//       dataByStandard[standard].totalAdvantageScoreAccuracy += parseFloat(advantageScoreAccuracy);
//       dataByStandard[standard].count += 1;
//     });

//     const averagedData = {};
//     for (let standard in dataByStandard) {
//       averagedData[standard] = {
//         averageConvertedMpiScore: (
//           dataByStandard[standard].totalConvertedMpiScore / dataByStandard[standard].count
//         ).toFixed(2),
//         averageMentalSpeedInSec: (
//           dataByStandard[standard].totalMentalSpeedInSec / dataByStandard[standard].count
//         ).toFixed(2),
//         averagePrideScoreAccuracyPercentage: (
//           dataByStandard[standard].totalPrideAccuracyScorePercentage / dataByStandard[standard].count
//         ).toFixed(2),
//         averageMpiConsistency: (
//           dataByStandard[standard].totalMpiConsistency / dataByStandard[standard].count
//         ).toFixed(2),
//         averageAwarenessScoreAccuracy: (
//           dataByStandard[standard].totalAwarenessScoreAccuracy / dataByStandard[standard].count
//         ).toFixed(2),
//         averageApplicationScoreAccuracy: (
//           dataByStandard[standard].totalApplicationScoreAccuracy / dataByStandard[standard].count
//         ).toFixed(2),
//         averageAdvantageScoreAccuracy: (
//           dataByStandard[standard].totalAdvantageScoreAccuracy / dataByStandard[standard].count
//         ).toFixed(2),
//       };
//     }

//     setStandardData(averagedData);
//   }, [studentData]);

//   const getCategory = (score) => {
//     if (score >= 0 && score <= 20) return 'Poor';
//     if (score >= 20 && score <= 40) return 'Bad';
//     if (score >= 40 && score <= 60) return 'Average';
//     if (score >= 60 && score <= 80) return 'Good';
//     if (score >= 80 && score <= 100) return 'Excellent';
//     return 'Unknown';
//   };

//   const columns = useMemo(
//     () => [
//       {
//         accessorKey: "sno",
//         header: "S.No",
//         size: 20,
//       },
//       {
//         accessorKey: "standard",
//         header: "Class",
//         size: 20,
//       },
//       {
//         accessorKey: "averagePrideScoreAccuracyPercentage",
//         header: " Accuracy",
//         size: 20,
//       },
//       {
//         accessorKey: "category",
//         header: "Grade",
//         size: 20,
//       },
//     ],
//     []
//   );

//   const data = useMemo(
//     () =>
//       Object.keys(standardData).map((standard, index) => ({
//         sno: index + 1,
//         standard,
//         averagePrideScoreAccuracyPercentage: standardData[standard].averagePrideScoreAccuracyPercentage,
//         category: getCategory(standardData[standard].averagePrideScoreAccuracyPercentage),
//       })),
//     [standardData]
//   );

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
//       <div className="relative bg-white p-5 rounded-lg w-[90%] max-w-[800px]">
//         <Container sx={{ py: 2 }}>
//           <MaterialReactTable
//             columns={columns}
//             data={data}
//             state={{ isLoading }}
//             muiTableHeadCellProps={{
//               sx: {
//                 backgroundColor: 'lightblue',
//                 borderRight: '1px solid rgba(224,224,224,1)',
//                 color: '#7ABD32',
//                 fontWeight: '700',
//                 fontSize: '16px',
//               },
//               align: 'center',
//             }}
//             muiTableBodyCellProps={{
//               align: 'center',
//               sx: {
//                 fontSize: '14px',
//               },
//             }}
//             muiTableFooterCellProps={{
//               align: 'center',
//             }}
//             initialState={{ pagination: { pageSize: 5, pageIndex: 0 } }}
//           />
//         </Container>
//       </div>
//     </div>
//   );
// };

// export default AllMpiClass;



































//  Basic Intelligence 




// import React, { useEffect, useMemo, useState } from "react";
// import { Container } from "@mui/material";
// import { MaterialReactTable } from "material-react-table";

// const AllMpiClass = () => {
//   const [studentData, setStudentData] = useState([]);
//   const [standardData, setStandardData] = useState({});
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     if (!token) {
//       console.log("Token not found");
//       setIsLoading(false);
//       return;
//     }

//     // Fetch junior scores
//     fetch("https://test.xqminds.com/api/schoolData/calculate-scores?level=junior", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         authorization: `Bearer ${token}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((juniorData) => {
//         // Fetch senior scores
//         fetch("https://test.xqminds.com/api/schoolData/calculate-scores?level=senior", {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             authorization: `Bearer ${token}`,
//           },
//         })
//           .then((res) => res.json())
//           .then((seniorData) => {
//             // Combine junior and senior data
//             const combinedData = [
//               ...(juniorData.studentsScores || []),
//               ...(seniorData.studentsScores || []),
//             ];
//             setStudentData(combinedData);
//             setIsLoading(false);
//           })
//           .catch((seniorError) => {
//             console.log("Error fetching senior scores:", seniorError);
//             setIsLoading(false);
//           });
//       })
//       .catch((juniorError) => {
//         console.log("Error fetching junior scores:", juniorError);
//         setIsLoading(false);
//       });
//   }, []);

//   useEffect(() => {
//     const dataByStandard = {};

//     studentData.forEach((student) => {
//       const {
//         standard,
//         convertedMpiScore,
//         mentalSpeedInSec,
//         prideAccuracyScorePercentage,
//         mpiConsistency,
//         scores,
//       } = student;
//       const {
//         awarenessScoreAccuracy,
//         applicationScoreAccuracy,
//         advantageScoreAccuracy,
//       } = scores;
//       if (!dataByStandard[standard]) {
//         dataByStandard[standard] = {
//           totalConvertedMpiScore: 0,
//           totalMentalSpeedInSec: 0,
//           totalPrideAccuracyScorePercentage: 0,
//           totalMpiConsistency: 0,
//           totalAwarenessScoreAccuracy: 0,
//           totalApplicationScoreAccuracy: 0,
//           totalAdvantageScoreAccuracy: 0,
//           count: 0,
//         };
//       }
//       dataByStandard[standard].totalConvertedMpiScore += parseFloat(convertedMpiScore);
//       dataByStandard[standard].totalMentalSpeedInSec += parseFloat(mentalSpeedInSec);
//       dataByStandard[standard].totalPrideAccuracyScorePercentage += parseFloat(prideAccuracyScorePercentage);
//       dataByStandard[standard].totalMpiConsistency += parseFloat(mpiConsistency);
//       dataByStandard[standard].totalAwarenessScoreAccuracy += parseFloat(awarenessScoreAccuracy);
//       dataByStandard[standard].totalApplicationScoreAccuracy += parseFloat(applicationScoreAccuracy);
//       dataByStandard[standard].totalAdvantageScoreAccuracy += parseFloat(advantageScoreAccuracy);
//       dataByStandard[standard].count += 1;
//     });

//     const averagedData = {};
//     for (let standard in dataByStandard) {
//       averagedData[standard] = {
//         averageConvertedMpiScore: (
//           dataByStandard[standard].totalConvertedMpiScore / dataByStandard[standard].count
//         ).toFixed(2),
//         averageMentalSpeedInSec: (
//           dataByStandard[standard].totalMentalSpeedInSec / dataByStandard[standard].count
//         ).toFixed(2),
//         averagePrideScoreAccuracyPercentage: (
//           dataByStandard[standard].totalPrideAccuracyScorePercentage / dataByStandard[standard].count
//         ).toFixed(2),
//         averageMpiConsistency: (
//           dataByStandard[standard].totalMpiConsistency / dataByStandard[standard].count
//         ).toFixed(2),
//         averageAwarenessScoreAccuracy: (
//           dataByStandard[standard].totalAwarenessScoreAccuracy / dataByStandard[standard].count
//         ).toFixed(2),
//         averageApplicationScoreAccuracy: (
//           dataByStandard[standard].totalApplicationScoreAccuracy / dataByStandard[standard].count
//         ).toFixed(2),
//         averageAdvantageScoreAccuracy: (
//           dataByStandard[standard].totalAdvantageScoreAccuracy / dataByStandard[standard].count
//         ).toFixed(2),
//       };
//     }

//     setStandardData(averagedData);
//   }, [studentData]);

//   const getCategory = (score) => {
//     if (score >= 0 && score <= 20) return 'Poor';
//     if (score >= 20 && score <= 40) return 'Bad';
//     if (score >= 40 && score <= 60) return 'Average';
//     if (score >= 60 && score <= 80) return 'Good';
//     if (score >= 80 && score <= 100) return 'Excellent';
//     return 'Unknown';
//   };

//   const columns = useMemo(
//     () => [
//       {
//         accessorKey: "sno",
//         header: "S.No",
//         size: 20,
//       },
//       {
//         accessorKey: "standard",
//         header: "Class",
//         size: 20,
//       },
//       {
//         accessorKey: "averageAwarenessScoreAccuracy",
//         header: "  Basic Intelligence ",
//         size: 20,
//       },
//       {
//         accessorKey: "category",
//         header: "Grade",
//         size: 20,
//       },
//     ],
//     []
//   );

//   const data = useMemo(
//     () =>
//       Object.keys(standardData).map((standard, index) => ({
//         sno: index + 1,
//         standard,
//         averageAwarenessScoreAccuracy: standardData[standard].averageAwarenessScoreAccuracy,
//         category: getCategory(standardData[standard].averageAwarenessScoreAccuracy),
//       })),
//     [standardData]
//   );

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
//       <div className="relative bg-white p-5 rounded-lg w-[90%] max-w-[800px]">
//         <Container sx={{ py: 2 }}>
//           <MaterialReactTable
//             columns={columns}
//             data={data}
//             state={{ isLoading }}
//             muiTableHeadCellProps={{
//               sx: {
//                 backgroundColor: 'lightblue',
//                 borderRight: '1px solid rgba(224,224,224,1)',
//                 color: '#7ABD32',
//                 fontWeight: '700',
//                 fontSize: '16px',
//               },
//               align: 'center',
//             }}
//             muiTableBodyCellProps={{
//               align: 'center',
//               sx: {
//                 fontSize: '14px',
//               },
//             }}
//             muiTableFooterCellProps={{
//               align: 'center',
//             }}
//             initialState={{ pagination: { pageSize: 5, pageIndex: 0 } }}
//           />
//         </Container>
//       </div>
//     </div>
//   );
// };

// export default AllMpiClass;































// // Situational Intelligence



// import React, { useEffect, useMemo, useState } from "react";
// import { Container } from "@mui/material";
// import { MaterialReactTable } from "material-react-table";

// const AllMpiClass = () => {
//   const [studentData, setStudentData] = useState([]);
//   const [standardData, setStandardData] = useState({});
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     if (!token) {
//       console.log("Token not found");
//       setIsLoading(false);
//       return;
//     }

//     // Fetch junior scores
//     fetch("https://test.xqminds.com/api/schoolData/calculate-scores?level=junior", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         authorization: `Bearer ${token}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((juniorData) => {
//         // Fetch senior scores
//         fetch("https://test.xqminds.com/api/schoolData/calculate-scores?level=senior", {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             authorization: `Bearer ${token}`,
//           },
//         })
//           .then((res) => res.json())
//           .then((seniorData) => {
//             // Combine junior and senior data
//             const combinedData = [
//               ...(juniorData.studentsScores || []),
//               ...(seniorData.studentsScores || []),
//             ];
//             setStudentData(combinedData);
//             setIsLoading(false);
//           })
//           .catch((seniorError) => {
//             console.log("Error fetching senior scores:", seniorError);
//             setIsLoading(false);
//           });
//       })
//       .catch((juniorError) => {
//         console.log("Error fetching junior scores:", juniorError);
//         setIsLoading(false);
//       });
//   }, []);

//   useEffect(() => {
//     const dataByStandard = {};

//     studentData.forEach((student) => {
//       const {
//         standard,
//         convertedMpiScore,
//         mentalSpeedInSec,
//         prideAccuracyScorePercentage,
//         mpiConsistency,
//         scores,
//       } = student;
//       const {
//         awarenessScoreAccuracy,
//         applicationScoreAccuracy,
//         advantageScoreAccuracy,
//       } = scores;
//       if (!dataByStandard[standard]) {
//         dataByStandard[standard] = {
//           totalConvertedMpiScore: 0,
//           totalMentalSpeedInSec: 0,
//           totalPrideAccuracyScorePercentage: 0,
//           totalMpiConsistency: 0,
//           totalAwarenessScoreAccuracy: 0,
//           totalApplicationScoreAccuracy: 0,
//           totalAdvantageScoreAccuracy: 0,
//           count: 0,
//         };
//       }
//       dataByStandard[standard].totalConvertedMpiScore += parseFloat(convertedMpiScore);
//       dataByStandard[standard].totalMentalSpeedInSec += parseFloat(mentalSpeedInSec);
//       dataByStandard[standard].totalPrideAccuracyScorePercentage += parseFloat(prideAccuracyScorePercentage);
//       dataByStandard[standard].totalMpiConsistency += parseFloat(mpiConsistency);
//       dataByStandard[standard].totalAwarenessScoreAccuracy += parseFloat(awarenessScoreAccuracy);
//       dataByStandard[standard].totalApplicationScoreAccuracy += parseFloat(applicationScoreAccuracy);
//       dataByStandard[standard].totalAdvantageScoreAccuracy += parseFloat(advantageScoreAccuracy);
//       dataByStandard[standard].count += 1;
//     });

//     const averagedData = {};
//     for (let standard in dataByStandard) {
//       averagedData[standard] = {
//         averageConvertedMpiScore: (
//           dataByStandard[standard].totalConvertedMpiScore / dataByStandard[standard].count
//         ).toFixed(2),
//         averageMentalSpeedInSec: (
//           dataByStandard[standard].totalMentalSpeedInSec / dataByStandard[standard].count
//         ).toFixed(2),
//         averagePrideScoreAccuracyPercentage: (
//           dataByStandard[standard].totalPrideAccuracyScorePercentage / dataByStandard[standard].count
//         ).toFixed(2),
//         averageMpiConsistency: (
//           dataByStandard[standard].totalMpiConsistency / dataByStandard[standard].count
//         ).toFixed(2),
//         averageAwarenessScoreAccuracy: (
//           dataByStandard[standard].totalAwarenessScoreAccuracy / dataByStandard[standard].count
//         ).toFixed(2),
//         averageApplicationScoreAccuracy: (
//           dataByStandard[standard].totalApplicationScoreAccuracy / dataByStandard[standard].count
//         ).toFixed(2),
//         averageAdvantageScoreAccuracy: (
//           dataByStandard[standard].totalAdvantageScoreAccuracy / dataByStandard[standard].count
//         ).toFixed(2),
//       };
//     }

//     setStandardData(averagedData);
//   }, [studentData]);

//   const getCategory = (score) => {
//     if (score >= 0 && score <= 20) return 'Poor';
//     if (score >= 20 && score <= 40) return 'Bad';
//     if (score >= 40 && score <= 60) return 'Average';
//     if (score >= 60 && score <= 80) return 'Good';
//     if (score >= 80 && score <= 100) return 'Excellent';
//     return 'Unknown';
//   };

//   const columns = useMemo(
//     () => [
//       {
//         accessorKey: "sno",
//         header: "S.No",
//         size: 20,
//       },
//       {
//         accessorKey: "standard",
//         header: "Class",
//         size: 20,
//       },
//       {
//         accessorKey: "averageAdvantageScoreAccuracy",
//         header: "Situational Intelligence",
//         size: 20,
//       },
//       {
//         accessorKey: "category",
//         header: "Grade",
//         size: 20,
//       },
//     ],
//     []
//   );

//   const data = useMemo(
//     () =>
//       Object.keys(standardData).map((standard, index) => ({
//         sno: index + 1,
//         standard,
//         averageAdvantageScoreAccuracy: standardData[standard].averageAdvantageScoreAccuracy,
//         category: getCategory(standardData[standard].averageAdvantageScoreAccuracy),
//       })),
//     [standardData]
//   );

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
//       <div className="relative bg-white p-5 rounded-lg w-[90%] max-w-[800px]">
//         <Container sx={{ py: 2 }}>
//           <MaterialReactTable
//             columns={columns}
//             data={data}
//             state={{ isLoading }}
//             muiTableHeadCellProps={{
//               sx: {
//                 backgroundColor: 'lightblue',
//                 borderRight: '1px solid rgba(224,224,224,1)',
//                 color: '#7ABD32',
//                 fontWeight: '700',
//                 fontSize: '16px',
//               },
//               align: 'center',
//             }}
//             muiTableBodyCellProps={{
//               align: 'center',
//               sx: {
//                 fontSize: '14px',
//               },
//             }}
//             muiTableFooterCellProps={{
//               align: 'center',
//             }}
//             initialState={{ pagination: { pageSize: 5, pageIndex: 0 } }}
//           />
//         </Container>
//       </div>
//     </div>
//   );
// };

// export default AllMpiClass;

























// Applying Intelligence




// import React, { useEffect, useMemo, useState } from "react";
// import { Container } from "@mui/material";
// import { MaterialReactTable } from "material-react-table";

// const AllMpiClass = () => {
//   const [studentData, setStudentData] = useState([]);
//   const [standardData, setStandardData] = useState({});
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     if (!token) {
//       console.log("Token not found");
//       setIsLoading(false);
//       return;
//     }

//     // Fetch junior scores
//     fetch("https://test.xqminds.com/api/schoolData/calculate-scores?level=junior", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         authorization: `Bearer ${token}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((juniorData) => {
//         // Fetch senior scores
//         fetch("https://test.xqminds.com/api/schoolData/calculate-scores?level=senior", {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             authorization: `Bearer ${token}`,
//           },
//         })
//           .then((res) => res.json())
//           .then((seniorData) => {
//             // Combine junior and senior data
//             const combinedData = [
//               ...(juniorData.studentsScores || []),
//               ...(seniorData.studentsScores || []),
//             ];
//             setStudentData(combinedData);
//             setIsLoading(false);
//           })
//           .catch((seniorError) => {
//             console.log("Error fetching senior scores:", seniorError);
//             setIsLoading(false);
//           });
//       })
//       .catch((juniorError) => {
//         console.log("Error fetching junior scores:", juniorError);
//         setIsLoading(false);
//       });
//   }, []);

//   useEffect(() => {
//     const dataByStandard = {};

//     studentData.forEach((student) => {
//       const {
//         standard,
//         convertedMpiScore,
//         mentalSpeedInSec,
//         prideAccuracyScorePercentage,
//         mpiConsistency,
//         scores,
//       } = student;
//       const {
//         awarenessScoreAccuracy,
//         applicationScoreAccuracy,
//         advantageScoreAccuracy,
//       } = scores;
//       if (!dataByStandard[standard]) {
//         dataByStandard[standard] = {
//           totalConvertedMpiScore: 0,
//           totalMentalSpeedInSec: 0,
//           totalPrideAccuracyScorePercentage: 0,
//           totalMpiConsistency: 0,
//           totalAwarenessScoreAccuracy: 0,
//           totalApplicationScoreAccuracy: 0,
//           totalAdvantageScoreAccuracy: 0,
//           count: 0,
//         };
//       }
//       dataByStandard[standard].totalConvertedMpiScore += parseFloat(convertedMpiScore);
//       dataByStandard[standard].totalMentalSpeedInSec += parseFloat(mentalSpeedInSec);
//       dataByStandard[standard].totalPrideAccuracyScorePercentage += parseFloat(prideAccuracyScorePercentage);
//       dataByStandard[standard].totalMpiConsistency += parseFloat(mpiConsistency);
//       dataByStandard[standard].totalAwarenessScoreAccuracy += parseFloat(awarenessScoreAccuracy);
//       dataByStandard[standard].totalApplicationScoreAccuracy += parseFloat(applicationScoreAccuracy);
//       dataByStandard[standard].totalAdvantageScoreAccuracy += parseFloat(advantageScoreAccuracy);
//       dataByStandard[standard].count += 1;
//     });

//     const averagedData = {};
//     for (let standard in dataByStandard) {
//       averagedData[standard] = {
//         averageConvertedMpiScore: (
//           dataByStandard[standard].totalConvertedMpiScore / dataByStandard[standard].count
//         ).toFixed(2),
//         averageMentalSpeedInSec: (
//           dataByStandard[standard].totalMentalSpeedInSec / dataByStandard[standard].count
//         ).toFixed(2),
//         averagePrideScoreAccuracyPercentage: (
//           dataByStandard[standard].totalPrideAccuracyScorePercentage / dataByStandard[standard].count
//         ).toFixed(2),
//         averageMpiConsistency: (
//           dataByStandard[standard].totalMpiConsistency / dataByStandard[standard].count
//         ).toFixed(2),
//         averageAwarenessScoreAccuracy: (
//           dataByStandard[standard].totalAwarenessScoreAccuracy / dataByStandard[standard].count
//         ).toFixed(2),
//         averageApplicationScoreAccuracy: (
//           dataByStandard[standard].totalApplicationScoreAccuracy / dataByStandard[standard].count
//         ).toFixed(2),
//         averageAdvantageScoreAccuracy: (
//           dataByStandard[standard].totalAdvantageScoreAccuracy / dataByStandard[standard].count
//         ).toFixed(2),
//       };
//     }

//     setStandardData(averagedData);
//   }, [studentData]);

//   const getCategory = (score) => {
//     if (score >= 0 && score <= 20) return 'Poor';
//     if (score >= 20 && score <= 40) return 'Bad';
//     if (score >= 40 && score <= 60) return 'Average';
//     if (score >= 60 && score <= 80) return 'Good';
//     if (score >= 80 && score <= 100) return 'Excellent';
//     return 'Unknown';
//   };

//   const columns = useMemo(
//     () => [
//       {
//         accessorKey: "sno",
//         header: "S.No",
//         size: 20,
//       },
//       {
//         accessorKey: "standard",
//         header: "Class",
//         size: 20,
//       },
//       {
//         accessorKey: "averageApplicationScoreAccuracy",
//         header: "Applying Intelligence",
//         size: 20,
//       },
//       {
//         accessorKey: "category",
//         header: "Grade",
//         size: 20,
//       },
//     ],
//     []
//   );

//   const data = useMemo(
//     () =>
//       Object.keys(standardData).map((standard, index) => ({
//         sno: index + 1,
//         standard,
//         averageApplicationScoreAccuracy: standardData[standard].averageApplicationScoreAccuracy,
//         category: getCategory(standardData[standard].averageApplicationScoreAccuracy),
//       })),
//     [standardData]
//   );

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
//       <div className="relative bg-white p-5 rounded-lg w-[90%] max-w-[800px]">
//         <Container sx={{ py: 2 }}>
//           <MaterialReactTable
//             columns={columns}
//             data={data}
//             state={{ isLoading }}
//             muiTableHeadCellProps={{
//               sx: {
//                 backgroundColor: 'lightblue',
//                 borderRight: '1px solid rgba(224,224,224,1)',
//                 color: '#7ABD32',
//                 fontWeight: '700',
//                 fontSize: '16px',
//               },
//               align: 'center',
//             }}
//             muiTableBodyCellProps={{
//               align: 'center',
//               sx: {
//                 fontSize: '14px',
//               },
//             }}
//             muiTableFooterCellProps={{
//               align: 'center',
//             }}
//             initialState={{ pagination: { pageSize: 5, pageIndex: 0 } }}
//           />
//         </Container>
//       </div>
//     </div>
//   );
// };

// export default AllMpiClass;
