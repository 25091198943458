// import React, { createContext, useContext, useState, useEffect } from 'react';

// const StandardContext = createContext();

// export const StandardProvider = ({ children }) => {
//   const [selectedStandard, setSelectedStandard] = useState('school');
//   const [standardsInSchool, setStandardsInSchool] = useState([]);
//   const [categoryMapping, setCategoryMapping] = useState({});
  
//   useEffect(() => {
//     const token = localStorage.getItem("token");

//     fetch(`https://test.xqminds.com/api/schoolData/section-wise-result?standard=6K&level=junior`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         "authorization": `Bearer ${token}`
//       }
//     })
//       .then((res) => res.json())
//       .then((res) => {
//         const fetchedStandards = res.standardsInSchool;
//         const mapping = {};
//         fetchedStandards.forEach(standard => {
//           if (standard.startsWith('5') || standard.startsWith('6')) {
//             mapping[standard] = 'junior';
//           } else if (standard.startsWith('7') || standard.startsWith('8')) {
//             mapping[standard] = 'senior';
//           } else if (standard.startsWith('9') || standard.startsWith('10') || standard.startsWith('11') || standard.startsWith('12')) {
//             mapping[standard] = 'youth';
//           }
//         });
//         setStandardsInSchool(fetchedStandards);
//         setCategoryMapping(mapping);
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//       });
//   }, []);

//   return (
//     <StandardContext.Provider value={{ selectedStandard, setSelectedStandard, standardsInSchool, categoryMapping }}>
//       {children}
//     </StandardContext.Provider>
//   );
// };

// export const useStandard = () => useContext(StandardContext);
















//this is the correct code


import React, { createContext, useContext, useState, useEffect } from 'react';

const StandardContext = createContext();

export const StandardProvider = ({ children }) => {
  const [selectedStandard, setSelectedStandard] = useState('school');
  const [standardsInSchool, setStandardsInSchool] = useState([]);
  const [categoryMapping, setCategoryMapping] = useState({});
  
  useEffect(() => {
    const token = localStorage.getItem("token");

    fetch(`https://test.xqminds.com/api/schoolData/section-wise-result?standard=6K&level=junior`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authorization": `Bearer ${token}`
      }
    })
      .then((res) => res.json())
      .then((res) => {
        const fetchedStandards = res.standardsInSchool;
        const mapping = {};
        fetchedStandards.forEach(standard => {
          if (standard.startsWith('5') || standard.startsWith('6')) {
            mapping[standard] = 'junior';
          } else if (standard.startsWith('7') || standard.startsWith('8')) {
            mapping[standard] = 'senior';
          } else if (standard.startsWith('9') || standard.startsWith('10') || standard.startsWith('11') || standard.startsWith('12')) {
            mapping[standard] = 'youth';
          }
        });
        setStandardsInSchool(fetchedStandards);
        setCategoryMapping(mapping);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <StandardContext.Provider value={{ selectedStandard, setSelectedStandard, standardsInSchool, categoryMapping }}>
      {children}
    </StandardContext.Provider>
  );
};

export const useStandard = () => useContext(StandardContext);
 