

// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import Sidebar from "./Sidebar/Sidebar";
// import Signin from "../image/Signin.gif";
// import bgPride from "../image/bgPride.png";
// // import { useToast } from "@chakra-ui/react";
// import open from "../../src/image/openEye.png"
// import close from "../../src/image/closeEye.png"
// // import toast, { Toaster } from "react-hot-toast";
// import Toast from './Toast/Toast';
// export default function Login() {
//   const [userType, setUserType] = useState('student');
//   const [credentials, setCredentials] = useState({
//     email: '',
//     password: ''
//   });
//    const [showToast, setShowToast] = useState(true);
//    const [load, setload] = useState(false);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const navigate = useNavigate();
//   // const toast = useToast()
//   // const handleChange = (e) => {
//   //   setCredentials({
//   //     ...credentials,
//   //     [e.target.name]: e.target.value
//   //   });
//   // };

//   const handleUserTypeChange = (e) => {
//     setUserType(e.target.value);
//   };

//   // const handleSubmit = async (e) => {
//   //   e.preventDefault();
//   //   const url = userType === 'student' ? 'https://test.xqminds.com/api/student/login' : 'https://reqres.in/api/login';
    
//   //   // Example fetch request (adjust as needed for your backend)
//   //   const response = await fetch(url, {
//   //     method: 'POST',
//   //     headers: {
//   //       'Content-Type': 'application/json'
//   //     },
//   //     body: JSON.stringify(credentials)
//   //   });
    
//   //   if (response.ok) {
//   //     const data = await response.json();
//   //     // console.log('Login successful', data);
//   //     if (userType === 'student') {
//   //       naviagte('/roadmap');
//   //     } else {
//   //       naviagte('/schooldashboard');
//   //     }
//   //   } else {
//   //     console.error('Login failed');
//   //   }
//   // };

//   const [toasts, setToasts] = useState([]);

//   const addToast = (type, message) => {
//     const id = Date.now();
//     setToasts([...toasts, { id, type, message }]);
//     setTimeout(() => removeToast(id), 3000); // Auto remove toast after 3 seconds
//   };

//   const removeToast = (id) => {
//     setToasts(toasts.filter(toast => toast.id !== id));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const url = userType === 'student' ? 'https://test.xqminds.com/api/student/login' : 'https://test.xqminds.com/api/school/login';

//     setload(true);
//     const payload = {
//       email,
//       password,
//     };
//     //console.log(payload);
//     fetch(url, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(payload),
//     })
//       .then((res) => res.json())
//       .then((res) => {
//         //  console.log(res,"res")

//         if(res.msg=="Login Successfull!!"){
//           localStorage.setItem("token", res.token);
//           setIsLoggedIn(true);
//           addToast('success', 'Login successful!');
//           // toast("login sucessfully",{
//           //   duration: 2000,
//           //   style: {
//           //     background: 'white',
//           //     color: '#167D5A',
//           //   },
//           // })
//           // toast({
//           //   description: `login sucessfully`,
//           //   status: "success",
//           //   duration: 4000,
//           //   position: "top",
//           //   isClosable: true,
//           // });
//           // alert("Login Successful");
//           // navigate("/roadmap");
//           if (userType === 'student') {
//             navigate('/roadmap');
//           } else {
//             navigate('/schooldashboard');
//           }
//         }
//      else if (res.msg=="Wrong Credential!!"){
//       // toast({
//       //   description: `Wrong Credential!!`,
//       //   status: "warning",
//       //   duration: 4000,
//       //   position: "top",
//       //   isClosable: true,
//       // });
//       // alert("wrong credential. Please try again.");
//       setload(false);
//     }
//       })
//             .catch((err) => {
//         console.log(err.message)
//         // toast({
//         //   description: `${err.message}`,
//         //   status: "error",
//         //   duration: 4000,
//         //   position: "top",
//         //   isClosable: true,
//         // });
//       })

//     setEmail("");
//     setPassword("");

//     setTimeout(() => {
//       setShowToast(false);
//     }, 4000);
//   };
//   return (
//     <div className="relative bg-center h-screen">
//       {/* <Toaster/> */}
//       <img
//         src={bgPride}
//         alt="Background"
//         className="absolute inset-0 object-cover w-full h-full"
//       />

//       <div className="absolute flex min-h-full ml-[200px] justify-start">
//         <div className="flex flex-col sm:mx-auto sm:w-full sm:max-w-sm">
//           <div className="flex justify-center items-center">
//             <img src={Signin} className="w-[150px]" />
//           </div>

//           <div>
//             <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-blue-950">
//               Sign in to your account
//             </h2>
//             <p className="text-gray-400 mt-3">
//               Enter your email & password to login
//             </p>
//           </div>

//           <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
//           <form 
//           onSubmit={handleSubmit}
//           action="#"
//           method="POST"
//           >
//           <div className="flex justify-around mb-4">
        
//           <label
//            className="flex items-center"
//            >
//             <input
//               type="radio"
//               value="student"
//               checked={userType === 'student'}
//               onChange={handleUserTypeChange}
//             />
//            <span className="ml-2">Student</span>
//           </label>
//           <label className="flex items-center">
//             <input
//               type="radio"
//               value="school"
//               checked={userType === 'school'}
//               onChange={handleUserTypeChange}
//             />
//             <span className="ml-2">School</span>
//           </label>
//         </div>
//         <div>
//         <label
//                   htmlFor="email"
//                   className="block text-md font-medium leading-6 mr-[280px] text-blue-800"
//                 >
//                   Email address
//                 </label>
//                 <div className="mt-2">
//                   <input
//                      type="text"
//                      name="email"
//                      value={email}
//                     //  onChange={handleChange}
//                     onChange={(e) => setEmail(e.target.value)}
//                     autoComplete="email"
//                     required
                    
                   
//                     placeholder="test@gmail.com"
//                     className="block w-full rounded-md border-0 py-1 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                   />
//                 </div>
        
//         </div>
       
//         <div>
//                 <div className="flex items-center mt-4 justify-between">
//                   <label
//                     htmlFor="password"
//                     className="block text-md font-medium leading-6 text-blue-800"
//                   >
//                     Password
//                   </label>
//                   <div className="text-sm">
//                     <a
//                       href="#"
//                       className="font-semibold text-indigo-600 hover:text-indigo-500"
//                     >
//                       {/* Forgot password? */}
//                     </a>
//                   </div>
//                 </div>
//                 <div className="mt-2 relative">
//                   <input
//                   type="password"
//                   name="password"
//                   value={password}
//                   // onChange={handleChange}
//                   onChange={(e) => setPassword(e.target.value)}
//                     id="password"
                    
//                     // type={showPassword ? "text" : "password"} // Toggle input type
//                     autoComplete="current-password"
//                     required
//                     placeholder="*******"
//                     className="block w-full rounded-md border-0 py-1 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                   />



//                   {/* <button
//                     type="button"
//                     className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
//                     onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state
//                   >
//                     {showPassword ? <img className="w-[20px]" src={open}/> : <img className="w-[20px]" src={close}/>}
//                   </button> */}





//                 </div>
//               </div>
//               {/* <button
// //                   type="submit"
// //                   className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
// //                   {load ? "Checking your details" : " Sign in"}
// //                 </button> */}
//         <button type="submit" 
//         className="flex w-full mt-4 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
//         >Sign in</button>
//           {/* <button >Show Success Toast</button> */}

//       </form>
//       <div className="toasts">
//         {toasts.map(toast => (
//           <Toast
//             key={toast.id}
//             type={toast.type}
//             message={toast.message}
//             onClose={() => removeToast(toast.id)}
//           />
//         ))}
//       </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
























import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import Signin from "../image/Signin.gif";
import bgPride from "../image/bgPride.png";
import Toast from './Toast/Toast';
import mobileloginbg from "../../src/image/mobileloginbg.png"

export default function Login() {
  const [userType, setUserType] = useState('student');
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [toasts, setToasts] = useState([]);
  const [load, setload] = useState(false);
  const navigate = useNavigate();

  const addToast = (type, message) => {
    const id = Date.now();
    setToasts([...toasts, { id, type, message }]);
    setTimeout(() => removeToast(id), 9000); // Auto remove toast after 4 seconds
  };

  const removeToast = (id) => {
    setToasts(toasts.filter(toast => toast.id !== id));
  };

  const handleUserTypeChange = (e) => {
    setUserType(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = userType === 'student' ? 'https://test.xqminds.com/api/student/login' : 'https://test.xqminds.com/api/school/login';

    setload(true);
    const payload = {
      email,
      password,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
         console.log(res, "res name")
        if (res.msg === "Login Successfull!!") {
          localStorage.setItem("token", res.token);
          localStorage.setItem("username", res.name);
          localStorage.setItem("gender", res.gender);
          localStorage.setItem("photo", res.photo);
          setIsLoggedIn(true);
          addToast('success', 'Login successful!');
          
          if (userType === 'student') {
            navigate('/roadmap');
          } else {
            navigate('/schooldashboard');
          }
        } else if (res.msg === "Wrong Credential!!") {
          addToast('warning', 'Wrong credentials. Please try again.');
          setload(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        addToast('danger', `Error: ${err.message}`);
        setload(false);
      });

    setEmail("");
    setPassword("");
  };

  return (
    <div className="relative w-full bg-center h-screen ">
      <img
        src={bgPride}
        alt="Background"
        className="absolute inset-0 object-cover w-[100%] h-full md:block hidden "
      />
      <div className="absolute flex min-h-full  md:justify-start md:ml-[200px] justify-start">
        <div className="flex flex-col md:w-[100%] p-2 m-auto ">
          <div className="flex justify-center items-center">
            <img src={Signin} className="w-[150px]  md:block hidden" />
            <img
        src={mobileloginbg}
        alt="Mobile Background"
        className="md:hidden  w-full h-full"
      />
          </div>

          <div>
            <h2 className=" mt-20 font-fredoka md:mt-10 text-center text-2xl font-medium leading-9 tracking-tight text-blue-950">
              Sign in to your account
            </h2>
            <p className="text-gray-400 mt-3">
              Enter your email & password to login
            </p>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={handleSubmit} action="#" method="POST">
              <div className="flex justify-around mb-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="student"
                    checked={userType === 'student'}
                    onChange={handleUserTypeChange}
                  />
                  <span className="ml-2"> Student</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="school"
                    checked={userType === 'school'}
                    onChange={handleUserTypeChange}
                  />
                  <span className="ml-2">School</span>
                </label>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-md font-fredoka font-medium leading-6 mr-[280px] text-blue-800"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="email"
                    required
                    placeholder="test@gmail.com"
                    className="block w-full rounded-md border-0 py-1 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <div className="flex items-center mt-4 justify-between">
                  <label
                    htmlFor="password"
                    className="block text-md font-fredoka font-medium leading-6 text-blue-800"
                  >
                    Password
                  </label>
                  <div className="text-sm">
                    <a
                      href="#"
                      className="font-semibold text-indigo-600 hover:text-indigo-500"
                    >
                      {/* Forgot password? */}
                    </a>
                  </div>
                </div>
                <div className="mt-2 relative">
                  <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    autoComplete="current-password"
                    required
                    placeholder="*******"
                    className="block w-full rounded-md border-0 py-1 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <button
                type="submit"
                className="flex w-full font-fredoka mt-4 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {load ? 'Logging in...' : 'Sign in'}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="absolute top-0 right-0 mt-4 mr-4">
        {toasts.map(toast => (
          <Toast
            key={toast.id}
            id={toast.id}
            type={toast.type}
            message={toast.message}
            onClose={removeToast}
          />
        ))}
      </div>
    </div>
  );
}

