import React from "react";
import { Circle } from "rc-progress";
import "../Circular/CircularProgressBar.css";

const CircularProgressSpeedBar = ({ percentage }) => {

  const getStrokeColor = (percentage) => {
    if (percentage >= 0 && percentage <= 20) {
      return "red";
    } else if (percentage >= 21 && percentage <= 40) {
      return "rgb(168,85,247)";
    } else if (percentage >= 41 && percentage <= 60) {
      return "#3498db";
    } else if (percentage >= 61 && percentage <= 80) {
      return "lightgreen";
    } else if (percentage >= 81 && percentage <= 100) {
      return "yellow";
    }
    return "lightgray"; // Default color if percentage is out of bounds
  };

  return (
    <div className="relative w-full h-[100%]">
      <Circle
        percent={percentage}
        strokeWidth="9"
        trailWidth=""
        trailColor="#EEEEEE"
        strokeColor={getStrokeColor(percentage)} // Set the stroke color dynamically
        className="w-full h-full"
      />
      <div className="absolute inset-0 flex items-center justify-center text-sm">
        {percentage}%
      </div>
    </div>
  );
};

export default CircularProgressSpeedBar;
