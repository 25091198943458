// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
//  import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from 'react-router-dom';
// import { ChakraProvider } from '@chakra-ui/react';
// import {Provider} from "react-redux"
// import store from './Component/Redux/store';
// import { CssBaseline } from '@mui/material';
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//    <ChakraProvider>

//    <BrowserRouter>
   
//   <Provider store={store}>
//   <CssBaseline />
//   <App/>

//   </Provider>
//    </BrowserRouter>
//    </ChakraProvider>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
// index.js

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react'; // Import ChakraProvider
import { Provider } from "react-redux";
import store from './Component/Redux/store';
import { CssBaseline } from '@mui/material';
import theme from '../src/Component/School/SchoolDashboard/Table/theme'; // Import your custom theme
import { StandardProvider } from './Component/School/SchoolDashboard/StandardContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
   <>
    <BrowserRouter>
        <CssBaseline />
  {/* <ChakraProvider theme={theme}> Provide the theme to ChakraProvider */}
      <Provider store={store}>
      <StandardProvider>
        <App />
        </StandardProvider>
      </Provider>
  {/* </ChakraProvider> */}
    </BrowserRouter>
  </>
);

reportWebVitals();

