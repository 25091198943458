import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import "./ActionPaln.css"

const ActionPlan = () => {
  const location = useLocation();
  const { selectedSkill = "attention", showConcern = false } =
    location.state || {};
  const [score, setScore] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const skillContentRes = await fetch(
        "https://test.xqminds.com/api/quiz/SkillContentAndRecommendation",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization:` Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const skillContentData = await skillContentRes.json();
      console.log(skillContentData, "skillContentData");

      setScore(skillContentData.contentObject);

      if (selectedSkill) {
        setSelectedData(skillContentData.contentObject[selectedSkill]);
      }
    } catch (err) {
      console.log(err);
      setError("Failed to fetch data.");
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedSkill]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!score || !selectedData) {
    return <div>Loading...</div>;
  }

  const skills = Object.keys(score);

  const handleClick = (skill) => {
    setSelectedData(score[skill]);
  };

  let paragraphNumber = 0; // Initialize paragraph number

  const renderSuggestion = (suggestion) => {
    let lineNumber = 0; // Initialize line number for each paragraph
    paragraphNumber++; // Increment paragraph number for each new suggestion

    return suggestion.split("\n").map((str, idx) => {
      const hasColon = str.includes(":") && !str.includes(":-");
      const skipNumbering = str.includes(":-");

      if (hasColon) {
        lineNumber++; // Increment line number for subpoints
      }

      const numbering = skipNumbering
        ? ""
        : hasColon
        ? `${paragraphNumber}.${lineNumber}`
        : `${paragraphNumber}.`;

      return (
        <div key={idx} className="suggestion-line">
          <span className={`line-color-${idx % 20}`}>
            {numbering} {str}
          </span>
          {/* <span className={line-color-${idx % 10}}>
                {parts.slice(1).join(":")}
              </span> */}

        </div>
      );
    });
  };

 

 
  return (
    <div className="flex flex-col gap-5">
      <div spacing={4} mb={4} className="w-[98%] m-auto flex flex-wrap gap-4">
        {skills.map((skill) => (
          <button
            key={skill}
            onClick={() => handleClick(skill)}
            className={`px-4 py-2 rounded-md font-fredoka font-medium ${
              selectedData === score[skill]
                ? "bg-blue-600 text-white border border-blue-600"
                : "bg-gray-100 text-gray-800 border border-gray-300"
            }`}
          >
            {skill.charAt(0).toUpperCase() + skill.slice(1)}
          </button>
        ))}
      </div>

      <Accordion defaultIndex={showConcern ? [1] : [0]}>
     

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                display="flex"
                alignItems="center"
                className="bg-blue-600 text-lg h-12 px-3 rounded-lg text-white"
              >
                Recommendation
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {Object.keys(selectedData)
              .filter((level) => selectedData[level]?.recommendations)
              .map((level, index) => (
                <div key={level} className="text-left">
                  <ul className="list-disc md:ml-20">
                    {selectedData[level].recommendations?.length > 0 ? (
                      selectedData[level].recommendations.map(
                        (recommendation, idx) => (
                          <p className="" key={idx}>
                            {renderSuggestion(recommendation.suggestion)}
                          </p>
                        )
                      )
                    ) : (
                     null
                    )}
                  </ul>
                </div>
              ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default ActionPlan;