

// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchSchoolScores } from "../../Redux/JuniorAction";
// import { fetchSeniorScores } from "../../Redux/SeniorAction";
// import { fetchYouthScores } from "../../Redux/YouthAction";
// import Sidebar from "../Sidebar/Sidebar";
// import SideData from "../../SideData/SideData";
// import upload from "../../../Component/School/SchoolImages/upload.gif";
// import addstudent from "../../../Component/School/SchoolImages/addstudent.gif";
// import codinatore from "../../../Component/School/SchoolImages/addcordinator.gif";
// import teacher from "../../../Component/School/SchoolImages/addteacher.gif";
// import CircularProgressBar from "../../School/Charts/CircularProgressBar";
// import SchoolMenu from "../SchoolMenu/SchoolMenu";
// import SchoolSideBar from "../SchoolSideBar/SchoolSideBar";
// import NextExam from "../../NextExamDate/NextExam";
// import DonutChart from "../Charts/DonutChart";
// import CircularChart from "../Charts/CircularChart";
// import Star from "../Charts/Star";
// import AccuracyChart from "../Charts/AccuracyChart";
// import UnderStandingChart from "../Charts/UnderStandingChart";
// import ColorCircle from "../ColorCircle/ColorCircle";
// import OverAllStudent from "./OverAllStudent/OverAllStudent";
// // import schoolScores from "./student.json";
// import Speed from "../Charts/Speed";
// import Topper from "./Topper/Topper";
// import TopRanker from "./TopRanker/TopRanker";
// import MainTable from "./Table/MainTable";
// import NextExamDate from "./SchoolExamDate/NextExamDate";
// import overall from "../../../image/overallstudent.png";
// import complete from "../../../image/complete.png";
// import pending from "../../../image/pending.png";
// import CompleteTaskTable from "./CompleteTaskTable/CompleteTaskTable";
// import NumberSpring from "../NumberSpring/NumberSpring";
// import AllMpiClass from "./AllMpiClass/AllMpiClass";
// import AccuracyMpi from "./AllMpiClass/AccuracyMpi";
// import ApplyingMpi from "./AllMpiClass/ApplyingMpi";
// import BasicIntelMpi from "./AllMpiClass/BasicIntelMpi";
// import SituationMpi from "./AllMpiClass/SituationMpi";
// import SpeedMpi from "./AllMpiClass/SpeedMpi";
// import ConsistancyMpi from "./AllMpiClass/ConsistancyMpi";
// import notification from "../../../image/notification.gif";
// import PerformanceMessage from "./PerformanceMessage";

// const SchoolDashboard = ({}) => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
//   const [showModal, setShowModal] = useState(false);
//   const [showComplete, setShowComplete] = useState(false);
//   const [showTopperModal, setShowTopperModal] = useState(false);

//   const [showMpi, setShowMpi] = useState(false);
//   const [showAccuracyMpi, setShowAccuracyMpi] = useState(false);
//   const [showApplyingMpi, setShowApplyingMpi] = useState(false);
//   const [showBasicMpi, setShowBasicMpi] = useState(false);
//   const [showSituationMpi, setShowSituationMpi] = useState(false);
//   const [showSpeedMpi, setShowSpeedMpi] = useState(false);
//   const [showConsistancyMpi, setShowConsistancyMpi] = useState(false);

//   const [averageStudentData, setaverageStudentData] = useState(null);
//   const [averageAccuracy, setaverageAccuracy] = useState(null);
//   const [showMessage, setShowMessage] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   const dispatch = useDispatch();

//   const {
//     loading: schoolScoresLoading,
//     schoolScores,
//     error: schoolScoresError,
//   } = useSelector((state) => state.schoolScores);
//   const {
//     loading: seniorScoresLoading,
//     seniorScores,
//     error: seniorScoresError,
//   } = useSelector((state) => state.seniorScores);
//   const {
//     loading: youthScoresLoading,
//     youthScores,
//     error: youthScoresError,
//   } = useSelector((state) => state.youthScores);

//   useEffect(() => {
//     dispatch(fetchSchoolScores());
//     dispatch(fetchSeniorScores());
//     dispatch(fetchYouthScores());
//   }, [dispatch]);

//   // console.log(seniorScores, "seniorScores");
//   // console.log(youthScores, "youthScores");
//   useEffect(() => {
//     console.log(schoolScores, "schoolScores");
//     console.log(seniorScores, "seniorScores");
//     console.log(youthScores, "youthScores");
//   }, [
//     schoolScoresLoading,
//     seniorScoresLoading,
//     youthScoresLoading,
//     schoolScores,
//     seniorScores,
//     youthScores,
//     schoolScoresError,
//     seniorScoresError,
//     youthScoresError,
//   ]);


//   // schoolScores means junior Score
//   useEffect(() => {
//     console.log(schoolScores, "schoolScores");
//     console.log(seniorScores, "seniorScores");
//     console.log(youthScores, "youthScores");
//   });

//   console.log(schoolScores, "schoolScores");

//   const MPIScore =
//     Number(schoolScores?.averageConvertedMpiScore || 0) +
//     Number(seniorScores?.averageConvertedMpiScore || 0) +
//     Number(youthScores?.averageConvertedMpiScore || 0);

//   const Accuracy =
//     Number(schoolScores?.averagePrideAccuracyScorePercentage || 0) +
//     Number(seniorScores?.averagePrideAccuracyScorePercentage || 0) +
//     Number(youthScores?.averagePrideAccuracyScorePercentage || 0);

//   const Speeds =
//     Number(schoolScores?.averageMentalSpeedInSec || 0) +
//     Number(seniorScores?.averageMentalSpeedInSec || 0) +
//     Number(youthScores?.averageMentalSpeedInSec || 0);

//   const Consistancy =
//     Number(schoolScores?.averageMpiConsistency || 0) +
//     Number(seniorScores?.averageMpiConsistency || 0) +
//     Number(youthScores?.averageMpiConsistency || 0);

//   const CompletedAssessment =
//     Number(schoolScores?.completedAssessmentStandardWise || 0) +
//     Number(seniorScores?.completedAssessmentStandardWise || 0) +
//     Number(youthScores?.completedAssessmentStandardWise || 0);

//   const UnderSatnding =
//     Number(schoolScores?.averageAwarenessScore || 0) +
//     Number(seniorScores?.averageAwarenessScore || 0) +
//     Number(youthScores?.averageAwarenessScore || 0);

//   const Situation =
//     Number(schoolScores?.averageAdvantageScore || 0) +
//     Number(seniorScores?.averageAdvantageScore || 0) +
//     Number(youthScores?.averageAdvantageScore || 0);

//   const Applying =
//     Number(schoolScores?.averageApplicationScore || 0) +
//     Number(seniorScores?.averageApplicationScore || 0) +
//     Number(youthScores?.averageApplicationScore || 0);
//     const toggleSidebar = () => {
//       setIsSidebarOpen(!isSidebarOpen);
//     };
//   return (
//     <div className="w-[99%] bg-center " >
//       <div className="flex gap-4">
      
//          <div className={`flex h-full m-auto gap-5 sticky top-1 ${isSidebarOpen ? 'block' : 'hidden'} md:block`}>
//          <SchoolSideBar />
//         </div>
//         <div className="w-[91.8%] mx-auto">
        
//            <div className="md:flex md:flex-row flex flex-col-reverse justify-start items-center  shadow-lg rounded-lg p-3 gap-4 sticky top-0 z-10 bg-white">
//            <NextExamDate />
//             <form className="flex items-center max-w-sm mx-auto">
             
              
            
//             </form>
//           </div>
//           <div className="main flex flex-col gap-4 md:flex md:flex-row w-full">
//             <div className="firstdiv md:w-[35%] sm:w-[90%]">
//               <div
//                 className="mpi  flex flex-col mt-5 items-center cursor-pointer shadow-xl rounded-lg transition duration-300 hover:bg-[#B6FFFA] sw-full sm:w-full md:w-full lg:full xl:w-full"
//                 style={{
//                   background: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
//                 }}
//                 onClick={() => setShowMpi(true)}
//                 >
                
//                 <p className="text-lg  p-2 text-[#167D5A] font-semibold sm:text-xl md:text-2xl">
//                   Over All Mpi Score
//                 </p>
//                 <div className="w-full flex items-center justify-center p-2">
//                   <CircularChart
//                     percentage={(MPIScore / schoolScores.standardCount).toFixed(
//                       1
//                     )}
//                     //selectSubject.MPIScore 
//                   />
                 
//                 </div>
                
//                 <div className="w-full flex justify-end  p-2 cursor-pointer">
//                   <p className="relative group w-[3%] right-0 bottom-0 cursor-pointer">
//                     <ColorCircle
//                       percentage={(
//                         MPIScore / schoolScores.standardCount
//                       ).toFixed(1)}
//                     />
//                     <div className="group-hover:block hidden absolute right-0 bottom-3 -mt-1 ml-4 z-10 w-64 ">
//                     <PerformanceMessage/>
//                     </div>
//                   </p>
//                 </div>
//                 {/* <p className="text-lg sm:text-xl md:text-2xl"> {(MPIScore / schoolScores.standardCount).toFixed(1)}</p> */}
            
              
//               </div>
//               <AllMpiClass  
//                    isVisible={showMpi}
//                    close={() => setShowMpi(false)}
//                   />
//               <div className="flex justify-end mt-5 gap-4 w-full h-auto">
//                 <div className="mpi flex flex-col items-center cursor-pointer w-full gap-5 shadow-xl rounded-lg transition duration-300 hover:bg-[#B6FFFA]"  
//                 onClick={() => setShowAccuracyMpi(true)}
//                 >
//                   <p className="text-[#167D5A] font-semibold">Accuracy</p>
//                   <div>
//                     <AccuracyChart
//                       percentage={(
//                         Accuracy / schoolScores.standardCount
//                       ).toFixed(1)}
//                     />
//                   </div>
//                   {/* <p>{(Accuracy / schoolScores.standardCount).toFixed(1)}</p> */}
//                   <div className="w-full flex justify-end  p-2 cursor-pointer">
//                     <p className="relative group w-[5%] right-0 bottom-0 cursor-pointer">
//                       <ColorCircle
//                         percentage={(
//                           Accuracy / schoolScores.standardCount
//                         ).toFixed(1)}
//                       />

//                       <div className="group-hover:block hidden absolute right-0 bottom-3 -mt-1 ml-4 z-10 w-64 ">
//                       <PerformanceMessage/>
//                       </div>
//                     </p>
//                   </div>
//                 </div>
//                 <AccuracyMpi  
//                isVisible={showAccuracyMpi}
//                close={() => setShowAccuracyMpi(false)}
//               />
//                 <div className="mpi flex flex-col items-center w-full cursor-pointer gap-4 shadow-xl rounded-lg transition duration-300 hover:bg-[#B6FFFA]" 
//                  onClick={() => setShowSpeedMpi(true)}
//                 >
//                   <p className="text-[#167D5A] font-semibold">Speed</p>
//                   <div className="w-[]">
//                     <Speed
//                       percentage={(Speeds / schoolScores.standardCount).toFixed(
//                         1
//                       )}
//                     />
//                   </div>
//                   {/* <p> percentage={(Speeds / schoolScores.standardCount).toFixed(1)}</p> */}
//                   <div className="w-full flex justify-end  p-2">
//                     {/* <p>
//                       <ColorCircle  percentage={(Speeds / schoolScores.standardCount).toFixed(1)}/>
//                     </p> */}
//                   </div>
//                 </div>
//                 <SpeedMpi  
//                  isVisible={showSpeedMpi}
//                  close={() => setShowSpeedMpi(false)}
//                 />
//               </div>
             
//               <div className="mpi h-[120px] flex flex-col mt-5 gap-4 cursor-pointer p-5 shadow-xl rounded-lg transition duration-300 hover:bg-[#B6FFFA]"
//                 onClick={() => setShowConsistancyMpi(true)}
//               >
//                 <p>Consistency</p>
//                 <div>
//                   <Star
//                     rating={(Consistancy / schoolScores.standardCount).toFixed(
//                       2
//                     )}
//                   />
//                 </div>
//                 <div className="w-full flex justify-end   cursor-pointer">
//                   {/* <p  className="relative group w-[5%] right-0 bottom-0 cursor-pointer" > */}
//                   <p className="relative group w-[1%] right-0 bottom-0 cursor-pointer">
//                     <ColorCircle
//                       percentage={(
//                         Consistancy / schoolScores.standardCount
//                       ).toFixed(1)}
//                     />
//                     <div className="group-hover:block hidden absolute right-0 top-full  z-10 w-64 ">
//                       <div className="absolute right-4 bottom-4 -mt-1 ml-4 z-10 w-64 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
//                       <PerformanceMessage/>
//                       </div>
//                     </div>
//                   </p>
//                 </div>

//                 {/* <p> percentage={(Consistancy / schoolScores.standardCount).toFixed(1)}</p> */}
//               </div>
              
//               <ConsistancyMpi 
//                 isVisible={showConsistancyMpi}
//                 close={() => setShowConsistancyMpi(false)}
//               />
//             </div>
//             <div className="secondDiv lg:w-[65%] md:w-[65%] sm:w-[100%] flex flex-col gap-4">
//               <div className="flex flex-col md:flex-row gap-4 mt-5 w-full h-auto md:h-[250px]">
//                 <div
//                   className="mpi cursor-pointer w-full shadow-xl rounded-lg  md:h-[250px] sm:h-[350px] sm:w-[90%] md:w-[50%] flex flex-col gap-4 justify-center items-center transition duration-300 hover:bg-yellow-300"
//                   onClick={() => setShowModal(true)}>
//                   <p className="text-lg  p-2 font-semibold sm:text-xl md:text-2xl text-blue-400">
//                     Overall Students Assigned
//                   </p>
//                   {/* <p className="text-xl font-bold">145531</p> */}
//                   <div className="flex flex-col gap-4">
//                     {/* {averageStudentData !== null && (
//                     <p className=" sm:text-xl text-xl font-bold md:text-2xl">
                      
//                        {averageStudentData.toFixed(2)}
//                     </p>
//                   )} */}
//                     <p className="font-bold text-3xl">
//                      <NumberSpring  n =  {schoolScores.overAllStudentAssigned}/>
//                     </p>

//                     <img className="w-[100px]" src={overall} alt="" srcset="" />
//                   </div>
//                 </div>
//                 <MainTable
//                   isVisible={showModal}
//                   onClose={() => setShowModal(false)}
//                 />
//                 <div className="flex flex-col h-[250px] gap-4 w-full md:w-[50%]">
//                   <div className="mpi shadow-xl rounded-lg h-[50%] md:h-[100%] cursor-pointer flex flex-col justify-center gap-5 items-center text-lg font-semibold md:border-none transition duration-300 hover:bg-yellow-300" 
//                    onClick={() => setShowComplete(true)}>
//                     <p className="text-blue-400">Complete Assessment</p>
//                     <div className="flex gap-4 justify-evenly items-center  w-[100%] ">
//                       <img
//                         className="w-[60px]"
//                         src={complete}
//                         alt=""
//                         srcset=""
//                       />
//                       <p className="ont-bold text-3xl">
//                         <NumberSpring n={CompletedAssessment}/>
//                       </p>
//                     </div>
                   
//                   </div>
//                   <CompleteTaskTable
//                       Visible={showComplete}
//                       close={() => setShowComplete(false)}
//                     />
//                   <div className="mpi shadow-xl rounded-lg h-[50%] md:h-[100%] flex flex-col justify-center gap-5 items-center text-lg font-semibold transition duration-300 hover:bg-yellow-300">
//                     <p className="text-blue-400">Yet to Complete</p>
//                     <div className="flex gap-4 justify-evenly items-center  w-[100%] ">
//                       <p className="ont-bold text-3xl">
//                        <NumberSpring n= { schoolScores.overAllStudentAssigned ? schoolScores.overAllStudentAssigned -
//                           CompletedAssessment:0} />
//                       </p>
//                       <img
//                         className="w-[60px]"
//                         src={pending}
//                         alt=""
//                         srcset=""
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="flex flex-col md:flex-row gap-4">
//                 <div className="flex flex-col gap-4 w-full md:w-[40%]"> 
//                   <div className="mpi shadow-xl flex justify-between p-4 items-center cursor-pointer rounded-lg h-auto md:h-auto transition duration-300 hover:bg-blue-300" 
//                                   onClick={() => setShowBasicMpi(true)}
//                   >
//                     <div className="flex justify-between p-2 gap-8 w-[40%] items-center">
//                       <p className="text-purple-500 font-semibold">
//                       Basic Intelligence 

//                       </p>
//                       {/* Awareness */}
//                       {/* <p>{(UnderSatnding / schoolScores.standardCount).toFixed(1)}</p> */}
//                     </div>
//                     <div className="flex justify-end gap-4">
//                       <div>
//                         <UnderStandingChart
//                           percentage={(
//                             UnderSatnding / schoolScores.standardCount
//                           ).toFixed(1)}
//                         />
//                       </div>
//                       <div className="w-full flex justify-end p-2 relative">
//                         <p className="relative group w-[20%] right-0 bottom-0 cursor-pointer">
//                           <ColorCircle
//                             percentage={(
//                               UnderSatnding / schoolScores.standardCount
//                             ).toFixed(1)}
//                           />
//                           <div className="group-hover:block hidden absolute right-0 top-full -mt-1 ml-4 z-10 w-64 ">
//                           <div className="absolute right-0 top-full -mt-1 ml-4 z-10 w-64 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
//                           <PerformanceMessage/>
//                             </div>
//                           </div>
//                         </p>

                      
//                       </div>
//                     </div>
//                   </div>
//                   <BasicIntelMpi 
//                     isVisible={showBasicMpi}
//                     close={() => setShowBasicMpi(false)}
//                   />
//                   <div className="mpi shadow-xl flex justify-between p-4 items-center cursor-pointer rounded-lg h-auto md:h-auto transition duration-300 hover:bg-blue-300" 
//                      onClick={() => setShowSituationMpi(true)}
//                   >
//                     <div className="flex justify-between p-2  gap-8    w-[40%] items-center ">
//                       <p className="text-purple-500 font-semibold">
//                       Situational Intelligence

//                       </p>
//                       {/* Advantage */}
//                       {/* <p>{(Situation / schoolScores.standardCount).toFixed(1)}</p> */}
//                     </div>
//                     <div className="flex  gap-2">
//                       <div>
//                         <UnderStandingChart
//                           percentage={(
//                             Situation / schoolScores.standardCount
//                           ).toFixed(1)}
//                         />
//                       </div>
//                       <div className="w-full flex justify-end  p-2 relative">
//                         <p className="relative group w-[20%] right-0 bottom-0 cursor-pointer">
//                           <ColorCircle
//                             percentage={(
//                               Situation / schoolScores.standardCount
//                             ).toFixed(1)}
//                           />
//                           <div className="group-hover:block hidden absolute right-0 bottom-3 -mt-1 ml-4 z-10 w-64 ">
//                             <div className="absolute right-0 top-full -mt-1 ml-4 z-10 w-64 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
//                             <PerformanceMessage/>
//                             </div>
//                           </div>
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                    <SituationMpi  
//                      isVisible={showSituationMpi}
//                      close={() => setShowSituationMpi(false)}
//                    />
//                   <div className="mpi shadow-xl flex justify-between p-4 items-center cursor-pointer rounded-lg h-auto md:h-auto transition duration-300 hover:bg-blue-300" 
//                   onClick={() => setShowApplyingMpi(true)}
//                   >
//                     <div className="flex justify-between p-2  gap-8    w-[40%]  items-center ">
//                       <p className="text-purple-500 font-semibold">
//                       Applying Intelligence
//                       </p>
//                       {/* Application */}
//                       {/* <p> {(Applying / schoolScores.standardCount).toFixed(1)}</p> */}
//                     </div>
//                     <div className="flex  gap-2">
//                       <div>
//                         <UnderStandingChart
//                           percentage={(
//                             Applying / schoolScores.standardCount
//                           ).toFixed(1)}
//                         />
//                       </div>
//                       <div className="w-full flex justify-end  p-2 relative">
//                         <p className="relative group  w-[20%] right-0 bottom-0 cursor-pointer">
//                           <div className="group-hover:block hidden absolute right-0 top-full  z-10 w-64 ">
//                             <div className="absolute right-4 bottom-4 -mt-1 ml-4 z-10 w-64 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
//                             <PerformanceMessage/>
//                             </div>
//                           </div>
//                           <ColorCircle
//                             percentage={(
//                               Applying / schoolScores.standardCount
//                             ).toFixed(1)}
//                           />
//                         </p>
//                       </div>
//                     </div>
//                   </div>
                        
//                   <ApplyingMpi  
//                      isVisible={showApplyingMpi}
//                      close={() => setShowApplyingMpi(false)}
//                   />
//                 </div>
//                 <div className="w-full h-full md:w-[60%] flex flex-col gap-4">
//                   <div
//                   //  className="mpi  font-semibold shadow-xl rounded-lg border h-[50%] md:h-[50%] flex flex-col items-center justify-center transition duration-300 hover:bg-yellow-300"
//                   // className="mpi shadow-xl rounded-lg p-5 flex flex-col gap-2 justify-center transition duration-300"
//                   // Set modal visibility
//                   >
//                     <div
//                       className="flex flex-col cursor-pointer "
//                       onClick={() => setShowTopperModal(true)}>
//                       {/* <p className=" text-purple-500 ">Top Performance</p> */}
//                       <TopRanker />
//                     </div>

//                     <Topper
//                       isVisible={showTopperModal}
//                       onClose={() => setShowTopperModal(false)}
//                     />
//                   </div>
//                   {/* <div className="mpi text-purple-500 font-semibold shadow-xl rounded-lg h-[50%] md:h-[50%] flex items-center justify-center transition duration-300 hover:bg-yellow-300">
//                     Recommendation
//                   </div> */}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {isSidebarOpen && (
//         <div className="fixed inset-0 z-50 flex md:hidden">
//           <div className=" bg-white shadow-lg">
//             <SchoolSideBar />
//           </div>
//           <div className="flex-grow" onClick={toggleSidebar}></div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default SchoolDashboard;













































































// import React, { useEffect, useState } from "react";

// import SchoolSideBar from "../SchoolSideBar/SchoolSideBar";

// import CircularChart from "../Charts/CircularChart";
// import Star from "../Charts/Star";
// import AccuracyChart from "../Charts/AccuracyChart";
// import UnderStandingChart from "../Charts/UnderStandingChart";
// import ColorCircle from "../ColorCircle/ColorCircle";
// import Speed from "../Charts/Speed";
// import Topper from "./Topper/Topper";
// import TopRanker from "./TopRanker/TopRanker";
// import MainTable from "./Table/MainTable";
// import NextExamDate from "./SchoolExamDate/NextExamDate";
// import overall from "../../../image/overallstudent.png";
// import complete from "../../../image/complete.png";
// import pending from "../../../image/pending.png";
// import CompleteTaskTable from "./CompleteTaskTable/CompleteTaskTable";
// import NumberSpring from "../NumberSpring/NumberSpring";
// import AllMpiClass from "./AllMpiClass/AllMpiClass";
// import AccuracyMpi from "./AllMpiClass/AccuracyMpi";
// import ApplyingMpi from "./AllMpiClass/ApplyingMpi";
// import BasicIntelMpi from "./AllMpiClass/BasicIntelMpi";
// import SituationMpi from "./AllMpiClass/SituationMpi";
// import SpeedMpi from "./AllMpiClass/SpeedMpi";
// import ConsistancyMpi from "./AllMpiClass/ConsistancyMpi";
// import PerformanceMessage from "./PerformanceMessage";

// const SchoolDashboard = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
//   const [showModal, setShowModal] = useState(false);
//   const [showComplete, setShowComplete] = useState(false);
//   const [showTopperModal, setShowTopperModal] = useState(false);

//   const [showMpi, setShowMpi] = useState(false);
//   const [showAccuracyMpi, setShowAccuracyMpi] = useState(false);
//   const [showApplyingMpi, setShowApplyingMpi] = useState(false);
//   const [showBasicMpi, setShowBasicMpi] = useState(false);
//   const [showSituationMpi, setShowSituationMpi] = useState(false);
//   const [showSpeedMpi, setShowSpeedMpi] = useState(false);
//   const [showConsistancyMpi, setShowConsistancyMpi] = useState(false);

//   const [averageStudentData, setaverageStudentData] = useState(null);
//   const [averageAccuracy, setaverageAccuracy] = useState(null);
//   const [showMessage, setShowMessage] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const [data, setData] = useState(null);
//   const [caraosalsScore, setCaraosalsScore] = useState(null);
//   const [selectedStandard, setSelectedStandard] = useState('school');
//   const [standardsInSchool, setStandardsInSchool] = useState([]);
//   const [categoryMapping, setCategoryMapping] = useState({});
//   const [initialLoad, setInitialLoad] = useState(true);
//   const [averageConvertedMpiScore, setAverageConvertedMpiScore] = useState(0);
//   const [averageMentalSpeedInSec, setAverageMentalSpeedInSec] = useState(0);
//   const [averagePrideAccuracyScorePercentage, setAveragePrideAccuracyScorePercentage] = useState(0);
//   const [averageMpiConsistency,setAverageMpiConsistency] = useState(0);
//  const [averageAwarenessScore,setAverageAwarenessScore] = useState(0);
//  const [averageAdvantageScore,setAverageAdvantageScore] = useState(0);
//  const [averageApplicationScore,setAverageApplicationScore] = useState(0); 
//  const [overallStudentsAssigned, setOverallStudentsAssigned] = useState(0);
//  const [completedAssessment, setCompletedAssessment] = useState(0);
 
//  useEffect(() => {
//   const token = localStorage.getItem("token");

//   fetch(`https://test.xqminds.com/api/schoolData/section-wise-result?standard=6K&level=junior`, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       "authorization": `Bearer ${token}`
//     }
//   })
//     .then((res) => res.json())
//     .then((res) => {
//       const fetchedStandards = res.standardsInSchool;
//       const mapping = {};
//       fetchedStandards.forEach(standard => {
//         if (standard.startsWith('5') || standard.startsWith('6')) {
//           mapping[standard] = 'junior';
//         } else if (standard.startsWith('7') || standard.startsWith('8')) {
//           mapping[standard] = 'senior';
//         } else if (standard.startsWith('9') || standard.startsWith('10') || standard.startsWith('11') || standard.startsWith('12')) {
//           mapping[standard] = 'youth';
//         }
//       });
//       setStandardsInSchool(fetchedStandards);
//       setCategoryMapping(mapping);
//       setSelectedStandard('school');

//       // console.log('Standards in School:', fetchedStandards);
//       // console.log('Category Mapping:', mapping);
//     })
//     .catch(error => {
//       console.error('Error fetching data:', error);
//     });
// }, []);

// useEffect(() => {
//   const token = localStorage.getItem("token");

//   fetch('https://test.xqminds.com/api/schoolData/school-dashboard-average', {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       "authorization": `Bearer ${token}`
//     }
//   })
//     .then((res) => res.json())
//     .then((res) => {
//       setAverageConvertedMpiScore(Number(res.averages.averageConvertedMpiScore) || 0);
//       setAverageMentalSpeedInSec(Number(res.averages.averageMentalSpeedInSec) || 0);
//       setAveragePrideAccuracyScorePercentage(Number(res.averages.averagePrideAccuracyScorePercentage) || 0);
//       setAverageMpiConsistency(Number(res.averages.averageMpiConsistency) || 0);
//       setAverageAdvantageScore(Number(res.averages.averageAdvantageScore) || 0);
//       setAverageApplicationScore(Number(res.averages.averageApplicationScore) || 0);
//       setAverageAwarenessScore(Number(res.averages.averageAwarenessScore) || 0);
//       setCaraosalsScore(res.contentObject);
//       setOverallStudentsAssigned(Number(res.averages.overAllStudentAssigned) || 0);
//       setCompletedAssessment(Number(res.averages.completedAssessmentStandardWise) || 0);
//       setInitialLoad(false); // Set to false after the first standard is selected
//     })
//     .catch(error => {
//       console.error('Error fetching data:', error);
//     });
// }, []);

// const handleChange = (event) => {
//   const selected = event.target.value;
//   setSelectedStandard(selected);

//   if (selected === 'school') {
//     // Fetch data for the whole school
//     // No need to dispatch actions here as we're using local state now
//     setCaraosalsScore(null); // Reset caraosalsScore to ensure it fetches new data
//   } else if (categoryMapping[selected]) {
//     const category = categoryMapping[selected];
//     const apiUrl = `https://test.xqminds.com/api/schoolData/section-wise-result?standard=${selected}&level=${category}`;

//     fetch(apiUrl, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         "authorization": `Bearer ${localStorage.getItem("token")}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((res) => {
//         setAverageConvertedMpiScore(Number(res.result.averageConvertedMpiScore) || 0);
//         setAverageMentalSpeedInSec(Number(res.result.averageMentalSpeedInSec) || 0);
//         setAveragePrideAccuracyScorePercentage(Number(res.result.averagePrideAccuracyScorePercentage) || 0);
//         setAverageMpiConsistency(Number(res.result.averageMpiConsistency) || 0);
//         setAverageAdvantageScore(Number(res.result.averageAdvantageScore) || 0);
//         setAverageApplicationScore(Number(res.result.averageApplicationScore) || 0);
//         setAverageAwarenessScore(Number(res.result.averageAwarenessScore) || 0);
//         setCaraosalsScore(res.contentObject);
//         setOverallStudentsAssigned(Number(res.result.overAllStudentAssigned) || 0);
//         setCompletedAssessment(Number(res.result.completedAssessmentStandardWise) || 0);

//       })
//       .catch((error) => {
//         console.error(`Error fetching data for standard ${selected}`, error);
//       });
//   }
// };

// // Displayed data based on initialLoad state or specific data
// const displayedScore = initialLoad 
//   ? (Number(averageConvertedMpiScore) || 0).toFixed(1) 
//   : (Number(averageConvertedMpiScore) || 0).toFixed(1);

// const displayedAccuracy = initialLoad 
//   ? (Number(averagePrideAccuracyScorePercentage) || 0).toFixed(1) 
//   : (Number(averagePrideAccuracyScorePercentage) || 0).toFixed(1);

// const displayedSpeed = initialLoad 
//   ? (Number(averageMentalSpeedInSec) || 0).toFixed(1) 
//   : (Number(averageMentalSpeedInSec) || 0).toFixed(1);

// const displayConsistency = initialLoad 
//   ? (Number(averageMpiConsistency) || 0).toFixed(1) 
//   : (Number(averageMpiConsistency) || 0).toFixed(1);

// const displayIntelligence = initialLoad 
//   ? (Number(averageAwarenessScore) || 0).toFixed(1) 
//   : (Number(averageAwarenessScore) || 0).toFixed(1);

// const displaySituation = initialLoad 
//   ? (Number(averageAdvantageScore) || 0).toFixed(1) 
//   : (Number(averageAdvantageScore) || 0).toFixed(1);

// const displayApplying = initialLoad 
//   ? (Number(averageApplicationScore) || 0).toFixed(1) 
//   : (Number(averageApplicationScore) || 0).toFixed(1);

//   const displayoverAllAssigned = initialLoad 
//   ? (overallStudentsAssigned || 0) 
//   : (overallStudentsAssigned || 0);

//   const displayCompleted = initialLoad 
//   ? (completedAssessment || 0) 
//   : (completedAssessment || 0); 

// const toggleSidebar = () => {
//   setIsSidebarOpen(!isSidebarOpen);
// };
//   return (
//     <div className="w-[99%] bg-center">
//       <div className="flex gap-4">
//         <div className={`flex h-full m-auto gap-5 sticky top-1 ${isSidebarOpen ? 'block' : 'hidden'} md:block`}>
//           <SchoolSideBar />
//         </div>
//         <div className="w-[91.8%] mx-auto">
//           <div className="md:flex md:flex-row flex flex-col-reverse justify-start items-center shadow-lg rounded-lg p-3 gap-4 sticky top-0 z-10 bg-white">
//             <NextExamDate />
//             <form className=" max-w-lg w-[30%] m-auto">
//         <div className="flex items-center ">
//           <label htmlFor="standards" className="text-gray-900 text-lg font-medium mr-4">Classes:</label>
//           <select
//             id="standards"
//             value={selectedStandard}
//             onChange={handleChange}
//             className="flex-1 px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
//           >
//             <option value="school">School</option>
//             {standardsInSchool.map((standard) => (
//               <option key={standard} value={standard}>
//                 {standard}
//               </option>
//             ))}
//           </select>
//         </div>

//         {caraosalsScore && (
//           <div className="mt-8">
//             <h2 className="text-xl font-semibold text-gray-800 mb-4">Data for {selectedStandard}</h2>
//             <pre className="bg-gray-100 p-6 rounded-lg border border-gray-200 overflow-x-auto">{JSON.stringify(caraosalsScore, null, 2)}</pre>
//           </div>
//         )}
//       </form>
//           </div>
//           <div className="main flex flex-col gap-4 md:flex md:flex-row w-full">
//             <div className="firstdiv md:w-[35%] sm:w-[90%]">
//               <div
//                 className="mpi flex flex-col mt-5 items-center cursor-pointer shadow-xl rounded-lg transition duration-300 hover:bg-[#B6FFFA] sw-full sm:w-full md:w-full lg:full xl:w-full"
//                 style={{
//                   background: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
//                 }}
//                 onClick={() => setShowMpi(true)}
//               >
//                 <p className="text-lg p-2 text-[#167D5A] font-semibold sm:text-xl md:text-2xl">
//                   Over All Mpi Score
//                 </p>
//                 <div className="w-full flex items-center justify-center p-2">
//                   <CircularChart
//                     percentage={displayedScore}
//                   />
//                 </div>
//                 <div className="w-full flex justify-end p-2 cursor-pointer">
//                   <p className="relative group w-[3%] right-0 bottom-0 cursor-pointer">
//                     <ColorCircle
//                       // percentage={(MPIScore / schoolScores.standardCount).toFixed(1)}
//                         percentage={displayedScore*10}
//                     />
//                     <div className="group-hover:block hidden absolute right-0 bottom-3 -mt-1 ml-4 z-10 w-64">
//                       <PerformanceMessage />
//                     </div>
//                   </p>
//                 </div>
//               </div>
//               <AllMpiClass
//                 isVisible={showMpi}
//                 close={() => setShowMpi(false)}
//               />
//               <div className="flex justify-end mt-5 gap-4 w-full h-auto">
//                 <div className="mpi flex flex-col items-center cursor-pointer w-full gap-5 shadow-xl rounded-lg transition duration-300 hover:bg-[#B6FFFA]"
//                   onClick={() => setShowAccuracyMpi(true)}
//                 >
//                   <p className="text-[#167D5A] font-semibold">Accuracy</p>
//                   <div>
//                     <AccuracyChart
//                       percentage={displayedAccuracy}
//                     />
//                   </div>
//                   <div className="w-full flex justify-end p-2 cursor-pointer">
//                     <p className="relative group w-[5%] right-0 bottom-0 cursor-pointer">
//                       <ColorCircle
//                         percentage={displayedAccuracy}
//                       />
//                       <div className="group-hover:block hidden absolute right-0 top-full z-10 w-64">
//                             <div className="absolute right-4 bottom-4 -mt-1 ml-4 z-10 w-64 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
//                             <PerformanceMessage />
//                             </div>
//                           </div>
//                     </p>
//                   </div>
//                 </div>
//                 <AccuracyMpi
//                   isVisible={showAccuracyMpi}
//                   close={() => setShowAccuracyMpi(false)}
//                 />
//                 <div className="mpi flex flex-col items-center w-full cursor-pointer gap-4 shadow-xl rounded-lg transition duration-300 hover:bg-[#B6FFFA]"
//                   onClick={() => setShowSpeedMpi(true)}
//                 >
//                   <p className="text-[#167D5A] font-semibold">Speed</p>
//                   <div className="w-[]">
//                     <Speed
//                       // percentage={(Speeds / schoolScores.standardCount).toFixed(1)}
//                       percentage={ displayedSpeed}
//                     />
//                   </div>
//                   <div className="w-full flex justify-end p-2">
//                   </div>
//                 </div>
//                 <SpeedMpi
//                   isVisible={showSpeedMpi}
//                   close={() => setShowSpeedMpi(false)}
//                 />
//               </div>
//               <div className="mpi h-[120px] flex flex-col mt-5 gap-4 cursor-pointer p-5 shadow-xl rounded-lg transition duration-300 hover:bg-[#B6FFFA]"
//                 onClick={() => setShowConsistancyMpi(true)}
//               >
//                 <p>Consistency</p>
//                 <div>
//                   <Star
//                     rating={displayConsistency}
//                   />
//                 </div>
//                 <div className="w-full flex justify-end cursor-pointer">
//                   <p className="relative group w-[1%] right-0 bottom-0 cursor-pointer">
//                     <ColorCircle
//                       // percentage={(Consistancy / schoolScores.standardCount).toFixed(1)}
//                       percentage={displayConsistency*20}
//                     />
//                     <div className="group-hover:block hidden absolute right-0 top-full z-10 w-64">
//                       <div className="absolute right-4 bottom-4 -mt-1 ml-4 z-10 w-64 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
//                       <PerformanceMessage />
//                       </div>
//                     </div>
//                   </p>
//                 </div>
//               </div>
//               <ConsistancyMpi
//                 isVisible={showConsistancyMpi}
//                 close={() => setShowConsistancyMpi(false)}
//               />
//             </div>
//             <div className="secondDiv lg:w-[65%] md:w-[65%] sm:w-[100%] flex flex-col gap-4">
//               <div className="flex flex-col md:flex-row gap-4 mt-5 w-full h-auto md:h-[250px]">
//                 <div
//                   className="mpi cursor-pointer w-full shadow-xl rounded-lg md:h-[250px] sm:h-[350px] sm:w-[90%] md:w-[50%] flex flex-col gap-4 justify-center items-center transition duration-300 hover:bg-yellow-300"
//                   onClick={() => setShowModal(true)}
//                 >
//                   <p className="text-lg p-2 font-semibold sm:text-xl md:text-2xl text-blue-400">
//                     Overall Students Assigned
//                   </p>
//                   <div className="flex flex-col gap-4">
//                     <p className="font-bold text-3xl">
//                       <NumberSpring n={displayoverAllAssigned} />
//                       {/* {displayoverAllAssigned}  */}
//                     </p>
//                     <img className="w-[100px]" src={overall} alt="" />
//                   </div>
//                 </div>
//                 <MainTable
//                   isVisible={showModal}
//                   onClose={() => setShowModal(false)}
//                 />
//                 <div className="flex flex-col h-[250px] gap-4 w-full md:w-[50%]">
//                   <div className="mpi shadow-xl rounded-lg h-[50%] md:h-[100%] cursor-pointer flex flex-col justify-center gap-5 items-center text-lg font-semibold md:border-none transition duration-300 hover:bg-yellow-300"
//                     onClick={() => setShowComplete(true)}
//                   >
//                     <p className="text-blue-400">Complete Assessment</p>
//                     <div className="flex gap-4 justify-evenly items-center w-[100%]">
//                       <img className="w-[60px]" src={complete} alt="" />
//                       <p className="ont-bold text-3xl">
//                         <NumberSpring n={displayCompleted} />
//                       </p>
//                     </div>
//                   </div>
//                   <CompleteTaskTable
//                     Visible={showComplete}
//                     close={() => setShowComplete(false)}
//                   />
//                   <div className="mpi shadow-xl rounded-lg h-[50%] md:h-[100%] flex flex-col justify-center gap-5 items-center text-lg font-semibold transition duration-300 hover:bg-yellow-300">
//                     <p className="text-blue-400">Yet to Complete</p>
//                     <div className="flex gap-4 justify-evenly items-center w-[100%]">
//                       <p className="ont-bold text-3xl">
//                         <NumberSpring n={displayoverAllAssigned ? displayoverAllAssigned-
//                           displayCompleted : 0} />
//                       </p>
//                       <img className="w-[60px]" src={pending} alt="" />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="flex flex-col md:flex-row gap-4">
//                 <div className="flex flex-col gap-4 w-full md:w-[40%]">
//                   <div className="mpi shadow-xl flex justify-between p-4 items-center cursor-pointer rounded-lg h-auto md:h-auto transition duration-300 hover:bg-blue-300"
//                     onClick={() => setShowBasicMpi(true)}
//                   >
//                     <div className="flex justify-between p-2 gap-8 w-[40%] items-center">
//                       <p className="text-purple-500 font-semibold">
//                         Basic Intelligence
//                       </p>
//                     </div>
//                     <div className="flex justify-end gap-4">
//                       <div>
//                         <UnderStandingChart
//                           // percentage={(UnderSatnding / schoolScores.standardCount).toFixed(1)}
//                           percentage={displayIntelligence}
//                         />
//                       </div>
//                       <div className="w-full flex justify-end p-2 relative">
//                         <p className="relative group w-[20%] right-0 bottom-0 cursor-pointer">
//                           <ColorCircle
//                             percentage={displayIntelligence}
//                           />
//                           <div className="group-hover:block hidden absolute right-0 top-full -mt-1 ml-4 z-10 w-64">
//                             <div className="absolute right-0 top-full -mt-1 ml-4 z-10 w-64 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
//                             <PerformanceMessage />
//                             </div>
//                           </div>
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <BasicIntelMpi
//                     isVisible={showBasicMpi}
//                     close={() => setShowBasicMpi(false)}
//                   />
//                   <div className="mpi shadow-xl flex justify-between p-4 items-center cursor-pointer rounded-lg h-auto md:h-auto transition duration-300 hover:bg-blue-300"
//                     onClick={() => setShowSituationMpi(true)}
//                   >
//                     <div className="flex justify-between p-2 gap-8 w-[40%] items-center">
//                       <p className="text-purple-500 font-semibold">
//                         Situational Intelligence
//                       </p>
//                     </div>
//                     <div className="flex gap-2">
//                       <div>
//                         <UnderStandingChart
//                           // percentage={(Situation / schoolScores.standardCount).toFixed(1)}
//                           percentage={displaySituation}
//                         />
//                       </div>
//                       <div className="w-full flex justify-end p-2 relative">
//                         <p className="relative group w-[20%] right-0 bottom-0 cursor-pointer">
//                           <ColorCircle
//                             percentage={displaySituation}
//                           />
//                           <div className="group-hover:block hidden absolute right-0 bottom-3 -mt-1 ml-4 z-10 w-64">
//                             <div className="absolute right-0 top-full -mt-1 ml-4 z-10 w-64 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
//                             <PerformanceMessage />
//                             </div>
//                           </div>
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <SituationMpi
//                     isVisible={showSituationMpi}
//                     close={() => setShowSituationMpi(false)}
//                   />
//                   <div className="mpi shadow-xl flex justify-between p-4 items-center cursor-pointer rounded-lg h-auto md:h-auto transition duration-300 hover:bg-blue-300"
//                     onClick={() => setShowApplyingMpi(true)}
//                   >
//                     <div className="flex justify-between p-2 gap-8 w-[40%] items-center">
//                       <p className="text-purple-500 font-semibold">
//                         Applying Intelligence
//                       </p>
//                     </div>
//                     <div className="flex gap-2">
//                       <div>
//                         <UnderStandingChart
//                           // percentage={(Applying / schoolScores.standardCount).toFixed(1)}
//                           percentage={displayApplying}
//                         />
//                       </div>
//                       <div className="w-full flex justify-end p-2 relative">
//                         <p className="relative group w-[20%] right-0 bottom-0 cursor-pointer">
//                           <div className="group-hover:block hidden absolute right-0 top-full z-10 w-64">
//                             <div className="absolute right-4 bottom-4 -mt-1 ml-4 z-10 w-64 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
//                             <PerformanceMessage />
//                             </div>
//                           </div>
//                           <ColorCircle
//                             percentage={displayApplying}
//                           />
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <ApplyingMpi
//                     isVisible={showApplyingMpi}
//                     close={() => setShowApplyingMpi(false)}
//                   />
//                 </div>
//                 <div className="w-full h-full md:w-[60%] flex flex-col gap-4">
//                   <div>
//                     <div className="flex flex-col cursor-pointer"
//                       onClick={() => setShowTopperModal(true)}>
//                       <TopRanker />
//                     </div>
//                     {/* <Topper
//                       isVisible={showTopperModal}
//                       onClose={() => setShowTopperModal(false)}
//                     /> */}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {isSidebarOpen && (
//         <div className="fixed inset-0 z-50 flex md:hidden">
//           <div className="bg-white shadow-lg">
//             <SchoolSideBar />
//           </div>
//           <div className="flex-grow" onClick={toggleSidebar}></div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default SchoolDashboard;

































// import React, { useEffect, useState } from "react";
// import SchoolSideBar from "../SchoolSideBar/SchoolSideBar";
// import CircularChart from "../Charts/CircularChart";
// import Star from "../Charts/Star";
// import AccuracyChart from "../Charts/AccuracyChart";
// import UnderStandingChart from "../Charts/UnderStandingChart";
// import ColorCircle from "../ColorCircle/ColorCircle";
// import Speed from "../Charts/Speed";
// import Topper from "./Topper/Topper";
// import TopRanker from "./TopRanker/TopRanker";
// import MainTable from "./Table/MainTable";
// import NextExamDate from "./SchoolExamDate/NextExamDate";
// import overall from "../../../image/overallstudent.png";
// import complete from "../../../image/complete.png";
// import pending from "../../../image/pending.png";
// import CompleteTaskTable from "./CompleteTaskTable/CompleteTaskTable";
// import NumberSpring from "../NumberSpring/NumberSpring";
// import AllMpiClass from "./AllMpiClass/AllMpiClass";
// import AccuracyMpi from "./AllMpiClass/AccuracyMpi";
// import ApplyingMpi from "./AllMpiClass/ApplyingMpi";
// import BasicIntelMpi from "./AllMpiClass/BasicIntelMpi";
// import SituationMpi from "./AllMpiClass/SituationMpi";
// import SpeedMpi from "./AllMpiClass/SpeedMpi";
// import ConsistancyMpi from "./AllMpiClass/ConsistancyMpi";
// import PerformanceMessage from "./PerformanceMessage";
// import StandardDropdown from "./StandardDropdown";
// import { useStandard } from "./StandardContext";

// const SchoolDashboard = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
//   const [showModal, setShowModal] = useState(false);
//   const [showComplete, setShowComplete] = useState(false);
//   const [showTopperModal, setShowTopperModal] = useState(false);

//   const [showMpi, setShowMpi] = useState(false);
//   const [showAccuracyMpi, setShowAccuracyMpi] = useState(false);
//   const [showApplyingMpi, setShowApplyingMpi] = useState(false);
//   const [showBasicMpi, setShowBasicMpi] = useState(false);
//   const [showSituationMpi, setShowSituationMpi] = useState(false);
//   const [showSpeedMpi, setShowSpeedMpi] = useState(false);
//   const [showConsistancyMpi, setShowConsistancyMpi] = useState(false);

//   const [averageStudentData, setaverageStudentData] = useState(null);
//   const [averageAccuracy, setaverageAccuracy] = useState(null);
//   const [showMessage, setShowMessage] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const [data, setData] = useState(null);
//   const [caraosalsScore, setCaraosalsScore] = useState(null);
//   const [initialLoad, setInitialLoad] = useState(true);
//   const [averageConvertedMpiScore, setAverageConvertedMpiScore] = useState(0);
//   const [averageMentalSpeedInSec, setAverageMentalSpeedInSec] = useState(0);
//   const [averagePrideAccuracyScorePercentage, setAveragePrideAccuracyScorePercentage] = useState(0);
//   const [averageMpiConsistency, setAverageMpiConsistency] = useState(0);
//   const [averageAwarenessScore, setAverageAwarenessScore] = useState(0);
//   const [averageAdvantageScore, setAverageAdvantageScore] = useState(0);
//   const [averageApplicationScore, setAverageApplicationScore] = useState(0); 
//   const [overallStudentsAssigned, setOverallStudentsAssigned] = useState(0);
//   const [completedAssessment, setCompletedAssessment] = useState(0);
  
//   const { selectedStandard, categoryMapping } = useStandard();

//   useEffect(() => {
//     if (selectedStandard === 'school') {
//       const token = localStorage.getItem("token");
//       fetch('https://test.xqminds.com/api/schoolData/school-dashboard-average', {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           "authorization": `Bearer ${token}`
//         }
//       })
//         .then((res) => res.json())
//         .then((res) => {
//           setAverageConvertedMpiScore(Number(res.averages.averageConvertedMpiScore) || 0);
//           setAverageMentalSpeedInSec(Number(res.averages.averageMentalSpeedInSec) || 0);
//           setAveragePrideAccuracyScorePercentage(Number(res.averages.averagePrideAccuracyScorePercentage) || 0);
//           setAverageMpiConsistency(Number(res.averages.averageMpiConsistency) || 0);
//           setAverageAdvantageScore(Number(res.averages.averageAdvantageScore) || 0);
//           setAverageApplicationScore(Number(res.averages.averageApplicationScore) || 0);
//           setAverageAwarenessScore(Number(res.averages.averageAwarenessScore) || 0);
//           setCaraosalsScore(res.contentObject);
//           setOverallStudentsAssigned(Number(res.averages.overAllStudentAssigned) || 0);
//           setCompletedAssessment(Number(res.averages.completedAssessmentStandardWise) || 0);
//           setInitialLoad(false); // Set to false after the first standard is selected
//         })
//         .catch(error => {
//           console.error('Error fetching data:', error);
//         });
//     } else if (categoryMapping[selectedStandard]) {
//       const token = localStorage.getItem("token");
//       const category = categoryMapping[selectedStandard];
//       const apiUrl = `https://test.xqminds.com/api/schoolData/section-wise-result?standard=${selectedStandard}&level=${category}`;

//       fetch(apiUrl, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           "authorization": `Bearer ${localStorage.getItem("token")}`,
//         },
//       })
//         .then((res) => res.json())
//         .then((res) => {
//           setAverageConvertedMpiScore(Number(res.result.averageConvertedMpiScore) || 0);
//           setAverageMentalSpeedInSec(Number(res.result.averageMentalSpeedInSec) || 0);
//           setAveragePrideAccuracyScorePercentage(Number(res.result.averagePrideAccuracyScorePercentage) || 0);
//           setAverageMpiConsistency(Number(res.result.averageMpiConsistency) || 0);
//           setAverageAdvantageScore(Number(res.result.averageAdvantageScore) || 0);
//           setAverageApplicationScore(Number(res.result.averageApplicationScore) || 0);
//           setAverageAwarenessScore(Number(res.result.averageAwarenessScore) || 0);
//           setOverallStudentsAssigned(Number(res.result.overAllStudentAssigned) || 0);
//           setCompletedAssessment(Number(res.result.completedAssessmentStandardWise) || 0);
//           setCaraosalsScore(res.contentObject);
//         })
//         .catch((error) => {
//           console.error(`Error fetching data for standard ${selectedStandard}`, error);
//         });
//     }
//   }, [selectedStandard, categoryMapping]);

//   const displayedScore = (Number(averageConvertedMpiScore) || 0).toFixed(1);
//   const displayedAccuracy = (Number(averagePrideAccuracyScorePercentage) || 0).toFixed(1);
//   const displayedSpeed = (Number(averageMentalSpeedInSec) || 0).toFixed(1);
//   const displayConsistency = (Number(averageMpiConsistency) || 0).toFixed(1);
//   const displayIntelligence = (Number(averageAwarenessScore) || 0).toFixed(1);
//   const displaySituation = (Number(averageAdvantageScore) || 0).toFixed(1);
//   const displayApplying = (Number(averageApplicationScore) || 0).toFixed(1);
//   const displayoverAllAssigned = initialLoad 
//   ? (overallStudentsAssigned || 0) 
//   : (overallStudentsAssigned || 0);

//   const displayCompleted = initialLoad 
//   ? (completedAssessment || 0) 
//   : (completedAssessment || 0); 



















// import React, { useEffect, useState } from "react";
// import SchoolSideBar from "../SchoolSideBar/SchoolSideBar";
// import CircularChart from "../Charts/CircularChart";
// import Star from "../Charts/Star";
// import AccuracyChart from "../Charts/AccuracyChart";
// import UnderStandingChart from "../Charts/UnderStandingChart";
// import ColorCircle from "../ColorCircle/ColorCircle";
// import Speed from "../Charts/Speed";
// import Topper from "./Topper/Topper";
// import TopRanker from "./TopRanker/TopRanker";
// import MainTable from "./Table/MainTable";
// import NextExamDate from "./SchoolExamDate/NextExamDate";
// import overall from "../../../image/overallstudent.png";
// import complete from "../../../image/complete.png";
// import pending from "../../../image/pending.png";
// import CompleteTaskTable from "./CompleteTaskTable/CompleteTaskTable";
// import NumberSpring from "../NumberSpring/NumberSpring";
// import AllMpiClass from "./AllMpiClass/AllMpiClass";
// import AccuracyMpi from "./AllMpiClass/AccuracyMpi";
// import ApplyingMpi from "./AllMpiClass/ApplyingMpi";
// import BasicIntelMpi from "./AllMpiClass/BasicIntelMpi";
// import SituationMpi from "./AllMpiClass/SituationMpi";
// import SpeedMpi from "./AllMpiClass/SpeedMpi";
// import ConsistancyMpi from "./AllMpiClass/ConsistancyMpi";
// import PerformanceMessage from "./PerformanceMessage";
// import StandardDropdown from "./StandardDropdown";
// import { useStandard } from "./StandardContext";

// const SchoolDashboard = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
//   const [showModal, setShowModal] = useState(false);
//   const [showComplete, setShowComplete] = useState(false);
//   const [showTopperModal, setShowTopperModal] = useState(false);
//   const [showMpi, setShowMpi] = useState(false);
//   const [showAccuracyMpi, setShowAccuracyMpi] = useState(false);
//   const [showApplyingMpi, setShowApplyingMpi] = useState(false);
//   const [showBasicMpi, setShowBasicMpi] = useState(false);
//   const [showSituationMpi, setShowSituationMpi] = useState(false);
//   const [showSpeedMpi, setShowSpeedMpi] = useState(false);
//   const [showConsistancyMpi, setShowConsistancyMpi] = useState(false);
//   const [averageStudentData, setaverageStudentData] = useState(null);
//   const [averageAccuracy, setaverageAccuracy] = useState(null);
//   const [showMessage, setShowMessage] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const [data, setData] = useState(null);
//   const [caraosalsScore, setCaraosalsScore] = useState(null);
//   const [initialLoad, setInitialLoad] = useState(true);
//   const [averageConvertedMpiScore, setAverageConvertedMpiScore] = useState(0);
//   const [averageMentalSpeedInSec, setAverageMentalSpeedInSec] = useState(0);
//   const [averagePrideAccuracyScorePercentage, setAveragePrideAccuracyScorePercentage] = useState(0);
//   const [averageMpiConsistency, setAverageMpiConsistency] = useState(0);
//   const [averageAwarenessScore, setAverageAwarenessScore] = useState(0);
//   const [averageAdvantageScore, setAverageAdvantageScore] = useState(0);
//   const [averageApplicationScore, setAverageApplicationScore] = useState(0); 
//   const [overallStudentsAssigned, setOverallStudentsAssigned] = useState(0);
//   const [completedAssessment, setCompletedAssessment] = useState(0);
//   const [topRankerData, setTopRankerData] = useState(null); 
  
//   const { selectedStandard, categoryMapping } = useStandard();

//   useEffect(() => {
//     if (selectedStandard === 'school') {
//       const token = localStorage.getItem("token");
//       fetch('https://test.xqminds.com/api/schoolData/school-dashboard-average', {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           "authorization": `Bearer ${token}`
//         }
//       })
//         .then((res) => res.json())
      
//         .then((res) => {
//           setAverageConvertedMpiScore(Number(res.averages.averageConvertedMpiScore) || 0);
//           setAverageMentalSpeedInSec(Number(res.averages.averageMentalSpeedInSec) || 0);
//           setAveragePrideAccuracyScorePercentage(Number(res.averages.averagePrideAccuracyScorePercentage) || 0);
//           setAverageMpiConsistency(Number(res.averages.averageMpiConsistency) || 0);
//           setAverageAdvantageScore(Number(res.averages.averageAdvantageScore) || 0);
//           setAverageApplicationScore(Number(res.averages.averageApplicationScore) || 0);
//           setAverageAwarenessScore(Number(res.averages.averageAwarenessScore) || 0);
//           setCaraosalsScore(res.contentObject);
//           setOverallStudentsAssigned(Number(res.averages.overAllStudentAssigned) || 0);
//           setCompletedAssessment(Number(res.averages.completedAssessmentStandardWise) || 0);
//           // setTopRankerData(res.topRanker); 
//           setInitialLoad(false); // Set to false after the first standard is selected
//         })
//         .catch(error => {
//           console.error('Error fetching data:', error);
//         });
//     } else if (categoryMapping[selectedStandard]) {
//       const token = localStorage.getItem("token");
//       const category = categoryMapping[selectedStandard];
//       const apiUrl = `https://test.xqminds.com/api/schoolData/section-wise-result?standard=${selectedStandard}&level=${category}`;

//       fetch(apiUrl, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           "authorization": `Bearer ${localStorage.getItem("token")}`,
//         },
//       })
//         .then((res) => res.json())
       
//         .then((res) => {
//           setAverageConvertedMpiScore(Number(res.result.averageConvertedMpiScore) || 0);
//           setAverageMentalSpeedInSec(Number(res.result.averageMentalSpeedInSec) || 0);
//           setAveragePrideAccuracyScorePercentage(Number(res.result.averagePrideAccuracyScorePercentage) || 0);
//           setAverageMpiConsistency(Number(res.result.averageMpiConsistency) || 0);
//           setAverageAdvantageScore(Number(res.result.averageAdvantageScore) || 0);
//           setAverageApplicationScore(Number(res.result.averageApplicationScore) || 0);
//           setAverageAwarenessScore(Number(res.result.averageAwarenessScore) || 0);
//           setOverallStudentsAssigned(Number(res.result.overAllStudentAssigned) || 0);
//           setCompletedAssessment(Number(res.result.completedAssessmentStandardWise) || 0);
//           setCaraosalsScore(res.contentObject);
//           // setTopRankerData(res.result.studentsScores); 
//         })
//         .catch((error) => {
//           console.error(`Error fetching data for standard ${selectedStandard}`, error);
//         });
//     }
//   }, [selectedStandard, categoryMapping]);

//   const displayedScore = (Number(averageConvertedMpiScore) || 0).toFixed(1);
//   const displayedAccuracy = (Number(averagePrideAccuracyScorePercentage) || 0).toFixed(1);
//   const displayedSpeed = (Number(averageMentalSpeedInSec) || 0).toFixed(1);
//   const displayConsistency = (Number(averageMpiConsistency) || 0).toFixed(1);
//   const displayIntelligence = (Number(averageAwarenessScore) || 0).toFixed(1);
//   const displaySituation = (Number(averageAdvantageScore) || 0).toFixed(1);
//   const displayApplying = (Number(averageApplicationScore) || 0).toFixed(1);
//   const displayoverAllAssigned = initialLoad 
//   ? (overallStudentsAssigned || 0) 
//   : (overallStudentsAssigned || 0);

//   const displayCompleted = initialLoad 
//   ? (completedAssessment || 0) 
//   : (completedAssessment || 0); 

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

  





























//this is the correct code



import React, { useEffect, useState } from "react";
import SchoolSideBar from "../SchoolSideBar/SchoolSideBar";
import CircularChart from "../Charts/CircularChart";
import Star from "../Charts/Star";
import AccuracyChart from "../Charts/AccuracyChart";
import UnderStandingChart from "../Charts/UnderStandingChart";
import ColorCircle from "../ColorCircle/ColorCircle";
import Speed from "../Charts/Speed";
// import Topper from "./Topper/Topper";
import TopRanker from "./TopRanker/TopRanker";
// import MainTable from "./Table/MainTable";
import NextExamDate from "./SchoolExamDate/NextExamDate";
import overall from "../../../image/overallstudent.png";
import complete from "../../../image/complete.png";
import pending from "../../../image/pending.png";
import CompleteTaskTable from "./CompleteTaskTable/CompleteTaskTable";
import NumberSpring from "../NumberSpring/NumberSpring";
// import AllMpiClass from "./AllMpiClass/AllMpiClass";
// import AccuracyMpi from "./AllMpiClass/AccuracyMpi";
// import ApplyingMpi from "./AllMpiClass/ApplyingMpi";
// import BasicIntelMpi from "./AllMpiClass/BasicIntelMpi";
// import SituationMpi from "./AllMpiClass/SituationMpi";
// import SpeedMpi from "./AllMpiClass/SpeedMpi";
// import ConsistancyMpi from "./AllMpiClass/ConsistancyMpi";
import PerformanceMessage from "./PerformanceMessage";
import StandardDropdown from "./StandardDropdown";
import { useStandard } from "./StandardContext";

import { useNavigate } from 'react-router-dom';

const SchoolDashboard = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [showModal, setShowModal] = useState(false);
  const [showComplete, setShowComplete] = useState(false);
  const [showTopperModal, setShowTopperModal] = useState(false);
  const [showMpi, setShowMpi] = useState(false);
  const [showAccuracyMpi, setShowAccuracyMpi] = useState(false);
  const [showApplyingMpi, setShowApplyingMpi] = useState(false);
  const [showBasicMpi, setShowBasicMpi] = useState(false);
  const [showSituationMpi, setShowSituationMpi] = useState(false);
  const [showSpeedMpi, setShowSpeedMpi] = useState(false);
  const [showConsistancyMpi, setShowConsistancyMpi] = useState(false);
  const [averageStudentData, setaverageStudentData] = useState(null);
  const [averageAccuracy, setaverageAccuracy] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [data, setData] = useState(null);
  const [caraosalsScore, setCaraosalsScore] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [averageConvertedMpiScore, setAverageConvertedMpiScore] = useState(0);
  const [averageMentalSpeedInSec, setAverageMentalSpeedInSec] = useState(0);
  const [averagePrideAccuracyScorePercentage, setAveragePrideAccuracyScorePercentage] = useState(0);
  const [averageMpiConsistency, setAverageMpiConsistency] = useState(0);
  const [averageAwarenessScore, setAverageAwarenessScore] = useState(0);
  const [averageAdvantageScore, setAverageAdvantageScore] = useState(0);
  const [averageApplicationScore, setAverageApplicationScore] = useState(0); 
  const [overallStudentsAssigned, setOverallStudentsAssigned] = useState(0);
  const [completedAssessment, setCompletedAssessment] = useState(0);
  const [topRankerData, setTopRankerData] = useState(null); 
  
  const { selectedStandard, categoryMapping } = useStandard();
  const navigate = useNavigate(); // Initialize useNavigate hook
  // useEffect(() => {
  //   if (selectedStandard === 'school') {
  //     const token = localStorage.getItem("token");
  //     fetch('https://test.xqminds.com/api/schoolData/school-dashboard-average', {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "authorization": `Bearer ${token}`
  //       }
  //     })
  //       .then((res) => res.json())
  //       .then((res) => {
  //         setAverageConvertedMpiScore(Number(res.averages.averageConvertedMpiScore) || 0);
  //         setAverageMentalSpeedInSec(Number(res.averages.averageMentalSpeedInSec) || 0);
  //         setAveragePrideAccuracyScorePercentage(Number(res.averages.averagePrideAccuracyScorePercentage) || 0);
  //         setAverageMpiConsistency(Number(res.averages.averageMpiConsistency) || 0);
  //         setAverageAdvantageScore(Number(res.averages.averageAdvantageScore) || 0);
  //         setAverageApplicationScore(Number(res.averages.averageApplicationScore) || 0);
  //         setAverageAwarenessScore(Number(res.averages.averageAwarenessScore) || 0);
  //         setCaraosalsScore(res.contentObject);
  //         setOverallStudentsAssigned(Number(res.averages.overAllStudentAssigned) || 0);
  //         setCompletedAssessment(Number(res.averages.completedAssessmentStandardWise) || 0);
  //         setTopRankerData(res.scores);  // Set topRankerData from the school-dashboard-average API
  //         setInitialLoad(false); // Set to false after the first standard is selected
  //       })
  //       .catch(error => {
  //         console.error('Error fetching data:', error);
  //       });
  //   } else if (categoryMapping[selectedStandard]) {
  //     const token = localStorage.getItem("token");
  //     const category = categoryMapping[selectedStandard];
  //     const apiUrl = `https://test.xqminds.com/api/schoolData/section-wise-result?standard=${selectedStandard}&level=${category}`;

  //     fetch(apiUrl, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "authorization": `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     })
  //       .then((res) => res.json())
  //       .then((res) => {
  //         setAverageConvertedMpiScore(Number(res.result.averageConvertedMpiScore) || 0);
  //         setAverageMentalSpeedInSec(Number(res.result.averageMentalSpeedInSec) || 0);
  //         setAveragePrideAccuracyScorePercentage(Number(res.result.averagePrideAccuracyScorePercentage) || 0);
  //         setAverageMpiConsistency(Number(res.result.averageMpiConsistency) || 0);
  //         setAverageAdvantageScore(Number(res.result.averageAdvantageScore) || 0);
  //         setAverageApplicationScore(Number(res.result.averageApplicationScore) || 0);
  //         setAverageAwarenessScore(Number(res.result.averageAwarenessScore) || 0);
  //         setOverallStudentsAssigned(Number(res.result.overAllStudentAssigned) || 0);
  //         setCompletedAssessment(Number(res.result.completedAssessmentStandardWise) || 0);
  //         setCaraosalsScore(res.contentObject);
  //         setTopRankerData(res.result.studentsScores);  // Set topRankerData from the section-wise API
  //       })
  //       .catch((error) => {
  //         console.error(`Error fetching data for standard ${selectedStandard}`, error);
  //       });
  //   }
  // }, [selectedStandard, categoryMapping]);

  // Determine top students from both data sets
  useEffect(() => {
    if (selectedStandard) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set('standard', selectedStandard);
      navigate(`${window.location.pathname}?${queryParams.toString()}`, { replace: true });
    }

    if (selectedStandard === 'school') {
      const token = localStorage.getItem("token");
      fetch('https://test.xqminds.com/api/schoolData/school-dashboard-average', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${token}`
        }
      })
        .then((res) => res.json())
        .then((res) => {
          setAverageConvertedMpiScore(Number(res.averages.averageConvertedMpiScore) || 0);
          setAverageMentalSpeedInSec(Number(res.averages.averageMentalSpeedInSec) || 0);
          setAveragePrideAccuracyScorePercentage(Number(res.averages.averagePrideAccuracyScorePercentage) || 0);
          setAverageMpiConsistency(Number(res.averages.averageMpiConsistency) || 0);
          setAverageAdvantageScore(Number(res.averages.averageAdvantageScore) || 0);
          setAverageApplicationScore(Number(res.averages.averageApplicationScore) || 0);
          setAverageAwarenessScore(Number(res.averages.averageAwarenessScore) || 0);
          setCaraosalsScore(res.contentObject);
          setOverallStudentsAssigned(Number(res.averages.overAllStudentAssigned) || 0);
          setCompletedAssessment(Number(res.averages.completedAssessmentStandardWise) || 0);
          setTopRankerData(res.scores);  // Set topRankerData from the school-dashboard-average API
          setInitialLoad(false); // Set to false after the first standard is selected
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    } else if (categoryMapping[selectedStandard]) {
      const token = localStorage.getItem("token");
      const category = categoryMapping[selectedStandard];
      const apiUrl = `https://test.xqminds.com/api/schoolData/section-wise-result?standard=${selectedStandard}&level=${category}`;

      fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setAverageConvertedMpiScore(Number(res.result.averageConvertedMpiScore) || 0);
          setAverageMentalSpeedInSec(Number(res.result.averageMentalSpeedInSec) || 0);
          setAveragePrideAccuracyScorePercentage(Number(res.result.averagePrideAccuracyScorePercentage) || 0);
          setAverageMpiConsistency(Number(res.result.averageMpiConsistency) || 0);
          setAverageAdvantageScore(Number(res.result.averageAdvantageScore) || 0);
          setAverageApplicationScore(Number(res.result.averageApplicationScore) || 0);
          setAverageAwarenessScore(Number(res.result.averageAwarenessScore) || 0);
          setOverallStudentsAssigned(Number(res.result.overAllStudentAssigned) || 0);
          setCompletedAssessment(Number(res.result.completedAssessmentStandardWise) || 0);
          setCaraosalsScore(res.contentObject);
          setTopRankerData(res.result.studentsScores);  // Set topRankerData from the section-wise API
        })
        .catch((error) => {
          console.error(`Error fetching data for standard ${selectedStandard}`, error);
        });
    }
  }, [selectedStandard, categoryMapping, navigate]); // Include navigate in dependencies

  const getTopStudents = () => {
    if (!topRankerData) return [];

    // Assuming we are interested in top 5 students based on convertedMpiScore
    return topRankerData
      .sort((a, b) => parseFloat(b.convertedMpiScore) - parseFloat(a.convertedMpiScore))
      .slice(0, 7);
  };

  const topStudents = getTopStudents();

  const displayedScore = (Number(averageConvertedMpiScore) || 0).toFixed(1);
  const displayedAccuracy = (Number(averagePrideAccuracyScorePercentage) || 0).toFixed(1);
  const displayedSpeed = (Number(averageMentalSpeedInSec) || 0).toFixed(1);
  const displayConsistency = (Number(averageMpiConsistency) || 0).toFixed(1);
  const displayIntelligence = (Number(averageAwarenessScore) || 0).toFixed(1);
  const displaySituation = (Number(averageAdvantageScore) || 0).toFixed(1);
  const displayApplying = (Number(averageApplicationScore) || 0).toFixed(1);
  const displayoverAllAssigned = initialLoad 
    ? (overallStudentsAssigned || 0) 
    : (overallStudentsAssigned || 0);

  const displayCompleted = initialLoad 
    ? (completedAssessment || 0) 
    : (completedAssessment || 0); 

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  return (
    <div className="w-[99%] bg-center">
      <div className="flex gap-4">
        <div className={`flex h-full m-auto gap-5 sticky top-1 ${isSidebarOpen ? 'block' : 'hidden'} md:block`}>
          <SchoolSideBar />
        </div>
        <div className="w-[91.8%] mx-auto">
          <div className="md:flex md:flex-row flex flex-col-reverse justify-start items-center shadow-lg rounded-lg p-3 gap-4 sticky top-0 z-10 bg-white">
            <NextExamDate />
            <StandardDropdown /> 
            {caraosalsScore && (
              <div className="mt-8">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">Data for {selectedStandard}</h2>
                <pre className="bg-gray-100 p-6 rounded-lg border border-gray-200 overflow-x-auto">{JSON.stringify(caraosalsScore, null, 2)}</pre>
              </div>
            )}
          </div>
          <div className="main flex flex-col gap-4 md:flex md:flex-row w-full">
            <div className="firstdiv md:w-[35%] sm:w-[90%] ">
              <div
                className="mpi flex flex-col mt-5 items-center cursor-pointer shadow-xl rounded-lg transition duration-300 hover:bg-[#B6FFFA] sw-full sm:w-full md:w-full lg:full xl:w-full"
                style={{
                  background: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
                onClick={() => setShowMpi(true)}
              >
                <p className="text-lg p-2  font-fredoka font-medium sm:text-xl md:text-2xl">
                  Mental Profiling Index (MPI) Score
                </p>
                <div className="w-full flex items-center justify-center p-2">
                  <CircularChart
                    percentage={displayedScore}
                  />
                </div>
                <div className="w-full flex justify-end p-2 cursor-pointer">
                  <p className="relative group w-[3%] right-0 bottom-0 cursor-pointer">
                    <ColorCircle
                      percentage={displayedScore * 10}
                    />
                    <div className=" group-hover:block hidden absolute right-0 bottom-3 -mt-1 ml-4 z-10 w-64  p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
                      <PerformanceMessage />
                    </div>
                  </p>
                </div>
              </div>
              {/* <AllMpiClass
                isVisible={showMpi}
                close={() => setShowMpi(false)}
              /> */}
              <div className="flex justify-end mt-5 gap-4 w-full h-auto">
                <div className="mpi flex flex-col items-center cursor-pointer w-full gap-5 shadow-xl rounded-lg transition duration-300 hover:bg-[#B6FFFA]"
                  onClick={() => setShowAccuracyMpi(true)}
                >
                  <p className=" font-fredoka font-medium  ">Accuracy</p>
                  <div>
                    <AccuracyChart
                      percentage={displayedAccuracy}
                    />
                  </div>
                  <div className="w-full flex justify-end p-2 cursor-pointer">
                    <p className="relative group w-[5%] right-0 bottom-0 cursor-pointer">
                      <ColorCircle
                        percentage={displayedAccuracy}
                      />
                      <div className="group-hover:block hidden absolute right-0 top-full z-10 w-64">
                        <div className="absolute right-4 bottom-4 -mt-1 ml-4 z-10 w-64 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
                          <PerformanceMessage />
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
                {/* <AccuracyMpi
                  isVisible={showAccuracyMpi}
                  close={() => setShowAccuracyMpi(false)}
                /> */}
                <div className="mpi flex flex-col items-center w-full cursor-pointer gap-4 shadow-xl rounded-lg transition duration-300 hover:bg-[#B6FFFA]"
                  onClick={() => setShowSpeedMpi(true)}
                >
                  <p className=" font-fredoka  font-medium ">Speed</p>
                  <div className="w-[]">
                    <Speed
                      percentage={displayedSpeed}
                    />
                  </div>
                  <div className="w-full flex justify-end p-2">
                  </div>
                </div>
                {/* <SpeedMpi
                  isVisible={showSpeedMpi}
                  close={() => setShowSpeedMpi(false)}
                /> */}
              </div>
              <div className="mpi h-[100px] flex flex-col mt-5 gap-4 cursor-pointer p-5  shadow-xl rounded-lg transition duration-300 hover:bg-[#B6FFFA]"
                onClick={() => setShowConsistancyMpi(true)}
              >
                <p className="font-fredoka font-medium ">Consistency</p>
                <div>
                  <Star
                    rating={displayConsistency}
                  />
                </div>
                <div className="w-full flex justify-end cursor-pointer">
                  <p className="relative group w-[1%] right-0 bottom-6 cursor-pointer">
                    <ColorCircle
                      percentage={displayConsistency * 20}
                    />
                    <div className="group-hover:block hidden absolute right-0 top-full z-10 w-64">
                      <div className="absolute right-4 bottom-4 -mt-1 ml-4 z-10 w-64 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
                        <PerformanceMessage />
                      </div>
                    </div>
                  </p>
                </div>
              </div>
              {/* <ConsistancyMpi
                isVisible={showConsistancyMpi}
                close={() => setShowConsistancyMpi(false)}
              /> */}
            </div>
            <div className="secondDiv lg:w-[65%] md:w-[65%] sm:w-[100%] flex flex-col gap-4">
              <div className="flex flex-col md:flex-row gap-4 mt-5 w-full h-auto md:h-[250px]">
                <div
                  className="mpi cursor-pointer w-full shadow-xl rounded-lg md:h-[250px] sm:h-[350px] sm:w-[90%] md:w-[50%] flex flex-col gap-4 justify-center items-center transition duration-300 hover:bg-yellow-300"
                  onClick={() => setShowModal(true)}
                >
                  <p className="text-lg p-2  font-fredoka font-medium  sm:text-xl md:text-2xl ">
                    Overall Students Assigned
                  </p>
                  <div className="flex flex-col gap-4">
                    <p className="font-bold text-3xl">
                    <NumberSpring n={displayoverAllAssigned} />
                    </p>
                    <img className="w-[100px]" src={overall} alt="" />
                  </div>
                </div>
                {/* <MainTable
                  isVisible={showModal}
                  onClose={() => setShowModal(false)}
                /> */}
                <div className="flex flex-col h-[250px] gap-4 w-full md:w-[50%]">
                  <div className="mpi shadow-xl rounded-lg h-[50%] md:h-[100%] cursor-pointer flex flex-col justify-center gap-5 items-center text-lg font-semibold md:border-none transition duration-300 hover:bg-yellow-300"
                    onClick={() => setShowComplete(true)}
                  >
                    <p className=" font-fredoka font-medium ">Complete Assessment</p>
                    <div className="flex gap-4 justify-evenly items-center w-[100%]">
                      <img className="w-[60px]" src={complete} alt="" />
                      <p className="ont-bold text-3xl">
                      <NumberSpring n={displayCompleted} />
                      </p>
                    </div>
                  </div>
                  {/* <CompleteTaskTable
                    Visible={showComplete}
                    close={() => setShowComplete(false)}
                  /> */}
                  <div className="mpi shadow-xl rounded-lg h-[50%] md:h-[100%] flex flex-col justify-center gap-5 items-center text-lg font-semibold transition duration-300 hover:bg-yellow-300">
                    <p className=" font-fredoka font-medium ">Yet to Complete</p>
                    <div className="flex gap-4 justify-evenly items-center w-[100%]">
                      <p className="ont-bold text-3xl">
                      <NumberSpring n={displayoverAllAssigned ? displayoverAllAssigned-
                          displayCompleted : 0} />
                      </p>
                      <img className="w-[60px]" src={pending} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-4  ">
                <div className="flex flex-col gap-4 w-full md:w-[40%]">
                  <div className="mpi shadow-xl flex justify-between p-4 items-center cursor-pointer rounded-lg h-auto md:h-auto transition duration-300 hover:bg-blue-300"
                    onClick={() => setShowBasicMpi(true)}
                  >
                    <div className="flex justify-between p-2 gap-8 w-[40%] items-center"> 
                      <p className="  font-fredoka font-medium ">
                        Basic Intelligence
                      </p>
                    </div>
                    <div className="flex justify-end gap-4">
                      <div>
                        <UnderStandingChart
                          percentage={displayIntelligence}
                        />
                      </div>
                      <div className="w-full flex justify-end p-2 relative">
                        <p className="relative group w-[20%] right-0 bottom-0 cursor-pointer">
                          <ColorCircle
                            percentage={displayIntelligence}
                          />
                          <div className="group-hover:block hidden absolute right-0 top-full -mt-1 ml-4 z-10 w-64">
                            <div className="absolute right-0 top-full -mt-1 ml-4 z-10 w-64 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
                              <PerformanceMessage />
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <BasicIntelMpi
                    isVisible={showBasicMpi}
                    close={() => setShowBasicMpi(false)}
                  /> */}
                  <div className="mpi shadow-xl flex justify-between p-4 items-center cursor-pointer rounded-lg h-auto md:h-auto transition duration-300 hover:bg-blue-300"
                    onClick={() => setShowSituationMpi(true)}
                  >
                    <div className="flex justify-between p-2 gap-8 w-[40%] items-center">
                      <p className=" font-fredoka font-medium ">
                        Situational Intelligence
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <div>
                        <UnderStandingChart
                          percentage={displaySituation}
                        />
                      </div>
                      <div className="w-full flex justify-end p-2 relative">
                        <p className="relative group w-[20%] right-0 bottom-0 cursor-pointer">
                          <ColorCircle
                            percentage={displaySituation}
                          />
                          <div className="group-hover:block hidden absolute right-0 bottom-3 -mt-1 ml-4 z-10 w-64">
                            <div className="absolute right-0 top-full -mt-1 ml-4 z-10 w-64 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
                              <PerformanceMessage />
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <SituationMpi
                    isVisible={showSituationMpi}
                    close={() => setShowSituationMpi(false)}
                  /> */}
                  <div className="mpi shadow-xl flex justify-between p-4 items-center cursor-pointer rounded-lg h-auto md:h-auto transition duration-300 hover:bg-blue-300"
                    onClick={() => setShowApplyingMpi(true)}
                  >
                    <div className="flex justify-between p-2 gap-8 w-[40%] items-center">
                      <p className=" font-fredoka font-medium ">
                        Applying Intelligence
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <div>
                        <UnderStandingChart
                          percentage={displayApplying}
                        />
                      </div>
                      <div className="w-full flex justify-end p-2 relative">
                        <p className="relative group w-[20%] right-0 bottom-0 cursor-pointer">
                          <div className="group-hover:block hidden absolute right-0 top-full z-10 w-64">
                            <div className="absolute right-4 bottom-4 -mt-1 ml-4 z-10 w-64 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">
                              <PerformanceMessage />
                            </div>
                          </div>
                          <ColorCircle
                            percentage={displayApplying}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <ApplyingMpi
                    isVisible={showApplyingMpi}
                    close={() => setShowApplyingMpi(false)}
                  /> */}
                </div>
                <div className="w-full h-full md:w-[60%] flex flex-col gap-4">
                  <div>
                    <div className="flex flex-col cursor-pointer"
                      onClick={() => setShowTopperModal(true)}>
                        <TopRanker data={topStudents} />
                    </div>
                    {/* <Topper
                      isVisible={showTopperModal}
                      onClose={() => setShowTopperModal(false)}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isSidebarOpen && (
        <div className="fixed inset-0 z-50 flex md:hidden">
          <div className="bg-white shadow-lg">
            <SchoolSideBar />
          </div>
          <div className="flex-grow" onClick={toggleSidebar}></div>
        </div>
      )}
    </div>
  );
};

export default SchoolDashboard;
