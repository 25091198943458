// import React from 'react'

// const OverAllStudent = ({isvisible,onClose}) => {
//     const data = [
//         { sno: 1, class: '5A', assigned: 10, completed: 8, pending: 2, spoc: 'Mr. Smith' },
//         { sno: 2, class: '6A', assigned: 12, completed: 10, pending: 2, spoc: 'Ms. Johnson' },
//         { sno: 3, class: '6B', assigned: 8, completed: 6, pending: 2, spoc: 'Mrs. Brown' },
//         // Add more data as needed
//       ];
//       if(!isvisible) return null
//   return (
//     <div className="container mx-auto mt-8">
//           <button className="text-white rounded-lg border mb-5 border-white text-md px-3 py-1"  onClick={() => onClose()}>Close</button>

//     <table className="min-w-full bg-white border border-gray-300">
//       <thead>
//         <tr>
//           <th className="py-2 px-4 border-b border-gray-300">S.No</th>
//           <th className="py-2 px-4 border-b border-gray-300">Class</th>
//           <th className="py-2 px-4 border-b border-gray-300">Assigned</th>
//           <th className="py-2 px-4 border-b border-gray-300">Completed</th>
//           <th className="py-2 px-4 border-b border-gray-300">Pending</th>
//           <th className="py-2 px-4 border-b border-gray-300">SPOC/Teacher</th>
//         </tr>
//       </thead>
//       <tbody>
//         {data.map((row, index) => (
//           <tr key={index} className="text-center">
//             <td className="py-2 px-4 border-b border-gray-300">{row.sno}</td>
//             <td className="py-2 px-4 border-b border-gray-300">{row.class}</td>
//             <td className="py-2 px-4 border-b border-gray-300">{row.assigned}</td>
//             <td className="py-2 px-4 border-b border-gray-300">{row.completed}</td>
//             <td className="py-2 px-4 border-b border-gray-300">{row.pending}</td>
//             <td className="py-2 px-4 border-b border-gray-300">{row.spoc}</td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   </div>
//   )
// }

// export default OverAllStudent




import React from 'react';
import PropTypes from 'prop-types';
import Example from './TS';
import MakeData from './MakeData';

const OverAllStudent = ({ isVisible, onClose }) => {
  const data = [
    { sno: 1, class: '5A', assigned: 10, completed: 8, pending: 2, spoc: 'Mr. Smith' },
    { sno: 2, class: '6A', assigned: 12, completed: 10, pending: 2, spoc: 'Ms. Johnson' },
    { sno: 3, class: '6B', assigned: 8, completed: 6, pending: 2, spoc: 'Mrs. Brown' },
    { sno: 4, class: '5A', assigned: 10, completed: 8, pending: 2, spoc: 'Mr. Smith' },
    { sno: 5, class: '6A', assigned: 12, completed: 10, pending: 2, spoc: 'Ms. Johnson' },
    { sno: 6, class: '6B', assigned: 8, completed: 6, pending: 2, spoc: 'Mrs. Brown' },
    { sno: 7, class: '5A', assigned: 10, completed: 8, pending: 2, spoc: 'Mr. Smith' },
    { sno: 8, class: '6A', assigned: 12, completed: 10, pending: 2, spoc: 'Ms. Johnson' },
    { sno: 9, class: '6B', assigned: 8, completed: 6, pending: 2, spoc: 'Mrs. Brown' },
    { sno: 10, class: '5A', assigned: 10, completed: 8, pending: 2, spoc: 'Mr. Smith' },
    // Add more data as needed
  ];

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0  bg-black bg-opacity-50 backdrop-blur-sm" onClick={onClose}></div>
      <div className="bg-white p-6 rounded-lg shadow-lg z-10 mx-4 w-full max-w-3xl">
       <p>OverAll Student Assigned</p>
        <div className="max-h-80 overflow-y-auto">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-300">S.No</th>
                <th className="py-2 px-4 border-b border-gray-300">Class</th>
                <th className="py-2 px-4 border-b border-gray-300">Assigned</th>
                <th className="py-2 px-4 border-b border-gray-300">Completed</th>
                <th className="py-2 px-4 border-b border-gray-300">Pending</th>
                <th className="py-2 px-4 border-b border-gray-300">SPOC/Teacher</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="text-center">
                  <td className="py-2 px-4 border-b border-gray-300">{row.sno}</td>
                  <td className="py-2 px-4 border-b border-gray-300">{row.class}</td>
                  <td className="py-2 px-4 border-b border-gray-300">{row.assigned}</td>
                  <td className="py-2 px-4 border-b border-gray-300">{row.completed}</td>
                  <td className="py-2 px-4 border-b border-gray-300">{row.pending}</td>
                  <td className="py-2 px-4 border-b border-gray-300">{row.spoc}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button className="mt-4 bg-blue-500 text-white py-2 px-4 rounded" onClick={onClose}>
          Close
        </button>
      </div>
     {/* <MakeData/> */}
    </div>
  );
};

OverAllStudent.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OverAllStudent;


// import {
//   MaterialReactTable,
//   createMRTColumnHelper,
//   useMaterialReactTable,
// } from 'material-react-table';
// import { Box, Button } from '@mui/material';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here
// import { data } from './makeData';

// const OverAllStudent = createMRTColumnHelper({ isVisible, onClose });

// const columns = [
//   columnHelper.accessor('id', {
//     header: 'ID',
//     size: 40,
//   }),
//   columnHelper.accessor('firstName', {
//     header: 'First Name',
//     size: 120,
//   }),
//   columnHelper.accessor('lastName', {
//     header: 'Last Name',
//     size: 120,
//   }),
//   columnHelper.accessor('company', {
//     header: 'Company',
//     size: 300,
//   }),
//   columnHelper.accessor('city', {
//     header: 'City',
//   }),
//   columnHelper.accessor('country', {
//     header: 'Country',
//     size: 220,
//   }),
// ];

// const csvConfig = mkConfig({
//   fieldSeparator: ',',
//   decimalSeparator: '.',
//   useKeysAsHeaders: true,
// });

// const Example = () => {
//   const handleExportRows = (rows) => {
//     const rowData = rows.map((row) => row.original);
//     const csv = generateCsv(csvConfig)(rowData);
//     download(csvConfig)(csv);
//   };

//   const handleExportData = () => {
//     const csv = generateCsv(csvConfig)(data);
//     download(csvConfig)(csv);
//   };

//   const table = useMaterialReactTable({
//     columns,
//     data,
//     enableRowSelection: true,
//     columnFilterDisplayMode: 'popover',
//     paginationDisplayMode: 'pages',
//     positionToolbarAlertBanner: 'bottom',
//     renderTopToolbarCustomActions: ({ table }) => (
//       <Box
//         sx={{
//           display: 'flex',
//           gap: '16px',
//           padding: '8px',
//           flexWrap: 'wrap',
//         }}
//       >
//         <Button
//           //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
//           onClick={handleExportData}
//           startIcon={<FileDownloadIcon />}
//         >
//           Export All Data
//         </Button>
//         <Button
//           disabled={table.getPrePaginationRowModel().rows.length === 0}
//           //export all rows, including from the next page, (still respects filtering and sorting)
//           onClick={() =>
//             handleExportRows(table.getPrePaginationRowModel().rows)
//           }
//           startIcon={<FileDownloadIcon />}
//         >
//           Export All Rows
//         </Button>
//         <Button
//           disabled={table.getRowModel().rows.length === 0}
//           //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
//           onClick={() => handleExportRows(table.getRowModel().rows)}
//           startIcon={<FileDownloadIcon />}
//         >
//           Export Page Rows
//         </Button>
//         <Button
//           disabled={
//             !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
//           }
//           //only export selected rows
//           onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
//           startIcon={<FileDownloadIcon />}
//         >
//           Export Selected Rows
//         </Button>
//       </Box>
//     ),
//   });

//   return <MaterialReactTable table={table} />;
// };

// export default OverAllStudent;
