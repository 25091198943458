import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const Radars = ({ perceiveIndex, resolveIndex, influenceIndex, deliverIndex, engageIndex }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      // Destroy previous chart instance if it exists
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      // Create new chart instance
      chartInstanceRef.current = new Chart(ctx, {
        type: 'radar',
        data: {
          labels: ['Perceive', 'Resolve', 'Influence', 'Deliver', 'Engage'],
          datasets: [{
            label: 'Radar Chart',
            data: [
              perceiveIndex || 0,
              resolveIndex || 0,
              influenceIndex || 0,
              deliverIndex || 0,
              engageIndex || 0
            ],
            backgroundColor: 'rgba(54, 162, 235, 0.1)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 2,
          }],
        },
        options: {
          elements: {
            line: {
              tension: 0,
            },
          },
          scales: {
            r: {
              suggestedMin: 0,
              suggestedMax: 10,
              ticks: {
                stepSize: 10,
              },
              grid: {
                color: (context) => {
                  const index = context.index;
                  return index === 5 ? 'black' : 'grey';
                }
              },
              scaleLabel: {
                display: false 
              }
            },
          },
          plugins: {
            legend: {
              display: false 
            }
          }
        },
      });
    }

    // Cleanup on component unmount
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [perceiveIndex, resolveIndex, influenceIndex, deliverIndex, engageIndex]); // Update dependencies

  return (
    <div style={{ width: '300px', height: '300px' }}>
      <canvas id="radarChart" ref={chartRef} />
    </div>
  );
};

export default Radars;

 