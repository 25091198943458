import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Quiz from "../image/Quiz.png";
import video from "../image/video.mp4";
import LineChart from "./Circular/LineChart";
import TestBackground from "../../src/image/TestBackground.jpg";
import QuestionBackground from "../../src/image/QuestionBackground.png";
import OptionBackground from "../../src/image/OptionBackground.png";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import prev from "../image/prev.gif"
import next from "../image/next.gif"
import Background from "../../src/image/background.jpg"
// {
//   sort_order: 1,
//   question:
//     "Sen is good at imitating and acting the exact mannerism of anyone he sees for five minutes. what skill do you think he possesses that makes it easier for him to do it?",
//   options: [
//     "Observational skills",
//     "Memory Skills",
//     "Acting Skills",
//     "Leadership Skills",
//     "All the above",
//     "None of the above",
//   ],
// }
const questions = [
  {
    sort_order: 1,
    question_body:
      "Sen is good at imitating and acting the exact mannerism of anyone he sees for five minutes. what skill do you think he possesses that makes it easier for him to do it?",
    video:
      "",
    image: "",
    options: [
      "Observational skills",
      "Memory Skills",
      "Acting Skills",
      "Leadership Skills",
      "All the above",
      "None of the above",
    ],
  },
  {
    sort_order: 2,
    question_body: "How many ducks are shown in the video",
    video: "https://d1pe7rjghiel3a.cloudfront.net/uploads/holistic_youth.mp4-1663327532848holistic_youth.mp4",

    image:
      "",

    options: [10, 11, 12, 15, 14],
    right_option: 3,
  },
  {
    sort_order: 3,
    question_body:
      "Shiv wants to win the  tennis tournament which he will be playing next week. In order to outplay the opponent, what are the things he should do.",
    video: "",

    image: "",
    options: [
      "He needs to watch the previous match videos of his opponent to understand his playing style",
      "He needs to observe on the ways his opponent is making errors while playing",
      "He needs to focus on his own strength to win the game",
      "All the Above",
      "None of the above",
    ],
    right_option: 4,
  },
  {
    sort_order: 4,
    question_body:
      "Shreyans is good at remembering all the lessons taught by his teacher during the class hours and writing it in a fresh notebook even after a month without referring or seeing the class work notebook. What skill do you think he possesses that makes it easier for him to do",
    video: "",

    image: "",
    options: [
      "Memory skills",
      "Attention Skills",
      "Concentration Skills",
      "Listening Skills",
      "All the above",
      "None of the above",
    ],
    right_option: 1,
  },
  {
    sort_order: 5,
    question_body: "What is the number shown in the image",
    video: "",

    image: "https://d39tbkl68vucdq.cloudfront.net/uploads/holistic_q13.jpg-1612254854016holistic_q13.jpg",
    options: [
      "844 633 4433",
      "833 644 3344",
      "744 833 4433",
      "844 733 4433",
      "822 733 4433",
    ],
    right_option: 4,
  },
  
  {
    sort_order: 6,
    question_body:
      "Ram has to memorize the lessons taught by his teacher to write in exams. What work do you think he should do to remember the lessons?",
    video: "",

    image: "",
    options: [
      "Create a mental pictures of the lessons with the appropriate functional descriptions",
      "Create list of pointers and read regularly",
      "Remember the lessons in the form of a story & tag with a appropriate topic",
      "All the above",
      "None of the above",
    ],
    right_option: 4,
  },
  {
    sort_order: 7,
    question_body:
      "Anaya has the power of finding out all the possible and probable obstacles she would face while solving a problem. What skill do you think she possess that makes it easier for her to find it",
    video: "",

    image: "",
    options: [
      "Analytical Skills",
      "Listening Skills",
      "Memory Skills",
      "All the above",
      "None of the above",
    ],
    right_option: 1,
  },
  {
    sort_order: 8,
    question_body:
      "Two people played chess 5 times. Both of them won the same number of games and there was not a single draw. How is this possible?",
    video: "",

    image: "",
    options: [
      "This is impossible",
      "One game was a tie",
      "One game was forfeited",
      "They were playing with different people",
      "The last game is still happening",
    ],
    right_option: 4,
  },
  {
    sort_order: 9,
    question_body:
      "How do we need to analyze all the potential reasons for any problem?",
    video: "",

    image: "",
    options: [
      "Analyze the functional properties of the problem",
      "Ask suggestions from random people regarding the problem",
      "Ask suggestions from the concerned people regarding the problem",
      "Check if any relevant information is missing",
      "Option 1,3 & 4",
      "Option 2,3 & 4",
    ],
    right_option: 5,
  },
  {
    sort_order: 10,
    question_body:
      "Sherin has an ability to create a solution and come out from any kind of awkward situation she is put into. What skill do you think she possesses that makes it easier for her to do that.",
    video: "",

    image: "",
    options: [
      "Problem Solving Skills",
      "Attention Skills",
      "Collaboration Skills",
      "All the above",
      "None of the above",
    ],
    right_option: 1,
  },
  {
    sort_order: 11,
    question_body:
      "What would be the best fitted next word in the sequence: <br/> fly, air, wind, weather, ……..",
    video: "",

    image: "",
    options: [
      "Eagle",
      "Sounding Balloon",
      "All the above",
      "None of the above",
    ],
    right_option: 3,
  },
  {
    sort_order: 12,
    question_body:
      "What would be the expectations of any new scientific product which comes to the market for sale..",
    video: "",

    image: "",
    options: [
      "It should be easy for the most to operate",
      "It should be cheap to buy",
      "It should benefit the most",
      "All the above",
      "None of the above",
    ],
    right_option: 4,
  },
  {
    sort_order: 13,
    question_body:
      "Senthil takes all suggestions and advice given to him by anyone which will make him grow better even if it is provided by people he doesn’t like. What ability do you think he possesses that makes it easier for him to do it.",
    video: "",
    image: "",
    options: [
      "Growth mindset",
      "Good communication",
      "Good collaboration",
      "Patience",
      "None of the above",
    ],
    right_option: 1,
  },
  {
    sort_order: 14,
    question_body: "When we lose any game 3 times continuously, it means",
    video: "",
    image: "",
    options: [
      "That sport is not for us",
      "Luck is not in favour of us",
      "Something is lacking in our skill or ability which needs to be addressed",
      "All the above",
      "None of the above",
    ],
    right_option: 3,
  },
  {
    sort_order: 15,
    question_body:
      "Srijith failed in four exams consecutively, so he was not interested to write the 5th time. Even though his parents and friends had the faith in him, he had a strong",
    video: "",
    image: "",
    options: [
      "Negative belief",
      "Negative thought process",
      "Fear that he might fail again",
      "All the above",
      "None of the above",
    ],
    right_option: 4,
  },
  {
    sort_order: 16,
    question_body:
      "Whenever Indira plays a sport, she always sees the best that she learned or gained even while losing the game. What skill do you think she possesses that makes it easier for her do it.",
    video: "",
    image: "",
    options: [
      "Good Attitude",
      "Good Expression",
      "All the above",
      "None of the above",
    ],
    right_option: 1,
  },
  {
    sort_order: 17,
    question_body: "Whenever things are not going as per our plans",
    video: "",
    image: "",
    options: [
      "Need to be patient and maintain the positive attitude",
      "Do whatever is mandatory even when things are not going properly",
      "Try to use all external influences even the unethical ones to bring it back as planned",
      "Option 1 & 2",
      "Option 2 & 3",
      "Option 1 & 3",
    ],
    right_option: 4,
  },
  {
    sort_order: 18,
    question_body:
      "What would be the appropriate way to express our suggestions to others? ",
    video: "",
    image: "",
    options: [
      "Express our suggestions or advice which will benefit the receiver",
      "Express the suggestions in the intended way we like to happen",
      "Express our suggestions anyways whether they take it or not",
      "All the above",
      "None of the above",
    ],
    right_option: 1,
  },
  {
    sort_order: 19,
    question_body:
      "Manoj always feel bad about his life and looks sad and try to avoid interactions with other people, he is",
    video: "",
    image: "",
    options: [
      "Emotionally weak",
      "Mentally weak",
      "Physically weak",
      "All the above",
      "None of the above",
    ],
    right_option: 1,
  },
  {
    sort_order: 20,
    question_body:
      " Diana was travelling to the nearby town to meet her friend after 10 years, while walking on a lonely street at night by 10 pm suddenly she felt someone touched her shoulder from behind, she was initially ____ ",
    video: "",
    image: "",
    options: [
      "Surprised",
      "Excited",
      "Scared",
      "All the above",
      "None of the above",
    ],
    right_option: 3,
  },
  {
    sort_order: 21,
    question_body:
      "How do we need to do to convey our inner feelings or emotions about the current circumstances to others?",
    video: "",
    image: "",
    options: [
      "Express the appropriate behaviors without disturbing other people present in that situation",
      "Use strong emotions and shout hard to seek others attention in the surroundings",
      "Express our emotions along with hard emotional behaviors whenever & wherever possible",
      "All the above",
    ],
    right_option: 1,
  },
  {
    sort_order: 22,
    question_body:
      "Yashmikha knows exactly how to convey her inner feelings or expectations to her mother and makes her mother understand what she wants. What skill do you think she possess that makes it easier for her do it",
    video: "",
    image: "",
    options: [
      "Good communication",
      "Good leadership",
      "Good analysis",
      "All the above",
      "None of the above",
    ],
    right_option: 1,
  },
  {
    sort_order: 23,
    question_body:
      "How would you convey to your friend that she has committed a mistake and make her accept that mistake",
    video: "",
    image: "",
    options: [
      "Convey to her that its quite natural for everyone to make mistakes",
      "Convey to her that it is ethical to accept the committed mistake",
      "Convey to her that accept the mistake or else you might look bad",
      "All the above",
      "None of the above",
    ],
    right_option: 1,
  },
  {
    sort_order: 24,
    question_body:
      "What do we need to do to convey our planned solutions to the outer world or to other people?",
    video: "",
    image: "",
    options: [
      "Communicate our solutions in the form of words, actions, or expressions",
      "Use friends help to convey our solutions to others",
      "Never convey our solutions to others",
      "Find if the person can understand by themselves",
      "Option 2 & 3",
      "Option 2 & 4",
    ],
  },
  {
    sort_order: 25,
    question_body:
      "Puneeth’s mathematics teacher gave new problems to be solved as homework to his class students; Puneeth knew only how to solve the first few steps of the problem. His friend knew to do only the last steps of the problem. So Puneeth convinced his friend to join him together to solve the problem and they both received appreciation from their teacher. What skill did they both used to solve the problem?",
    video: "",
    image: "",
    options: [
      "Collaborative work skills",
      "Leadership skills",
      "Communication skills",
      "All the above",
      "None of the above",
    ],
  },
  {
    sort_order: 26,
    question_body:
      "Sooraj’s best friends krish, varun, and priyanka were put in different teams when the class was divided for the school Inter House competition. Tina, swaroop and ruchi were added to his team whom he doesn’t usually like. This new team combination will",
    video: "",
    image: "",
    options: [
      "Create an opportunity to make new friends",
      "Reduce teams victory options due to new people",
      "All the above",
      "None of the above",
    ],
  },
  {
    sort_order: 27,
    question_body:
      "What do we need to do to become a good teammate and a contributing member of the team?",
    video: "",
    image: "",
    options: [
      "Do all the work with commitment",
      "Show genuine interest on others well being",
      "Try to avoid conflicts within the team",
      "All the above",
      "None of the above",
    ],
  },
  {
    sort_order: 28,
    question_body:
      "Franky wanted to create a green environment in his neighborhood. So he started first to plant saplings in his own backyard and his nearby places. Inspired by his activities his neighbors also wanted to grow trees but did not know how to do it. So he helped them by teaching them how to do it and his neighborhood became a green environment in reality within a few months. What skills did he use to inspire others?",
    video: "",
    image: "",
    options: [
      "Leadership skills",
      "Marketing skills",
      "Teamwork",
      "All the above",
      "None of the above",
    ],
  },
  {
    sort_order: 29,
    question_body:
      "There are 3 students who wants to become the leader of the class, who would be the right person for it so that the whole class benefits from it in terms of good education and unity",
    video: "",
    image: "",
    options: [
      "One who is the first rank holder in the class",
      "One who is kind hearted",
      "One who is good at studies and helps others",
      "One who is your friend",
      "All the above",
      "None of the above",
    ],
  },
  {
    sort_order: 30,
    question_body:
      "What are the duties & advantages you can use as a team leader to reach the team goal at the earliest?",
    video: "",
    image: "",
    options: [
      "Use my influence and achieve results using my position",
      "Make my friends in the top positions and make them happy",
      "Choose & appoint the appropriate people for the work roles who are specialized in it",
      "Inspire & motivate others through my actions and make them work with wholeheartedness",
      "Option 1 & 2",
      "Option 3 & 4",
    ],
  },
];

const Test = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showQuestion, setShowQuestion] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const [questionTimer, setQuestionTimer] = useState(0);
  const [answerTimer, setAnswerTimer] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [testData, setTestData] = useState([]);
  const [userSelected, setUserSelected] = useState(false);
  const [timerColor, setTimerColor] = useState("text-black");
  const questionInterval = useRef(null);
  const [percentage, setPercentage] = useState(0);
  

  let count = 0;

  // useEffect(() => {
  //   // const timer = setTimeout(() => {
  //   //   if (questionTimer >= 3) {
  //   //     setTimerColor("text-red-500");
  //   //   }
  //   // }, 3000);

  //   return () => clearTimeout(timer);
  // }, [questionTimer]);
  useEffect(() => {
    // Start question timer
    if (questionInterval.current) {
      clearInterval(questionInterval.current);
    }
    questionInterval.current = setInterval(() => {
      setQuestionTimer((prevTime) => {
        if (prevTime === 10000) {
          handleShowAnswer();
          return prevTime;
        } else {
          return prevTime + 1;
        }
      });
    }, 1000);

    // Clean up function
    return () => clearInterval(questionInterval.current);
  }, [currentQuestion]);

  useEffect(() => {
    // Start answer timer
    if (showOptions) {
      //console.log(answerTimer,"answertimer",answerTimerCount,"answerTimerCount")
      let timer = answerTimer || 0;

      const answerInterval = setInterval(() => {
        timer++;
        setAnswerTimer((prev) => {
          //console.log(prev,"prev", answerTimer,"ans")
          return timer;
        });
        if (timer === 11000) {
          clearInterval(answerInterval);
          handleNextQuestion(timer);
        }
      }, 1000);
 
      // Clean up function
      return () => clearInterval(answerInterval);
    }
  }, [showOptions, selectedOption]); // Include selectedOption in the dependency array

  // console.log(answerTimer,"answertimermrmrm")
  const handleNextQuestion = (timer) => {
    // Store data for current question
    const newData = {
      sort_order: questions[currentQuestion].sort_order,
      question: questions[currentQuestion].question_body,
      selectedOption,
      questionReadTime: questionTimer,
      optionReadTime: userSelected ? answerTimer : timer - 1,
    };
    setTestData((prevData) => [...prevData, newData]);
    // Reset timers and update current question
    console.log(newData, "newData");
    let percentage = (newData.sort_order / questions.length) * 100;
    setPercentage(percentage.toFixed(2));
    setQuestionTimer(0);
    setAnswerTimer(0);
    setUserSelected(false);
    setSelectedOption(null);
    // setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    // setShowQuestion(true);
    // setShowOptions(false);
    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      setShowQuestion(true);
      setShowOptions(false);
    } else {
      // Redirect to the result page
      // handleFinishTest();
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setUserSelected(true);
  };

  const handleSubmitAnswer = () => {
    // Move to the next question if an option is selected
    if (selectedOption !== null) {
      handleNextQuestion();
    }
  };

  const handleShowAnswer = () => {
    setShowQuestion(false);
    setShowOptions(true);
    setAnswerTimer(0);
    clearInterval(questionInterval.current);
  };

  const navigate = useNavigate();
  console.log(currentQuestion, questions.length, "question length");
  const handleFinishTest = () => {
    //console.log(testData)
    const payload = {
      answers: testData,
    };
    navigate("/result");
    //console.log(payload)

    fetch("https://shy-pear-panther-sari.cyclic.app/quiz", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
       console.log(res, "resrr");
        // localStorage.setItem("token", res.token);
        //navigate(location.state , {replace:true} )
        //console.log(location.state)
      })

      .catch((err) => console.log(err));
  };


  const [store, setStore] = useState([]);



  const fetchDataFromBackend = async () => {
    try {
      const response = await fetch('https://shy-pear-panther-sari.cyclic.app/questions', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      console.log(data,"dataa");
      setStore(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchDataFromBackend();
  }, []);
  return (
    <div
      className="w-[100%] h-[170vh]  bg-blue-600  mx-auto  rounded-lg shadow-md -screen bg-cover bg-center"
        style={{ backgroundImage: `url(${Background})` }}
      >
      {/* <div className="w-[100%] position mt-[5px] ">
        <video
          width="100%"
          height="110%"
          autoPlay
          muted
          loop
          playsInline
          controls={false}>
          <source src={video} type="video/mp4" />
        </video>
      </div> */}
      <div className=" absolute top-0 w-[100%] h-[100vh]  ">
        
        <div className="w-[85%]  flex  justify-end ">
          <button
            disabled={currentQuestion+1 !== questions.length ? true : false}
            // disabled={true}
            onClick={handleFinishTest}
            className="flex items-center mt-10 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
            <span>Submit</span>
            <svg
              className="w-5 h-5 ml-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
            </svg>
          </button>
        </div>

        {/* <div className="flex justify-center items-center ">
          <img className="w-[150px]" src={Quiz} alt="" />
        </div> */}
        {/* <div  className=" w-3/5 mx-auto p-4 bg-gray-100 rounded-lg shadow-md flex justify-between items-center">
      <div className="mt-4 flex justify-between items-center">
    {showOptions && (
      <p className="mb-5">
        <strong>Answer Timer:</strong> {formatTime(answerTimer)}
      </p>
    )}

    {showQuestion && (
      <p className="mb-5">
        <strong> Timer:</strong> {formatTime(questionTimer)}
      </p>
    )}
  </div>

  <div className="flex items-center">
    <Link to="/result">
      <button
        onClick={handleFinishTest}
        className="flex items-center bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
      >
        <span>Complete Test</span>
        <svg
          className="w-5 h-5 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17 8l4 4m0 0l-4 4m4-4H3"
          ></path>
        </svg>
      </button>
    </Link>
  </div>
</div> */}

        <div className="mt-10">
          {showQuestion && (
            <div className=" w-[85%]  m-auto rounded-xl  ">
              {showQuestion && (
                <div className=" w-[70%] m-auto bg-gradient-to-r from-purple-400 to-blue-400 rounded-xl shadow-2xl py-2">
                  <div className="container mx-auto px-4">
                    <div className="flex justify-around items-center">
                      <p className="text-white text-2xl font-semibold">
                        Mock Test
                      </p>
                      <div className="w-48">
                        <LineChart percentage={percentage} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="mt-10 h-auto flex justify-center items-center -screen bg-cover bg-center "
                style={{
                  backgroundImage: `url(${QuestionBackground})`,
                  backgroundSize: "100% auto",
                }}
                >
                {showQuestion && (
                  <div className="mx-auto flex flex-col gap-4 w-3/5 mb-48 rounded-lg  p-4   ">
                    <div className="flex justify-start  ">
                      <h2 className="text-xl font-semibold   text-black">
                        {`Question ${currentQuestion + 1}`}
                      </h2>
                    </div>
                    <div className="flex ">
                      <p className="text-gray-800  font-semibold m-auto text-center">
                        {questions[currentQuestion].question_body}

                      </p>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex w-full   justify-center items-center ">
                  {questions[currentQuestion].video ? (
                    <div className="w-[65%] -mt-24  ">
                      <video
                        width="auto"
                        height="auto"
                        autoPlay
                        muted
                        loop
                        playsInline>
                        <source
                          src={questions[currentQuestion].video}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  ) : questions[currentQuestion].image ? (
                    <div className="w-[45%] -mt-24 ">
                      <img
                        src={questions[currentQuestion].image}
                        alt="Question Image"
                        className="w-auto h-auto"
                      />
                    </div>
                  ) : (
                    <p className="text-gray-800  text-center"></p>
                  )}
                </div>

                <div
                  className={`flex justify-evenly gap-x-24 items-center ${
                    questions[currentQuestion].video ||
                    questions[currentQuestion].image
                      ? ""
                      : "-mt-28"
                  } `}>
                  <div className="flex items-center bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                    <FaArrowLeft className="mr-2 animate-bounce " />
                    Previous
                  </div>
                  <div className="">
                    {showQuestion && (
                      <button
                        onClick={handleShowAnswer}
                        className="bg-yellow-500 text-white px-4 py-2 rounded-md  hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300 ease-in-out">
                        See Options
                      </button>
                    )}
                  </div>
                  <div className="flex items-center bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                    Next
                    <FaArrowRight className="ml-2 animate-bounce" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="">
          {showOptions && (
            <div className="w-[80%] m-auto  h-[75vh] rounded-3xl p-7 ">
             <div className="w-[23%] m-auto">
             <h2 className="text-2xl font-bold mb-4 mr-4 text-white bg-yellow-500 p-2  rounded-xl">{`Question ${
                currentQuestion + 1
              }`}</h2>
             </div>
              {/* <h2 className="text-xl font-bold mb-4 text-white">
                {" "}
                Available Options
              </h2> */}

              {showOptions && (
                <div className="grid grid-row-2 gap-5 w-[80%] m-auto p-5  rounded-2xl">
                  <div className="grid grid-cols-2  gap-5 w-full   items-center justify-center  ">
                    {questions[currentQuestion].options.map((option, index) => (
                      <div
                        key={index}
                        className={`  flex items-center justify-between bg-white h-[110px] gap-5  w-full cursor-pointerjustify-center hover:bg-slate-200 hover:shadow-lg hover:border hover:border-gray-500 rounded-md  ${
                          selectedOption === option ? "checked" : ""
                        }`}
                        
                        onClick={() => handleOptionSelect(option)}>
                        <input
                          type="radio"
                          id={`option_${index}`}
                          name="options"
                          value={option}
                          checked={selectedOption === option}
                          onChange={() => handleOptionSelect(option)}
                          className="hidden "
                        />

                
                <label
                          htmlFor={`option_${index}`}
                          className={`py-2 rounded-md w-[80%]   ${
                            selectedOption === option
                              ? "  text-black  font-serif text-xl  transition-all duration-300 ease-in-out "
                              : ""
                          }`}
                          style={{
                            minWidth: "150px",
                            fontSize:
                              selectedOption === option ? "1.2rem" : "1rem",
                          }}>
                          {option}
                        </label>

                        {/* Custom radio button */}
                        <div
                          className={`w-5 h-5 border-2  border-blue-700 rounded-full  mr-4 flex items-center justify-center ${
                            selectedOption === option ? "   bg-blue-500" : ""
                          }`}>
                        
                          {selectedOption === option && (
                            <div className="w-2 h-2 bg-white  rounded-full "></div>
                          )}
                        </div>
                </div>
                     
                    ))}
                  </div>
                </div>
              )}

              {/* {currentQuestion == questions.length - 1 ? ( */}
              {/* <div className="w-[85%]  flex  justify- ">
                <button
               
                  onClick={handleFinishTest}
                  className="flex items-center mt-10  bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                  <span>Submit</span>
                  <svg
                    className="w-5 h-5 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                  </svg>
                </button>
              </div> */}
              {/* ) : ( */}
              <div className="flex justify-evenly items-center mt-5 ">
                <div className="flex items-center bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                  <FaArrowLeft className="mr-2 animate-bounce " />
                  Previous
                </div>
              
                <div className="flex items-center bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                <button
                    onClick={handleSubmitAnswer}
                    className=" ">
                     Next
                  </button>
                </div>
              </div>

              {/* )} */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes < 10 ? "0" + minutes : minutes}:${
    seconds < 10 ? "0" + seconds : seconds
  }`;
};

export default Test;
