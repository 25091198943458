// import React from 'react';
// import { useStandard } from './StandardContext';

// const StandardDropdown = () => {
//   const { selectedStandard, setSelectedStandard, standardsInSchool } = useStandard();

//   const handleChange = (event) => {
//     setSelectedStandard(event.target.value);
//   };

//   return (
//     <form className="flex items-center max-w-sm mx-auto">
//       <label htmlFor="standards" className="text-gray-900 text-lg font-medium mr-4">Classes:</label>
//       <select
//         id="standards"
//         value={selectedStandard}
//         onChange={handleChange}
//         className="flex-1 px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
//       >
//         <option value="school">School</option>
//         {standardsInSchool.map((standard) => (
//           <option key={standard} value={standard}>
//             {standard}
//           </option>
//         ))}
//       </select>
//     </form>
//   );
// };

// export default StandardDropdown;
















//this is the correct code



import React from 'react';
import { useStandard } from './StandardContext';

const StandardDropdown = () => {
  const { selectedStandard, setSelectedStandard, standardsInSchool } = useStandard();

  const handleChange = (event) => {
    setSelectedStandard(event.target.value);
  };

  return (
    <form className="flex items-center max-w-sm mx-auto">
      <label htmlFor="standards" className="text-gray-900 text-lg font-medium mr-4">Classes:</label>
      <select
        id="standards"
        value={selectedStandard}
        onChange={handleChange}
        className="flex-1 px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
      >
        <option value="school">School</option>
        {standardsInSchool.map((standard) => (
          <option key={standard} value={standard}>
            {standard}
          </option>
        ))}
      </select>
    </form>
  );
};

export default StandardDropdown;