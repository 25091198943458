import React, { useState } from "react";
import "../Sidebar/Sidebar.css";
import image10 from "../../../src/image/image 10.png";
import microsoft from "../../../src/image/microsoft-windows.png";
import profileimg1 from "../../../src/image/profile image1.png";
import profileimg2 from "../../../src/image/profile image 2.png";
import perfimg1 from "../../../src/image/pref image1.png";
import perfimg2 from "../../../src/image/profile image 2.png";
import actimage from "../../../src/image/act image.png";
import stdimage from "../../../src/image/std image.png";
import teacherImg from "../../../src/image/teacherimg.png";
import Assesment from "../../../src/image/Assessment img.png";
import office from "../../../src/image/office.png";
import Dashboard from "../../image/Dashboard.gif";
import Profile from "../../image/Profile.gif";
import Performance from "../../image/Performance.gif";
import ActionPlan from "../../image/ActionPlan.gif";
import StudentProfile from "../../image/StudentProfile.gif";
import Assessment from "../../image/Assessment.gif";
import Login from "../../image/Login.gif";
import logo from "../../image/logo.png"

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Image,
  Toast,
  useToast,
} from "@chakra-ui/react";
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
const Sidebar = () => {
  const navigate = useNavigate();

  let token = localStorage.getItem("token");
  // console.log( token ,"token aa jaa")

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("checkExamStatus");
    navigate("/");
  };

  const sidebaarData = [
    {
      id: 1,
      img: Dashboard,
      title: "Dashboard",
      subitem: [],
      route: "/result",
    },
    {
      id: 2,
      img: Profile,
      title: "Profile",
      subitem: [],
      route: "/studentprofile",
    },
    {
      id: 3,
      img: Performance,
      title: "Performance",
      subitem: [
        // {
        //   id: 11,
        //   title: "Ranking",
        //   route: "/",
        // },
        // {
        //   id: 12,
        //   title: "Growth",
        //   route: "/",
        // },
        // // {
        // //   id:13,
        // //   title:"Chart",
        // //   route:"/"
        // // },
        // {
        //   id: 14,
        //   title: "Polarity",
        //   route: "/",
        // },
      ],
       route: "/performance",
      // route: "",
    },
    {
      id: 4,
      img: ActionPlan,
      title: "Action Plan",
      subitem: [],
      route: "/actionRecomendation",
    },
    // {
    //   id:5,
    //   img:StudentProfile,
    //   title:"Student Profile",
    //   subitem:[],
    //   route:"/result"
    // },
    // {
    //   id:6,
    //   img:teacherImg,
    //   title:"Teachers Profile",
    //   subitem:[],
    //   route:"/"
    // },
    {
      id: 7,
      img: Assessment,
      title: "Assessment",
      subitem: [],
      route: "/term",
    },
  ];
  const [showParagraph, setShowParagraph] = useState(false);
  // const toast= useToast()
  let checkexam=JSON.parse(localStorage.getItem("checkExamStatus"))
  console.log()
   const handleRouteNavigate=(route)=>{
    // console.log(route,checkexam,"checking dataaaaaaaaaaaaaa")
    if(checkexam?.totalMarks&&route=="/term"){
      return toast({
        description: `You have already taken the assessment. Please wait for your next assessment schedule.`,
        status: "success",
        duration: 4000,
        position: "top",
        isClosable: true,
      });
    }
    navigate(route)
   }
  return (
    <div
    className={`rounded w-[100px] sticky   flex flex-col h-[99vh] items-center hover:w-[180px]  duration-500 ease-in-out shadow-2xl`}
    onMouseEnter={() => setShowParagraph(true)}
    onMouseLeave={() => setShowParagraph(false)}
  >
    <div className="flex justify-center w-[100px] h-[100px] mt-10  items-center">
      <img
        src={logo}
        alt="err"
        className={`w-[80px] ${showParagraph && "w-[200px]"}`}
      />
    </div>
  
    <Accordion defaultIndex={[0]}  allowMultiple>
      <div>
        {sidebaarData.map((el, index) => (
          <div key={index}>
            <AccordionItem borderColor="transparent">
              <h2>
                <AccordionButton
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                  _hover={{ backgroundColor: "lightblue" }}
                >
                 
                    <Box
                    onClick={()=>handleRouteNavigate(el.route)}
                      as="span"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                       marginTop:"5px"
                      }}
                      
                      textAlign="left"
                    >
                      <Image src={el.img} className="w-[32px]" />
                     { showParagraph && <p className="duration-700 ease-in-out ">{ el.title}</p>}
                    </Box>
                 
                  {el.subitem.length > 0 && <AccordionIcon />}
                </AccordionButton>
              </h2>
              {el.subitem.length > 0 && (
                <AccordionPanel pb={4}>
                  {el.subitem.map((subEl, subIndex) => (
                    <Link key={subIndex} to={subEl.route}>
                     {showParagraph&& <p className="duration-700 ease-in-out ">{subEl.title}</p>}
                    </Link>
                  ))}
                </AccordionPanel>
              )}
            </AccordionItem>
          </div>
        ))}
        {token ? (
          <button
            className="flex items-center gap-2 hover:bg-[lightblue] w-[100%] p-[10px]"
            onClick={handleLogout}
          >
            {" "}
            <img src={Login} className="w-[32px]" />
            {showParagraph && "Logout"}
          </button>
        ) : (
          <Link to="/">
            {" "}
            <button className="flex items-center gap-2 hover:bg-[lightblue] w-[100%] p-[10px]">
              {" "}
              <img src={Login} className="w-[32px]" />
             {showParagraph && "Login"}
            </button>
          </Link>
        )}
      </div>
    </Accordion>
    <ToastContainer />
  </div>
  
  );
};
export default Sidebar;
const MenuItem = ({ image, text, handleClick }) => {
  return (
    <div className="menu-item" onClick={handleClick}>
      <img src={image} alt="" />
      <p>{text}</p>
    </div>
  );
};
