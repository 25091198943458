import React, { useEffect, useRef, useState } from "react";
import "../Circular/CircularProgressBar.css";

import "../NewStudentDashboard/NewStudent.css";
import Reprtboy1 from "../../image/Reportboy1.jpg"
import Reprtboy2 from "../../image/Reportboy2.jpg"
import Reprtboy3 from "../../image/Reportboy3.jpg"
import Reprtboy4 from "../../image/Reportboy4.jpg"
import Reprtboy5 from "../../image/Reportboy5.jpg"

import Reprtgirl4 from "../../image/Reportgirl4.webp"
import Reprtgirl1 from "../../image/Reportgirl1.jpg"
import Reprtgirl2 from "../../image/Reportgirl2.jpg"
import Reprtgirl3 from "../../image/Reportgirl3.jpg"
import Reprtgirl5 from "../../image/Reportgirl5.jpg"

import Sidebar from "../Sidebar/Sidebar";

import  Radar  from "../../Component/Report/ReportCharts/Radar";
import Gaurge from "./ReportCharts/Gaurge";
import Polarity from "./Polarity/Polarity";
import  CircularProgressSpeedBar from "../../Component/Circular/CircularProgessSpeedBar"
import StarRating from "../../Component/Circular/StarRating";
import DownloadPDFButton from "./DownloadPDFButton";
import PerformanceTable from "./PerformanceTable";

const Report = () => {
  const [score, setScore] = useState({});
  const contentRef = useRef();
  const GetRequest = () => {
    fetch("https://test.xqminds.com/api/quiz/report-generate", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        setScore(res);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    GetRequest();
  }, []);

  
  const mpiScore = score?.personalityCount || 0;

  const gender = localStorage.getItem("gender"); 
  
  const profilePic = localStorage.getItem("photo");
  const userName = localStorage.getItem("username");
  
// console.log(gender,"gender")
  let imageSrc;
  if (gender === "Male") {
    if (mpiScore === 1) {
        imageSrc = Reprtboy1;
    } else if (mpiScore === 2) {
        imageSrc = Reprtboy2;
    } else if (mpiScore === 3) {
        imageSrc = Reprtboy3;
    } else if (mpiScore === 4) {
        imageSrc = Reprtboy4;
    } else if (mpiScore === 5) {
        imageSrc = Reprtboy5;
    }
} else if (gender === "Female") {
    if (mpiScore === 1) {
        imageSrc = Reprtgirl1;
    } else if (mpiScore === 2) {
        imageSrc = Reprtgirl2;
    } else if (mpiScore === 3) {
        imageSrc = Reprtgirl3;
    } else if (mpiScore === 4) {
        imageSrc = Reprtgirl4;
    } else if (mpiScore === 5) {
        imageSrc = Reprtgirl5;
    }
}
  

const prideScore = score?.prideAccuracyScorePercentage || 0;

const getPrideScoreMessage = (score) => {
  if (score >= 85) {
    return {
      label: "Performing",
      description: "An exceptional proficiency and readiness",
    };
  } else if (score >= 70) {
    return {
      label: "Progressing",
      description: "Strong capabilities with good progress towards higher performance",
    };
  } else if (score >= 55) {
    return {
      label: "Promising",
      description: "Promising abilities showing considerable improvement",
    };
  } else if (score >= 40) {
    return {
      label: "Preparing",
      description: "A strong foundation with room for improvement",
    };
  } else {
    return {
      label: "Potential",
      description: "Developing potential that can be nurtured with consistent effort",
    };
  }
};

const { label, description } = getPrideScoreMessage(prideScore);

const highlightWords = (text) => {
  const wordsToHighlight = ["Percieve", 'Resolve', 'Influence', 'Deliver', 'Engage'];

  // Extract text after the first colon
  const contentAfterColon = text.split(':').slice(1).join(':').trim();

  // First, highlight specific words
  const regexWords = new RegExp(`\\b(${wordsToHighlight.join('|')})\\b`, 'gi');
  let highlightedText = contentAfterColon.replace(regexWords, (match) => `<strong>${match}</strong>`);

  // Make content between single quotes bold
  const regexQuotes = /'(.*?)'/g;
  highlightedText = highlightedText.replace(regexQuotes, (match, p1) => `<strong>${p1}</strong>`);

  // Make content between ** bold
  const regexStars = /\*\*(.*?)\*\*/g;
  highlightedText = highlightedText.replace(regexStars, (match, p1) => `<strong>${p1}</strong>`);

  // Prepend the "WHOLESOME CAPABILITIES PROFILE" text
  const prependedText = `<strong></strong>${highlightedText}`;

  return prependedText;
};

const reportText = score?.report?.report || "Loading";



  // Extract Skills and Recommendations
  const extractSkillsAndRecommendations = (planText) => {
    const skillSections = planText.match(/Skill:\s*[\s\S]*?(?=Skill:|PRIDE:|INTELLIGENCE:|$)/g) || [];
      return skillSections.map(section => {
      const [skillPart, recommendationPart] = section.split("Recommendation:");
      const skill = skillPart.replace("Skill:", "").trim();
      const recommendation = recommendationPart.trim();
      return { skill, recommendation };
    });
  };

  // Extract PRIDE Aspects and Recommendations
  const extractPrideAspectsAndRecommendations = (planText) => {
    const prideSections = planText.match(/Aspect:\s*[\s\S]*?(?=Aspect:|INTELLIGENCE:|$)/g) || [];
    return prideSections.map(section => {
      const [aspectPart, recommendationPart] = section.split("Recommendation:");
      const aspect = aspectPart.replace("Aspect:", "").trim();
      const recommendation = recommendationPart.trim();
      return { aspect, recommendation };
    });
  };

  const skillsWithRecommendations = score?.plan?.plan ? extractSkillsAndRecommendations(score.plan.plan) : [];
  const prideWithRecommendations = score?.plan?.plan ? extractPrideAspectsAndRecommendations(score.plan.plan) : [];







  return (
    <div className="flex ">
      <div className=" flex sticky top-0 h-full">
        <Sidebar />
      </div>
     
      <div  ref={contentRef}  className=" flex flex-col w-[100%] items-center justify-center m-auto gap-20 ">

        <div className="flex flex-col items-center gap-3 justify-center m-auto">
          
       
          <h1 className="text-3xl font-medium font-fredoka text-center underline   mb-8">
          Welcome to PRIDE Assessment
          </h1>

         

          <div className="grid grid-cols-1 gap-12 w-[160%] shadow-2xl rounded-2xl p-4 ">
  {/* Row 1 */}
  <div className="flex gap-4 justify-evenly w-full ">
  <div className="relative">
      <p className="font-fredoka">MPI Profile</p>
      <div className="relative mt-4 ">
        <img
          className="w-[250px] h-[250px] shadow-lg rounded-full p-8"
          src={imageSrc}
          alt="MPI Profile"
        />

        {/* Small Circle with the Value */}
        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 translate-y-1/2 flex justify-center items-center w-16 h-16 bg-blue-500 text-white rounded-full">
          <span className="text-2xl font-bold">{score?.allReportData?.convertedMpiScore || 0}</span>
        </div>
      </div>
    </div>

<div>
<p className="font-fredoka">PRIDE Distribution </p> 
    <div className=" shadow-lg rounded-full p-4">
    <Radar />
    </div>
</div>
  </div>
  
  {/* Row 2 */}
  <div className="flex flex-col gap-8 ">
  <div className="flex justify-evenly ">
    {/* Headings */}
    <h2 className="text-center w-[30%] text-lg font-fredoka">Accuracy</h2>
    <h2 className="text-center w-[30%] text-lg font-fredoka">Speed</h2>
    <h2 className="text-center w-[30%] text-lg font-fredoka">Consistency</h2>
  </div>

  <div className="flex justify-evenly gap-8">
    {/* Accuracy */}
    <div className="w-[20%] shadow-lg rounded-full p-4">
      <CircularProgressSpeedBar percentage={score.allReportData?.prideAccuracyScorePercentage || 0} />
    </div>

    {/* Speed */}
    <div className="w-[20%]  shadow-lg rounded-full">
      <Gaurge />
    </div>

    {/* Consistency */}
    <div className="w-[20%] shadow-lg rounded-full flex flex-col gap-5 justify-center items-center p-4">
      <StarRating rating={score.allReportData?.mpiConsistency || 0} />
      <p>{score.allReportData?.mpiConsistency || 0}</p>
    </div>
  </div>
</div>

</div>

        </div>
    
    <div className=" flex flex-col gap-8">
    <div className=" flex flex-col gap-4">
     <div className=" m-auto flex flex-col justify-center items-center">
          <p className="font-bold text-3xl">
          {userName}  brain performance on a day to day basis 
          </p>
        </div>
        <div className="flex gap-10 justify-center items-center">
        <div>
          <p className="font-semibold">Polarity Score Scale </p>
          </div>
          <div>
          <p className="font-semibold">0-20 </p>
          <p className="w-[25px] h-[25px] rounded-full bg-red-500 justify-center items-center"></p>
          </div>
          <div>
          <p className="font-semibold">21-40 </p>
          <p className="w-[25px] h-[25px] rounded-full bg-purple-700 m-auto"></p>
          </div>
          <div>
          <p className="font-semibold">41-60 </p>
          <p className="w-[25px] h-[25px] rounded-full bg-blue-500 m-auto"></p>
          </div>
          <div>
          <p className="font-semibold">61-80 </p>
          <p className="w-[25px] h-[25px] rounded-full bg-green-500 m-auto"></p>
          </div>
          <div>
          <p className="font-semibold">81-100 </p>
          <p className="w-[25px] h-[25px] rounded-full  m-auto"  style={{ backgroundColor: '#FFFF00' }}></p>
          </div>
        </div>
     </div>
     <div className="">
     <Polarity/>

     </div>
    </div>
        <div className="w-[90%]  m-auto  flex flex-col gap-8 ">
          <p className="font-medium font-fredoka text-3xl  flex justify-center items-center">
            WHOLESOME CAPABILITIES PROFILE
          </p>
          <div className="flex justify-center items-center  gap-5  shadow-2xl rounded-lg p-4 ">
          
            <div className="w-[90%]  flex flex-col gap-5 justify-start text-left p-10">
           
              {/* <p>{score?.report?.report|| "Loading"}</p> */}
              <p dangerouslySetInnerHTML={{ __html: highlightWords(reportText) }}></p>
            </div>
          </div>
        </div>

        <div className="flex  rounded-lg  ">
         

            {/* <p className="  font-bold text-3xl ">Skills & Recommendations</p> */}
          <div className="mt-8 flex gap-8 w-[90%] justify-center   m-auto ">
            {/* <img className="w-[300px] h-[200px] mt-10" src={twokid} alt="" /> */}
            <div className="w-[50%] flex flex-col rounded-2xl gap-8 p-5 border shadow-2xl">
              <p className="">
        {skillsWithRecommendations.length > 0 ? (
          <div className=" p-4 ">
            <h3 className="text-xl font-medium font-fredoka text-center">Skills & Recommendations</h3>
            {skillsWithRecommendations.map((item, index) => (
              <div key={index} className="mb-4">
                <p className="font-medium font-fredoka underline text-start ">{item.skill}</p>
                <p className="text-justify">{item.recommendation}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>Loading...</p>
        )}
        </p>
      </div>
      <div className="w-[50%]  flex flex-col rounded-2xl gap-8 p-5 border shadow-2xl">
            <p className="">
            {prideWithRecommendations.length > 0 ? (
          <div className=" p-4  ">
            <h3 className="text-xl font-medium font-fredoka text-center">PRIDE Aspects & Recommendations</h3>
            {prideWithRecommendations.map((item, index) => (
              <div key={index} className="mb-4">
                <p className="font-medium font-fredoka underline text-start">{item.aspect}</p>
                <p className="text-justify">{item.recommendation}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>Loading...</p>
        )}
            </p>
         
          </div>
          </div>
        </div>
<PerformanceTable/>
        {/* <div className="flex gap-16 rounded-lg mt-14  ml-28">
          <div className="mt-8">
            <p className="font-bold text-3xl ">PRIDE Aspects & Recommendations</p>
            <img
              className="w-[300px] h-[200px] mt-10"
              // src={ProductivityProfile}
              alt=""
            />
          </div>
         //contect
        </div> */}

        {/* <div className="flex gap-16 rounded-lg mt-14 ml-28">
          <div className="w-[45%]  flex flex-col rounded-2xl gap-8 p-5 border shadow-2xl">
            <p className="text-start">
              Intelligence is the unique capacity to apply shared knowledge in
              distinctive ways, learned from the same educational foundation.
              Vikas excels in applying learned knowledge linearly but struggles
              with different perspectives, possibly due to limited exposure or
              reliance on external instructions, indicating a need for improved
              situational awareness
            </p>
            <div>
              <p className="font-bold text-start mb-8">Suggestions</p>
              <p className="text-start">
                <span className="font-bold ">Precision:</span> Excels in
                understanding situations with precision
              </p>
              <p className="text-start">
                <span className="font-bold ">Resolution:</span> Capable of
                devising proper solutions to problems or needs
              </p>
              <p className="text-start">
                <span className="font-bold ">Delivery:</span> Effectively
                communicates needs in a easily understandable manner
              </p>
            </div>
          </div>

          <div className="mt-8">
            <p className="font-bold text-3xl ">Intelligence</p>
            <img className="w-[300px] h-[200px] mt-10" src={intelligence} alt="" />
          </div>
       
     </div> */}
<div className="flex gap-8">
<div className="w-[250px]">
{/* <WeaknessCarousel/> */}

{/* <ProfileCarousalStrong/> */}
</div>

{/* <div className="w-[250px]">
<WeaknessCarousel/>
</div> */}
</div>
      </div>
      {/* <div className="w-[20%] sticky top-2 right-0 h-full">
        <SideData />
      </div> */}
       <div className="fixed bottom-4 right-4 z-50">
    <DownloadPDFButton contentRef={contentRef} filename="AssessmentReport.pdf" />
  </div>
    </div>
  );
};

export default Report;


