import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import mpiScore from "../../../src/image/mpiScore.png";
// import mpiGrowth from "../../../src/image/mpiGrowth.png";


import { Link, useNavigate } from "react-router-dom";
import "../NewStudentDashboard/NewStudent.css";

import TouchSlider from "../Carousol/Slider";
import StudentScore from "./StudentScore";
import SideData from "../SideData/SideData";
import expertmadam from "../../image/expertTeacher.jpg";
import startPlanning from "../../image/startPlaning.jpg";
import { Button } from "@chakra-ui/react";
import gain from "../../image/gainKnow.jpg";
import notification from "../../image/notification.gif";

import WeaknessCarousel from "../Carousol/WeaknessCarousel";
import StrengthCarouse from "../Carousol/StrengthCarouse";
import Strength from "../Carousol/Strength";
import Weakness from "../Carousol/Weakness";
import NextExam from "../NextExamDate/NextExam";
import Recommendation from "../Carousol/Recommendation";
const NewStudent = ({ isLoggedIn }) => {
  const [score, setScore] = useState({});
  
  const [CaraosalsScore, setCaraosalsScore] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [username, setUsername] = useState('');
 
  const GetRequestCaraosal = () => {
    fetch("https://test.xqminds.com/api/quiz/SkillContent",{
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        //  console.log(res, "skillsssss");
        setCaraosalsScore(res.contentObject);
      })

      .catch((err) => console.log(err));
  };
  // console.log(CaraosalsScore, "skills data attention");
  useEffect(() => {
    GetRequestCaraosal();
  }, []);

  const navigate = useNavigate();

  let token = localStorage.getItem("token");
  // console.log( token ,"token aa jaa")

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  // const GoToReport = () => {
  //   navigate("/report");
  // };
  // const GetRequest = () => {
  //   fetch("https://test.xqminds.com/api/quiz/testOption", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       // console.log(res.nextExamDate,"ressss");
  //       setScore(res);
  //     })
  //     .catch((err) => console.log(err));
  // };
  // useEffect(() => {
  //   GetRequest();
  // }, []);

  const handleProfilePage=()=>{
    navigate("/studentprofile")
  }
  
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);
  // console.log("Days remaining:", daysRemaining);
  return  (
    <div className="relative w-full bg-center h-screen">
     

      <div className="flex h-full gap-1">
        <div className={`flex h-full gap-5 sticky top-1 ${isSidebarOpen ? 'block' : 'hidden'} md:block`}>
          <Sidebar />
        </div>

        <div className="md:w-[91%] w-[95%] md:mt-1 m-auto flex flex-col gap-5">
          <div className="md:flex md:flex-row flex flex-col-reverse justify-between items-center  shadow-lg rounded-lg p-3 gap-4 sticky top-0 z-10 bg-white">
            <NextExam />
            <form className="flex items-end max-w-sm mr-5">
              {/* <label htmlFor="simple-search" className="sr-only">
                Search
              </label>
              <div className="relative w-full">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-yellow-500 dark:text-yellow-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="simple-search"
                  className="border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-blue-600 dark:placeholder-yellow-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search..."
                  required
                />
              </div>
              <button
                type="submit"
                className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            <div>
              <img className="w-[50px]" src={notification} alt="Notification" />
            </div>
            <button
        data-collapse-toggle="navbar-search"
        type="button"
        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-search"
        aria-expanded={isSidebarOpen}
        onClick={toggleSidebar}
      >
        <span className="sr-only">Open main menu</span>
        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button> */}
           <div className="text-black text-lg ">
              Hello  <span className="font-semibold">{username}</span>
              </div>
            </form>
          </div>

          <div className="flex flex-col w-[100%] gap-5">
            <div className="flex w-[100%] items-center rounded-lg">
              <StudentScore />
            </div>
            <div className="flex mt-80 md:mt-0  flex-col justify-start">
              <div className="w-[100%] grid grid-cols-1 md:grid-cols-3 justify-start gap-5 items-center">
                <div className="flex flex-col w-[100%]">
                  <div className="rounded-lg" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                    <div className="mt-2">
                      <h1 className="font-bold text-xl ">Strong Zone</h1>
                    </div>
                    <Strength />
                  </div>
                </div>
                <div className="flex flex-col w-[100%]">
                  <div className="rounded-lg" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                    <div className="mt-2">
                      <h1 className="font-bold text-xl ">Area of Improvement</h1>
                    </div>
                    <Weakness />
                  </div>
                </div>
                <div className="flex flex-col w-[100%]">
                  <div className="rounded-lg" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                    <div className="mt-2">
                      <h1 className="font-bold text-xl">Recommendation</h1>
                    </div>
                    <Recommendation />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Conditionally render the Sidebar component */}
      {isSidebarOpen && (
        <div className="fixed inset-0 z-50 flex md:hidden">
          <div className=" bg-white shadow-lg">
            <Sidebar />
          </div>
          <div className="flex-grow" onClick={toggleSidebar}></div>
        </div>
      )}
    </div>
  );
};
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}
export default NewStudent;

{
  /*    <div>
            <p className="border-red-600">
              hyxe Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Architecto atque corrupti exercitationem nesciunt, explicabo nisi
              at fugit dolorum enim, nostrum vitae. Dicta voluptatem accusantium
              expedita cum a doloremque, natus quo.{" "}
            </p>

            <div className="flex flex-col justify-center items-center">
              <Radars />
              <div className="flex gap-5">
                <div className="w-[100%] flex justify-center  gap-5 items-center">
                  <div>
                    <h1 className="font-bold text-2xl text-blue-600">
                      Causal Factors for <span className="text-red-400"> Weakness</span> Areas
                    </h1>
                    <div className="mt-5">
                      <TouchSlider />
                    </div>
                  </div>
                  <div>
                    <h1 className="font-bold text-2xl text-blue-600">
                      Recomendation for learning
                    </h1>
                    <div className="mt-5">
                      <TouchSlider />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */
}