

import React, { useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Carousel } from 'react-responsive-carousel';

function TouchSlider() {
  const data = [
    {
      id: 1,
      title: "Critical Thinking",
      subHeading: "Weak Application ",
      para: "Prioritising, Orgranising ",
      extra:"read more..."
    },
    {
      id: 2,
      title: "Collaboration Ability",
      subHeading: "Less clarity on the Advantage of Adjusting in Team Setup",
      para: "Vikas has less. He values him from leadership opportunities in the future.",
      extra:"read more..."
    },
    {
      id: 3,
      title: "Leadership",
      subHeading: "Less clarity on the Advantage of using Leadership Characteristics ",
      para: "Vikas has less the needs, he chooses to use his known network rather than the appropriate network to achieve the goal for better outcomes.",
      extra:"read more..."
    },
  ];

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [CaraosalsScore, setCaraosalsScore] = useState([]);

  const handlePrev = () => {
    setCurrentSlide(currentSlide === 0 ? CaraosalsScore.length - 1 : currentSlide - 1);
  };

  const handleNext = () => {
    setCurrentSlide(currentSlide === CaraosalsScore.length - 1 ? 0 : currentSlide + 1);
  };

  
  return (
    <div className="flex justify-center items-center ">
      <div className=" w-full overflow-hidden relative">
     
      <div className="w-[93%] ml-7 mt-3">
      <Carousel showThumbs={false} autoPlay showStatus={false} showIndicators={false} infiniteLoop>

            {data.map((item) => (
             <div className="flex flex-col justify-center items-center h-[175px] " >
                <h2 className="text-xl font-semibold">{item.title}</h2>
                {/* <h3 className="text-lg font-semibold text-gray-600">{item.subHeading}</h3> */}
                <p className="text-base mt-2">{item.para}</p>
              </div>
            ))}
          </Carousel>
        </div>
        
      </div>
    </div>
  );
}

export default TouchSlider;

