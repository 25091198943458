export const FETCH_TEST_OPTION_REQUEST = 'FETCH_TEST_OPTION_REQUEST';
export const FETCH_TEST_OPTION_SUCCESS = 'FETCH_TEST_OPTION_SUCCESS';
export const FETCH_TEST_OPTION_FAILURE = 'FETCH_TEST_OPTION_FAILURE';


export const FETCH_SCHOOL_SCORES_REQUEST = 'FETCH_SCHOOL_SCORES_REQUEST';
export const FETCH_SCHOOL_SCORES_SUCCESS = 'FETCH_SCHOOL_SCORES_SUCCESS';
export const FETCH_SCHOOL_SCORES_FAILURE = 'FETCH_SCHOOL_SCORES_FAILURE';


// Existing action types...
export const FETCH_SENIOR_SCORES_REQUEST = 'FETCH_SENIOR_SCORES_REQUEST';
export const FETCH_SENIOR_SCORES_SUCCESS = 'FETCH_SENIOR_SCORES_SUCCESS';
export const FETCH_SENIOR_SCORES_FAILURE = 'FETCH_SENIOR_SCORES_FAILURE';


export const FETCH_YOUTH_SCORES_REQUEST = 'FETCH_YOUTH_SCORES_REQUEST';
export const FETCH_YOUTH_SCORES_SUCCESS = 'FETCH_YOUTH_SCORES_SUCCESS';
export const FETCH_YOUTH_SCORES_FAILURE = 'FETCH_YOUTH_SCORES_FAILURE';


export const FETCH_SCHOOL_EXAM_DATES_REQUEST = 'FETCH_SCHOOL_EXAM_DATES_REQUEST';
export const FETCH_SCHOOL_EXAM_DATES_SUCCESS = 'FETCH_SCHOOL_EXAM_DATES_SUCCESS';
export const FETCH_SCHOOL_EXAM_DATES_FAILURE = 'FETCH_SCHOOL_EXAM_DATES_FAILURE';