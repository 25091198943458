

import React, { useEffect, useMemo, useState } from "react";
import { Container } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
function MainTable({ isVisible, onClose }) {
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStandard, setSelectedStandard] = useState(null);
  
  const GetRequest = () => {
    fetch("https://test.xqminds.com/api/schoolData/table", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setStudents(res.combinedData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false); // Set loading state to false even if there is an error
      });
  };

  useEffect(() => {
    GetRequest();
  }, []);

  const columns = [
      {
        accessorKey: "sno", // Custom SNo accessor
        header: "SNo", // Header for SNo column
        size: 50, // Adjust size as needed
        minSize: 50, // Minimum width
        maxSize: 50, // Maximum width
       
        muiTableBodyCellProps: ({
          cell
          }) => ({
          sx: {
            color: '#7ABD32' ,
            fontWeight: '600',
            fontSize: '18px',
            
          }
        })
      },
      // {
      //   accessorKey: "studentName",
      //   header: "Full Name",
      //   enableClickToCopy: false,
      // },
      {
        accessorKey: "standard",
        header: "Class",
        size: 50,
        minSize: 50,
        maxSize: 50,
        muiTableBodyCellProps: ({ cell }) => ({
          sx: {
            color: "#EF4444",
            fontWeight: "700",
            fontSize: "18px",
          },
        }),
      },
      {
        accessorKey: "studentCountStandardWise",
        header: "Assigned",
        size: 50, // Adjust size as needed
        minSize: 50, // Minimum width
        maxSize: 50, // Maximum width
        // enableClickToCopy: true,
        muiTableBodyCellProps: ({
          cell
          }) => ({
          sx: {
            color: '#EF4444' ,
            fontWeight:"700",
            fontSize: '17px',
          }
        })
      },
      {
        accessorKey: "examCountStandardWise",
        header: "Completed",
        size: 50, // Adjust size as needed
        minSize: 50, // Minimum width
        maxSize: 50, // Maximum width
        muiTableBodyCellProps: ({
          cell
          }) => ({
          sx: {
            color: '#EF4444' ,
            fontWeight:"700",
            fontSize: '17px',
          }
        })
      },
      {
        accessorKey: "pending",
        header: "Pending",
        size: 50, // Adjust size as needed
        minSize: 50, // Minimum width
        maxSize: 50, // Maximum width
        muiTableBodyCellProps: ({
          cell
          }) => ({
          sx: {
            color: '#EF4444' ,
            fontWeight:"700",
            fontSize: '17px',
          }
        })
      },
      // {
      //   accessorKey: "hasGivenExam",
      //   header: "Has Given Exam",
      //   Cell: ({ cell }) => (cell.getValue() ? "Completed" : "Pending"),
      //   muiTableBodyCellProps: ({ cell }) => ({
      //     sx: {
      //       color: cell.getValue() ? 'rgba(22, 184, 44, 0.5)' : 'red',
      //       fontWeight: cell.getValue() ? 'bold' : 'normal',

      //     },
      //   }),
      // },
      {
        accessorKey: "spoc.name",
        header: "SPOC/Teacher",
        size: 50, // Adjust size as needed
        minSize: 50, // Minimum width
        maxSize: 50, // Maximum width
        muiTableBodyCellProps: ({
          cell
        }) => ({
        sx: {
          color: '#EF4444' ,
          fontWeight:"700",
          fontSize: '17px',
        }
        })
      },
      // {
      //   accessorKey: "spoc.email",
      //   header: "SPOC Email",
      // },
      // {
      //   accessorKey: "spoc.phone",
      //   header: "SPOC Phone",
      // },
      // {
      //   accessorKey: "spoc.role",
      //   header: "SPOC Role",
      // },
    ];
   
      const handleRowClick = (row) => {
    setSelectedStandard(row.original.standard);
  };
const uniqueStandards = useMemo(() => {
  const unique = [];
  const map = new Map();
  let sno = 1;
  for (const item of students) {
    if (!map.has(item.standard)) {
      map.set(item.standard, true);
      unique.push({ ...item, sno: sno++ });
    }
  }
  return unique;
}, [students]);

const filteredData = useMemo(() => {
  if (!selectedStandard) return [];
  return students
    .filter((student) => student.standard === selectedStandard)
    .map((student, index) => ({ ...student, sno: index + 1 }));
}, [students, selectedStandard]);

 
    // const table = useMemo(() => ({
    //   columns,
    //   data: students,
    //   initialState: { pagination: { pageSize: 5, pageIndex: 0 } },
    // }), [students, columns]);
  
    const table = useMemo(() => ({
      columns,
      data: uniqueStandards,
      initialState: { pagination: { pageSize: 5, pageIndex: 0 } },
    }), [uniqueStandards, columns]);
  
  if (!isVisible) return null;
  return (

    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
    <div className="relative bg-white p-2 md:p-5 rounded-lg w-[90%] md:w-[65%] max-w-[100%]">
      <div className="flex justify-end">
        
        <button
          className="bg-red-500 text-white py-1 px-2 rounded"
          onClick={onClose}
        >
          Close
        </button>
      </div>
      <p className="text-[#3b1886] font-semibold text-xl">OVERALL STUDENTS ASSIGNED</p>
      <Container sx={{ py: 2 }}>
        <div style={{ height: '100%',width: '100%', overflowY: 'auto' }}>
          <MaterialReactTable 
          {...table}  
          //  columns={columns}    
          //  data={uniqueStandards}
          // tableInstance={tableInstance}
           
           muiTableHeadCellProps={{
             sx: {
              //  backgroundColor: 'lightblue',
               borderRight: '1px solid rgba(224,224,224,1)',
               color: '#7ABD32', // Adjusted color for better visibility
               fontWeight: '700',
               fontSize: '16px',
              
               
                // fontSize: '18px',
             },
             align: 'center',
          }}
          
          muiTableBodyCellProps={{
            align: 'center'
          }} muiTableFooterCellProps={{
            align: 'center'
          }} 
          />
        </div>
{selectedStandard && (
  <>
    <p className="text-[#3b1886] font-semibold text-xl mt-4">
      Pending Data for Class {selectedStandard}
    </p>
    <div style={{ height: "100%", width: "100%", overflowY: "auto" }}>
      <MaterialReactTable
        columns={columns}
        data={filteredData}
        muiTableHeadCellProps={{
          sx: {
            borderRight: "1px solid rgba(224,224,224,1)",
            color: "#7ABD32",
            fontWeight: "700",
            fontSize: "16px",
            align: "center",
          },
          align: "center",
        }}
        muiTableBodyCellProps={{
          align: "center",
        }}
        muiTableFooterCellProps={{
          align: "center",
        }}
      />
    </div>
  </>
)}
      </Container>
    </div>
  </div>
  );
}

export default MainTable;











// import React, { useEffect, useMemo, useState } from "react";
// import { Container } from "@mui/material";
// import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

// function MainTable({ isVisible, onClose }) {
//   const [students, setStudents] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [selectedStandard, setSelectedStandard] = useState(null);

//   const GetRequest = () => {
//     fetch("https://test.xqminds.com/api/schoolData/table", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((res) => {
//         setStudents(res.combinedData);
//         setIsLoading(false);
//       })
//       .catch((err) => {
//         console.log(err);
//         setIsLoading(false);
//       });
//   };

//   useEffect(() => {
//     GetRequest();
//   }, []);

//   const columns = [
//           {
//             accessorKey: "sno", // Custom SNo accessor
//             header: "SNo", // Header for SNo column
//             size: 50, // Adjust size as needed
//             minSize: 50, // Minimum width
//             maxSize: 50, // Maximum width
           
//             muiTableBodyCellProps: ({
//               cell
//               }) => ({
//               sx: {
//                 color: '#7ABD32' ,
//                 fontWeight: '600',
//                 fontSize: '18px',
                
//               }
//             })
//           },
//           // {
//           //   accessorKey: "studentName",
//           //   header: "Full Name",
//           //   enableClickToCopy: false,
//           // },
//           {
//             accessorKey: "standard",
//             header: "Class",
//             size: 50, // Adjust size as needed
//             minSize: 50, // Minimum width
//             maxSize: 50, // Maximum width
//             muiTableBodyCellProps: ({
//               cell
//               }) => ({
//               sx: {
//                 color: '#EF4444' ,
//                 fontWeight:"700",
//                 fontSize: '18px',
//               }
//             })
//           },
//           {
//             accessorKey: "studentCountStandardWise",
//             header: "Assigned",
//             size: 50, // Adjust size as needed
//             minSize: 50, // Minimum width
//             maxSize: 50, // Maximum width
//             // enableClickToCopy: true,
//             muiTableBodyCellProps: ({
//               cell
//               }) => ({
//               sx: {
//                 color: '#EF4444' ,
//                 fontWeight:"700",
//                 fontSize: '17px',
//               }
//             })
//           },
//           {
//             accessorKey: "examCountStandardWise",
//             header: "Completed",
//             size: 50, // Adjust size as needed
//             minSize: 50, // Minimum width
//             maxSize: 50, // Maximum width
//             muiTableBodyCellProps: ({
//               cell
//               }) => ({
//               sx: {
//                 color: '#EF4444' ,
//                 fontWeight:"700",
//                 fontSize: '17px',
//               }
//             })
//           },
//           {
//             accessorKey: "pending",
//             header: "Pending",
//             size: 50, // Adjust size as needed
//             minSize: 50, // Minimum width
//             maxSize: 50, // Maximum width
//             muiTableBodyCellProps: ({
//               cell
//               }) => ({
//               sx: {
//                 color: '#EF4444' ,
//                 fontWeight:"700",
//                 fontSize: '17px',
//               }
//             })
//           },
//           // {
//           //   accessorKey: "hasGivenExam",
//           //   header: "Has Given Exam",
//           //   Cell: ({ cell }) => (cell.getValue() ? "Completed" : "Pending"),
//           //   muiTableBodyCellProps: ({ cell }) => ({
//           //     sx: {
//           //       color: cell.getValue() ? 'rgba(22, 184, 44, 0.5)' : 'red',
//           //       fontWeight: cell.getValue() ? 'bold' : 'normal',
    
//           //     },
//           //   }),
//           // },
//           {
//             accessorKey: "spoc.name",
//             header: "SPOC/Teacher",
//             size: 50, // Adjust size as needed
//             minSize: 50, // Minimum width
//             maxSize: 50, // Maximum width
//             muiTableBodyCellProps: ({
//               cell
//             }) => ({
//             sx: {
//               color: '#EF4444' ,
//               fontWeight:"700",
//               fontSize: '17px',
//             }
//             })
//           },
//           // {
//           //   accessorKey: "spoc.email",
//           //   header: "SPOC Email",
//           // },
//           // {
//           //   accessorKey: "spoc.phone",
//           //   header: "SPOC Phone",
//           // },
//           // {
//           //   accessorKey: "spoc.role",
//           //   header: "SPOC Role",
//           // },
//         ];
       
    
     

//   const handleRowClick = (row) => {
//     setSelectedStandard(row.original.standard);
//   };

//   const uniqueStandards = useMemo(() => {
//     const unique = [];
//     const map = new Map();
//     let sno = 1;
//     for (const item of students) {
//       if (!map.has(item.standard)) {
//         map.set(item.standard, true);
//         unique.push({ ...item, sno: sno++ });
//       }
//     }
//     return unique;
//   }, [students]);

//   const filteredData = useMemo(() => {
//     if (!selectedStandard) return [];
//     return students
//       .filter((student) => student.standard === selectedStandard)
//       .map((student, index) => ({ ...student, sno: index + 1 }));
//   }, [students, selectedStandard]);

//   if (!isVisible) return null;

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
//       <div className="relative bg-white p-5 rounded-lg w-[90%] max-w-[65%]">
//         <div className="flex justify-end">
//           <button
//             className="bg-red-500 text-white py-1 px-2 rounded"
//             onClick={onClose}
//           >
//             Close
//           </button>
//         </div>
//         <p className="text-[#3b1886] font-semibold text-xl">
//           OVERALL STUDENTS ASSIGNED
//         </p>
//         <Container sx={{ py: 2 }}>
//           <div style={{ height: "100%", width: "100%", overflowY: "auto" }}>
//             <MaterialReactTable
//               columns={columns}
//               data={uniqueStandards}
//               muiTableHeadCellProps={{
//                 sx: {
//                   borderRight: "1px solid rgba(224,224,224,1)",
//                   color: "#7ABD32",
//                   fontWeight: "700",
//                   fontSize: "16px",
//                 },
//               }}
//               muiTableBodyCellProps={{
//                 align: "center",
//               }}
//               muiTableFooterCellProps={{
//                 align: "center",
//               }}
//               onRowClick={(event, row) => handleRowClick(row)}
//             />
//           </div>
//           {selectedStandard && (
//             <>
//               <p className="text-[#3b1886] font-semibold text-xl mt-4">
//                 Pending Data for Class {selectedStandard}
//               </p>
//               <div style={{ height: "100%", width: "100%", overflowY: "auto" }}>
//                 <MaterialReactTable
//                   columns={columns}
//                   data={filteredData}
//                   muiTableHeadCellProps={{
//                     sx: {
//                       borderRight: "1px solid rgba(224,224,224,1)",
//                       color: "#7ABD32",
//                       fontWeight: "700",
//                       fontSize: "16px",
//                       align: "center",
//                     },
//                     align: "center",
//                   }}
//                   muiTableBodyCellProps={{
//                     align: "center",
//                   }}
//                   muiTableFooterCellProps={{
//                     align: "center",
//                   }}
//                 />
//               </div>
//             </>
//           )}
//         </Container>
//       </div>
//     </div>
//   );
// }

// export default MainTable;
