
import React from "react";

const Star = ({ rating }) => {
    let filledPercentage = rating * 20; // Calculate the filled percentage for the stars

    // Function to determine star color based on rating
    const getStarColor = (rating) => {
        if (rating >= 0 && rating < 1) return "red";
        if (rating >= 1 && rating < 2) return "rgb(168,85,247)";
        if (rating >= 2 && rating < 3) return "blue";
        if (rating >= 3 && rating < 4) return "green";
        if (rating >= 4 && rating <= 5) return "yellow";
        return "#ff8c00"; // Default color
    };

    const starColor = getStarColor(rating);

    // Inline styles for the stars
    const starContainerStyle = {
        textAlign: "left",
        fontSize: "20px", // Adjust star size as needed
        lineHeight: "1",
        position: "relative",
        display: "inline-block",
    };

    const filledStarStyle = {
        color: starColor, // Color for filled stars based on rating
        width: `${filledPercentage}%`,
        overflow: "hidden",
        whiteSpace: "nowrap",
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: "1",
    };

    const emptyStarStyle = {
        color: "#eee", // Color for empty stars
        position: "relative",
        zIndex: "0",
    };

    return (
        <div style={starContainerStyle}>
            <div style={filledStarStyle}>★★★★★</div>
            <div style={emptyStarStyle}>★★★★★</div>
        </div>
    );
};

export default Star;

