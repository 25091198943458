import React from 'react'

const PerformanceMessage = () => {
  return (
    <div>
   
                        <div>
                          <div className="flex items-center mb-2">
                            <div className="w-4 h-4 bg-red-500 rounded-full mr-2"></div>
                            <span>Poor performance</span>
                          </div>
                          <div className="flex items-center mb-2">
                            <div className="w-4 h-4 bg-purple-500 rounded-full mr-2"></div>
                            <span>Weak performance</span>
                          </div>
                          <div className="flex items-center mb-2">
                            <div className="w-4 h-4 bg-blue-500 rounded-full mr-2"></div>
                            <span>Average performance</span>
                          </div>
                          <div className="flex items-center mb-2">
                            <div className="w-4 h-4 bg-green-500 rounded-full mr-2"></div>
                            <span>Good performance</span>
                          </div>
                          <div className="flex items-center mb-2">
                            <div className="w-4 h-4 bg-yellow-500 rounded-full mr-2"></div>
                            <span>Excellent performance</span>
                          </div>
                        </div>
    </div>
  )
}

export default PerformanceMessage