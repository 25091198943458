import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSchoolScores } from '../../../Redux/JuniorAction'; 
const NextExamDate = () => {
    // const [nextExamDate, setNextExamDate] = useState("");
    // const GetRequest = () => {
    //     fetch("https://test.xqminds.com/api/quiz/testOption", {
    //       method: "GET",
    //       headers: {
    //         "Content-Type": "application/json",
    //         authorization: `Bearer ${localStorage.getItem("token")}`,
    //       },
    //     })
    //       .then((res) => res.json())
    //       .then((res) => {
    //         console.log(res.nextExamDate,"ressss");
    //         setNextExamDate(res.nextExamDate)
    //         setScore(res);
    //       })
    //       .catch((err) => console.log(err));
    //   };
    //   useEffect(() => {
    //     GetRequest();
    //   }, []);
    const dispatch = useDispatch();  
    const {
        loading: schoolScoresLoading,
        schoolScores,
        error: schoolScoresError,
      } = useSelector((state) => state.schoolScores);
     
    //  console.log(testOption,"testOption")
    useEffect(() => {
      dispatch(fetchSchoolScores());
    }, [dispatch]);
    
    const today = new Date();
      const targetDate = new Date(schoolScores.nextExam); // Replace "2024-04-04" with your target date
      const differenceMs = targetDate - today;
      const daysRemaining = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
      const formattedDate = targetDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
  return (
    <div className="flex flex-col md:flex-row justify-start items-center rounded-lg gap-4 sticky top-0 z-10 bg-white p-2">
    <div className="flex flex-col md:flex-row justify-center items-center gap-2 md:gap-4 md:text-sm">
    <h1 className="text-lg  font-arial md:text-2xl">
        Next Assessment should be taken on  {formattedDate}
      </h1>
     
    </div>
    <div className="flex justify-center items-center rounded-lg bg-lightblue p-2  w-full md:w-auto">
      <span className=" text-lg md:text-xl" style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px",
                backgroundColor: "lightblue",
                padding: "15px",
                height: "50px",
                width: "auto",
              }}>
        {daysRemaining} days left
      </span>
    </div>
  </div>
  
  )
}

export default NextExamDate