import React from "react";
import { Link } from "react-router-dom";
import "./Term.css";

const TermCondition = () => {
  return (
    <div className="grid grid-cols-1 justify-center relative bg-white px-6 py-12 sm:py-24">
      <div className="w-[80%] mx-auto">
        <h2 className="text-center text-3xl font-fredoka font-medium text-gray-900 mb-6">
        INSTRUCTIONS
        </h2>
        <div>
          <p className="font-semibold"></p>
          <ul className="mt-5 list-disc flex flex-col text-xl   gap-5 justify-start text-left font-arial list-inside m-auto w-[100%]">
            <li >
              <strong>Listen Up</strong>: Hook up your device to a speaker or earphones. This way, you won't miss a beat in the audio parts of our quest! 🎧
            </li>
            <li className="mt-2">
              <strong >Watch and Learn</strong>: When you see a video or hear an audio question, pay close attention like a detective on a case! Once the answer choices appear, you won't get a replay, so keep those eyes and ears peeled! 🔍
            </li>
            <li className="mt-2">
              <strong>Make Your Mark</strong>: You'll find <strong>5 or 6 choices</strong> for each question, but only one can be your answer. Make your pick, send it off, and it's set in stone – no second chances! 🗝
            </li>
            <li className="mt-2">
              <strong>No Rush</strong>: You've got all the time in the world to complete this journey.You can take a break or If you can, try to do it all in one go – it's the best way to play! ⏱
            </li>
            <li className="mt-2">
              <strong>Discover Your Path</strong>: After submitting, head back to your login panel and click on reports to see a snapshot of your adventure (your report). 🗺
            </li>
            <li className="mt-2">
              <strong>Seal the Deal</strong>: Don't forget to hit the <strong>submit</strong> button when you're done. If you dash off without submitting, just hit the resume button on your 'My Assessments Page' to jump back in. 🚀
            </li>
           
          </ul>
        </div>
        <div className="flex  justify-center">
          <Link to="/testSession">
            <button className="px-6 py-3 mt-10 bg-indigo-600 text-white font-medium font-fredoka rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              OK
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TermCondition;


// import React from "react";
// import TextGenerateEffect from "../TermCondition/TermDemo";

// const words = `INSTRUCTIONS
// Please read carefully all the instructions given below`

// function TermCondition() {
//   return <TextGenerateEffect words={words} />;
// }

// export default TermCondition;

// import React from "react";
// import TextGenerateEffect from "../TermCondition/TermDemo";

// // Define an array containing each line separately with color and size information
// const lines = [
//   { text: "Oxygen ge high.", color: "red", size: "text-xs" },
//   { text: "In a catastrophic emergency, we're taking giant, panicked breaths.", color: "blue", size: "text-sm" },
//   { text: "Suddenly you become euphoric, docile.", color: "green", size: "text-base" },
//   { text: "You accept your fate. It's all right here.", color: "orange", size: "text-lg" },
//   { text: "Emergency water landing, six hundred miles an hour.", color: "purple", size: "text-xl" },
//   { text: "Blank faces, calm as Hindu cows", color: "cyan", size: "text-2xl" }
// ];

// function TermCondition() {
//   return (
//     <>
//       {/* Map over the lines array and render TextGenerateEffect for each line */}
//       {lines.map((line, index) => (
//         <TextGenerateEffect key={index} words={line.text} className={`${line.color} ${line.size}`} />
//       ))}
//     </>
//   );
// }

// export default TermCondition;
