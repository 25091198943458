import React from 'react'
import Login from '../Login.jsx'
import Test from '../Test'
import Sidebar from '../Sidebar/Sidebar'
import NewStudent from '../NewStudentDashboard/NewStudent'
import Performance from '../Performance/Performance'
import Radars from '../Chart/Radar'
import Speed from '../Chart/Speed'
import Worm from '../Chart/Worm'
import ProgressiveBar from '../Chart/ProgressiveBar'
import Roadmap from "../Roadmap.jsx"
import { Route, Routes } from 'react-router-dom'
import Result from "../Result.jsx"
import Report from "../Report/Report.jsx"
import TermCondition from "../TermCondition/TermCondition.jsx"
import Assignment from "../Assignment.jsx"
import Home from "../Home.jsx"
import Gaurge from "../Chart/Gaurge.js"
import StudentProfile from '../StudentProfile/StudentProfile.jsx'
// import Rough from '../NewStudentDashboard/Rough.jsx'
import Test2 from '../Test2.jsx'
import PrivateRoute from './PrivateRoute.js'
import SchoolDashboard from '../School/SchoolDashboard/SchoolDashboard.jsx'
import TextRevealCardPreview  from '../Break/Break.jsx'
import Recomandation from '../Recomendation/Recomandation.jsx'
import SchoolSideBar from '../School/SchoolSideBar/SchoolSideBar.jsx'
import AddStudent from '../School/AddStudent/AddStudent.jsx'
import AllCharts from '../School/Charts/AllCharts.jsx'
import OverAllStudent from '../School/SchoolDashboard/OverAllStudent/OverAllStudent.jsx'
import MainTable from '../School/SchoolDashboard/Table/MainTable.jsx'
import Topper from '../School/SchoolDashboard/Topper/Topper.jsx'
import ViewStudent from '../School/ViewStudent/ViewStudent.jsx'
import ViewTeacher from '../School/ViewTeacher/ViewTeacher.jsx'
import AddTeacher from '../School/AddTeacher/AddTeacher.jsx'
import AllMpiClass from '../School/SchoolDashboard/AllMpiClass/AllMpiClass.jsx'
import Extra from '../Add/Extra.jsx'
import TestSession from '../TermCondition/TestSession.jsx'
import Test3 from '../Test3.jsx'
import SchoolPerformance from '../School/SchoolPerformance/SchoolPerformance.jsx'
import ActionPlan from '../ActionPlan/ActionPlan.jsx'
import ActionRecomendation from '../ActionPlan/ActionRecomendation.jsx'



const AllRoutes = () => {
  return (
    <div>
      <Routes>
     <Route path='/roadmap' element={<Roadmap/>}>Roadmap</Route>
      <Route path='/' element={<Login/>}>login</Route>
      <Route path='/test2' element={ <PrivateRoute Component={Test2}/>}>Test</Route>
      <Route path='/test3' element={ <PrivateRoute Component={Test3}/>}>Test</Route>
      {/* <Route path='/reult' element={ <PrivateRoute Component={Result}/> }>Result</Route> */}
      <Route path='/term' element={<PrivateRoute Component={TermCondition}/> }>Term</Route>
      <Route path='/testSession' element={<TestSession/>}>test</Route>
      <Route path='/assignment' element={<Assignment/>}>Term</Route>
      <Route path='/test' element={<Test/>}>Term</Route>
      <Route path='/sidebar' element={<Sidebar/>}>sidebar</Route>
      <Route path='/result' element={<NewStudent />}>Student</Route>
      <Route path='/report' element={<Report />}>Report</Route>
      <Route path='/performance' element={ <PrivateRoute Component={Performance}/>}>Performance</Route>
      <Route path='/radar' element={<Radars/>}>Radar</Route>
      <Route path='/worm' element={<Worm />}>Worm</Route>
      <Route path='/speed' element={<Speed />}>Speed</Route> 
      <Route path='/progressive' element={<ProgressiveBar />}>ProgressiveBar</Route>
      <Route path='/gaurge' element={<Gaurge />}>Gaurge</Route>
      <Route path='/studentprofile' element={ <PrivateRoute Component={StudentProfile}/>}>StudentProfile</Route>
      <Route path='/schooldashboard' element={<SchoolDashboard />}>SchoolDashboard</Route>
      <Route path='/break' element={<TextRevealCardPreview />}>BackgroundBoxesDemo</Route>
      {/* <Route path='/actionplan' element={<ActionPlan />}>ActionPlan</Route> */}
      <Route path='/actionRecomendation' element={<ActionRecomendation />}>ActionPlan</Route>
      <Route path='/schoolsidebar' element={<SchoolSideBar />}>SchoolSideBar</Route>
      <Route path='/addstudent' element={<AddStudent />}>Recomandation</Route>
      <Route path='/allcharts' element={<AllCharts />}>AllCharts</Route>
      <Route path='/overall' element={<OverAllStudent />}>OverAll</Route>
      <Route path='/table' element={<MainTable />}>Table</Route>
      <Route path='/topper' element={<Topper />}>Table</Route>
      <Route path='/viewstudent' element={<ViewStudent />}>Table</Route>
      <Route path='/addteacher' element={<AddTeacher />}>Table</Route>

      <Route path='/viewteacher' element={<ViewTeacher />}>Table</Route>
      <Route path='/allmpiclass' element={<AllMpiClass />}>Table</Route>
      <Route path='/extra' element={<Extra />}>Table</Route>
      <Route path='/schoolPerformance' element={<SchoolPerformance />}>Table</Route>





      {/* <Route path='/rough' element={<Rough />}>StudentProfile</Route> */}

      







   
    </Routes>
    </div>
  )
}

export default AllRoutes