import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import roadmap from "../../src/image/Roadmap.jpg";
import student from "../../src/image/student.jpg";
import studentRoadmap from "../../src/image/studentRoadmap.png";
import Toast from './Toast/Toast';
import roadmapbg from "../../src/image/roadmapbg.png";
import testbackground4 from "../../src/image/testbackground4.png";

const Roadmap = () => {
  const [assessmentData, setAssessmentData] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();



  const [questionLength, setQuestionLength] = useState(0);
  const refreshed = localStorage.getItem('testSessionRefreshed');


      useEffect(() => {
        const fetchAnsweredQuestions = async () => {
          try {
            const response = await fetch("https://test.xqminds.com/api/quiz/answers", {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            });
    
            if (!response.ok) {
              throw new Error("Failed to fetch answered questions");
            }
    
            const data = await response.json();
            const length = data.length || 0;  // Assuming the API returns an array of answered questions
            setQuestionLength(length);
          } catch (err) {
            console.error("Error fetching answered questions:", err);
          }
        };
    
        fetchAnsweredQuestions();
      }, []);


  // Function to fetch assessment data
  const GetRequest = () => {
    fetch("https://test.xqminds.com/api/quiz/testOption", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAssessmentData(data);
        localStorage.setItem('checkExamStatus', JSON.stringify(data));
      })
      .catch((err) => {
        console.log(err, "report error");
      });
  };

  useEffect(() => {
    GetRequest();
  }, []);

  // Function to check eligibility for taking the assessment
  const canTakeAssessment = () => {
    if (!assessmentData) return false;
    const currentDate = new Date();
    const latestExamDate = new Date(assessmentData.latestExamDateofStudent);
    const nextAssessmentDate = new Date(assessmentData.nextExamDate);
     return currentDate > latestExamDate && currentDate < nextAssessmentDate && questionLength >= 90;
    // return currentDate > latestExamDate && currentDate < nextAssessmentDate 

    
  };

  let checkexam = JSON.parse(localStorage.getItem("checkExamStatus"));

  const handleRouteNavigate = () => {
    if (checkexam?.totalMarks && questionLength >= 90) {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
      return;
    }
    navigate("/term");
  };

  // Function to check if report button should be enabled

 

  // const canViewReport = () => {
  //   return !!checkexam?.totalMarks;
  // };

  const canViewReport = () => {
    return questionLength >= 90 
  };

  return (
    <div className="w-[100%] h-[100vh] sm:h-[100vh] md:h-[100vh] lg:h-[100vh] xl:h-[100vh] flex flex-col items-center justify-center">
      <img src={testbackground4} alt="Background" className="w-full h-[100vh] sm:h-[100vh] md:h-[100vh] lg:h-[100vh] xl:h-[100vh]" />
      
      <div className="absolute flex min-h-full w-[100%] justify-center items-center">
        <div className="w-[70%] flex flex-col">
          <div className="flex md:flex md:flex-row md:justify-end sm:flex flex-col-reverse items-center w-[100%] gap-14 md:gap">
            <div className="flex flex-col sm:text-center sm:flex sm:justify-center md:gap-10 gap-4">
              <p className="font-semibold text-blue-600 font-fredoka lg:text-6xl sm:text-4xl md:text-5xl text-4xl">Welcome to PRIDE Assessment.</p>
              <p className="font-semibold text-center md:text-4xl text-3xl font-arial text-black">“ Discover your mind’s potential”</p>
            </div>
            <img src={studentRoadmap} alt="" className="rounded-full md:w-[45%] lg:w-[40%] w-[30%] sm:w-[40%]" />
          </div>
          <div className="w-[100%] mt-5 flex flex-col font-fredoka gap-5 justify-center items-center">
            <button
              onClick={handleRouteNavigate}
              className={`bg-green-500 w-[350px] text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 ${canTakeAssessment() ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              <Link
                to={canTakeAssessment() ? "#" : "/term"}
                onClick={(e) => !canTakeAssessment() && e.preventDefault()}
              >
                Take Assessment
              </Link>
            </button>
            <button
              disabled={!canViewReport()}
              className={`bg-blue-500 w-[350px] text-white px-4 py-2 font-fredoka rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${!canViewReport() ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              <Link
                to={canViewReport() ? "/result" : "#"}
                onClick={(e) => !canViewReport() && e.preventDefault()}
              >
                Click here for report
              </Link>
            </button>
          </div>
        </div>
      </div>
      {showToast && (
        <Toast
          message="You have already taken the assessment. Please wait for your next assessment schedule."
          onClose={() => setShowToast(false)}
        />
      )}
    </div>
  );
};

export default Roadmap;
