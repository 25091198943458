import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';

const DonutChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const options = {
      series: [44, 55, 41, 17, 15],
      chart: {
        width: 380,
        type: 'donut',
        toolbar: {
          show: false, // Disable toolbar
        },
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'gradient',
      },
      legend: {
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex];
        },
      },
      title: {
        text: 'Gradient Donut with custom Start-angle',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      }],
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    // Cleanup function to destroy the chart when component unmounts
    return () => {
      chart.destroy();
    };
  }, []);

  return <div id="chart" ref={chartRef}></div>;
};

export default DonutChart;
