// import React from 'react'
// import { Link } from 'react-router-dom'
// import bg from "../../image/roadmapbg.png"
// import testbackground4 from "../../image/testbackground4.png";

// const TestSession = () => {
//   return (
//     <div className="w-[100%] h-[100vh]  sm:h-[100vh] md:h-[100vh] lg:h-[100vh] xl:h-[100vh] flex flex-col items-center justify-center ">
//       <img src={testbackground4} alt="Background" className=" w-full h-[100vh]  sm:h-[100vh] md:h-[100vh] lg:h-[100vh] xl:h-[100vh]" />

//       <div className="absolute flex  min-h-full gap-5 w-[100%] justify-center items-center">

//     <div className='flex  flex-col gap-5'>

//     <div className='flex flex-col gap-5'>
//         <p className='text-3xl font-bold'>Take a PRIDE Assessment</p>
//         <p className='text-xl font-semibold'>Your PRIDE report will be generated only after completing Session 1 and Session 2.</p>
//         </div>

//         <div className=" mpi flex justify-start w-[70%] m-auto border border-yellow-500 p-4">
//   <div>
//     <p className="font-bold text-lg text-left">Session I</p>
//     <p>
//       Assesses your Knowledge, Applicative Ability, and Situational Intelligence over 5 important skills - Attention, Critical Thinking, Mindset, Expressions, Collaboration.
//     </p>
//     <p className="text-right">
//     <Link to="/test2">
//       <button className="px-6  py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
//         Test 1
//       </button>
//     </Link>
//   </p>
//   </div>
// </div>

// <div className="mpi flex justify-start w-[70%] m-auto border border-yellow-500 p-4">
//   <div>
//     <p className="font-bold text-lg text-left">Session II</p>
//     <p>
//     Assesses your Knowledge, Applicative Ability and Situational Intelligence over 5 important skills - Memmory , Creative Thinking, Attitude, Communication,Leadership
//     </p>
//    <p className="text-right">
//    <Link to="/test3">
//       <button className="px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mt-4">
//         Test 2
//       </button>
//     </Link>
//    </p>
//   </div>
// </div>

//           </div>
//         </div>
//     </div>
//   )
// }

// export default TestSession

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import bg from "../../image/roadmapbg.png";
import testbackground4 from "../../image/testbackground4.png";

const TestSession = () => {
  const [questionLength, setQuestionLength] = useState(0);
  const refreshed = localStorage.getItem('testSessionRefreshed');
  const navigate = useNavigate();

  window.onload = function() {
    disableBackButton();
};

function disableBackButton() {
    // Add a new history entry so the user can't go back to the previous page
    window.history.pushState(null, null, window.location.href);

    window.onpopstate = function() {
        // When the user tries to go back, push the same state again
        window.history.pushState(null, null, window.location.href);
    };
}


  useEffect(() => {
    // Fetch the length of answered questions from the API
    if (refreshed === 'false') {
      localStorage.setItem('testSessionRefreshed', 'true');
      window.location.reload();
    }else{

    
    fetch("https://test.xqminds.com/api/quiz/answers", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // Assuming the API returns the length of answered questions
        const length = data.length || 0;
        setQuestionLength(length);
      })
      .catch((err) => {
        console.error("Error fetching answered questions:", err);
      });
  }
}, []);

  const isTest1Completed = questionLength >= 45;
  const isTest2Completed = questionLength >= 90;
  const isTest1ButtonEnabled = questionLength < 45;
  const isTest2ButtonEnabled = questionLength >= 45 && questionLength < 90;


  useEffect(() => {
    if (!isTest1ButtonEnabled || !isTest2ButtonEnabled) {
      disableBackButton();
    }
  }, [isTest1ButtonEnabled, isTest2ButtonEnabled]);

  return (
    <div className="w-[100%] h-[100vh] sm:h-[100vh] md:h-[100vh] lg:h-[100vh] xl:h-[100vh] flex flex-col items-center justify-center">
      <img
        src={testbackground4}
        alt="Background"
        className="w-full h-[100vh] sm:h-[100vh] md:h-[100vh] lg:h-[100vh] xl:h-[100vh]"
      />

      <div className="absolute flex min-h-full gap-5 w-[100%] justify-center items-center">
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-5">
            <p className="text-3xl font-fredoka">Take the PRIDE Assessment</p>
            <p className="text-xl font-fredoka">
              Your PRIDE report will be generated only after completing Session
              1 and Session 2.
            </p>
          </div>

          <div className="mpi flex justify-center w-[70%] m-auto border border-yellow-500 p-4">
            <div className=" w-full">
              <p className="font-fredoka text-2xl text-center">Session 1</p>
              {/* <p>
                Assesses your Knowledge, Applicative Ability, and Situational
                Intelligence over 5 important skills - Attention, Critical
                Thinking, Mindset, Expressions, Collaboration.
              </p> */}
              <p className="text-right">
                <Link to={isTest1ButtonEnabled ? "/test2" : "#"}>
                  <button
                    className={`px-6 py-3 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
                      isTest1ButtonEnabled
                        ? "bg-blue-600 hover:bg-blue-700"
                        : "bg-gray-400 cursor-not-allowed"
                    }`}
                    disabled={!isTest1ButtonEnabled}>
                    {isTest1Completed ? "Completed" : "Click here"}
                  </button>
                </Link>
              </p>
            </div>
          </div>

          <div className="mpi flex justify-center w-[70%] m-auto border border-yellow-500 p-4">
            <div className="w-full">
              <p className="font-fredoka text-2xl text-center">Session 2</p>
              {/* <p>
                Assesses your Knowledge, Applicative Ability and Situational
                Intelligence over 5 important skills - Memory, Creative
                Thinking, Attitude, Communication, Leadership.
              </p> */}
              <p className="text-right">
                <Link to={isTest2ButtonEnabled ? "/test3" : "#"}>
                  <button
                    className={`px-6 py-3 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mt-4 ${
                      isTest2ButtonEnabled
                        ? "bg-blue-600 "
                        : "bg-gray-400 cursor-not-allowed"
                    }`}
                    disabled={!isTest2ButtonEnabled}>
                    {isTest2Completed ? "Completed" : "Click here"}
                  </button>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestSession;
