

import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const RADIAN = Math.PI / 180;
const data = [
  { name: '0-30s', value: 30, color: '#00ff00' },
  { name: '31-60s', value: 30, color: '#ff8c00' },
  { name: '61-120s', value: 60, color: '#ff0000' },
];
const cx = 150;
const cy = 200;
const iR = 80;
const oR = 125;

const needle = (score, data, cx, cy, iR, oR, color) => {
  
  let total = 120;
  const ang = 180.0 * (1 - score?.mentalSpeedInSec / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx;
  const y0 = cy;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle key="needle-base" cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path key="needle-path" d={`M${xba} ${yba} L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="none" fill={color} />,
  ];
};

const Speed = () => {
  const [value, setValue] = useState(0);
 const [score,setScore] = useState({})
  useEffect(() => {
    // Fetching data or performing any necessary initialization
    // For demonstration, setting a static value
    setValue(100);
  }, []);


  const GetRequest = () => {
    fetch("https://test.xqminds.com/api/quiz/testOption", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())

      .then((res) => {
        console.log(res);
        setScore(res);
      })

      .catch((err) => console.log(err));
  };
  useEffect(() => {
    GetRequest();
  }, []);

  return (
    <PieChart width={300} height={250}>
      <Pie
        dataKey="value"
        startAngle={180}
        endAngle={0}
        data={data}
        cx={cx}
        cy={cy}
        innerRadius={iR}
        outerRadius={oR}
        fill="#8884d8"
        stroke="none"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      {needle(score, data, cx, cy, iR, oR, '#d0d000')}
    </PieChart>
  );
};

export default Speed;
