import React from "react";
import { Circle } from "rc-progress";
// import "../Circular/CircularProgressBar.css";

const AccuracyChart = ({ percentage }) => {

  const getStrokeColor = (percentage) => {
    if (percentage >= 0 && percentage <= 20) {
      return "red";
    } else if (percentage >= 21 && percentage <= 40) {
      return "rgb(168,85,247)";
    } else if (percentage >= 41 && percentage <= 60) {
      return "#3498db";
    } else if (percentage >= 61 && percentage <= 80) {
      return "lightgreen";
    } else if (percentage >= 81 && percentage <= 100) {
      return "yellow";
    }
    return "lightgray"; // Default color if percentage is out of bounds
  };
  
    return (
        <div className="relative w-28 h-24">
          <Circle
            percent={percentage*1}
            strokeWidth="9"
            trailWidth="9"
            // strokeColor="#ed3d3d"
            strokeColor={getStrokeColor(percentage)} 
            trailColor="#EEEEEE"
           
            // strokeColor={{
            //   '0%': 'lightblue',
            //   '100%': 'blue',
            // }}
            className="absolute top-0 left-0 w-full h-full"
          />
          <div className="absolute inset-0 flex items-center font-semibold justify-center text-lg">{percentage}</div>
        </div>
      );
    }

export default AccuracyChart