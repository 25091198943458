import React, { useState, useEffect } from 'react';
import LineChart1 from "../../Circular/LineChart1";


const Polarity = () => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
      fetchDataFromBackend();
    }, []);
  
    const fetchDataFromBackend = async () => {
      try {
        const response = await fetch('https://test.xqminds.com/api/quiz/report-generate', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        // console.log(data, "dataaaa");
        


        setChartData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };



    const IntelligenceData = [
        {
          description: "Following the appropriate process to complete the work",
          score: chartData?.prideScore?.applicationScore || 0,
          chart: <LineChart1 percentage={chartData?.prideScore?.applicationScore || 0} />,
        },
        {
          description:
            "Altering the plans and action basis the situational needs/demands",
          score: chartData?.prideScore?.advantageScore || 0,
          chart: <LineChart1 percentage={chartData?.prideScore?.advantageScore || 0} />,
        },
        {
          description:
            "Understanding the underlying concept/reason of the incident",
          score: chartData?.prideScore?.awarenessScore || 0,
          chart: <LineChart1 percentage={chartData?.prideScore?.awarenessScore || 0} />,
        },
      ];
    
      // Step 2: Sort the data array by the `score` value in ascending order
      IntelligenceData.sort((a, b) => b.score - a.score);
    
      IntelligenceData.forEach((item, index) => {
        item.staticValue = index + 1;
      });

      const prideScores = [
        {
          name: "Knowing details precisely",
          score: chartData?.prideScore?.perceiveScore || 0,
          chartComponent: <LineChart1 percentage={chartData?.prideScore?.perceiveScore || 0} />,
        },
        {
          name: "Solving Problems effectively",
          score: chartData?.prideScore?.resolveScore || 0,
          chartComponent: <LineChart1 percentage={chartData?.prideScore?.resolveScore || 0} />,
        },
        {
          name: "Explaining intentions Clearly",
          score: chartData?.prideScore?.deliverScore || 0,
          chartComponent: <LineChart1 percentage={chartData?.prideScore?.deliverScore || 0} />,
        },
        {
          name: "Making wise decisions",
          score: chartData?.prideScore?.influenceScore || 0,
          chartComponent: <LineChart1 percentage={chartData?.prideScore?.influenceScore || 0} />,
        },
        {
          name: "Building strong relationships",
          score: chartData?.prideScore?.engageScore || 0,
          chartComponent: <LineChart1 percentage={chartData?.prideScore?.engageScore || 0} />,
        },
      ];
    
      prideScores.sort((a, b) => b.score - a.score);
      prideScores.forEach((item, index) => {
        item.staticValue = index + 1;
      });



      const sortedSkillScores = [
        {
          label: "Conveying intentions with appropriate linguistics",
          value: chartData?.prideScore?.communicationScore || 0,
          chartComponent: <LineChart1 percentage={chartData?.prideScore?.communicationScore || 0} />,
          
        },
       {
          label: "Remembering the important info for future use",
          value: chartData?.prideScore?.memoryScore || 0,
           chartComponent: <LineChart1 percentage={chartData?.prideScore?.memoryScore || 0} />,
        },
       {
          label: "Thinking out of the box or planning innovatively",
          value: chartData?.prideScore?.creativeScore || 0,
           chartComponent: <LineChart1 percentage={chartData?.prideScore?.creativeScore || 0} />,
        },
       {
          label: "Focusing on every instruction / detail carefully",
          value: chartData?.prideScore?.attentionScore || 0,
           chartComponent: <LineChart1 percentage={chartData?.prideScore?.attentionScore || 0} />,
        },
       {
          label: "Delegating and guiding others respectfully Leadership",
          value: chartData?.prideScore?.leadershipScore || 0,
           chartComponent: <LineChart1 percentage={chartData?.prideScore?.leadershipScore || 0} />,
        },
        {
          label: "Using logical brain to come to a conclusion",
          value: chartData?.prideScore?.criticalScore || 0,
           chartComponent: <LineChart1 percentage={chartData?.prideScore?.criticalScore || 0} />,
        },
         {
          label: "Using assertive body language to convey emotions",
          value: chartData?.prideScore?.expressionScore || 0,
           chartComponent: <LineChart1 percentage={chartData?.prideScore?.expressionScore || 0} />,
        },
        {
          label: "Approaching life with moral set of values & ethics",
          value: chartData?.prideScore?.attitudeScore || 0,
           chartComponent: <LineChart1 percentage={chartData?.prideScore?.attitudeScore || 0} />,
        },
       {
          label: "Adapting well within a group for team synergy",
          value: chartData?.prideScore?.collaborationScore || 0,
           chartComponent: <LineChart1 percentage={chartData?.prideScore?.collaborationScore || 0} />,
        },
     {
          label: "Internalizing the life learnings with right frame of mind",
          value: chartData?.prideScore?.mindsetScore || 0,
           chartComponent: <LineChart1 percentage={chartData?.prideScore?.mindsetScore || 0} />,
        },
    ];
      
    
      
      sortedSkillScores.sort((a, b) => b.value - a.value);
      sortedSkillScores.forEach((item, index) => {
        item.staticValue = index + 1;
      });

     


  return (
    <div>

<div className="w-[90%]  m-auto overflow-hidden rounded-lg shadow-lg">
          <table className="border-collapse w-full">
            <thead>
              <tr className="bg-blue-500 text-white rounded-t-lg">
                <th className="py-3 px-6 text-left">
                 PRIDE Skill Activities
                </th>
                <th className="py-3 px-6 text-left flex justify-center items-center">
                Polarity (Frequently Performed Better)

                </th>
                <th className="py-3 px-6 text-left">Rank</th>
              </tr>
            </thead>
         <tbody>
  {sortedSkillScores.map((item, index)  => (
    <tr key={index} className="border-b border-gray-200">
      <td className="py-3 px-6">{item.label}</td>
      <td className="py-3 px-6">
       {item.chartComponent} 
      </td>
      {/* <td className="py-3 px-6">{value}</td> */}
      <td className="py-3 px-6">{item.staticValue}</td>
    </tr>
  ))}
  
</tbody>
          </table>
        </div>

        <div className="w-[90%] m-auto mt-20 overflow-hidden rounded-lg shadow-lg">
          <table className="border-collapse w-full">
            <thead>
              <tr className="bg-blue-500 text-white rounded-t-lg">
                <th className="py-3 w-[29%] px-6 text-left">
                PRIDE Process Activities
                </th>
                <th className="py-3 px-6 text-left flex justify-center items-center">
                Polarity (Frequently Performed Better)
                </th>
                <th className="py-3 px-6 text-left">Rank</th>
              </tr>
            </thead>
            <tbody>
      {prideScores.map((item, index) => (
        <tr key={index} className="border-b border-gray-200">
          <td className="py-3 px-6">{item.name}</td>
          <td className="py-3 px-6">{item.chartComponent}</td>
          {/* <td className="py-3 px-6">{item.score}</td> */}
          <td className="py-3 px-6">{item.staticValue}</td>
        </tr>
      ))}
    </tbody>
          </table>
        </div>

        <div className="w-[90%] m-auto mt-10 overflow-hidden rounded-lg shadow-lg">
          <table className="border-collapse w-full">
            <thead>
              <tr className="bg-blue-500 text-white rounded-t-lg">
                <th className="py-3 px-6 text-left">
                PRIDE Intelligence Activities
                </th>
                <th className="py-3 px-6 text-left flex justify-center items-center">
                Polarity (Frequently Performed Better)
                </th>
                <th className="py-3 px-6 text-left">Rank</th>
              </tr>
            </thead>
            <tbody>
      {IntelligenceData.map((row, index) => (
        <tr key={index} className="border-b border-gray-200">
          <td className="py-3 px-6">{row.description}</td>
          <td className="py-3 px-6">{row.chart}</td>
          <td className="py-3 px-6">{row.staticValue}</td>
        </tr>
    ))}
      </tbody>
            
          </table>
        </div>
    </div>
  )
}

export default Polarity