import React, { useEffect, useState } from "react";

const SchoolPerformanceNav = ({ data, rout, setRout, handleTitleClick }) => {
  const [activePageId, setActivePageId] = useState("");
 
  
  const handleClick = (id) => {
    setActivePageId(id);
    setRout(id);
  };

  return (
    <div className="w-[100%] rounded-lg ">
     <nav className="bg-gray-50 dark:bg-blue-600">
     <div className="px-4 py-3 mx-auto">
        <div className="flex flex-wrap justify-center md:justify-start">
          <ul className="grid grid-cols-2 md:grid-cols-5 gap-4 items-center justify-center w-full font-medium mt-0 text-sm">
            {data.map((el) => (
              <li key={el.id} className="w-full">
                <div
                  onClick={() => handleClick(el.id)}
                  className={`text-gray-900 mpi p-2 rounded-lg shadow-xl  font-serif  dark:text-white hover:font-bold transition-all duration-200 ease-in-out ${
                    el.id === activePageId ? "bg-yellow-400 rounded-lg " : ""
                  } text-center`}
                >
                  <p className="text-base font-fredoka w-full ">
                    {el.title}
                  </p>
                  <p className="text-base w-full font-fredoka  " >
                    {el.sub}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
</nav>

    </div>
  );
};

export default SchoolPerformanceNav;

















// import React, { useEffect, useState } from "react";
// import RadialBarChart from "../SchoolCharts/RadialBarChart";

// const SchoolPerformanceNav = ({ data, rout, setRout, handleTitleClick }) => {
//   const [activePageId, setActivePageId] = useState("");
//   const [selectedStandard, setSelectedStandard] = useState('5A');
//   const [standardsInSchool, setStandardsInSchool] = useState([]);
//   const [categoryMapping, setCategoryMapping] = useState({});
//   const [caraosalsScore, setCaraosalsScore] = useState(null);
//   const [chartData, setChartData] = useState({ series: [], labels: [] });

//   useEffect(() => {
//     const token = localStorage.getItem("token");

//     fetch(`https://test.xqminds.com/api/schoolData/section-wise-result?standard=6K&level=junior`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         "authorization": `Bearer ${token}`
//       }
//     })
//       .then((res) => res.json())
//       .then((res) => {
//         const fetchedStandards = res.standardsInSchool;
//         const mapping = {};
//         fetchedStandards.forEach(standard => {
//           if (standard.startsWith('5') || standard.startsWith('6')) {
//             mapping[standard] = 'junior';
//           } else if (standard.startsWith('7') || standard.startsWith('8')) {
//             mapping[standard] = 'senior';
//           } else if (standard.startsWith('9') || standard.startsWith('10') || standard.startsWith('11') || standard.startsWith('12')) {
//             mapping[standard] = 'youth';
//           }
//         });
//         setStandardsInSchool(fetchedStandards);
//         setCategoryMapping(mapping);

//         // Fetch data for the default selected standard
//         fetchDataForStandard('5A', mapping['5A']);
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//       });
//   }, []);

//   const fetchDataForStandard = (standard, category) => {
//     const apiUrl = `https://test.xqminds.com/api/schoolData/section-wise-result?standard=${standard}&level=${category}`;

//     fetch(apiUrl, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         "authorization": `Bearer ${localStorage.getItem("token")}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((res) => {
//         console.log('API Response:', res);
//         if (res.result) {
//           setChartData({
//             series: [
//               res.result.averageTotalAdvantageIndexValue * 10 || 0,
//               res.result.averageTotalApplicationIndexValue * 10 || 0,
//               res.result.averageTotalAwarenessIndexValue * 10 || 0,
//             ],
//             labels: ['Advantage', 'Application', 'Awareness'],
//           });
//         } else {
//           setChartData({ series: [], labels: [] });
//         }

//         setCaraosalsScore(res.contentObject);
//       })
//       .catch((error) => {
//         console.error(`Error fetching data for standard ${standard}`, error);
//       });
//   };

//   const handleChange = (event) => {
//     const selected = event.target.value;
//     setSelectedStandard(selected);
//     setCaraosalsScore(null); // Reset caraosalsScore to ensure it fetches new data

//     if (categoryMapping[selected]) {
//       fetchDataForStandard(selected, categoryMapping[selected]);
//     }
//   };

//   const handleClick = (id) => {
//     setActivePageId(id);
//     setRout(id);
//   };

//   return (
//     <div className="w-[100%] rounded-lg">
//       <nav className="bg-gray-50 dark:bg-blue-600">
//         <div className="px-4 py-3 mx-auto">
//           <div className="flex flex-wrap justify-center md:justify-start">
//             <ul className="grid grid-cols-2 md:grid-cols-5 gap-4 items-center justify-center w-full font-medium mt-0 text-sm">
//               {data.map((el) => (
//                 <li key={el.id} className="w-full">
//                   <div
//                     onClick={() => handleClick(el.id)}
//                     className={`text-gray-900 mpi p-2 rounded-lg shadow-xl font-serif dark:text-white hover:font-bold transition-all duration-200 ease-in-out ${
//                       el.id === activePageId ? "bg-yellow-400 rounded-lg font-bold" : ""
//                     } text-center`}
//                   >
//                     <p className="text-base w-full ">
//                       {el.title}
//                     </p>
//                     <p className="text-base w-full " style={{ fontStyle: "italic" }}>
//                       {el.sub}
//                     </p>
//                   </div>
//                 </li>
//               ))}
//               <form className="max-w-lg w-[30%] m-auto">
//                 <div className="flex items-center">
//                   <label htmlFor="standards" className="text-gray-900 text-lg font-medium mr-4">Classes:</label>
//                   <select
//                     id="standards"
//                     value={selectedStandard}
//                     onChange={handleChange}
//                     className="flex-1 px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
//                   >
//                     {standardsInSchool.map((standard) => (
//                       <option key={standard} value={standard}>
//                         {standard}
//                       </option>
//                     ))}
//                   </select>
//                 </div>

//                 {caraosalsScore && (
//                   <div className="mt-8">
//                     <h2 className="text-xl font-semibold text-gray-800 mb-4">Data for {selectedStandard}</h2>
//                     <pre className="bg-gray-100 p-6 rounded-lg border border-gray-200 overflow-x-auto">{JSON.stringify(caraosalsScore, null, 2)}</pre>
//                   </div>
//                 )}
//               </form>
//             </ul>
//           </div>
//         </div>
//       </nav>
//       <RadialBarChart chartData={chartData} />
//     </div>
//   );
// };

// export default SchoolPerformanceNav; 
